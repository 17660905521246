import React from "react";
//Custom Components
import BubbleView from "../../../../../../ressources/components/bubbleView/bubbleView";
export default function Screen(props) {
    const viewHtml = props.store.viewContent;
    const viewTitle = props.store.viewTitle;

    //Rendering part
    function renderContent() {
        switch (true) {
            case !viewHtml:
                return <h5>AUCUNE DONNEE RECUE ...</h5>;
            default:
                return <div dangerouslySetInnerHTML={{ __html: viewHtml }}></div>;
        }
    }
    return (
        <>
            {props.setStyle("./base")}
            <BubbleView logo={true} title={viewTitle} backBtn={false} onHomeClick={props.returnHome}>
                <div>{renderContent()}</div>
            </BubbleView>
        </>
    );
}
