import React from "react";
import "./arianWire.css";
function ArianWire(props) {
    function handlePathClick(hash) {
        if (hash) {
            console.log(hash);
            console.log(props.viewHash);
            if (hash === props.viewHash) {
                props.redirectTo("ghost", "", true); //To reset the subview navigation
            }
            setTimeout(function () {
                props.redirectTo(hash);
            }, 0);
        }
    }

    let userJourney = props.userJourney || [];
    let viewIconCls,
        viewTxt,
        arianItem,
        arianContent = [];
    userJourney.map(function (userPath, index) {
        viewIconCls = props.i18n("icon.view." + userPath + "_icon");
        viewTxt = props.i18n("label.view." + userPath);
        arianItem = (
            <li
                className={"arianItem " + (userPath ? "clickable" : "")}
                onClick={() => handlePathClick(userPath)}
                key={index}
                tabIndex={userPath ? 0 : -1}
            >
                {viewIconCls ? <span className={"iconArianItem " + viewIconCls}></span> : ""}
                <span className="txtArianItem">{viewTxt}</span>
            </li>
        );
        arianContent.push(arianItem);
        if (index + 1 !== userJourney.length) {
            arianContent.push(<span className="fal fa-long-arrow-right arianItemSeparator" key={"sep-" + index}></span>);
        }
        viewIconCls = "";
        viewTxt = "";
        return null;
    });
    return <>{arianContent.length > 0 ? <ul className="arianContainer">{arianContent}</ul> : ""}</>;
}

export default ArianWire;
