import React from "react";
import ImgBackground from "../imgBackground/imgBackground";
import "./portalTitle.css";
export default function PortalTitle(props) {
    /**
     * @param props {Object} [{}]: {
     *      @param {String} title [''] : Le contenu du titre à afficher
     *
     *      @param {String} subtitle [''] : Un sous-titre facultatif
     *
     *      @param {Boolean} logo [false] : Pour décider si on affiche le logo de la comany au dessus du titre
     *
     *      @param {String} className [''] : Une classe supplémentaire à ajouter au conteneur du composant
     *
     *  }
     */
    const title = props.title || "";
    const subtitle = props.subtitle || "";
    const logo = !!props.logo;
    return (
        <div className={`portalTitleContainer${!!props.className ? " " + props.className : ""}`}>
            {logo && <ImgBackground className="mainLogo companyLogo" />}
            {props.children === "Mon espace personnel qu'est-ce que c'est ?" || title === "Mon espace personnel qu'est-ce que c'est ?" ? 
            <h2 className="mainTitle">{props.children || title}</h2> : <h1 className="mainTitle">{props.children || title}</h1>
            }
            {subtitle && <p className="subtitle">{subtitle}</p>}
        </div>
    );
}
