//---------- Logical Components and modules ----------//
import React from "react";
import { useState, useEffect } from "react";
//Custom library
import { isArray, isObject } from "../../../../ressources/lib/js/globalFunctions.js";
//---------- UI Components ----------//
//Custom UI Components
import DesktopScreen from "./screens/base/desktop/screen.js";
import PhoneScreen from "./screens/base/phone/screen.js";
//-----------------------------------//
export default function Controller(props) {
    //Local Consts
    const screenWidth = props.screenWidth;
    let breakPoint = 980; //px
    const appTheme = props.appTheme;
    const store = props.store;
    const updateStore = props.updateStore;
    const cloneStore = props.cloneStore;
    const filterFieldsComboData = [
        {
            label: props.i18n("label.field.product_name") + "/" + props.i18n("label.field.supplier_name"),
            value: ["product_name", "supplier_name"],
        },
        { label: props.i18n("label.field.invoice_issue_date"), value: ["invoice_issue_date", "invoice_issue_date_display"] },
        { label: props.i18n("label.field.payment_due_date"), value: ["payment_due_date", "payment_due_date_display"] },
        { label: props.i18n("label.field.quantity"), value: ["quantity", "quantity_display"] },
        { label: props.i18n("label.field.total_due"), value: ["total_due", "total_due_display"] },
    ];
    //Local States
    const [storeUpdatedFromController, setStoreUpdatedFromController] = useState(false);
    const [filterFields, setFilterFields] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [filteredStore, setFilteredStore] = useState(cloneStore());
    //Helper functions
    function formatMonth(value) {
        if (typeof value?.toLocaleDateString !== "function") value = new Date(value);
        let formatted = value.toLocaleDateString("fr-FR", { year: "numeric", month: "long" });
        return formatted.charAt(0).toUpperCase() + formatted.slice(1);
    }
    function formatPrice(value) {
        return new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(value);
    }
    function quantityDisplayValue(rowData) {
        return `${parseFloat(rowData.quantity)} ${rowData.product_unit}`;
    }
    function patchStore(store) {
        if (typeof store?.payments?.data?.forEach == "function") {
            const suffix = "_display";
            const dateFieldList = ["invoice_issue_date", "payment_due_date"];
            const floatFieldList = ["quantity", "total_due"];
            store?.payments?.data?.forEach(function (invoiceLine) {
                dateFieldList.forEach(function (dateKey) {
                    invoiceLine[dateKey + suffix] = formatMonth(invoiceLine[dateKey]);
                });
                invoiceLine["quantity" + suffix] = quantityDisplayValue(invoiceLine);
                invoiceLine["total_due" + suffix] = formatPrice(invoiceLine?.total_due);
            });
        }
        if (store?.header) {
            store.header.total_paid_display = formatPrice(store.header.total_paid);
        }
        return store;
    }
    function filterData(data, filterFields, filterValue) {
        //console.log("filterFields : ", filterFields);
        //console.log("filterValue : ", filterValue);
        let filteredData = [];
        function valueContainsFilterValue(value, filterValue) {
            let contains = false;
            if (!filterValue) return true;
            if (typeof value === "number") value = value.toString();
            value = value.trim().toLowerCase();
            filterValue = filterValue.trim().toLowerCase();
            contains = value.search(filterValue) >= 0;
            return contains;
        }
        if (isArray(data) && filterFields.length > 0 && !!filterValue) {
            data.forEach(function (item) {
                let keepItem = false;
                item = isObject(item) ? item : {};
                Object.keys(item).forEach(function (itemField) {
                    let itemFieldIsIntoFilter = !!filterFields.filter(function (filterValue) {
                        return filterValue === itemField;
                    })[0];
                    if (itemFieldIsIntoFilter && !keepItem) {
                        let itemFieldValue = item[itemField];
                        keepItem = valueContainsFilterValue(itemFieldValue, filterValue);
                    }
                });
                if (keepItem) filteredData.push(item);
            });
        } else {
            filteredData = data;
        }
        return filteredData;
    }
    //Effects treatments
    useEffect(
        function () {
            if (!storeUpdatedFromController) {
                //To prevent inifinie loop when calling updateStore
                //We update the store with data patched when store is updated
                updateStore(patchStore(cloneStore()));
                setStoreUpdatedFromController(true);
            } else {
                setStoreUpdatedFromController(false);
            }
        },
        [store]
    );
    useEffect(
        function () {
            //We apply filter on store each time store, filterValue or filterFields are updated
            const clonedStore = cloneStore();
            clonedStore.payments.data = filterData(clonedStore.payments.data, filterFields, filterValue);
            setFilteredStore(clonedStore);
        },
        [store, filterFields, filterValue]
    );
    //Communication functions
    function exportAsaproExcel(event) {
        return props
            .request({
                type: "ajaxGetFile",
                action: "export_xls_invoice",
            })
            .then(function (res) {
                return Promise.resolve(res);
            })
            .catch(function (error) {
                console.error(error);
                console.error(error.msg);
                return Promise.reject(error);
            });
    }
    //Pre-rendering treatments
    //console.log(store);
    console.log(filteredStore);
    const controllerProps = {
        exportAsaproExcel: exportAsaproExcel,
        store: filteredStore,
        setFilterFields: setFilterFields,
        setFilterValue: setFilterValue,
        filterFieldsComboData: filterFieldsComboData,
    };
    switch (appTheme) {
        default:
            if (screenWidth < breakPoint) return <PhoneScreen {...props} {...controllerProps} />;
            return <DesktopScreen {...props} {...controllerProps} />;
    }
}
