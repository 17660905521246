import React from "react";
import { useState, useEffect, useRef } from "react";
//External Library
import ReactToPdf from "react-to-pdf";
/*
On utilise 'react-to-print' pour transférer certains éléments de la page dans une vue d'impression gérée par le navigateur via "window.print()"
    # !!! Window.print n'est pas géré par certaines webViews mobiles (firefox android) mais par contre est supporté par IE5...
    # Et en plus c'est galère à mettre en forme...

On peut utiliser 'react-to-pdf' pour générer directement un PDF à télécharger en insérant des éléments de la page via les modules "jsPDF" et "html2canvas"
    # !!! Peut-être plus stable sur les webViews mobiles mais nécessité d'implémenter des polyfills pour IE et pas de prévisualisation ni d'option pour imprimer directement

Si l'on veut générer un PDF totalement différent de la page web avec les données fournies, utiliser 'react-pdf/renderer' ou 'pdfmake' (choix à définir)
*/
//External UI Components
import { DataGrid, Column, SearchPanel, FilterRow, Paging, Pager, Export } from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
//Custom UI Components
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import FilterToolbar from "../components/filterToolbar.js";
export default function Screen(props) {
    //Init constants
    const printPdfRef = useRef();
    const viewData = props.store;
    const exportAsaproExcel = props.exportAsaproExcel;
    //Pre-rendering treatments
    function headerCellRender(headerTitle, headerClassName) {
        return (
            <div className={`column-header-content${!!headerClassName ? " " + headerClassName : ""}`}>
                <p>{headerTitle}</p>
                <span className="fas fa-sort"></span>
            </div>
        );
    }
    function exportGrid(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        exportDataGrid({
            worksheet: worksheet,
            component: e.component,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }
    let toPdfFct;
    function buildOptionsBtn() {
        return [
            /*{
                //Frontend PDF exporter
                icon: "far fa-file-pdf",
                onClick: function () {
                    toPdfFct();
                },
            },*/
            {
                //ASAPRO EXCEL exporter
                icon: "xlsxfile",
                onClick: exportAsaproExcel,
                text: "Export Excel",
            },
        ];
    }
    function renderContent() {
        return (
            <DataGrid
                id="dataGrid"
                dataSource={viewData.payments.data}
                keyExpr="id"
                onExporting={exportGrid}
                allowColumnResizing={false}
                columnResizingMode="widget"
                columnAutoWidth={true}
                customizeColumns={function (columns) {
                    columns[0].sortIndex = 1;
                }}
                /*onToolbarPreparing={function (e) {
            //Deprecated mais au moins ça marche...
            const optionsBtn = buildOptionsBtn();
            console.warn(e);
            optionsBtn.forEach(function (optionBtnItem) {
                e.toolbarOptions.items.unshift({
                    disabled: false,
                    locateInMenu: "auto",
                    location: "after",
                    widget: "dxButton",
                    class: "dx-datagrid-export-button",
                    name: "exportButton",
                    options: optionBtnItem,
                });
            });
        }}*/
                /*onOptionChanged={function (e) {
            console.log("OptionChanged : ", e);
        }}
        onRowCollapsing={function (e) {
            console.log("RowRemoving : ", e);
        }}*/
            >
                <Column
                    dataField="product_name"
                    dataType="string"
                    width="25%"
                    minWidth={150}
                    headerCellRender={function () {
                        return headerCellRender(props.i18n("label.field.product_name"), "product_name");
                    }}
                ></Column>
                <Column
                    dataField="supplier_name"
                    dataType="string"
                    width="25%"
                    minWidth={200}
                    headerCellRender={function () {
                        return headerCellRender(props.i18n("label.field.supplier_name"), "supplier_name");
                    }}
                ></Column>
                <Column
                    alignment="right"
                    dataField="invoice_issue_date_display"
                    dataType="string"
                    //customizeText={(e) => formatMonth(e?.value)}
                    width="10%"
                    minWidth={145}
                    calculateSortValue={function (rowData) {
                        return new Date(rowData.invoice_issue_date);
                    }}
                    //selectedFilterOperation=">="
                    headerCellRender={function () {
                        return headerCellRender(props.i18n("label.field.invoice_issue_date"), "invoice_issue_date");
                    }}
                />
                <Column
                    dataField="invoice_issue_date"
                    name="invoice_issue_date_search"
                    dataType="string"
                    visible={false}
                    calculateDisplayValue={(e) => e?.invoice_issue_date_display} /*for searchPanel*/
                />
                <Column
                    alignment="right"
                    dataField="payment_due_date_display"
                    dataType="string"
                    //customizeText={(e) => formatMonth(e?.value)}
                    width="10%"
                    minWidth={135}
                    //selectedFilterOperation=">="
                    calculateSortValue={function (rowData) {
                        return new Date(rowData.payment_due_date);
                    }}
                    headerCellRender={function () {
                        return headerCellRender(props.i18n("label.field.payment_due_date"), "payment_due_date");
                    }}
                />
                <Column
                    dataField="payment_due_date"
                    name="payment_due_date_search"
                    dataType="string"
                    visible={false}
                    calculateDisplayValue={(e) => e?.payment_due_date_display} /*for searchPanel*/
                />
                <Column
                    alignment="right"
                    dataField="quantity_display"
                    dataType="string"
                    width="10%"
                    minWidth={105}
                    calculateSortValue={function (rowData) {
                        return rowData.quantity;
                    }}
                    headerCellRender={function () {
                        return headerCellRender(props.i18n("label.field.quantity"), "quantity");
                    }}
                    //calculateDisplayValue={quantityDisplayValue}
                    //selectedFilterOperation=">="
                />
                <Column
                    dataField="quantity"
                    name="quantity_search"
                    dataType="string"
                    visible={false}
                    calculateDisplayValue={(e) => e?.quantity_display} /*for searchPanel*/
                />
                <Column
                    alignment="right"
                    dataField="total_due_display"
                    dataType="string"
                    width="10%"
                    minWidth={125}
                    calculateSortValue={function (rowData) {
                        return rowData.total_due;
                    }}
                    headerCellRender={function () {
                        return headerCellRender(props.i18n("label.field.total_due"), "total_due");
                    }}
                    //selectedFilterOperation=">="
                />
                <Column
                    dataField="total_due"
                    name="total_due_search"
                    dataType="string"
                    visible={false}
                    calculateDisplayValue={(e) => e?.total_due_display} /*for searchPanel*/
                />
                <SearchPanel visible={false} /*Recherche globale*/ />
                <FilterRow visible={false} /*Recherche par colonne*/ />
                <Export enabled={false} />
                <Paging />
                <Pager
                    visible={true}
                    allowedPageSizes="auto"
                    displayMode="adaptive"
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />
            </DataGrid>
        );
    }
    //Rendering part
    return (
        <div className="inv_invoice" ref={printPdfRef}>
            {props.setStyle("./base/desktop")}
            <ReactToPdf
                targetRef={printPdfRef}
                filename="Paiements.pdf"
                x={10}
                y={10}
                options={{
                    orientation: "landscape",
                    unit: "px",
                    format: [window.innerWidth, window.innerHeight],
                    hotfixes: ["px_scaling"],
                }}
                scale={1}
            >
                {({ toPdf }) => (toPdfFct = toPdf)}
            </ReactToPdf>
            <div className="viewHeader">
                <h2 className="title">Synthèse des paiements&nbsp;:&nbsp;{viewData.header.total_paid_display}</h2>
                <PortalBtn className="reverse" iconCls="fal fa-download" /*"dx-icon dx-icon-xlsxfile"*/ onClick={exportAsaproExcel}>
                    Télécharger au format Excel
                </PortalBtn>
            </div>
            <FilterToolbar {...props} realTimeFilter={false} />
            {renderContent()}
        </div>
    );
}
