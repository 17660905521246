//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect } from "react";
//Custom Library
import { formValidator, editFormState, formatName, PasswordFieldsValidator } from "../../../../ressources/lib/js/globalFunctions";
//---------- UI Components ----------//
//Custom UI Components
import DesktopScreen from "./screens/desktop/base/screen.js";
import InitView from "../../../../ressources/components/initView/initView";
//-----------------------------------//
//#########################################################################################//
export default function Controller(props) {
    //Url format to access this view : 'https://${portalDns}#register_personnal_contact?token='xxx'
    //LocalHost ex : http://localhost:3000/#register_personnal_contact?token='xxx'
    const [step, setStep] = useState("acceptAccess"); //'acceptAccess' => 'confirmPhone' => ('setPassword') => 'accountActive'
    //                                                  => 'accessRefused'
    const showcaseList = props.showcaseList;
    const [initState, setInitState] = useState("loading"); //'loading' || 'error'
    const [clientData, _setClientData] = useState({
        masked_contact_phone: "",
        contact_username: "",
        contact_ent_entity_id: "",
        client_first_name: "",
        client_last_name: "",
        rol_type_rol_id: "",
    });
    function setClientData(field, value) {
        editFormState(field, value, clientData, _setClientData);
    }
    const defaultRegisterForm = {
        token: "",
        confirm_contact_phone: "",
        password: "",
        password_confirm: "",
        birth_datetime: "",
        contact_action: "",
        cgu: false,
        consent: false,
        rgpd: false,
    };
    const [registerForm, _setRegisterForm] = useState(defaultRegisterForm);
    function setRegisterForm(field, value) {
        editFormState(field, value, registerForm, _setRegisterForm);
    }
    const [formValidationCount, setFormValidationCount] = useState(0);
    const registerValidator = new formValidator({
        token: [{ conditionCode: "required_token", validate: () => !!registerForm.token }],
        confirm_contact_phone: [
            { conditionCode: "required_confirm_contact_phone", validate: () => !!registerForm.confirm_contact_phone },
            { conditionCode: "is_phone_type", validate: () => registerForm.confirm_contact_phone.length == 10 },
        ],
        birth_datetime: [{ conditionCode: "required_birth_datetime", validate: () => !!registerForm.birth_datetime }],
        password: new PasswordFieldsValidator(registerForm)["password"],
        password_confirm: new PasswordFieldsValidator(registerForm)["password_confirm"],
        cgu: [{ conditionCode: "required_cgu_agreement", validate: () => !!registerForm.cgu }],
        consent: [{ conditionCode: "required_consent", validate: () => !!registerForm.consent }],
        rgpd: [{ conditionCode: "required_rgpd_agreement", validate: () => !!registerForm.rgpd }],
    });

    //View Managing Functions
    function getMaskedContactPhone(token) {
        //Commun contacts "pros" et "persos"
        props.setRequestState("loading");
        props
            .request({
                action: "get_masked_contact_phone",
                data: {
                    token: token,
                },
            })
            .then(function (res) {
                _setClientData(res.data || {});
                setInitState("");
            })
            .catch(function (err) {
                setInitState("error");
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            })
            .finally(function () {
                props.setRequestState("");
            });
    }

    function refuseAccess() {
        //Commun contacts "pros" et "persos"
        return new Promise(function (rs, rj) {
            props.setRequestState("loading");
            props
                .msgBox("Notification du refus au bénéficiaire", "info", "process")
                .then(function (boxInstance) {
                    props
                        .request({
                            action: "refuse_contact_access",
                            data: registerForm,
                        })
                        .then(function (res) {
                            setStep("accessRefused");
                            return rs();
                        })
                        .catch(function (err) {
                            props
                                .msgBox(err.msg, "error", "msg")
                                .then(function () {})
                                .catch(function () {})
                                .finally(function () {
                                    return rj();
                                });
                        })
                        .finally(function () {
                            props.setRequestState("");
                            boxInstance.close();
                        });
                })
                .catch(function () {
                    return rj();
                });
        });
    }

    function acceptAccess() {
        //Spécifique contact perso
        setFormValidationCount(formValidationCount + 1);
        const validationState = registerValidator.validate(["token", "confirm_contact_phone"]);
        if (!validationState.success) {
            props
                //.msgBox(props.i18n("error." + validationState.errors[0]), "error", "msg")
                .msgBox(props.i18n("error.invalid_form"), "error", "msg")
                .then(function () {})
                .catch(function () {});
            return;
        }
        props.setRequestState("loading");
        props
            .request({
                action: "accept_contact_access",
                data: registerForm,
            })
            .then(function (res) {
                setClientData("contact_username", res?.data?.username || "");
                const needAccountCreation = res.data.ask_password;
                if (!needAccountCreation) {
                    setStep("accountActive");
                } else {
                    setFormValidationCount(0);
                    setStep("setPassword");
                }
            })
            .catch(function (err) {
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            })
            .finally(function () {
                props.setRequestState("");
            });
    }

    function createUser() {
        //Spécifique contact perso
        setFormValidationCount(formValidationCount + 1);
        const validationState = registerValidator.validate();
        if (!validationState.success) {
            console.error(validationState);
            props
                //.msgBox(props.i18n("error." + validationState.errors[0]), "error", "msg")
                .msgBox(props.i18n("error.invalid_form"), "error", "msg")
                .then(function () {})
                .catch(function () {});
            return;
        }
        props.setRequestState("loading");
        props
            .request({
                action: "create_adm_user",
                data: {
                    ...registerForm,
                },
            })
            .then(function (res) {
                setClientData("username", res?.data?.username || "");
                setStep("accountActive");
            })
            .catch(function (err) {
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            })
            .finally(function () {
                props.setRequestState("");
            });
    }

    //helper functions
    function formatClientName() {
        return formatName({ first_name: clientData.client_first_name, last_name: clientData.client_last_name });
    }
    //Effect treatments
    useEffect(function () {
        setInitState("loading");
        let queryParams = props.getViewContext();
        if (!queryParams.token || !queryParams.action) {
            setInitState("error");
            return props
                .msgBox(props.i18n("error.forbidden_view"), "error", "msg")
                .then(function () {
                    window.history.back();
                })
                .catch(function () {});
        }
        console.log(queryParams);
        setRegisterForm(["token", "contact_action"], [queryParams.token, queryParams.action]);
        getMaskedContactPhone(queryParams.token);
    }, []); //*/

    if (initState === "loading") {
        return <InitView type="loading" />;
    }
    if (initState === "error") {
        return <InitView type="errorLoading" />;
    }

    function getCguData() {
        //console.log(showcaseList);
        let cguItem = showcaseList.find(function (item) {
            return item?.content_param_id === 9014;
        });
        const cguParamId = cguItem?.content_param_id;
        const cguParamTitle = cguItem?.title;
        return {
            content_param_id: cguParamId,
            title: cguParamTitle,
            url: "/#/showcase_view?admParameterId=" + cguParamId + "&viewTitle=" + cguParamTitle,
        };
    }
    function getRgpdData() {
        let rgpdItem = showcaseList.find(function (item) {
            return item?.content_param_id === 9017;
        });
        const rgpdParamId = rgpdItem?.content_param_id;
        const rgpdParamTitle = rgpdItem?.title;
        return {
            content_param_id: rgpdParamId,
            title: rgpdParamTitle,
            url: "/#/showcase_view?admParameterId=" + rgpdParamId + "&viewTitle=" + rgpdParamTitle,
        };
    }

    return (
        <DesktopScreen
            {...props}
            registerForm={registerForm}
            formValidationCount={formValidationCount}
            clientData={clientData}
            step={step}
            setStep={setStep}
            cguData={getCguData()}
            rgpdData={getRgpdData()}
            formattedClientName={formatClientName()}
            refuseAccess={refuseAccess}
            acceptAccess={acceptAccess}
            setRegisterForm={setRegisterForm}
            registerValidator={registerValidator}
            createUser={createUser}
        />
    );
}
