import React from "react";
import "./pscConnect.css";
export default function PscConnect(props) {
    function onClickPscConnect() {
        document.Wrapper.request({
            type: "ajax",
            module: "view",
            view: "login",
            action: "ask_login_psc_connect",
            data: {
                callback_url: window.location.origin + "?type=psc_connect",
            },
        })
            .then(function (response) {
                let pscConnectDns = response.data.location;
                window.location.href = pscConnectDns;
            })
            .catch(function (error) {
                console.error(error.msg);
                props.msgBox(error.msg, "error", "msg");
            });
    }
    return (
        <div className="pscConnectContainer">
            {props.txtInfos ? <p className="pscConnectTxt">{props.txtInfos}</p> : ""}
            <div className="pscConnectBtnRow">
                <button
                    className="pscConnectBtn"
                    onClick={onClickPscConnect}
                    tabIndex={props.tabIndex ? props.tabIndex[0] : null}
                    aria-label="S'identifier avec Pro Santé Connect"
                ></button>
            </div>
            <a
                className="pscConnectLink"
                href="https://esante.gouv.fr/produits-services/pro-sante-connect"
                target="_blank"
                rel="noreferrer"
                tabIndex={props.tabIndex ? props.tabIndex[1] : null}
            >
                Qu'est-ce que Pro Santé Connect ?
            </a>
        </div>
    );
}
