import React from "react";
import { useState } from "react";
import cloneDeep from "clone-deep";
import MenuItem from "../menuItem/menuItem";
import "./navSectionMenu.css";

export default function NavSectionMenu(props) {
    /**
     * @param {Object} props : {
     *
     *      @param {String} title [''] : Le titre affiché avant les items de menu, quand on est à la racine du menu
     *          @desc Quand on est dans un sous-menu, c'est le titre de l'item de la branche parente qui est affiché
     *
     *      @param {Object[]} data [[]] : La liste des items de menu de ce menu
     *          @example : [{direction: 'client_file', items: null}, {direction: 'home_user', items:[{direction: 'user_subscription', items:null}]}]
     *
     * }
     */
    const initMenuContent = props.data || [];
    //const initParentMenuContent = props.parentMenuContent || null;

    const [menuContent, _setMenuContent] = useState(initMenuContent);
    function setMenuContent(menuContent) {
        const newMenuContent = cloneDeep(menuContent);
        _setMenuContent(newMenuContent);
    }
    const [parentItemDataList, _setParentItemDataList] = useState([]);
    function addParentItemData(menuContent) {
        const newParentItemDataList = [...parentItemDataList];
        newParentItemDataList.push(menuContent);
        //console.warn(newParentItemDataList);
        _setParentItemDataList(newParentItemDataList);
    }
    function removeLastParentItemData() {
        const newParentItemDataList = [...parentItemDataList];
        if (newParentItemDataList.length > 0) newParentItemDataList.pop();
        _setParentItemDataList(newParentItemDataList);
    }

    //Event handlers
    function onMenuItemClick(event) {
        switch (event.type) {
            case "goToNavSection":
                const menuItemData = event.data || {};
                const menuContentToAdd = cloneDeep(menuContent);
                addParentItemData({ direction: menuItemData.direction, items: menuContentToAdd });
                setMenuContent(menuItemData.items);
                break;
            default:
                return;
        }
    }
    function returnToPreviousSubMenu() {
        const lastParentItemData = parentItemDataList[parentItemDataList.length - 1];
        setMenuContent(lastParentItemData.items);
        removeLastParentItemData();
    }

    //Rendering functions
    function displayItemList() {
        const menuItemList = [];
        menuContent.forEach(function (itemData) {
            const itemDirection = itemData?.direction || null;
            //console.log(itemData);
            menuItemList.push(
                <MenuItem
                    key={`menuitem_${itemDirection}`}
                    className="vertical"
                    data={itemData}
                    subNavType="navSection" //'navSection' || 'tree'
                    redirectTo={props.redirectTo}
                    i18n={props.i18n}
                    onClick={onMenuItemClick}
                />
            );
        });
        return menuItemList;
    }
    function displayNavsectionTitle() {
        switch (true) {
            case parentItemDataList.length > 0:
                const currentParentContent = parentItemDataList[parentItemDataList.length - 1];
                const parentContentLabel =
                    typeof props.i18n === "function"
                        ? props.i18n("label.view." + currentParentContent.direction)
                        : currentParentContent.direction;
                return (
                    <li className="currentNavsectionTitle clickable" onClick={returnToPreviousSubMenu}>
                        <span className="subnavLogo fas fa-chevron-left"></span>
                        <span className="label">{parentContentLabel || "unknown"}&nbsp;:</span>
                    </li>
                );
            default:
                return <div className="currentNavsectionTitle">{props.title || ""}</div>;
        }
    }
    return (
        <ul className="navsectionmenuContainer">
            {displayNavsectionTitle()}
            {displayItemList()}
        </ul>
    );
}
