import React from "react";
import PortalController from "../../../../ressources/components/portalController/portalController.js";
import Controller from "./controller.js";
export default function Note(props) {
    const useServerPlug = false;
    const loadDataParams = {
        id: null,
        msg_category_id: [],
        flt_status: [2, 3],
        is_public: false,
        flt_show_mine_only: false,
        flt_show_del: false,
        get_plug_data: useServerPlug === true,
    };
    return (
        <PortalController {...props} usePlug={false} storePlug={{}} customInit={false} loadDataParams={loadDataParams}>
            <Controller useServerPlug={useServerPlug} />
        </PortalController>
    );
}
