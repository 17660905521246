import React from "react";
import { useEffect } from "react";
export default function Screen(props) {
    const viewDisclaimer = props.viewDisclaimer || "";
    const url = props.url || ""; //Param 9302
    useEffect(function () {
        const detailInfo = `
            <div class="proDictionaryDetailContainer">
                En partenariat avec
                <img class="proDictionaryLogo" src="./ressources/_shared/images/pro_dictionary_logo.png"/>
                </div>
            <p class="infoContainer">${props.i18n("info.view." + props.viewName)}</p>
        `;
        props.setViewInfo(detailInfo);
    }, []);
    return (
        <>
            {props.setStyle("./base")}
            {viewDisclaimer && <span className="disclaimerContainer">{viewDisclaimer}</span>}
            <iframe className="iframeContainer" src={url}></iframe>;
        </>
    );
}
