//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect } from "react";

//---------- UI Components ----------//
//External libraries
import { Form } from "react-bootstrap";
//Custom UI Components
import BubbleView from "../../../../../../../ressources/components/bubbleView/bubbleView";
import PortalPhone from "../../../../../../../ressources/components/portalFields/portalPhone/portalPhone";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalFeedback from "../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
import PortalPassword from "../../../../../../../ressources/components/portalFields/portalPassword/portalPassword";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText";
import PortalCheck from "../../../../../../../ressources/components/portalFields/portalCheck/portalCheck";
//-----------------------------------//
//#########################################################################################//
export default function Screen(props) {
    const clientData = props.clientData || {};
    const registerForm = props.registerForm || {};
    const step = props.step;
    const cguData = props.cguData || "";
    const rgpdData = props.rgpdData || "";
    const [acceptDialogTrigger, setAcceptDialogTrigger] = useState(false);
    function triggerDialog() {
        setAcceptDialogTrigger(!acceptDialogTrigger);
    }
    useEffect(
        function () {
            if (step === "acceptAccess") {
                console.log(registerForm);
                if (registerForm.contact_action === "accept") {
                    //Validation de l'acceptation
                    const i18nKey = "info.action.confirm_contact_access";
                    const boxMsg = props.i18n(i18nKey, [props.formattedClientName]);
                    props
                        .msgBox(boxMsg, "info", "confirm")
                        .then(function () {
                            props.setStep("confirmPhone");
                        })
                        .catch(function () {
                            window.close();
                            props.redirectTo("login");
                        });
                } else if (registerForm.contact_action === "refuse") {
                    //Validation du refus
                    const i18nKey = "info.action.refuse_contact_access";
                    const boxMsg = props.i18n(i18nKey, [props.formattedClientName]);
                    props
                        .msgBox(boxMsg, "info", "confirm")
                        .then(function () {
                            props.refuseAccess().catch(function (err) {
                                triggerDialog();
                            });
                        })
                        .catch(function () {
                            window.close();
                            props.redirectTo("login");
                        });
                } else {
                    //Action inconnue
                    props
                        .msgBox("Action inconnue", "error")
                        .then(() => {
                            props.redirectTo("login");
                        })
                        .catch(() => {
                            window.close();
                            props.redirectTo("login");
                        });
                }
            }
        },
        [acceptDialogTrigger]
    );

    useEffect(
        function () {
            let msg = "";
            switch (step) {
                case "accessRefused":
                    msg = props.i18n("info.success_refuse_contact_access", [props.formattedClientName]);
                    props
                        .msgBox(msg)
                        .then(function () {
                            window.close();
                            props.redirectTo("login");
                        })
                        .catch(function () {});
                    break;
                case "accountActive":
                    msg = props.i18n("info.success_confirm_contact_access", [
                        props.formattedClientName,
                        props.appName,
                        props.appName,
                        clientData.contact_username,
                    ]);
                    props
                        .msgBox(msg)
                        .then(() => {
                            props.redirectTo("login");
                        })
                        .catch(() => {});
                    break;
                default:
                    break;
            }
        },
        [step]
    );

    function displayConfirmPhone() {
        function onSubmitForm(e) {
            e.preventDefault();
            props.acceptAccess();
        }
        const contactPhoneValidation = props.registerValidator.fields.confirm_contact_phone.validate();
        return (
            <Form id="confirmPhoneForm" onSubmit={onSubmitForm}>
                <Form.Group>
                    <h4 className="header">
                        Veuillez confirmer votre numéro de téléphone
                        <br />({clientData.masked_contact_phone})
                    </h4>
                </Form.Group>
                <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                <Form.Group controlId="confirmContactPhone">
                    <Form.Label>
                        {props.i18n("label.field.confirm_contact_phone")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalPhone
                        id="confirmContactPhone"
                        autoFocus
                        onChange={(e, newValue) => props.setRegisterForm("confirm_contact_phone", newValue)}
                        autoComplete="tel-national"
                        name="confirmContactPhone"
                        value={props.registerForm.confirm_contact_phone}
                        //required={true}
                        aria-required={true}
                        tooltip={props.i18n("info.field.confirm_contact_phone", [
                            props.formattedClientName,
                            clientData.masked_contact_phone,
                        ])}
                        isInvalid={!contactPhoneValidation.success && props.formValidationCount > 0}
                        aria-invalid={!contactPhoneValidation.success && props.formValidationCount > 0}
                        aria-describedby="confirmContactPhone-1 confirmContactPhone-2 confirmContactPhone-3 confirmContactPhone-4"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback
                            fieldId="confirmContactPhone"
                            msgList={contactPhoneValidation.errors}
                            i18n={props.i18n}
                            viewName={props.viewName}
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <PortalBtn type="submit" className="validate centered" state={props.requestState}>
                    Valider
                </PortalBtn>
            </Form>
        );
    }
    function displaySetPassword() {
        function onSubmitForm(e) {
            e.preventDefault();
            props.createUser();
        }
        const passwordValidation = props.registerValidator.fields.password.validate();
        const passwordConfirmValidation = props.registerValidator.fields.password_confirm.validate();
        const birthDatetimeValidation = props.registerValidator.fields.birth_datetime.validate();
        const cguValidationResult = props.registerValidator.fields.cgu.validate();
        const consentValidationResult = props.registerValidator.fields.consent.validate();
        const rgpdValidationResult = props.registerValidator.fields.rgpd.validate();
        return (
            <Form id="createUsernameForm" onSubmit={onSubmitForm} noValidate>
                <Form.Group>
                    <h4 className="header">
                        Vous ne disposez pas encore de compte utilisateur, veuillez remplir le formulaire ci-dessous pour que nous puissions
                        vous en créer un avec l'identifiant suivant :
                        <br /> <br />
                        {clientData.contact_username}
                    </h4>
                </Form.Group>
                <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                <Form.Group controlId="birthDate">
                    {!!props.i18n("label.field.birth_datetime") && (
                        <Form.Label>
                            {props.i18n("label.field.birth_datetime")} <span aria-hidden={true}>*</span>
                        </Form.Label>
                    )}
                    <PortalText
                        id="birthDate"
                        onChange={(e) => props.setRegisterForm("birth_datetime", e.target.value)}
                        type="date"
                        autoComplete="bday"
                        name="birthDate"
                        placeholder={props.i18n("placeholder.field.birth_datetime")}
                        value={props.registerForm.birth_datetime}
                        required={true}
                        aria-required={true}
                        isInvalid={!birthDatetimeValidation.success && props.formValidationCount > 0}
                        aria-invalid={!birthDatetimeValidation.success && props.formValidationCount > 0}
                        aria-describedby="birthDate-1"
                    />
                    {props.formValidationCount > 0 && (
                        <PortalFeedback fieldId="birthDate" msgList={birthDatetimeValidation.errors} i18n={props.i18n} />
                    )}
                </Form.Group>
                <Form.Group controlId="contactPassword">
                    {!!props.i18n("label.field.current_password") && (
                        <Form.Label>
                            {props.i18n("label.field.current_password")}&nbsp;<span aria-hidden={true}>*</span>
                        </Form.Label>
                    )}
                    <PortalPassword
                        id="contactPassword"
                        autoFocus
                        iconsLeft="far fa-lock-alt"
                        onChange={(e) => props.setRegisterForm("password", e.target.value)}
                        autoComplete="password"
                        placeholder={props.i18n("placeholder.field.current_password")}
                        value={props.registerForm.password}
                        //required={true}
                        aria-required={true}
                        tooltip={props.i18n("info.field.new_password")}
                        isInvalid={!passwordValidation.success && props.formValidationCount > 0}
                        aria-invalid={!passwordValidation.success && props.formValidationCount > 0}
                        aria-describedby="contactPassword-1 contactPassword-2 contactPassword-3 contactPassword-4"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback
                            fieldId="contactPassword"
                            msgList={passwordValidation.errors}
                            i18n={props.i18n}
                            viewName={props.viewName}
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    {props.i18n("label.field.password_confirm") && (
                        <Form.Label>
                            {props.i18n("label.field.password_confirm")}&nbsp;<span aria-hidden={true}>*</span>
                        </Form.Label>
                    )}
                    <PortalPassword
                        id="confirmPassword"
                        autoFocus
                        iconsLeft="far fa-lock-alt"
                        onChange={(e) => props.setRegisterForm("password_confirm", e.target.value)}
                        autoComplete="password"
                        value={props.registerForm.password_confirm}
                        placeholder={props.i18n("placeholder.field.password_confirm")}
                        //required={true}
                        aria-required={true}
                        //tooltip={props.i18n("info.field.password_confirm")}
                        isInvalid={!passwordConfirmValidation.success && props.formValidationCount > 0}
                        aria-invalid={!passwordConfirmValidation.success && props.formValidationCount > 0}
                        aria-describedby="confirmPassword-1 confirmPassword-2 confirmPassword-3 confirmPassword-4"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback
                            fieldId="confirmPassword"
                            msgList={passwordConfirmValidation.errors}
                            i18n={props.i18n}
                            viewName={props.viewName}
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group className="checkGroup">
                    <PortalCheck
                        inputName="checkCGU"
                        active={props.registerForm.cgu}
                        onChange={() => props.setRegisterForm("cgu", !props.registerForm.cgu)}
                        text={
                            //TODO: Changer ce paramètre en 'label' = car on peut y insérer du JSX'
                            /*<>
                                {props.i18n("label.field.cgu_agreement_1")}&nbsp;
                                <button type="button" className="portalLinkBtn cguLink" onClick={openCgu}>
                                    {props.i18n("label.field.cgu_agreement_2")}
                                </button>
                                &nbsp;
                                <span aria-hidden={true}>*</span>
                            </>*/
                            props.i18n("label.field.cgu_agreement", [cguData.url])
                        }
                        textAlign="right"
                        required={true}
                        invalid={!cguValidationResult.success && props.formValidationCount > 0}
                        aria-describedby="checkCgu-1"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback fieldId="checkCgu" msgList={cguValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group className="checkGroup">
                    <PortalCheck
                        inputName="checkConsent"
                        active={props.registerForm.consent}
                        onChange={() => props.setRegisterForm("consent", !props.registerForm.consent)}
                        text={props.i18n("label.field.consent")}
                        textAlign="right"
                        required={true}
                        invalid={!consentValidationResult.success && props.formValidationCount > 0}
                        aria-describedby="checkConsent-1"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback fieldId="checkConsent" msgList={consentValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group className="checkGroup">
                    <PortalCheck
                        inputName="checkRgpd"
                        active={props.registerForm.rgpd}
                        onChange={() => props.setRegisterForm("rgpd", !props.registerForm.rgpd)}
                        text={props.i18n("label.field.rgpd_agreement", [rgpdData.url])}
                        textAlign="right"
                        required={true}
                        invalid={!rgpdValidationResult.success && props.formValidationCount > 0}
                        aria-describedby="checkRgpd-1"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback fieldId="checkRgpd" msgList={rgpdValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <PortalBtn type="submit" className="validate centered" state={props.requestState}>
                    Valider
                </PortalBtn>
            </Form>
        );
    }

    function displayContent() {
        switch (step) {
            case "confirmPhone":
                return displayConfirmPhone();
            case "setPassword":
                return displaySetPassword();
            default:
                return "";
        }
    }
    //Rendering part
    return (
        <>
            {props.setStyle("./desktop/base")}
            <BubbleView
                logo={true}
                title="Inscription entourage proche"
                className={"registerPersoPannel" + (!displayContent() ? " noContent" : "")}
            >
                {displayContent()}
            </BubbleView>
        </>
    );
}
