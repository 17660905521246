/**********Imports**********/
//IE support
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
//Base React modules
import React from "react";
import ReactDOM from "react-dom";

//React Router module (https://reactrouter.com/web/guides/quick-start) => Navigation API
import { HashRouter, Switch, Route } from "react-router-dom";

//React Redux module (https://react-redux.js.org/) => Centralized data storing API
import { Provider } from "react-redux";

//Project components
import InitApp from "./application/initApp.js";
import asaStore from "./asaStore";
/***************************/
function onDeviceReady() {
    //Loading devExtreme french config
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={asaStore}>
                <HashRouter>
                    <Switch>
                        <Route path="/*" key="initRoute">
                            <InitApp />
                        </Route>
                    </Switch>
                </HashRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root")
    );
}
if (window.location.href.indexOf("file:") === 0) {
    // Apk mode
    //document.addEventListener("deviceready", onDeviceReady, false);
    onDeviceReady();
} else {
    //Browser mode
    onDeviceReady();
}
