import React from "react";
import ArianWire from "../arianWire/arianWire";
import "./viewBanner.css";

export default function ViewBanner(props) {
    const userJourney = props.userJourney || [];
    const viewInfo = props.viewInfo;
    function currentViewTitleRender() {
        let iconDisplay = "";
        const viewName = userJourney[userJourney.length - 1] || "";
        if (typeof props.i18n === "function") {
            const currentViewIcon = props.i18n("icon.view." + viewName + "_icon");
            if (currentViewIcon) {
                iconDisplay = <span className={`icon ${currentViewIcon}`}></span>;
            }
        }
        return (
            <>
                {iconDisplay}
                {props.i18n("label.view." + viewName)}
            </>
        );
    }

    return (
        <div className={`viewBannerContainer${props.className ? " " + props.className : ""}`}>
            <ArianWire userJourney={userJourney} i18n={props.i18n} redirectTo={props.redirectTo} viewHash={props.viewHash} />
            <div className="currentViewContainer">
                <h1 className="currentViewTitleContainer">{currentViewTitleRender()}</h1>
                {viewInfo && <div className="viewInfoContainer" dangerouslySetInnerHTML={{ __html: viewInfo }}></div>}
            </div>
        </div>
    );
}
