//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect, useRef } from "react";
import cloneDeep from "clone-deep";
//Custom libraty
import { getObjectValue } from "../../../../ressources/lib/js/globalFunctions";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
//Custom UI Components
import InitView from "../../../../../src/ressources/components/initView/initView";
import MealToolBar from "./components/mealToolBar.js";
import DishCell from "./components/dishCell.js";
import PortalCheck from "../../../../ressources/components/portalFields/portalCheck/portalCheck.js";
import ItemRow from "../../../../ressources/components/itemRow/itemRow";
import PortalText from "../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalBtn from "../../../../ressources/components/portalBtn/portalBtn";
import { Form } from "react-bootstrap";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//
function ClientMeal(props) {
    // A personnaliser en fonction de la vue
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const plugLoadingDelay = 2000; //Pour simuler un temps de chargement
    const plugLoadingError = false; //Pour simuler une erreur de chargement
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        viewData1: "Donnée n°1",
        viewData2: "Donnée n°2",
    };
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [numDays, setNumDays] = useState(7);
    const [repClientMenuDate, setRepClientMenuDate] = useState(null);
    const [repClientMenuDateModif, setRepClientMenuDateModif] = useState(false);
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [dateModifList, setDateModifList] = useState([]);
    const [triggerReload, setTriggerReload] = useState(false);
    // Init functions
    function loadViewData(input) {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        console.log(input);
        setIsloading(true);
        setErrorLoading(false);
        if (dataPlug && useDataPlug) {
            setTimeout(function () {
                if (plugLoadingError) {
                    setErrorLoading(true);
                    setIsloading(false);
                } else {
                    setViewData(dataPlug);
                    setIsloading(false);
                }
            }, plugLoadingDelay);
            return;
        }
        props
            .request({
                data: input, // A personnaliser en fonction de la vue
            })
            .then(function (response) {
                setViewData(response.data);
                handleResponseHelp(response);
                const repCliMenuDate = getObjectValue(response, ["data", "rep_client_menu_date"]);
                setRepClientMenuDate(repCliMenuDate);
                //Once datas are loaded from the server, all non-submitted UI modifications will be erased
            })
            .catch(function (error) {
                setErrorLoading(true);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            })
            .finally(function () {
                setRepClientMenuDateModif(false);
                setDateModifList([]);
                setIsloading(false);
            });
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        //Mise à jour du fil d'ariane
        props.updateUserJourney(["home", "home_client", "client_meal"]);
        return function () {
            // Ré-initialiser les warning du layout à la destruction de la vue (Le layout ne peut pas le faire lui-même car le onhashchange est plus lent que l'instanciation d'une nouvelle vue)
            props.setHelpDisplay(false);
            props.setHelpContent("");
            props.setHelpImportance("info");
        };
    }, []);
    useEffect(
        function () {
            //V3 - Breakpoint instinct (The best ;))
            const breakPoints = {
                //minScreenWidth:numberOfDays
                0: 1,
                650: 2,
                800: 3,
                1000: 4,
                1200: 5,
                1400: 6,
                1600: 7,
            };
            const orderedBreakPoints = Object.keys(breakPoints).sort((el1, el2) => el1 - el2);
            const screenWidth = props.screenWidth || 0;
            let localNumDays = breakPoints[orderedBreakPoints[0]] || 1;
            let firstBreakPointIndex = 0,
                secondBreakPointIndex = 1;
            let firstBreakPoint = +orderedBreakPoints[firstBreakPointIndex];
            let secondBreakPoint = +orderedBreakPoints[secondBreakPointIndex] || null;
            let follow = screenWidth >= firstBreakPoint;
            while (follow) {
                if (!secondBreakPoint) {
                    //BreakPoint to use is the last of the table
                    localNumDays = breakPoints[firstBreakPoint];
                    follow = false;
                } else {
                    switch (follow) {
                        case screenWidth < firstBreakPoint:
                            follow = false;
                            localNumDays = breakPoints[firstBreakPoint];
                            break;
                        case screenWidth >= firstBreakPoint && screenWidth < secondBreakPoint:
                            follow = false;
                            localNumDays = breakPoints[firstBreakPoint];
                        default:
                            firstBreakPointIndex++;
                            secondBreakPointIndex++;
                            firstBreakPoint = +orderedBreakPoints[firstBreakPointIndex];
                            secondBreakPoint = +orderedBreakPoints[secondBreakPointIndex];
                            break;
                    }
                }
            }
            setNumDays(localNumDays);
        },
        [props.screenWidth, isLoading]
    );
    // Init render treatments (On déclanche le submit à l'init de la vue et à chaque fois qu'on change d'ent_entity_id)
    const mealToolBar = (
        <MealToolBar
            numDays={numDays}
            isLoading={isLoading}
            onSubmit={loadViewData}
            clientEntEntityId={props.selectedClient.ent_entity_id}
            triggerReload={triggerReload}
        />
    );
    if (isLoading && !errorLoading) {
        return (
            <>
                {props.setStyle("./normal")}
                {mealToolBar}
                <InitView type="loading" />
            </>
        );
    }
    if (errorLoading) {
        return (
            <>
                {props.setStyle("./normal")}
                {mealToolBar}
                <InitView type="errorLoading" />
            </>
        );
    }
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)

    // View constants

    // View global functions

    // UI event handlers
    function onChangeCell(newRepClientMenuDate, date) {
        if (date && newRepClientMenuDate[date] && typeof newRepClientMenuDate[date] === "object") {
            newRepClientMenuDate[date]["par_status_treatment_id"] = "953";
        }
        const isNewDate = dateModifList.findIndex((el) => el === date);
        if (isNewDate < 0) {
            const newDateModifList = [...dateModifList];
            newDateModifList.push(date);
            setDateModifList(newDateModifList);
        }
        setRepClientMenuDate(newRepClientMenuDate);
        setRepClientMenuDateModif(true);
    }
    function onSubmitModifs() {
        const plugInput = {
            __initial: {
                "2022-04-27": {
                    id: "839722",
                    par_status_treatment_id: "957",
                    pro_product_id: "500",
                    rep_menu_id: "135",
                    rep_menu_quantity: "1",
                    rep_menu_par_status_treatment_id: null,
                    tournee_ent_entity_id: "3210040",
                    address_container: {
                        ent_entity_postal_address_id: "20239",
                        formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                        latitude: "46.210259200000000",
                        longitude: "6.147580900000000",
                    },
                    _audit: "Id <b>839722</b><br/>Cr\u00e9\u00e9 par joweathe le 21/04/2022 11:08:48",
                    section: {
                        300: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586341",
                            rep_menu_section_generic_id: "509",
                        },
                        303: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        306: {
                            rep_dish_id: "3396",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586342",
                            rep_menu_section_generic_id: "572",
                        },
                        309: {
                            rep_dish_id: "66",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586343",
                            rep_menu_section_generic_id: "629",
                        },
                        315: {
                            rep_dish_id: "32",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586344",
                            rep_menu_section_generic_id: "734",
                        },
                        318: {
                            rep_dish_id: "633",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586345",
                            rep_menu_section_generic_id: "647",
                        },
                        321: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        328: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                    },
                },
                "2022-04-28": {
                    id: "839723",
                    par_status_treatment_id: "953",
                    pro_product_id: "500",
                    rep_menu_id: "135",
                    rep_menu_quantity: "1",
                    rep_menu_par_status_treatment_id: null,
                    tournee_ent_entity_id: "3210040",
                    address_container: {
                        ent_entity_postal_address_id: "20239",
                        formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                        latitude: "46.210259200000000",
                        longitude: "6.147580900000000",
                    },
                    _audit: "Id <b>839723</b><br/>Cr\u00e9\u00e9 par joweathe le 21/04/2022 11:08:48<br/>Modifi\u00e9 par brice.rigot@hippocad.com le 22/04/2022 17:03:45",
                    section: {
                        300: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586346",
                            rep_menu_section_generic_id: "509",
                        },
                        303: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        306: {
                            rep_dish_id: "3411",
                            quantity: "4",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586347",
                            rep_menu_section_generic_id: "572",
                        },
                        309: {
                            rep_dish_id: "775",
                            quantity: "1",
                            par_status_treatment_id: "953",
                            rep_client_menu_date_dish_id: "5586348",
                            rep_menu_section_generic_id: "620",
                        },
                        315: {
                            rep_dish_id: "32",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586349",
                            rep_menu_section_generic_id: "734",
                        },
                        318: {
                            rep_dish_id: "90",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586350",
                            rep_menu_section_generic_id: "647",
                        },
                        321: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        328: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                    },
                },
                "2022-04-29": {
                    id: "839724",
                    par_status_treatment_id: "957",
                    pro_product_id: "500",
                    rep_menu_id: "135",
                    rep_menu_quantity: "1",
                    rep_menu_par_status_treatment_id: null,
                    tournee_ent_entity_id: "3210040",
                    address_container: {
                        ent_entity_postal_address_id: "20239",
                        formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                        latitude: "46.210259200000000",
                        longitude: "6.147580900000000",
                    },
                    _audit: "Id <b>839724</b><br/>Cr\u00e9\u00e9 par joweathe le 21/04/2022 11:08:48",
                    section: {
                        300: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586351",
                            rep_menu_section_generic_id: "509",
                        },
                        303: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        306: {
                            rep_dish_id: "3424",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586352",
                            rep_menu_section_generic_id: "572",
                        },
                        309: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586353",
                            rep_menu_section_generic_id: "629",
                        },
                        315: {
                            rep_dish_id: "32",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586354",
                            rep_menu_section_generic_id: "734",
                        },
                        318: {
                            rep_dish_id: "21",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586355",
                            rep_menu_section_generic_id: "647",
                        },
                        321: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        328: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                    },
                },
                "2022-04-30": {
                    id: "839725",
                    par_status_treatment_id: "957",
                    pro_product_id: "500",
                    rep_menu_id: "135",
                    rep_menu_quantity: "1",
                    rep_menu_par_status_treatment_id: null,
                    tournee_ent_entity_id: "3210040",
                    address_container: {
                        ent_entity_postal_address_id: "20239",
                        formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                        latitude: "46.210259200000000",
                        longitude: "6.147580900000000",
                    },
                    _audit: "Id <b>839725</b><br/>Cr\u00e9\u00e9 par joweathe le 21/04/2022 11:08:48",
                    section: {
                        300: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586356",
                            rep_menu_section_generic_id: "509",
                        },
                        303: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        306: {
                            rep_dish_id: "3440",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586357",
                            rep_menu_section_generic_id: "572",
                        },
                        309: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586358",
                            rep_menu_section_generic_id: "629",
                        },
                        315: {
                            rep_dish_id: "32",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586359",
                            rep_menu_section_generic_id: "734",
                        },
                        318: {
                            rep_dish_id: "62",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586360",
                            rep_menu_section_generic_id: "647",
                        },
                        321: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        328: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                    },
                },
                "2022-05-01": {
                    id: "839726",
                    par_status_treatment_id: "957",
                    pro_product_id: "500",
                    rep_menu_id: "135",
                    rep_menu_quantity: "1",
                    rep_menu_par_status_treatment_id: null,
                    tournee_ent_entity_id: "3210040",
                    address_container: {
                        ent_entity_postal_address_id: "20239",
                        formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                        latitude: "46.210259200000000",
                        longitude: "6.147580900000000",
                    },
                    _audit: "Id <b>839726</b><br/>Cr\u00e9\u00e9 par joweathe le 21/04/2022 11:08:48",
                    section: {
                        300: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586361",
                            rep_menu_section_generic_id: "509",
                        },
                        303: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        306: {
                            rep_dish_id: "3452",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586362",
                            rep_menu_section_generic_id: "572",
                        },
                        309: {
                            rep_dish_id: "",
                            quantity: "",
                            par_status_treatment_id: "",
                            rep_client_menu_date_dish_id: "5586363",
                            rep_menu_section_generic_id: "629",
                        },
                        315: {
                            rep_dish_id: "32",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586364",
                            rep_menu_section_generic_id: "734",
                        },
                        318: {
                            rep_dish_id: "3454",
                            quantity: "1",
                            par_status_treatment_id: "952",
                            rep_client_menu_date_dish_id: "5586365",
                            rep_menu_section_generic_id: "647",
                        },
                        321: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                        328: {
                            rep_dish_id: null,
                            quantity: null,
                            par_status_treatment_id: null,
                            rep_menu_section_generic_id: null,
                            rep_client_menu_date_dish_id: null,
                        },
                    },
                },
            },
            __default: {},
            "2022-04-27": {
                id: 839722,
                consomme: true,
                client_ent_entity_id: 3257393,
                dsp_timeslot_id: 200,
                start_date: "2022-04-27",
                pro_product_id: 500,
                rep_menu_id: 135,
                quantity: 1,
                tournee_ent_entity_id: 3210040,
                address_container: {
                    ent_entity_postal_address_id: 20239,
                    formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                    latitude: 46.2102592,
                    longitude: 6.1475809,
                },
                section: {
                    300: { rep_client_menu_date_dish_id: 5586341, rep_menu_section_generic_id: 509, quantity: null, rep_dish_id: null },
                    303: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    306: { rep_client_menu_date_dish_id: 5586342, rep_menu_section_generic_id: 572, quantity: 1, rep_dish_id: 3396 },
                    309: { rep_client_menu_date_dish_id: 5586343, rep_menu_section_generic_id: 629, quantity: 1, rep_dish_id: 66 },
                    315: { rep_client_menu_date_dish_id: 5586344, rep_menu_section_generic_id: 734, quantity: 1, rep_dish_id: 32 },
                    318: { rep_client_menu_date_dish_id: 5586345, rep_menu_section_generic_id: 647, quantity: 1, rep_dish_id: 633 },
                    321: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    328: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                },
            },
            "2022-04-28": {
                id: 839723,
                consomme: true,
                client_ent_entity_id: 3257393,
                dsp_timeslot_id: 200,
                start_date: "2022-04-28",
                pro_product_id: 500,
                rep_menu_id: 135,
                quantity: 1,
                tournee_ent_entity_id: 3210040,
                address_container: {
                    ent_entity_postal_address_id: 20239,
                    formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                    latitude: 46.2102592,
                    longitude: 6.1475809,
                },
                section: {
                    300: { rep_client_menu_date_dish_id: 5586346, rep_menu_section_generic_id: 509, quantity: null, rep_dish_id: null },
                    303: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    306: { rep_client_menu_date_dish_id: 5586347, rep_menu_section_generic_id: 572, quantity: 5, rep_dish_id: 3411 },
                    309: { rep_client_menu_date_dish_id: 5586348, rep_menu_section_generic_id: 620, quantity: 1, rep_dish_id: 775 },
                    315: { rep_client_menu_date_dish_id: 5586349, rep_menu_section_generic_id: 734, quantity: 2, rep_dish_id: 32 },
                    318: { rep_client_menu_date_dish_id: 5586350, rep_menu_section_generic_id: 647, quantity: 1, rep_dish_id: 90 },
                    321: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    328: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                },
            },
            "2022-04-29": {
                id: 839724,
                consomme: true,
                client_ent_entity_id: 3257393,
                dsp_timeslot_id: 200,
                start_date: "2022-04-29",
                pro_product_id: 500,
                rep_menu_id: 135,
                quantity: 1,
                tournee_ent_entity_id: 3210040,
                address_container: {
                    ent_entity_postal_address_id: 20239,
                    formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                    latitude: 46.2102592,
                    longitude: 6.1475809,
                },
                section: {
                    300: { rep_client_menu_date_dish_id: 5586351, rep_menu_section_generic_id: 509, quantity: null, rep_dish_id: null },
                    303: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    306: { rep_client_menu_date_dish_id: 5586352, rep_menu_section_generic_id: 572, quantity: 1, rep_dish_id: 3424 },
                    309: { rep_client_menu_date_dish_id: 5586353, rep_menu_section_generic_id: 629, quantity: null, rep_dish_id: null },
                    315: { rep_client_menu_date_dish_id: 5586354, rep_menu_section_generic_id: 734, quantity: 1, rep_dish_id: 32 },
                    318: { rep_client_menu_date_dish_id: 5586355, rep_menu_section_generic_id: 647, quantity: 1, rep_dish_id: 21 },
                    321: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    328: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                },
            },
            "2022-04-30": {
                id: 839725,
                consomme: true,
                client_ent_entity_id: 3257393,
                dsp_timeslot_id: 200,
                start_date: "2022-04-30",
                pro_product_id: 500,
                rep_menu_id: 135,
                quantity: 1,
                tournee_ent_entity_id: 3210040,
                address_container: {
                    ent_entity_postal_address_id: 20239,
                    formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                    latitude: 46.2102592,
                    longitude: 6.1475809,
                },
                section: {
                    300: { rep_client_menu_date_dish_id: 5586356, rep_menu_section_generic_id: 509, quantity: null, rep_dish_id: null },
                    303: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    306: { rep_client_menu_date_dish_id: 5586357, rep_menu_section_generic_id: 572, quantity: 1, rep_dish_id: 3440 },
                    309: { rep_client_menu_date_dish_id: 5586358, rep_menu_section_generic_id: 629, quantity: null, rep_dish_id: null },
                    315: { rep_client_menu_date_dish_id: 5586359, rep_menu_section_generic_id: 734, quantity: 1, rep_dish_id: 32 },
                    318: { rep_client_menu_date_dish_id: 5586360, rep_menu_section_generic_id: 647, quantity: 1, rep_dish_id: 62 },
                    321: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    328: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                },
            },
            "2022-05-01": {
                id: 839726,
                consomme: true,
                client_ent_entity_id: 3257393,
                dsp_timeslot_id: 200,
                start_date: "2022-05-01",
                pro_product_id: 500,
                rep_menu_id: 135,
                quantity: 1,
                tournee_ent_entity_id: 3210040,
                address_container: {
                    ent_entity_postal_address_id: 20239,
                    formatted_address: "Rue Docteur-Alfred-Vincent 23, 1201 Gen\u00e8ve, Suisse",
                    latitude: 46.2102592,
                    longitude: 6.1475809,
                },
                section: {
                    300: { rep_client_menu_date_dish_id: 5586361, rep_menu_section_generic_id: 509, quantity: null, rep_dish_id: null },
                    303: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    306: { rep_client_menu_date_dish_id: 5586362, rep_menu_section_generic_id: 572, quantity: 1, rep_dish_id: 3452 },
                    309: { rep_client_menu_date_dish_id: 5586363, rep_menu_section_generic_id: 629, quantity: null, rep_dish_id: null },
                    315: { rep_client_menu_date_dish_id: 5586364, rep_menu_section_generic_id: 734, quantity: 1, rep_dish_id: 32 },
                    318: { rep_client_menu_date_dish_id: 5586365, rep_menu_section_generic_id: 647, quantity: 1, rep_dish_id: 3454 },
                    321: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                    328: { rep_client_menu_date_dish_id: null, rep_menu_section_generic_id: null, quantity: null, rep_dish_id: null },
                },
            },
            __justify_dt: ["2022-04-28"],
            __justify: "test",
        };

        const dataInput = Object.assign(
            {
                __initial: viewData?.rep_client_menu_date,
                __default: {},
                __justify_dt: dateModifList,
                __justify: "test", //TODO: Faire un popup pour entrer un message justificatif
            },
            repClientMenuDate || {}
        );
        props
            .request({
                action: "post_client_meal_data",
                data: dataInput,
            })
            .then(function (res) {
                props.msgBox("Votre demande de modification de repas a bien été soumise", "info", "msg");
                setTriggerReload(!triggerReload);
            })
            .catch(function (err) {
                console.error(err?.msg);
                props.msgBox(err?.msg, "error", "msg");
            });
    }
    function onCancelModifs() {
        setRepClientMenuDate(viewData?.rep_client_menu_date);
        setRepClientMenuDateModif(false);
        setDateModifList([]);
    }
    // Pre-rendering treatments
    function ParStatusTreatmentCell({ data, statusList, onChange, repClientMenuDate }) {
        /*useEffect(function () {
            console.log("data : ", data);
            console.log("statusList : ", statusList);
            console.log("onChange : ", onChange);
        }, []);*/
        const currentStatus = statusList.selected || "";
        const currentStatusData = getObjectValue(statusList, ["list", currentStatus]) || {};
        return (
            <div className={`mealTable-cell-content ${currentStatusData.cls_color}`}>
                <PortalCheck
                    inputName={`parStatusTreatment${data.date}`}
                    text={currentStatusData.name}
                    textAlign="right"
                    active={+data.rep_menu_quantity > 0}
                />
            </div>
        );
    }
    function ComboCell({ data, comboList, onChange, repCliMenuDate }) {
        /*useEffect(function () {
            console.log("data : ", data);
            console.log("statusList : ", comboList);
            console.log("onChange : ", onChange);
        }, []);*/
        function changeOption(event) {
            const newValue = event.target.value;
            const section = getObjectValue(repCliMenuDate, [data?.date, "section"]);
            if (section && typeof section === "object") {
                //We always clean the menu part once we change pro_product or rep_menu
                for (let dishId in section) {
                    section[dishId]["rep_dish_id"] = null;
                    section[dishId]["quantity"] = null;
                    section[dishId]["par_status_treatment_id"] = null;
                }
            }
            switch (comboList?.field) {
                case "pro_product_id":
                    repCliMenuDate[data?.date]["pro_product_id"] = newValue;
                    repCliMenuDate[data?.date]["rep_menu_id"] = null;
                    onChange(repCliMenuDate, data?.date);
                    break;
                case "rep_menu_id":
                    repCliMenuDate[data?.date]["rep_menu_id"] = newValue;
                    onChange(repCliMenuDate, data?.date);
                    break;
                default:
                    return;
            }
        }
        const comboField = comboList.field || "";
        const currentCombo = comboList.selected || "";
        //const currentComboData = getObjectValue(comboList, ["list", currentCombo]) || {};
        const comboOptionDisplay = [];
        for (let comboOption in getObjectValue(comboList, ["list"]) || {}) {
            comboOptionDisplay.push(
                <option value={comboOption} key={`combo${comboField}${comboOption}${data?.date}`}>
                    {getObjectValue(comboList, ["list", comboOption, "name"])}
                </option>
            );
        }
        comboOptionDisplay.unshift(<option value={null} key="comboNull"></option>);
        return (
            <div className="mealTable-cell-content">
                <Form.Control
                    onChange={changeOption}
                    as="select"
                    autoComplete="off"
                    name="mealMoment"
                    value={currentCombo}
                    aria-required={false}
                    aria-invalid={false}
                >
                    <optgroup label="Choix disponibles">{comboOptionDisplay}</optgroup>
                </Form.Control>
            </div>
        );
    }

    function buildTable() {
        //Data is structured to be constructed column by column
        //?. optionnal chaining
        //Beginning of the process
        if (repClientMenuDate && typeof repClientMenuDate === "object") {
            let headerListDisplay = [<th key="colHeader-0" scope="col" className="mealTable-col-header"></th>];
            //On initialise la liste des lignes du tableau avec leur id et leur rank (pour pouvoir les remplir et les interchanger indépendemment)
            const staticRowList = {
                pro_product_id: { id: "pro_product_id", name: "Bouquet", rank: 2 },
                rep_menu_id: { id: "rep_menu_id", name: "Menu", rank: 3 },
                par_status_treatment_id: { id: "par_status_treatment_id", name: "Consomme", rank: 1 },
                address_container: { id: "address_container", name: "Adresse", rank: 10000 },
            };
            const dynamicRowList = cloneDeep(getObjectValue(viewData, ["rep_menu_section"])); //We break the reference with the source object into viewData state
            for (let menuId in dynamicRowList) {
                //To ensure rank is numeric and above 10 (1-9 ranks reserved to "static" rows)
                if (getObjectValue(dynamicRowList, [menuId, "rank"])) dynamicRowList[menuId]["rank"] = +dynamicRowList[menuId]["rank"] + 10;
            }
            const rowList = Object.assign({}, staticRowList, dynamicRowList);
            let orderedRowListKeys = [];
            let staticRowListKeys = Object.getOwnPropertyNames(staticRowList);
            for (let rowId in rowList) {
                orderedRowListKeys.push(rowId);
                //Header init of each row
                rowList[rowId]["jsx"] = [
                    <th
                        key={`rowHeader${rowId}`}
                        scope="row"
                        className={`mealTable-row-header${staticRowListKeys.includes(rowId) ? " static" : ""}`}
                    >
                        {rowList[rowId]["name"]}
                    </th>,
                ];
            }
            //RowListKeys sorting
            orderedRowListKeys.sort(function (key, nextKey) {
                return rowList[key]["rank"] - rowList[nextKey]["rank"];
            });
            //Data Mapping and rowList and headerListDisplay filling
            let jsxRow, currentProProductId, currentRepMenuId;
            for (let date in repClientMenuDate) {
                //Header adding
                headerListDisplay.push(
                    <th key={`colHeader${date}`} scope="col" className="mealTable-col-header">
                        {getObjectValue(viewData, ["date", date]) || date}
                    </th>
                );
                //RowList filling
                currentProProductId = getObjectValue(repClientMenuDate, [date, "pro_product_id"]);
                currentRepMenuId = getObjectValue(repClientMenuDate, [date, "rep_menu_id"]);
                jsxRow = getObjectValue(rowList, ["par_status_treatment_id", "jsx"]);
                if (jsxRow) {
                    jsxRow.push(
                        <td key={`cellParStatus${date}`} className="mealTable-cell">
                            <ParStatusTreatmentCell
                                data={Object.assign({ date: date }, repClientMenuDate[date] || {})}
                                statusList={{
                                    selected: getObjectValue(repClientMenuDate, [date, "par_status_treatment_id"]),
                                    list: getObjectValue(viewData, ["par_status_treatment_id"]) || {},
                                    field: "par_status_treatment_id",
                                }}
                                onChange={onChangeCell}
                                repClientMenuDate={cloneDeep(repClientMenuDate)}
                            />
                        </td>
                    );
                }
                jsxRow = getObjectValue(rowList, ["pro_product_id", "jsx"]);
                if (jsxRow) {
                    jsxRow.push(
                        <td key={`cellProProduct${date}`} className="mealTable-cell">
                            <ComboCell
                                data={Object.assign({ date: date }, repClientMenuDate[date] || {})}
                                comboList={{
                                    selected: getObjectValue(repClientMenuDate, [date, "pro_product_id"]),
                                    list: getObjectValue(viewData, ["collection", date]) || {},
                                    field: "pro_product_id",
                                }}
                                onChange={onChangeCell}
                                repCliMenuDate={cloneDeep(repClientMenuDate)}
                            />
                        </td>
                    );
                }
                jsxRow = getObjectValue(rowList, ["rep_menu_id", "jsx"]);
                if (jsxRow) {
                    jsxRow.push(
                        <td key={`cellRepMenu${date}`} className="mealTable-cell">
                            <ComboCell
                                data={Object.assign({ date: date }, repClientMenuDate[date] || {})}
                                comboList={{
                                    selected: getObjectValue(repClientMenuDate, [date, "rep_menu_id"]),
                                    list: getObjectValue(viewData, ["collection", date, currentProProductId, "rep_menu"]) || {},
                                    field: "rep_menu_id",
                                }}
                                onChange={onChangeCell}
                                repCliMenuDate={cloneDeep(repClientMenuDate)}
                            />
                        </td>
                    );
                }
                jsxRow = getObjectValue(rowList, ["address_container", "jsx"]);
                if (jsxRow) {
                    jsxRow.push(
                        <td key={`cellAddress${date}`} className="mealTable-cell">
                            <div className="mealTable-cell-content address">
                                {getObjectValue(repClientMenuDate, [date, "address_container", "formatted_address"])}
                            </div>
                        </td>
                    );
                }
                for (let menuId in dynamicRowList) {
                    jsxRow = getObjectValue(rowList, [menuId, "jsx"]);
                    if (jsxRow) {
                        jsxRow.push(
                            <td key={`cellRepDish${menuId}${date}`} className="mealTable-cell">
                                <DishCell
                                    data={Object.assign(
                                        {
                                            id: getObjectValue(repClientMenuDate, [date, "id"]),
                                            date: date,
                                            pro_product_id: currentProProductId,
                                            rep_menu_id: currentRepMenuId,
                                            rep_menu_quantity: getObjectValue(repClientMenuDate, [date, "rep_menu_quantity"]),
                                        },
                                        getObjectValue(repClientMenuDate, [date, "section", menuId]) || {}
                                    )}
                                    comboList={{
                                        selected: getObjectValue(repClientMenuDate, [date, "section", menuId, "rep_dish_id"]),
                                        list:
                                            getObjectValue(viewData, [
                                                "collection",
                                                date,
                                                currentProProductId,
                                                "rep_menu",
                                                currentRepMenuId,
                                                "rep_menu_section",
                                                menuId,
                                            ]) || [],
                                        field: menuId,
                                    }}
                                    statusList={{
                                        selected: getObjectValue(repClientMenuDate, [date, "section", menuId, "par_status_treatment_id"]),
                                        list: getObjectValue(viewData, ["par_status_treatment_id"]) || {},
                                        field: menuId,
                                    }}
                                    minMax={
                                        getObjectValue(viewData, [
                                            "rep_menu_section_min_max",
                                            currentProProductId,
                                            currentRepMenuId,
                                            menuId,
                                        ]) || {}
                                    }
                                    onChange={onChangeCell}
                                    repCliMenuDate={cloneDeep(repClientMenuDate)}
                                />
                            </td>
                        );
                    }
                }
            }
            //Table Building
            let rowListDisplay = [];
            let rowContentDisplay = [];
            for (let i = 0; i < orderedRowListKeys.length; i++) {
                rowContentDisplay = getObjectValue(rowList, [orderedRowListKeys[i], "jsx"]) || [];
                rowListDisplay.push(
                    <tr key={`row${orderedRowListKeys[i]}`} className="mealTable-row">
                        {rowContentDisplay}
                    </tr>
                );
            }
            return (
                <table className="mealTable">
                    <caption></caption>
                    <thead className="mealTable-head">
                        <tr className="mealTable-head-row">{headerListDisplay}</tr>
                    </thead>
                    <tbody className="mealTable-body">{rowListDisplay}</tbody>
                </table>
            );
        }
    }
    function buildSubmitSection() {
        if (!repClientMenuDateModif) {
            return "";
        }
        return (
            <ItemRow fill="stretch" minElWidth={200} maxElWidth={450} className="submitSection">
                <PortalBtn className="validate" disabled={isLoading} onClick={onSubmitModifs}>
                    Demande de modification
                </PortalBtn>
                <PortalBtn disabled={isLoading} onClick={onCancelModifs}>
                    Annuler
                </PortalBtn>
            </ItemRow>
        );
    }
    // Rendering part
    return (
        <>
            {props.setStyle("./normal")}
            {mealToolBar}
            {buildTable()}
            {buildSubmitSection()}
        </>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default ClientMeal; // A personnaliser en fonction de la vue
