import React from "react";
import { useState, useEffect } from "react";
import PortalText from "../portalText/portalText";
import TooltipWrapper from "../tooltipWrapper/tooltipWrapper";
/*
Install : - La bibliothèque font-awesome doit être intallée dans le projet
          - Les styles css associés (paragraphe portalCheck) doivent être chargés dans le projet
Configs :
{
    groupsize = NUMBER (opt) [2] => Le nombre de chiffres du numéro de téléphone à grouper ensemble
        # En France, on groupe par 2 (XX XX XX XX XX XX)
    
    separator = STRING (opt) [' '] => Le symbole séparateur entre chaque groupement de chiffres
        # En France, le séparateur est un espace XX XX XX XX XX XX)

    Tous les paramètres disponibles dans un Form.Control sauf le paramètre 'type'
        # Ce paramètre est 'tel'
    
    tooltip = STRING (opt) [''] => Le contenu du tooltip associé au champ
        # Pour afficher une brève description du contenu du champ    
    
    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input

    displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
        # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

    activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'actif'
        # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

    actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
        # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
        # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
        # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'

    //TODO: Rajouter une option 'mask' pour remplacer le 'groupsize' et 'separator' et une option 'showMask' pour afficher le format restant et une validCharlist sous regex ou liste
        # Pour un numéro français, le masque serait : 'xx xx xx xx xx'
}
*/

const PortalPhone = React.forwardRef(function (props, ref) {
    //Formating functions
    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    function filterString(rawString, validCharList, maxLength, formerString, cursorIndex) {
        //This function transforms a string into a valid phone number value without formatting
        //Ex : '04g5drz7s5  f669753 43f 43'=>'0457566975'
        rawString = rawString || "";
        formerString = formerString || "";
        maxLength = typeof maxLength !== "number" ? 10000000 : maxLength;
        if ((!validCharList && validCharList.length !== 0) || typeof validCharList.find !== "function") {
            return {
                filteredString: rawString,
                cursorIndex: cursorIndex,
            };
        }
        let newCursorIndex = typeof cursorIndex === "number" ? cursorIndex : rawString.length;
        let rawFilteredString = "";
        let foundChar;
        for (let i = 0; i < rawString.length; i++) {
            foundChar = validCharList.find(function (validChar) {
                return rawString[i] === validChar;
            });
            if (foundChar) {
                rawFilteredString += rawString[i];
            } else if (i < cursorIndex) {
                newCursorIndex--;
            }
        }

        if (
            rawFilteredString.length > formerString.length &&
            rawFilteredString.length > maxLength &&
            newCursorIndex < rawFilteredString.length &&
            newCursorIndex < formerString.length
        ) {
            // We are in this case => Former Number : 0145789365 => RawFilteredString : 011227|cursor|4578|maxLength|9365 => Final number : 0112279365 and not 0112274578
            rawFilteredString =
                rawFilteredString.slice(0, newCursorIndex) +
                rawFilteredString.slice(newCursorIndex + rawFilteredString.length - formerString.length);
        }
        return {
            filteredString: rawFilteredString.slice(0, maxLength),
            cursorIndex: newCursorIndex < maxLength ? newCursorIndex : maxLength,
        };
    }
    function formatString(string, fGroupSize, separator, cursorIndex) {
        string = string || "";
        fGroupSize = typeof fGroupSize !== "number" ? 2 : fGroupSize;
        separator = separator || " ";
        cursorIndex = typeof cursorIndex !== "number" ? string.length : cursorIndex;
        fGroupSize = fGroupSize || 2;
        separator = separator || " ";
        const groupedCharList = [],
            groupList = [];
        const groupCount = Math.ceil(string.length / fGroupSize);
        for (let i = 0; i < fGroupSize; i++) {
            groupList.push("");
        }
        for (let i = 0; i < groupCount; i++) {
            groupedCharList.push(groupList);
        }
        let finalCursorIndex = cursorIndex;
        //console.log("Former cursor index : ", cursorIndex);
        let charIndex = 0;
        let formattedString = "";
        for (let groupIndex = 0; groupIndex < groupedCharList.length; groupIndex++) {
            for (let groupCharIndex = 0; groupCharIndex < groupedCharList[groupIndex].length; groupCharIndex++) {
                formattedString += string[charIndex] || "";
                charIndex++;
            }
            if (groupIndex !== groupedCharList.length - 1) {
                //We don't add separator at the end of the string, only between 2 groups
                formattedString += separator;
                if (charIndex < cursorIndex) finalCursorIndex += separator.length; //We don't move cursor when separators are added after the initial position
            }
        }
        //console.log("New cursor Index : ", finalCursorIndex);
        return { formattedString: formattedString, cursorIndex: finalCursorIndex };
    }
    //Init
    const groupSize = typeof props.groupSize !== "number" ? 2 : props.groupSize;
    const separator = props.separator || " ";
    const validCharList = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const defaultValue = props.defaultValue ? filterString(props.defaultValue, validCharList, 10, "", null)["filteredString"] : "";
    const parentValue = props.value;
    const onChange = props.onChange;
    const [hasChanged, setHasChanged] = useState(false);
    const [componentInit, setComponentInit] = useState(true);
    const [value, setValue] = useState(
        parentValue ? filterString(parentValue, validCharList, 10, "", null)["filteredString"] : defaultValue
    );
    const [displayValue, setDisplayValue] = useState(
        !hasChanged || !value
            ? formatString(defaultValue, groupSize, separator, null)["formattedString"]
            : formatString(value, groupSize, separator, null)["formattedString"]
    );
    const filteredProps = deleteProps(
        "ref",
        "tooltip",
        "tooltipAlign",
        "type",
        "value",
        "defaultValue",
        "onChange",
        "groupSize",
        "separator"
    );

    //Handler functions
    function onValueChange(e) {
        setHasChanged(true);
        let cursorIndex = e.target.selectionEnd || 0;
        const filteredResult = filterString(e.target.value, validCharList, 10, value, cursorIndex);
        const newValue = filteredResult["filteredString"];
        const newDisplay = formatString(newValue, groupSize, separator, filteredResult["cursorIndex"]);
        if (typeof onChange === "function" && value !== newValue) onChange(e, newValue);
        setValue(newValue);
        setDisplayValue(newDisplay["formattedString"]);
        setTimeout(function () {
            //The selection range has to be set after the value update
            e.target.setSelectionRange(newDisplay["cursorIndex"], newDisplay["cursorIndex"]);
        }, 0);
    }
    useEffect(
        function () {
            if (!componentInit) {
                //To prevent effect triggering during component init
                onValueChange({
                    target: {
                        value: parentValue,
                        setSelectionRange: function () {},
                    },
                });
            }
        },
        [parentValue] //To trigger field change when higher state value changes
    );
    useEffect(function () {
        setComponentInit(false);
    }, []);
    //Pre-rendering treatments
    return (
        <TooltipWrapper tooltip={props?.tooltip} tooltipAlign={props?.tooltipAlign}>
            <PortalText ref={ref} type="tel" {...filteredProps} value={displayValue} onChange={onValueChange} />
        </TooltipWrapper>
    );
});
export default PortalPhone;
