import React from "react";
import { useEffect } from "react";
//Custom Components
export default function Screen(props) {
    const viewHtml = props.store.viewContent;
    const viewTitle = props.store.viewTitle;

    useEffect(function () {
        props.setViewLayoutData(["title"], [viewTitle]);
    }, []);

    //Rendering part
    function renderContent() {
        switch (true) {
            case !viewHtml:
                return <h5>AUCUNE DONNEE RECUE ...</h5>;
            default:
                return <div dangerouslySetInnerHTML={{ __html: viewHtml }}></div>;
        }
    }
    return (
        <>
            {props.setStyle("./v_yvelines")}
            <div className="showcaseContainer">{renderContent()}</div>
        </>
    );
}
