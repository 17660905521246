//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//External libraries
import deepClone from "clone-deep";
//React librarie
import React from "react";
import { useState, useEffect } from "react";
//Custom Library
import { isArray } from "../../../../../ressources/lib/js/globalFunctions.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
//Custom UI Components
import WarningBlock from "../../../../../ressources/components/warningBlock/warningBlock.js";
import BtnToolTip from "../../../../../ressources/components/btnToolTip/btnToolTip.js";
import NavBarV2 from "./components/navBar/navBar_v2.js";
import ArianWire from "./components/arianWire/arianWire.js";
import Footer from "../../../components/footer/footer.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//

export default function Screen(props) {
    //States
    const [menuStateLeft, setMenuStateLeft] = useState("");
    const [menuStateRight, setMenuStateRight] = useState("");
    //Refs

    //Effects treatments
    useEffect(function () {
        //console.log("layout instanciated");
    }, []);
    //View global functions
    function buildDxMenu(menu) {
        //TODO: Simplifier cette fonction => 'home_views' est maintenant inutile
        if (!menu) {
            return { menu_left: [], menu_right: [], home_views: [] };
        }
        // return a deeply cloned object validated
        function buildHomeConfig(rawHome) {
            rawHome = rawHome || [];
            let homeConfig = deepClone(rawHome);
            homeConfig.map(function (homeItem) {
                homeItem.title = props.i18n("label.view." + homeItem.direction);
                homeItem.description = props.i18n("info.view." + homeItem.direction);
                homeItem.icon = props.i18n("icon.view." + homeItem.direction + "_icon");
                return null;
            });
            return homeConfig;
        }
        let leftTreeConfig = deepClone(menu.menu_left);
        let rightTreeConfig = deepClone(menu.menu_right);
        let homeViewsConfig = {
            home: buildHomeConfig(menu.home_view),
        };
        let menuConfig = {};
        function buildTreeStage(rawTreeConfig, homeConfig, depth, branchName) {
            //format: [{},{}...] => Will modify the entry input and return the input in the right format
            depth = typeof depth === "number" ? depth : 0;
            branchName = branchName || "";
            if (isArray(rawTreeConfig)) {
                rawTreeConfig.forEach(function (rawTreeItem, index) {
                    rawTreeItem.id = depth > 0 ? `${depth + 1}_${index + 1}` : `${index + 1}`;
                    rawTreeItem.text = props.i18n("label.view." + rawTreeItem.direction);
                    rawTreeItem.icon = props.i18n("icon.view." + rawTreeItem.direction + "_icon");
                    rawTreeItem.selected = props.viewHash === rawTreeItem.direction;
                    if (rawTreeItem.items) {
                        // There's a deeper config level inside this menu AND this item is a branch
                        rawTreeItem.expanded = true;
                        //rawTreeItem.selected = false;//It was to prevent branch menu items to have a selected state
                        buildTreeStage(rawTreeItem.items, homeConfig, depth + index, rawTreeItem.direction);
                    } else if (branchName) {
                        //This item is a feather inside a branch (not at the root level)
                        //we fill the homeConfig with its information
                        if (!homeConfig[branchName]) {
                            homeConfig[branchName] = [];
                        }
                        homeConfig[branchName].push({
                            title: props.i18n("label.view." + rawTreeItem.direction),
                            direction: rawTreeItem.direction,
                            description: props.i18n("info.view." + rawTreeItem.direction),
                            icon: props.i18n("icon.view." + rawTreeItem.direction + "_icon"),
                        });
                    }
                });
            } else {
                console.error("buildTreeStageError - Wrong data : expected an Array and got ", rawTreeConfig);
            }
            return { treeConfig: rawTreeConfig, homeConfig: homeConfig };
        }
        menuConfig.menu_left = buildTreeStage(leftTreeConfig, homeViewsConfig, 0)["treeConfig"];
        let lastResult = buildTreeStage(rightTreeConfig, homeViewsConfig, 0);
        menuConfig.menu_right = lastResult.treeConfig;
        menuConfig.home_views = lastResult.homeConfig;
        //console.log("Treated Menu :", menuConfig);
        return menuConfig;
    }
    //State managing functions
    function closeAllMenu() {
        handleMenuStateLeft("close");
        handleMenuStateRight("close");
    }
    //UI event handlers
    function handleMenuStateLeft(action) {
        if (menuStateRight) {
            handleMenuStateRight("close");
        }
        switch (action) {
            case "open":
                setMenuStateLeft(" open");
                break;
            case "close":
                setMenuStateLeft("");
                break;
            case "toggle":
            default:
                if (!menuStateLeft) {
                    setMenuStateLeft(" open");
                } else {
                    setMenuStateLeft("");
                }
                break;
        }
    }
    function handleMenuStateRight(action) {
        if (menuStateLeft) {
            handleMenuStateLeft("close");
        }
        switch (action) {
            case "open":
                setMenuStateRight(" open");
                break;
            case "close":
                setMenuStateRight("");
                break;
            case "toggle":
            default:
                if (!menuStateRight) {
                    setMenuStateRight(" open");
                } else {
                    setMenuStateRight("");
                }
                break;
        }
    }
    function onClickContentView(event) {
        closeAllMenu();
    }
    // Pre-rendering treatments
    // warningBlocks display
    let warningBlockTop, warningBlockBottom;

    //Help display
    let helpBtn = "",
        btnText = "Info";
    if (props.helpContent && props.helpContent.length > 0) {
        if (props.helpDisplay) {
            warningBlockTop = (
                <WarningBlock
                    {...props}
                    adaptive={false}
                    className="top"
                    destroy={function () {
                        props.showHelp(false);
                    }}
                    content={props.helpContent}
                    importance={props.helpImportance}
                />
            );
        }
        helpBtn = (
            <BtnToolTip
                className="helpBtn"
                iconCls={`fa${props.helpDisplay ? "r" : "s"} fa-lightbulb`}
                onClick={() => props.showHelp(!props.helpDisplay)}
            >
                {btnText}
            </BtnToolTip>
        );
    }
    // Rendering part
    return (
        <>
            {props.setStyle("./private/base")}
            <div className="layoutContainer">
                <NavBarV2
                    {...props}
                    handleMenuStateLeft={handleMenuStateLeft}
                    handleMenuStateRight={handleMenuStateRight}
                    closeAllMenu={closeAllMenu}
                    menuConfig={buildDxMenu(props.menu)}
                    menuStateLeft={menuStateLeft}
                    menuStateRight={menuStateRight}
                />
                <div className="arianBlock" tabIndex={1000} onClick={onClickContentView}>
                    <div className="backContainer">
                        <BtnToolTip iconCls="fas fa-home" onClick={() => props.redirectTo("home")}></BtnToolTip>
                    </div>
                    <ArianWire {...props} />
                    {helpBtn}
                </div>
                <div id="layoutViewContainer" className="viewContainer layoutViewContainer" tabIndex={1001} onClick={onClickContentView}>
                    <div className="viewBlock">
                        {warningBlockTop}
                        <div className="layoutViewContent">{props.children}</div>
                        {warningBlockBottom}
                        <Footer {...props} />
                    </div>
                </div>
            </div>
        </>
    );
}
//###########################################################################################//
