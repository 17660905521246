//######################################## Imports ########################################//
/**********Logic Components and modules**********/
//External libraries
//React Hooks
import React from "react";
import { useState, useEffect } from "react";

//Custom libraries
import { isObject, isArray, editFormState } from "../ressources/lib/js/globalFunctions.js";
//React Redux Hooks (https://react-redux.js.org/) => Global store API
import { useSelector, useDispatch } from "react-redux";
//DevExtreme French language
import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";

//React Router Components (https://reactrouter.com/web/guides/quick-start) => Navigation API
import { Switch, Route, useHistory } from "react-router-dom";
//e
//Project modules
//Reducers and Selectors for asaStore connexion
import {
    selectPublicHash,
    selectAppTheme,
    selectAppName,
    selectStatisticsUrl,
    selectStatisticsId,
    selectPublicHomeUrl,
    selectDefaultView,
    selectI18n,
    selectShowcaseList,
    selectAppContext,
    selectDynamicFooter,
} from "./reducers/public/public.js";
import {
    changeSelectedClient,
    setLoginData,
    resetPrivateData,
    selectPrivateHash,
    selectSSoDisconnect,
    selectLoggedUser,
    selectSelectedClient,
    selectAttachedClient,
    selectMenu,
} from "./reducers/private/private.js";

/************************************************/

/**********UI Components**********/
//Project components
import { getViewComponent, getViewDirectory } from "../viewDictionnary.js";
import NotFound from "./views/public/notFound.js";
import PublicLayout from "./layouts/public/main.js";
import PrivateLayout from "./layouts/private/main.js";
import MsgBox from "../ressources/components/msgBox/msgBox";
import InitView from "../ressources/components/initView/initView.js";
/*********************************/
//#########################################################################################//

//######################################## Component definition ########################################//
function App(props) {
    /********** Variables definition **********/
    //Redux bound state variables
    const appTheme = useSelector(selectAppTheme);
    const appName = useSelector(selectAppName);
    const statisticsUrl = useSelector(selectStatisticsUrl);
    const statisticsId = useSelector(selectStatisticsId);
    const publicViewList = useSelector(selectPublicHash);
    const publicHomeUrl = useSelector(selectPublicHomeUrl);
    const privateViewList = useSelector(selectPrivateHash);
    const defaultView = useSelector(selectDefaultView);
    const i18n = useSelector(selectI18n);
    const ssoDisconnect = useSelector(selectSSoDisconnect);
    const showcaseList = useSelector(selectShowcaseList);
    const loggedUser = useSelector(selectLoggedUser);
    const selectedClient = useSelector(selectSelectedClient);
    const attachedClient = useSelector(selectAttachedClient);
    const menu = useSelector(selectMenu); //Object already cloned from the store
    const footerContent = useSelector(selectDynamicFooter);
    const appContext = useSelector(selectAppContext);

    //Redux dispatcher
    const dispatch = useDispatch();

    //React-router hooks
    const history = useHistory();
    //Local state variables
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [viewHash, setViewHash] = useState(window.location.hash.slice(2));
    const [viewGroup, setViewGroup] = useState("");
    const [viewInfo, _setViewInfo] = useState("");
    function setViewInfo(htmlContent) {
        setTimeout(function () {
            //TO let App clean previous viewInfo before another view can set the new one
            _setViewInfo(htmlContent);
        }, 0);
    }
    const [msgBoxData, setMsgBoxData] = useState([]);
    const [isRouting, setIsRouting] = useState(true);

    const [userJourney, setUserJourney] = useState([]);
    const [helpContent, setHelpContent] = useState("");
    const [helpImportance, setHelpImportance] = useState("info");
    const [helpDisplay, setHelpDisplay] = useState(false);
    const initViewLayoutData = {
        title: "",
        disclaimer: "",
    };
    const [viewLayoutData, _setViewLayoutData] = useState(initViewLayoutData);
    function setViewLayoutData(field, value) {
        setTimeout(function () {
            //TO let App clean previous viewLayoutData before another view can set the new one
            editFormState(field, value, viewLayoutData, _setViewLayoutData);
        }, 0);
    }
    //Other local static variables

    /******************************************/
    /********** eventHandler functions **********/
    //UI events
    function redirectTo(hash, context, replace) {
        if (context) {
            context = typeof context === "object" && !context.length ? context : {};
            let paramString = Object.keys(context).length > 0 ? "?" : "";
            for (let key in context) {
                paramString += `${key}=${context[key]}&`;
            }
            hash += paramString;
        }
        if (!replace) {
            history.push(hash);
        } else {
            history.replace(hash);
        }
    }
    function openExternal(input) {
        const errorTpl = "ERROR - function openExternal - ";
        const defaultInput = {
            url: "", //L'URL d'ouverture
            sameWindow: false, //Pour ouvrir sur le même onglet
            force: false, //Pour forcer l'ouverture sans l'affichage d'une popup de confirmation
            msg: "", //Le contenu du message de confirmation
            msgBoxConfig: {
                content: i18nSelector("info.action.open_external", [appName]),
                level: "info",
                type: "confirm",
                title: i18nSelector("label.action.open_external"),
                btnConfirmTxt: i18nSelector("label.action.accept_open_external"),
                btnRefuseTxt: i18nSelector("label.action.refuse_open_external"),
                className: "",
            },
        };
        //Input default setting
        if (!input || typeof input !== "object") input = {};
        if (!input.url) {
            console.error(errorTpl + "No url specified");
            return Promise.reject();
        }
        if (!input.msgBoxConfig || typeof input.msgBoxConfig !== "object") input.msgBoxConfig = {};
        input.msgBoxConfig = {
            content: input.msgBoxConfig.content || defaultInput.msgBoxConfig.content,
            level: input.msgBoxConfig.level || defaultInput.msgBoxConfig.level,
            type: "confirm",
            title: input.msgBoxConfig.title || defaultInput.msgBoxConfig.title,
            btnConfirmTxt: input.msgBoxConfig.btnConfirmTxt || defaultInput.msgBoxConfig.btnConfirmTxt,
            btnRefuseTxt: input.msgBoxConfig.btnRefuseTxt || defaultInput.msgBoxConfig.btnRefuseTxt,
            className: input.msgBoxConfig.className || defaultInput.msgBoxConfig.className,
        };
        //Functionnal treatments
        function goTo() {
            if (!!input.sameWindow) {
                window.location.href = input.url;
            } else {
                setTimeout(function () {
                    //To patch a React bug which crashes (conflicts with states update) when a blocking function is executed
                    window.open(input.url);
                }, 0);
            }
        }
        if (!!input.force) {
            goTo();
            return Promise.resolve();
        }
        return msgBox(input.msgBoxConfig)
            .then(function () {
                goTo();
                return Promise.resolve();
            })
            .catch(function () {
                return Promise.reject();
            });
    }
    function getViewHash() {
        //This hash is set with queryString inside
        return window.location.hash.slice(2);
    }
    function getViewContext() {
        const searchStr = history.location.search.slice(1);
        const searchParams = {};
        searchStr.split("&").forEach(function (paramStr) {
            let paramCouple = paramStr.split("=");
            if (paramCouple[0]) searchParams[decodeURIComponent(paramCouple[0])] = decodeURIComponent(paramCouple[1]);
        });
        //console.log(searchParams);
        return searchParams;
    }
    function getViewType(hash) {
        //Return 'public' || 'private' || undefined
        let isPublic = !!publicViewList.filter(function (publicView) {
            return publicView === hash.split("?")[0]; //To clean query strings potentially attached
        })[0];
        let isPrivate = !!privateViewList.filter(function (privateEl) {
            return privateEl.name === hash.split("?")[0]; //To clean query strings potentially attached
        })[0];
        return isPublic ? "public" : isPrivate ? "private" : undefined;
    }
    function getViewGroup(hash) {
        if (!hash) return "";
        const localMenu = menu || {};
        const menuToSearch = localMenu.menu_home;
        if (typeof menuToSearch !== "object") return "";
        let groupName = "";
        let hashIsInside = false;
        let menuGroupContent = [];
        Object.keys(menuToSearch).forEach(function (menuGroupName) {
            if (menuGroupName !== "home") {
                menuGroupContent = menuToSearch[menuGroupName];
                if (isArray(menuGroupContent)) {
                    hashIsInside = !!menuGroupContent.filter(function (subMenuItem) {
                        return subMenuItem?.direction === hash;
                    })[0];
                    if (hashIsInside) groupName = menuGroupName;
                }
            }
        });
        return groupName;
    }
    function getViewDisclaimer(hash) {
        const i18nCode = "disclaimer.view." + hash;
        const disclaimer = i18nSelector(i18nCode);
        if (i18nCode === disclaimer) return "";
        return disclaimer;
    }
    function onHashChange() {
        //Pour fermer le menu du layout
        document.activeElement.blur();
        //Pour remettre le scroller du layout en haut
        scrollLayout(0);
        //Pour le fil d'ariane dans les vues
        const userJourney = ["home"];
        if (viewHash !== "home") userJourney.push(viewHash);
        setUserJourney(userJourney);
        //Pour resetter l'aide remplie dans le getData
        resetHelp();
        _setViewLayoutData(initViewLayoutData);
        setViewGroup(getViewGroup(viewHash));
        _setViewInfo("");
    }
    function onResize() {
        //let htmlTag = document.getElementsByTagName("html")[0];
        let deviceHeight = window.innerHeight;
        document.documentElement.style.setProperty("--vh", `${deviceHeight}px`);
        //htmlTag.setAttribute("style",`height:${deviceHeight}px`); // Patch pour régler le problème du "height: 100vh" sur le html avec les navigateurs des appareils mobiles qui utilisent webkit (Safari/iOS - Chrome/android)
        setScreenWidth(function (prevActiveWidth) {
            //Optimization for better fluidity (The width update each 10px)
            if (Math.abs(prevActiveWidth - window.innerWidth) > 9) {
                return window.innerWidth;
            } else {
                return prevActiveWidth;
            }
        });
    }
    function scrollLayout(y) {
        y = +y;
        console.log("scrollLayout");
        const layoutScrollerComponent = document.getElementById("layoutViewContainer");
        if (layoutScrollerComponent) {
            layoutScrollerComponent.scrollTop = y;
        }
    }
    function returnHome() {
        if (!!loggedUser && loggedUser.ent_entity_id) {
            history.push("home");
        } else if (!!publicHomeUrl) {
            //window.location.href = publicHomeUrl;
            window.open(publicHomeUrl, "__blank"); //On ouvre toujours les liens externes sur un nouvel onglet
        } else {
            history.push("login");
        }
        return;
    }
    function logout() {
        //Backend deconnection
        document.Wrapper.request({
            type: "ajax",
            module: "view",
            view: "login",
            action: "post_logout",
            data: "",
        })
            .then(function (response) {
                //We don't do anything
            })
            .catch(function (error) {
                console.error(`Erreur déconnexion serveur :\n${error.msg}\n`, error);
            });
        //Frontend deconnection
        document.Wrapper.request({
            type: "sessionStorage",
            module: "session",
            view: "all",
            action: "clear_session",
            data: "",
        })
            .then(function (response) {
                if (ssoDisconnect) {
                    //On redirige vers la page de déconnexion france_connect
                    window.location.href = ssoDisconnect;
                } else {
                    //On retourne sur le login et on clean le store privé
                    redirectTo("login");
                    dispatch(resetPrivateData()); //{action: 'private/resetPrivateData', payload:'toto'}
                }
            })
            .catch(function (error) {
                redirectTo("login");
                dispatch(resetPrivateData());
                console.error(error.msg);
            });
    }
    function removeQueryString(hash) {
        const queryStartIndex = hash.indexOf("?");
        if (queryStartIndex < 0) return hash;
        return hash.slice(0, queryStartIndex);
    }
    function i18nSelector(pathCode, replacementList) {
        pathCode = typeof pathCode === "string" ? pathCode : "";
        const pathList = pathCode.split(".");
        const viewName = removeQueryString(getViewHash()); //We can't use 'viewHash' state because it's not updated synchronously
        // const rootCode = pathList[pathList.length - 1];
        replacementList = typeof replacementList === "object" && replacementList.length ? replacementList : [];
        const i18nDictionnary = i18n;
        if (!i18nDictionnary) {
            console.error("i18n not found");
            return pathCode;
        }
        // function isIconCode() {
        //     return rootCode.search("_icon") > -1;
        // }
        function findI18nStr(pathList, dictionnary) {
            if (typeof dictionnary[pathList[0]] === "object") {
                //We can follow the search deeper
                let newDictionnary = dictionnary[pathList[0]];
                pathList.shift();
                return findI18nStr(pathList, newDictionnary);
            } else {
                //We're at the end of the tree
                let foundStr = dictionnary[pathList[0]];
                if (!dictionnary.hasOwnProperty(pathList[0])) return pathCode;
                return foundStr;
            }
        }
        let foundStr = findI18nStr([viewName].concat(pathList), i18nDictionnary);
        /*console.log(foundStr);
        console.log(viewName);*/
        if (foundStr === pathCode) foundStr = findI18nStr(["global"].concat(pathList), i18nDictionnary);
        if (foundStr === pathCode) foundStr = findI18nStr(["root"].concat(pathList), i18nDictionnary);
        replacementList.forEach(function (replacement, index) {
            foundStr = foundStr.replaceAll("${" + index + "}", replacement);
        });
        return foundStr;
    }
    function msgBox(content, level, type, title) {
        /*
            Fonction : Permet d'afficher une boîte de dialogue à l'utilisateur
                # A utiliser à la place des "alert" dans les vues

            content: STRING / 'HTML_STRING' / JSX_CONTENT (opt) [''] => Le contenu du message de la boîte de dialogue

            level: STRING (opt) ['info'] => Définit le niveau d'importance de la boîte de dialogue
                # Niveaux possibles : 'info', 'warning', 'error'
                # Ça change juste l'apparence de la boîte

            type: STRING (opt) ['msg'] => Définit le type d'interaction de la boîte de dialogue
                # Types possibles : 'msg', 'confirm', 'process'
                # Type 'msg' : Affichage d'un message avec le niveau souhaité et d'un bouton d'acceptation pour fermer la boîte de dialogue
                    - Dans ce cas, la Promesse retournée par la fonction se résout toujours

                # Type 'confirm' : Affichage d'un message avec le niveau souhaité et de 2 boutons pour confirmer ou non ce qui est demandé
                    - Dans ce cas, la Promesse retournée par la fonction se résout quand l'utilisateur accepte et se rejette quand l'utilisateur refuse

                # Type 'process' : Affichage d'une suite de messages espacés dans le temps commandés par une instance pilotable de la boîte de dialogue
                    - Dans ce cas la Promesse retournée par la fonction est déjà résolue et l'instance pour piloter la boîte de dialogue se trouve dans la callback du 'then'

            title : STRING / JSX (opt) ['${levelDefault}'] => Permet d'afficher un header en haut de la boîte de dialogue
                # Si on ne met rien, un titre par défaut s'affiche en fonction du niveau d'importance ('level') de la boîte de dialogue

            Return :
                CAS type = 'msg' OU 'confirm'
                    # Une Promesse qui se résout quand l'utilisateur a validé le message et qui se rejette quand l'utilisateur a refusé le message

                CAS type = 'process'
                    # Une Promesse déjà résolue avec l'instance de pilotage de la boîte de dialogue dans la callback du 'then'
                    # boxInstance => {
                         set : CALLBACK FUNCTION(param)
                            # Callback permettant de modifier les informations de la boîte de dialogue de manière asynchrone
                            # param : {
                                content : Nouveau message dans la boîte de dialogue
                                title : Nouveau titre de la boîte de dialogue
                                level : Nouveau niveau de la boîte de dialogue
                            }
                        ,close : CALLBACK FUNCTION()
                            # Callback permettant de fermer l'instance de la boîte de dialogue en cours
                    }
                    # !!! Ne pas oublier de FERMER la box à la fin des processus (boxInstance.close()) !!!

            EXEMPLES :
                type 'msg' => msgBox('Contenu', 'warning', 'msg', 'TITRE !')
                .then(function(){console.log("User has closed the box !")})
                .catch();

                type 'confirm' => msgBox('Are you Okay ?', 'info', 'confirm', 'TITRE !')
                    .then(function(){console.log("User has accepted and closed the box !")})
                    .catch(function(){console.log("User has denied and closed the box !")});

                type 'process' => msgBox('Premier msg du process...', 'info', 'process', 'TITRE 1 !')
                    .then(function(boxInstance){
                        setTimeout(() => boxInstance.set({ content: Second msg du process..." }), 3000);
                        setTimeout(() => boxInstance.set({ content: "Processus terminé !" }), 5000);
                        setTimeout(() => boxInstance.close(), 7000);
                    })
                    .catch();
        */
        const input = isObject(content) && !content.$$typeof ? content : {}; //To prevent React elements to be taken as input objects
        const newBoxContent = {
            title: input.title || title,
            type: input.type || type,
            level: input.level || level,
            content: input.content || content,
            className: input.className,
            btnConfirmTxt: input.btnConfirmTxt,
            btnRefuseTxt: input.btnRefuseTxt,
            resolve: null,
            reject: null,
        };
        let promise = new Promise(function (rs, rj) {
            newBoxContent.resolve = rs;
            newBoxContent.reject = rj;
        });
        function closeBox() {
            //Pour fermer l'instance msgBox courante => on supprime le message correspondant dans la liste
            setMsgBoxData(function (formerArray) {
                let newBoxData = [...formerArray];
                newBoxData.shift();
                return newBoxData;
            });
        }
        if (type === "process") {
            function setBoxState(newBoxState) {
                newBoxState = newBoxState || {};
                setMsgBoxData(function (formerArray) {
                    let newBoxData = [...formerArray];
                    newBoxData[0] = {
                        title: newBoxState.title || input.title || title,
                        type: input.type || type,
                        level: newBoxState.level || input.level || level,
                        content: newBoxState.content || input.content || content,
                        className: newBoxState.className || input.className,
                        btnConfirmTxt: input.btnConfirmTxt,
                        btnRefuseTxt: input.btnRefuseTxt,
                        resolve: null,
                        reject: null,
                    };
                    return newBoxData;
                });
            }
            const boxInstance = { set: setBoxState, close: closeBox };
            promise.then(function (boxInstance) {}).catch(function (error) {});
            newBoxContent.resolve(boxInstance);
        } else {
            promise
                .then(function (res) {})
                .catch(function (error) {})
                .finally(function () {
                    closeBox();
                });
        }
        //Dès qu'on a créé la promesse, on ajoute le message correspondant dans la liste
        setMsgBoxData((formerArray) => [...formerArray, newBoxContent]);
        return promise;
    }
    function isConnected() {
        return !!loggedUser.adm_user_id;
    }
    //Effects events
    function manageAppRouting() {
        function checkSpecialModeAttempt() {
            //Le format des params doit être comme ceci : dns/#/?type=<typeList[i]>&<other_param>...
            const typeList = ["france_connect", "confirm_new_username", "test", "psc_connect"];
            const searchStr = history.location.search;
            const firstParamStr = searchStr.slice(1).split("&")[0];
            if (firstParamStr) {
                let paramCouple = firstParamStr.split("=");
                if (paramCouple[0] === "type") {
                    let specialMode = typeList.find((specModItem) => specModItem === paramCouple[1]);
                    if (!specialMode) return null;
                    return { type: specialMode, token_string: searchStr };
                }
            }
            return null;
        }
        function autoLoginRouting(data) {
            //Pour avoir la vue login en fond au cas où on affiche un message d'erreur
            document.Wrapper.request({
                type: "ajax",
                module: "view",
                view: "login",
                action: "post_auto_login",
                data: data,
            })
                .then(function (newSession) {
                    // La connexion a réussie
                    document.Wrapper.request({
                        type: "sessionStorage",
                        module: "session",
                        view: "all",
                        action: "set_session",
                        data: newSession.data,
                    })
                        .then(function (response) {})
                        .catch(function (error) {
                            // L'enregistrement de la nouvelle session dans le sessionStorage a échoué => On poursuit sans refresh pour ne pas perdre les données (L'URL restera "sale")
                            console.error("FAILED SAVING SESSION AFTER AUTO LOGIN");
                            console.error(error);
                        })
                        .finally(function () {
                            dispatch(setLoginData(newSession.data)); // On enregistre la session dans le store React
                            history.location.search = "";
                            redirectTo(newSession.data.default_view || "home", null, true);
                            setIsRouting(false);
                        });
                })
                .catch(function (error) {
                    //La connexion a échouée => On nettoie la zone de query de l'URL et on redirige vers la vue par défaut
                    //Peut-être qu'il faut clean le sessionStorage par acqis de consciense
                    console.error(error);
                    history.location.search = "";
                    //On force la déconnexion (en particulier la déconnexion franceConnect)
                    logout(); //Background async
                    let errorMsg = i18nSelector("error.autologin_" + (data?.type || "unknown"));
                    msgBox("AUTO_LOGIN_FAIL : " + errorMsg, "error", "msg").then(function (res) {
                        if (!!error.data && !!error?.data?.sso_disconnect) {
                            window.location.href = error.data.sso_disconnect;
                        } else {
                            redirectTo(defaultView || "login", null, true);
                        }
                        setIsRouting(false);
                    });
                });
        }
        function classicRouting() {
            document.Wrapper.request({
                type: "sessionStorage",
                module: "session",
                view: "all",
                action: "get_session",
                data: "",
            })
                .then(function (response) {
                    // Si rien en session => On redirige vers la vue publique par défaut (généralement le login)
                    if (!response.data) {
                        //Pas de données en cache
                        if (viewHash && viewHash[0] !== "?") {
                            redirectTo(viewHash, null, true);
                        } else {
                            redirectTo(defaultView || "login", null, true);
                        }
                    } else {
                        // Si on a une session active => On redirige soit sur le hash initialement présent dans l'url (cas d'un refresh), ou sinon sur la vue privée par défaut
                        dispatch(setLoginData(response.data)); //Synchrone ou pas ? => En tout cas ça marche
                        if (viewHash) {
                            redirectTo(viewHash, null, true);
                        } else {
                            redirectTo(response.data.default_view || "home", null, true);
                        }
                    }
                })
                .catch(function (error) {
                    console.error(error.msg);
                    console.log("Cache system is not working");
                    redirectTo(defaultView, null, true);
                })
                .finally(function () {
                    setIsRouting(false);
                });
        }
        //On check si on cherche à faire de l'auto-login
        let specialModeData = checkSpecialModeAttempt(); //synchrone
        //console.log("auto login data : ", autoLoginData);
        if (specialModeData) {
            //On est dans un mode spécial => On check le type pour lancer la bonne séquence d'actions
            switch (true) {
                case specialModeData.type === "france_connect":
                case specialModeData.type === "psc_connect":
                    //On est en mode "autoLogin" (Essai de connexion => stockage de la session dans le sessionStorage => refresh le l'app)
                    autoLoginRouting(specialModeData);
                    break;
                default:
                    msgBox("Le mode que vous souhaitez activer n'est pas reconnu par l'application", "error", "msg").then(function (res) {
                        redirectTo(defaultView || "login", null, true);
                        setIsRouting(false);
                    });
                    break;
            }
        } else {
            //On est en mode "classique" (check de session puis redirection)
            classicRouting();
        }
    }
    function enableMatomo() {
        //https://developer.matomo.org/guides/tracking-javascript-guide
        if (!statisticsUrl || typeof statisticsUrl !== "string") return;
        if (!statisticsId) {
            console.error("Error Enabling statistics data with Matomo on " + statisticsUrl + " :\nSiteId not configured...");
            return;
        }
        console.warn("Enabling statistics data with Matomo on " + statisticsUrl + " with siteId : " + statisticsId);
        var _paq = (window._paq = window._paq || []);
        //tracker methods like "setCustomDimension" should be called before "trackPageView"
        _paq.push(["enableLinkTracking"]);
        (function () {
            var u = statisticsUrl;
            _paq.push(["setTrackerUrl", u + "matomo.php"]);
            _paq.push(["setSiteId", statisticsId]); //50 => Pour la 78
            var d = document,
                g = d.createElement("script"),
                s = d.getElementsByTagName("script")[0];
            g.async = true;
            g.src = u + (u[u.length - 1] !== "/" ? "/" : "") + "matomo.js";
            s.parentNode.insertBefore(g, s);
        })();
    }
    function trackMatomoViewConsulting(viewName) {
        if (!window._paq || !viewName) return;
        window._paq.push(["setCustomUrl", "/#/" + viewName]);
        window._paq.push(["trackPageView", viewName]);
    }
    /********************************************/

    /********** Effects treatments **********/
    //After App mounting only
    useEffect(function () {
        window.onresize = function () {
            onResize();
        };
        //To allow external React scripts to access msgBox triggering
        document.React = {};
        document.React.msgBox = msgBox;
    }, []);
    useEffect(function () {
        window.onhashchange = function () {
            const rawNewHash = getViewHash();
            const cleanNewHash = removeQueryString(rawNewHash);
            setViewHash(rawNewHash);
            trackMatomoViewConsulting(cleanNewHash);
        };
    }, []);
    //After App mounting only => When a user arrives on website (or Application)
    useEffect(function () {
        //console.error(history);
        manageAppRouting();
    }, []);
    useEffect(function () {
        // Instanciation des textes français des composants devextreme
        loadMessages(frMessages);
        locale("fr");
    }, []);
    useEffect(enableMatomo, []); //Our statistics tracking solution
    useEffect(onHashChange, [viewHash]);
    //After App mounting AND when menu AND i18n is changing (update of viewGroup with the latest data into menu AND i18n)
    useEffect(
        function () {
            setViewGroup(getViewGroup(viewHash)); //Once menu is loaded to affect viewGroup
        },
        [menu, i18n]
    );
    //State managing functions
    function updateUserJourney(newJourney) {
        //DEPRECATED => Effect above used instead
        //setUserJourney(newJourney);
    }
    function showHelp(bool) {
        bool = !!bool;
        if (helpContent && bool) {
            setHelpDisplay(true);
        } else {
            setHelpDisplay(false);
        }
    }
    function resetHelp() {
        setHelpDisplay(false);
        setHelpImportance("info");
        setHelpContent("");
    }
    //Posting functions
    function openBoxClientSession(client) {
        return document.Wrapper.request({
            type: "ajax",
            module: "view",
            view: "private",
            action: "open_box_session",
            data: client,
        });
    }
    function updateSelectedClient(client) {
        if (client.ent_entity_id !== selectedClient.ent_entity_id) {
            function changeClient() {
                redirectTo("home"); //To prevent 'view not found' when we go to a space where the view doesn't exist
                dispatch(changeSelectedClient(client));
                document.Wrapper.request({
                    type: "sessionStorage",
                    module: "session",
                    view: "all",
                    action: "update_selected_client",
                    data: client,
                })
                    .then(function (response) {})
                    .catch(function (error) {
                        console.error(error.msg);
                    });
            }
            function displayClientName() {
                return client.first_name + " " + client.last_name;
            }
            if (client.ent_entity_id !== loggedUser.ent_entity_id && false) {
                //Feature désactivée pour l'instant
                //TODO: Comment savoir quand un client a une box sur laquelle se connecter ?
                //Pour l'instant on suppose que tous les clients ont une box sauf celui qui est connecté (Intervenant)
                //Mais ça marche pas quand un bénéficiaire est tuteur d'un autre et qu'il a une box
                msgBox("Ouverture du carnet de liaison de " + displayClientName(), "info", "process", "Ouverture carnet de liaison").then(
                    function (boxInstance) {
                        openBoxClientSession(client)
                            .then(function (res) {
                                boxInstance.set({
                                    content: `Carnet de liaison de ${displayClientName()} ouvert avec succès`,
                                });
                                setTimeout(function () {
                                    boxInstance.close();
                                    changeClient();
                                }, 1000);
                            })
                            .catch(function (err) {
                                boxInstance.close();
                                msgBox("Echec lors de l'ouverture du carnet de liaison de " + displayClientName(), "error").then(() =>
                                    changeClient()
                                );
                            });
                    }
                );
            } else {
                changeClient();
            }
        }
    }
    /****************************************/

    /********** Treatments before each rendering and after each action occuring (effect/handler triggered or state variable updating) **********/

    function getPortalType() {
        const availableTypes = ["client", "pro", "employee"];
        const defaultType = "client";
        let type = appContext.portalType;
        const isAvailable = !!availableTypes.filter(function (availableType) {
            return availableType === type;
        })[0];
        return isAvailable ? type : defaultType;
    }

    function buildSetStyle(viewType, viewDirectory) {
        if (typeof viewType !== "string") viewType = "";
        if (typeof viewDirectory !== "string") viewDirectory = "";
        const viewTypePath = viewType ? "/" + viewType : "";
        const viewDirectoryPath = viewDirectory ? "/" + viewDirectory : "";
        function setStyle(path) {
            /**
             * @param path => STRING : Fin du chemin d'accès vers le fichier css dans les ressources
             *      # Le dossier racine est '/ressources/$public_private/$viewName/'
             */
            if (typeof path !== "string") path = "";
            if (path.indexOf(".") === 0) path = path.slice(1);
            if (path.indexOf("/") === 0) path = path.slice(1);
            if (path[path.length - 1] === "/") path = path.slice(0, path.length - 1);
            path = path ? "/" + path : "";
            return (
                <>
                    <link
                        key={`shared_${viewType}_${viewDirectory}`}
                        rel="stylesheet"
                        href={`ressources/_shared${viewTypePath}${viewDirectoryPath}${path}/style.css`}
                    ></link>
                    <link
                        key={`override_${viewType}_${viewDirectory}`}
                        rel="stylesheet"
                        href={`ressources/${appTheme}${viewTypePath}${viewDirectoryPath}${path}/override.css`}
                    ></link>
                </>
            );
        }
        return setStyle;
    }
    //Switch building
    function buildSwitch(defaultAppProps) {
        //Function that builds the list of hash accessibles and the different views to instanciate inside
        let viewFound,
            viewDirectory,
            ViewComponent,
            routeComponent,
            routeList = [];
        function buildRoute(input) {
            input = {
                viewName: input.viewName || "",
                viewDirectory: input.viewDirectory || "",
                viewId: input.viewId || "",
                viewComponent: input.viewComponent || "",
                viewType: input.viewType || "",
            };
            const appProps = { ...defaultAppProps }; //Breaking the reference
            function request(param) {
                //TODO : This function has to be in <PortalController />
                return new Promise(function (rs, rj) {
                    let defaultParams = {
                        type: "ajax",
                        module: "view",
                        view: input.viewName,
                        adm_view_id: input.viewId,
                        selected_client: selectedClient,
                        action: "get_data",
                        //print:'query',
                        data: {},
                        ask_notify: false,
                        notify: false,
                    };
                    if (isObject(param)) {
                        for (let key in param) {
                            defaultParams[key] = param[key];
                        }
                    }
                    Promise.resolve()
                        .then(function () {
                            if (!defaultParams.ask_notify) return Promise.resolve();
                            return msgBox(
                                "Souhaitez-vous notifier cette action dans le carnet de liaison ?",
                                "info",
                                "confirm",
                                "Notification"
                            )
                                .then(function (res) {
                                    delete defaultParams.ask_notify;
                                    defaultParams.notify = true;
                                })
                                .catch(function (err) {})
                                .finally(function () {
                                    return Promise.resolve();
                                });
                        })
                        .then(function () {
                            return document.Wrapper.request(defaultParams);
                        })
                        .then((res) => rs(res)) //TODO: Gérer l'affichage de l'aide à ce niveau (ne plus le déléguer aux contrôleurs des vues)
                        .catch(function (err) {
                            console.error(err);
                            const i18nErrCode = "error." + err.code;
                            const i18nErrMsg = i18nSelector(i18nErrCode);
                            if (i18nErrMsg !== i18nErrCode) err.msg = i18nErrMsg;
                            rj(err);
                        });
                });
            }
            appProps.viewName = input.viewName;
            appProps.viewId = input.viewId;
            appProps.request = request;
            appProps.setStyle = buildSetStyle(input.viewType, input.viewDirectory);
            if (input.viewName && input.viewComponent) {
                return (
                    <Route path={"/" + input.viewName} key={"route_" + input.viewName}>
                        <input.viewComponent {...appProps} />
                    </Route>
                );
            }
            return "";
        }
        //Public Switch building
        let publicArray = [...publicViewList];
        publicArray.push("to_build_public");
        publicArray.forEach((viewName) => {
            viewFound = getViewComponent("public", viewName);
            viewDirectory = getViewDirectory(viewName);
            ViewComponent = viewFound.component || NotFound;
            routeComponent = buildRoute({
                viewName: viewName,
                viewDirectory: viewDirectory,
                viewComponent: ViewComponent,
                viewType: "public",
            });
            if (routeComponent) routeList.push(routeComponent);
        });
        //Private Switch building
        let privateArray = [...privateViewList];
        privateArray.push({ id: "", name: "to_build_private" });
        privateArray.forEach((viewInfo) => {
            if (viewInfo.name) {
                viewFound = getViewComponent("private", viewInfo.name);
                viewDirectory = getViewDirectory(viewInfo.name);
                ViewComponent = viewFound.component || NotFound;
                routeComponent = buildRoute({
                    viewName: viewInfo.name,
                    viewDirectory: viewDirectory,
                    viewId: viewInfo.id,
                    viewComponent: ViewComponent,
                    viewType: "private",
                });
                if (routeComponent) routeList.push(routeComponent);
            }
        });
        const appProps = { ...defaultAppProps };
        appProps.viewName = "not_found";
        appProps.setStyle = buildSetStyle("public", "notFound");
        routeList.push(
            <Route key={"route_" + appProps.viewName}>
                <NotFound {...appProps} />
            </Route>
        );
        return routeList;
    }
    function renderApp() {
        const defaultAppProps = {
            appName: appName,
            appTheme: appTheme,
            appContext: appContext,
            portalType: getPortalType(), //"client" || "pro" || "employee"
            i18n: i18nSelector,
            logout: logout,
            ssoDisconnect: ssoDisconnect,
            redirectTo: redirectTo,
            openExternal: openExternal,
            screenWidth: screenWidth,
            viewHash: viewHash,
            viewGroup: viewGroup,
            viewInfo: viewInfo,
            setViewInfo: setViewInfo,
            viewDisclaimer: getViewDisclaimer(viewHash),
            viewName: "", //Commonly same as viewHash but set by the switch itself (property instanciated faster)
            i18nViewName: i18nSelector("label.view." + viewHash),
            viewId: "",
            viewType: getViewType(viewHash),
            isConnected: isConnected(),
            setStyle: function () {},
            getViewContext: getViewContext,
            msgBox: msgBox,
            menu: menu,
            scrollLayout: scrollLayout,
            helpImportance: helpImportance,
            setHelpImportance: setHelpImportance,
            helpContent: helpContent,
            setHelpContent: setHelpContent,
            showHelp: showHelp,
            helpDisplay: helpDisplay,
            setHelpDisplay: setHelpDisplay,
            viewLayoutData: viewLayoutData,
            setViewLayoutData: setViewLayoutData,
            loggedUser: loggedUser,
            selectedClient: selectedClient,
            updateSelectedClient: updateSelectedClient,
            attachedClient: attachedClient,
            userJourney: userJourney,
            updateUserJourney: updateUserJourney,
            footerContent: footerContent,
            showcaseList: showcaseList,
            request: function () {}, //TODO: Put this function inside <PortalController />
            returnHome: returnHome,
        };
        const appProps = { ...defaultAppProps }; //Breaking the reference
        //console.log(viewHash);
        //console.log(getViewType(viewHash));
        switch (true) {
            case isRouting:
                return <InitView type="loading" loadingMsg="Redirection de l'application" />;
            case isConnected() /*&& getViewType(viewHash) !== "public"*/:
                appProps.viewName = "private_layout";
                appProps.setStyle = buildSetStyle("layouts");
                return (
                    <PrivateLayout {...appProps}>
                        <Switch>{buildSwitch(defaultAppProps)}</Switch>
                    </PrivateLayout>
                );
            default:
                appProps.viewName = "public_layout";
                appProps.setStyle = buildSetStyle("layouts");
                return (
                    <PublicLayout {...appProps}>
                        <Switch>{buildSwitch(defaultAppProps)}</Switch>
                    </PublicLayout>
                );
        }
    }
    /*******************************************************************************************************************************************/

    /********** Rendering part **********/
    return (
        <>
            {appTheme === "agirc" ? <div className="coverBanner"> </div> : ""}
            <MsgBox
                level={msgBoxData.length > 0 ? msgBoxData[0]["level"] : null}
                type={msgBoxData.length > 0 ? msgBoxData[0]["type"] : null}
                title={msgBoxData.length > 0 ? msgBoxData[0]["title"] : null}
                className={msgBoxData.length > 0 ? msgBoxData[0]["className"] : null}
                btnConfirmTxt={msgBoxData.length > 0 ? msgBoxData[0]["btnConfirmTxt"] : null}
                btnRefuseTxt={msgBoxData.length > 0 ? msgBoxData[0]["btnRefuseTxt"] : null}
                resolve={msgBoxData.length > 0 ? msgBoxData[0]["resolve"] : null}
                reject={msgBoxData.length > 0 ? msgBoxData[0]["reject"] : null}
            >
                {msgBoxData.length > 0 ? msgBoxData[0]["content"] : null}
            </MsgBox>
            {renderApp()}
        </>
    );
    /************************************/
}
//######################################################################################################//

export default App;
