import React from "react";
import { useState, useEffect } from "react";
import BtnToolTip from "../btnToolTip/btnToolTip";
import InitView from "../initView/initView";
import ContextMenu from "../contextMenu/contexMenu";
import { findAbsolutePosition } from "../../../ressources/lib/js/globalFunctions.js";
import "./portalList.css";
import PortalBtn from "../portalBtn/portalBtn";
import PortalToolbar from "../portalToolbar/portalToolbar";

/*
Configs : 
{
    data : ARRAY (!!REQ) [[]] => Liste des données à afficher dans la liste 

    itemRender : FUNCTION (!!REQ) [JSON.stringify(data)] => Fonction qui doit retourner le JSX de l'item
        # Paramètres => itemRender(
            itemData : OBJECT [{}] => Les données de l'item correspondant
                # Si l'item possède une propritété 'onClick', et qu'elle stocke une fonction, alors elle sera exécutée au clique sur l'item
                # Le premier paramètre passé au sein de cette fonction sera 'itemData' (les données de l'item correspondant)
        )

    className : STRING (opt) [''] => Classe à affecter au conteneur global

    header : STRING OU JSX (opt) [''] => Contenu du header de la liste
        # Le header ne sera affiché que si il y a du contenu à l'intérieur
    
    isLoading : BOOLEAN (opt) [false] => Afficher l'écran de chargement du contenu de la liste

    errorLoading : BOOLEAN (opt) [false] => Afficher l'écran d'erreur de chargement du contenu de la liste

    noData : BOOLEAN (opt) [false] => Afficher l'écran de données vides du contenu de la liste

    noDataMsg : STRING (opt) ['Aucune donnée n'a été chargée'] => Le message affiché lorsque la liste n'a ramené aucune donnée
        # S'affiche lorsque 'noData' est à true

    itemSelectable : BOOLEAN (opt) [true] => Pour autoriser ou non la sélection d'un item

    onItemClick : FUNCTION (opt) [emptyFctn] => Fonction callBack qui sera exécutée lorsqu'on cliquera sur l'item
        # Fctn(itemData){} => Les params passés dans la Callback si elle existe
            itemData : OBJECT [{}] => Les données de l'item correspondant

        # Sera 'override', si une fonction est settée dans la propriété 'onClick' de la data de l'item

    setItemClass : FUNCTION (opt) [emptyFctn] => Fonction qui peut rajouter des classes supplémentaires à chaque item de la liste
        # Fctn(itemData){} => Les params passés dans la Callback si elle existe
            itemData : OBJECT [null] => La donnée de l'item correspondant de la liste (un élément du tableau 'data' ci-dessus)
        
        # Return => STRING (REQ) => La string qui représente les classes à ajouter à l'item

        # Pratique pour changer de couleur de fond en fonction des données de l'item
    
    setItemContextMenuOption : FUNCTION (opt) [emptyFctn] => Fonction qui construit la liste des configurations du contextMenu pour chaque item de la liste
        # Fctn(itemData){} => Les params passés dans la Callback si elle existe
            itemData : OBJECT [null] => La donnée de l'item correspondant de la liste (un élément du tableau 'data' ci-dessus)

        # Return => ARRAY (REQ) => La liste des configurations du contextMenu pour chaque item de la liste
            # Voir la description de 'optionConfigs' dans le fichier du composant 'ContextMenu'

    itemContextMenuRender : FUNCTION (opt) [emptyFctn] => Fonction qui remplace le rendu par défaut du contextMenu
        # Voir la description de 'itemRender' dans le fichier du composant 'ContextMenu'
        # Le 'contexMenu' n'est pas utilisable lorsqu'un item n'est pas 'selectable'

    noPagination : BOOLEAN (opt) [false] => Permet de retirer le bloc de pagination de la liste
}
*/
export default function PortalList(props) {
    const itemSelectable = !!props.itemSelectable;
    const data = typeof props?.data?.forEach === "function" ? props.data : [];
    const isLoading = !!props.isLoading;
    const errorLoading = !!props.errorLoading;
    const noPagination = !!props.noPagination;

    const groupSizeList = [10, 50, 100, 500, 1000];
    const [groupSize, setGroupSize] = useState(groupSizeList[0]);
    const [pageNum, setPageNum] = useState(1);
    const [displayData, setDisplayData] = useState([]);

    //ContextMenu states
    const [contextMenuTrigger, setContextMenuTrigger] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [contextMenuOptions, setContextMenuOptions] = useState([]);

    //Effect functions
    function calculateDataset() {
        const dataSet = noPagination ? data : data.slice(groupSize * (pageNum - 1), groupSize * pageNum);
        setDisplayData(dataSet);
    }
    useEffect(calculateDataset, [data, groupSize, pageNum]);

    //Helper functions
    function canOpenContextMenu(optionConfigs) {
        return itemSelectable && optionConfigs?.length > 0;
    }

    //UX event handlers
    function onItemClick(event, itemData) {
        if (typeof itemData?.onClick === "function" && itemSelectable) {
            itemData.onClick();
        } else if (typeof props.onItemClick === "function" && itemSelectable) {
            props.onItemClick(itemData);
        }
    }
    function changePageNum(newPageNum) {
        newPageNum = !isNaN(+newPageNum) ? +newPageNum : 0;
        setPageNum(newPageNum < 1 ? 1 : Math.min(newPageNum, calculatePageCount(data.length)));
    }
    function openContextMenu(event, optionConfigs) {
        event.stopPropagation();
        if (canOpenContextMenu(optionConfigs)) {
            const clientX = event?.clientX || findAbsolutePosition(event?.target)["x"] || 0;
            const clientY = event?.clientY || findAbsolutePosition(event?.target)["y"] || 0;
            event.preventDefault();
            setContextMenuX(clientX);
            setContextMenuY(clientY);
            setContextMenuOptions(optionConfigs);
            setContextMenuTrigger(!contextMenuTrigger);
        }
    }

    //UI functions
    function defaultItemRender(itemData) {
        return JSON.stringify(itemData);
    }
    function emptyListRender() {
        return <InitView type="noData" noDataMsg={props.noDataMsg} />;
    }
    function loaderRender(isLoading, errorLoading) {
        return <InitView type={isLoading ? "loading" : errorLoading ? "errorLoading" : ""} />;
    }
    function buildItemList() {
        const itemList = [];
        let contextMenuBtn;
        displayData.forEach(function (itemData, index) {
            let contexMenuOptions = typeof props.setItemContextMenuOption === "function" ? props.setItemContextMenuOption(itemData) : [];
            contextMenuBtn = canOpenContextMenu(contexMenuOptions) ? (
                <BtnToolTip iconCls="fas fa-bars" onClick={(e) => openContextMenu(e, contexMenuOptions, index)} />
            ) : null;
            itemList.push(
                <li key={"listitem-" + index}>
                    <button
                        className={`portal-list-item${itemSelectable ? " selectable" : ""}${
                            typeof props.setItemClass === "function" ? " " + props.setItemClass(itemData) : ""
                        }`}
                        onClick={(e) => onItemClick(e, itemData)}
                        onContextMenu={(e) => openContextMenu(e, contexMenuOptions)}
                    >
                        {typeof props.itemRender === "function" ? props.itemRender(itemData) : defaultItemRender(itemData)}
                        {contextMenuBtn}
                    </button>
                </li>
            );
        });
        if (itemList.length < 1) {
            itemList.push(
                <li key={"alone-item"} className="alone-item">
                    {emptyListRender()}
                </li>
            );
        }
        return itemList;
    }
    function chooseRender() {
        if (isLoading || errorLoading) return <li className="alone-item">{loaderRender(isLoading, errorLoading)}</li>;
        return buildItemList();
    }
    function calculatePageCount(itemNumber) {
        return Math.ceil(itemNumber / groupSize);
    }
    function buildOptionValues(itemNumber) {
        const displayValues = [];
        groupSizeList.forEach(function (value, i) {
            if (itemNumber >= value || i === 0) {
                displayValues.push(
                    <option key={value} value={value}>
                        {value}
                    </option>
                );
            }
        });
        return displayValues;
    }
    function bottomDisabled() {
        return displayData.length < 1 || props.isLoading || props.errorLoading;
    }
    function paginationRender() {
        if (noPagination) return "";
        const pageCount = calculatePageCount(data.length);
        function clickablePageRender(clickPageCount) {
            const paginationList = [];
            clickPageCount = +clickPageCount || 1;
            function getFirstClickablePageNumber() {
                //TODO: Mettre le "firstClickablePageNumber" dans un Etat
                return pageNum > clickPageCount ? pageNum - clickPageCount + 1 : 1;
            }
            function getLastClickablePageNumber() {
                return pageNum > clickPageCount ? pageNum : Math.min(clickPageCount, pageCount);
            }
            for (let i = getFirstClickablePageNumber(); i <= getLastClickablePageNumber(); i++) {
                paginationList.push(
                    <li>
                        <PortalBtn
                            className={pageNum !== i ? "reverse" : ""}
                            key={`clickablPageNumber-${i}`}
                            onClick={function (e) {
                                setPageNum(i);
                            }}
                            disabled={bottomDisabled()}
                        >
                            {i}
                        </PortalBtn>
                    </li>
                );
            }
            return paginationList;
        }
        function groupPageRender() {
            const groupPageList = [];
            groupSizeList.forEach(function (groupSizeValue, index) {
                if (groupSizeValue <= data.length || groupSizeValue <= groupSize) {
                    //console.warn(groupSize);
                    //console.warn(groupSizeValue);
                    groupPageList.push(
                        <li>
                            <PortalBtn
                                key={`clickableGroupSize-${groupSizeValue}`}
                                disabled={bottomDisabled()}
                                aria-current="true"
                                onClick={function (e) {
                                    setPageNum(1);
                                    setGroupSize(groupSizeValue);
                                }}
                                className={groupSizeValue !== groupSize ? "reverse" : ""}
                            >
                                {groupSizeValue}
                            </PortalBtn>
                        </li>
                    );
                }
            });
            return groupPageList;
        }
        function endToolsRender() {
            return (
                <>
                <li>
                    <BtnToolTip
                        iconAlign="left"
                        iconCls="fas fa-angle-left"
                        aria-current="true"
                        onClick={(e) => changePageNum(pageNum - 1)}
                        disabled={bottomDisabled()}
                        className="btnDecreasePagination noBorder"
                    >
                        Page Précédente
                    </BtnToolTip>
                </li>
                    {clickablePageRender(3)}
                    <li>
                        <BtnToolTip
                            iconCls="fas fa-angle-right"
                            iconAlign="right"
                            aria-current="true"
                            onClick={(e) => changePageNum(pageNum + 1)}
                            disabled={bottomDisabled()}
                            className="btnIncreasePagination noBorder"
                        >
                            Page Suivante
                        </BtnToolTip>
                    </li>
                </>
            );
        }
        return (
            <PortalToolbar
                className={`portal-list-footer${bottomDisabled() ? " disabled" : ""}`}
                noBorder
                startToolsRender={groupPageRender}
                endToolsRender={endToolsRender}
            />
        );
    }
    return (
        <>
            <ContextMenu
                openTrigger={contextMenuTrigger}
                optionConfigs={contextMenuOptions}
                xPos={contextMenuX}
                yPos={contextMenuY}
                autoClose={true}
                closeTrigger={null}
                itemRender={props.itemContextMenuRender}
            />
            <div className={"portal-list-container" + (props.className ? " " + props.className : "")}>
                {props.header ? <div className="portal-list-header">{props.header}</div> : ""}
                <ul className="portal-list-content" tabIndex={-1}>
                    {chooseRender()}
                </ul>
                {paginationRender()}
            </div>
        </>
    );
}
