//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect, useRef } from "react";
//Custom librarie
import { checkFieldPaste, isEmailType, areEquivalent, buildFeedBack, Password } from "../../../../ressources/lib/js/globalFunctions.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom UI Components
import InitView from "../../../../ressources/components/initView/initView";
import PopupView from "../../../../ressources/components/popupView/popupView.js";
import PortalPassword from "../../../../ressources/components/portalFields/portalPassword/portalPassword.js";
import PortalText from "../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalBtn from "../../../../ressources/components/portalBtn/portalBtn.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//
function UserAccount(props) {
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        profile: {
            account_name: "",
            agent: "Complément",
            bic: "",
            birth_datetime: "1984-11-01",
            city: "Monaco",
            domiciliation: "",
            email: "jean.kaby@hotmail.com",
            email_pro: "",
            ent_entity_id: "156987",
            ent_type_civility_id: 1,
            ent_type_graduation_id: "",
            ent_type_job_id: "",
            external_ref: "",
            fax: "",
            first_name: "Jean",
            formatted_address: "",
            housename: "Porte verte",
            iban: "",
            is_client: true,
            label: "Chez ses parents",
            last_name: "Kaby",
            mobile_phone: "06 22 41 74 89",
            neighborhood: "Bâtiment C",
            number: "14",
            par_country: "FRANCE",
            par_sex_id: "",
            phone: "04 15 68 74 32",
            phone_pro: "",
            place_name: "Les Rosiers",
            pobox: "Escalier A, 5ème Etage, Apparetment 816",
            postcode: "74125",
            referenceDossier: "25478412",
            street_address: "Rue des lilas",
        },
        par_country: {
            1: "FRANCE",
            2: "ESPAGNE",
        },
    };
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [isSendingRequest, setIsSendingRequest] = useState(false); //Don't delete
    const [showPopup, setShowPopup] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [confirmNewEmail, setConfirmNewEmail] = useState("");
    const [changePasswordFirstTry, setChangePasswordFirstTry] = useState(false);
    const [changeLoginFirstTry, setChangeLoginFirstTry] = useState(false);
    const [deleteAccountFirstTry, setDeleteAccountFirstTry] = useState(false);
    const changeLoginFocusRef = useRef();
    const currentPasswordFocusRef = useRef();
    // Init functions
    function loadViewData() {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        setIsloading(true);
        setErrorLoading(false);
        if (dataPlug && useDataPlug) {
            setViewData(dataPlug);
            setIsloading(false);
            return;
        }
        props
            .request({
                selected_client: null,
            })
            .then(function (response) {
                setViewData(response.data);
                setUserName(response.data.username);
                handleResponseHelp(response);
                setIsloading(false);
            })
            .catch(function (error) {
                setErrorLoading(true);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            });
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        //Mise à jour du fil d'ariane
        props.updateUserJourney(["home", "user_account"]); // A personnaliser en fonction de la vue
    }, []);
    useEffect(function () {
        //Chargement des données de la vue (Bouchon ou non)
        loadViewData();
    }, []);
    useEffect(
        function () {
            switch (showPopup) {
                case "change_password":
                    if (currentPasswordFocusRef.current) currentPasswordFocusRef.current.focus();
                    break;
                case "delete_account":
                    if (currentPasswordFocusRef.current) currentPasswordFocusRef.current.focus();
                    break;
                case "change_email":
                    if (changeLoginFocusRef.current) changeLoginFocusRef.current.focus();
                    break;
                default:
                    break;
            }
        },
        [showPopup]
    );
    // Init render treatments
    if (isLoading && !errorLoading) {
        return <InitView type="loading" />;
    }
    if (errorLoading) {
        return <InitView type="errorLoading" />;
    }
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)
    // View constants
    const formEditProfile = {
        coordinates: {},
        address: {},
    };

    // View global functions
    function checkPasswordStates() {
        if (currentPassword.length < 1) {
            props.msgBox("Veuillez renseigner votre mot de passe actuel", "error", "msg");
            return false;
        }
        if (newPassword.length < 1) {
            props.msgBox("Veuillez renseigner votre nouveau mot de passe", "error", "msg");
            return false;
        }
        let newPwdCheck = Password.checkPassword(newPassword);
        if (!newPwdCheck.status) {
            props.msgBox(
                "Votre nouveau mot de passe ne respecte pas les critères de sécurité :<br/><br/>" + newPwdCheck.msg,
                "error",
                "msg"
            );
            return false;
        }
        if (areEquivalent(currentPassword, newPassword)) {
            props.msgBox("Votre nouveau mot de passe doit être différent de l'ancien", "error", "msg");
            return false;
        }
        let confirmCheck = Password.checkConfirmation(newPassword, confirmNewPassword);
        if (!confirmCheck.status) {
            props.msgBox(confirmCheck.msg, "error", "msg");
            return false;
        }
        return true;
    }
    function checkEmailStates() {
        if (!isEmailType(newEmail)) {
            props.msgBox("Veuillez renseigner un email valide", "error", "msg");
            return false;
        }
        if (!areEquivalent(newEmail, confirmNewEmail)) {
            props.msgBox("Votre confirmation d'email n'est pas valide", "error", "msg");
            return false;
        }
        return true;
    }
    function clearPopupFieldStates() {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setNewEmail("");
        setConfirmNewEmail("");
        setChangeLoginFirstTry(false);
        setChangePasswordFirstTry(false);
        setDeleteAccountFirstTry(false);
    }
    function formatDate(asaproDate) {
        if (typeof asaproDate !== "undefined" && asaproDate != null) {
            let formattedDate = asaproDate.split("-");
            // Conversion affichage 1939-11-13 => 13/11/1929
            formattedDate = formattedDate[2] + "/" + formattedDate[1] + "/" + formattedDate[0];
            return formattedDate;
        }
        return asaproDate;
    }

    // UI event handlers
    function handleCurrentPasswordChange(event) {
        if (!checkFieldPaste(currentPassword, event.target.value)) {
            setCurrentPassword(event.target.value);
        } else {
            setCurrentPassword("");
        }
    }
    function handleNewPasswordChange(event) {
        if (!checkFieldPaste(newPassword, event.target.value)) {
            setNewPassword(event.target.value);
        } else {
            setNewPassword("");
        }
    }
    function handleConfirmNewPasswordChange(event) {
        if (!checkFieldPaste(confirmNewPassword, event.target.value)) {
            setConfirmNewPassword(event.target.value);
        } else {
            setConfirmNewPassword("");
        }
    }
    function setEditProfileFormField(section, field, value) {
        // Exemple d'utilisation : setEditProfileFormField('coordinates', 'phone', '0645645643') => form['coordinates']['phone'] = "0645645643";
        // Exemple d'utilisation : setEditProfileFormField('address', 'city', 'Morzine') => form['address']['city'] = "Morzine";
        formEditProfile[section][field] = value;
    }
    function onHidePopup() {
        setShowPopup("");
        clearPopupFieldStates();
    }
    function postEditProfile(event) {
        //Don't delete
        event.preventDefault();
        setIsSendingRequest(true);
        props
            .request({
                action: "post_user_account_data",
                selected_client: null,
                data: formEditProfile,
            })
            .then(function (response) {
                props.msgBox("Vos données ont bien été mises à jour", "info", "msg");
                console.log(response);
            })
            .catch(function (error) {
                console.error("Erreur lors de l'enregistrement des nouvelles données de l'utilisateur (post_user_account_data)");
                console.error(error.msg);
                props.msgBox("Échec de la mise à jour de vos informations", "error", "msg");
            })
            .finally(function () {
                setIsSendingRequest(false);
            });
    }
    function postNewPassword() {
        if (!checkPasswordStates()) {
            setChangePasswordFirstTry(true);
            return false;
        }
        setIsSendingRequest(true);
        props
            .request({
                action: "change_user_account_password",
                selected_client: null,
                data: {
                    current_password: currentPassword,
                    new_password: newPassword,
                    confirm_new_password: confirmNewPassword,
                },
            })
            .then(function (response) {
                console.log(response);
                props.msgBox("Mot de passe modifié avec succès", "info", "msg").then(function (res) {
                    setShowPopup("");
                    clearPopupFieldStates();
                });
            })
            .catch(function (error) {
                console.error(error.msg);
                props.msgBox(error.msg, "error", "msg");
            })
            .finally(() => setIsSendingRequest(false));
    }
    function postNewEmail() {
        if (!checkEmailStates()) {
            setChangeLoginFirstTry(true);
            return false;
        }
        if (currentPassword.length < 1) {
            setChangeLoginFirstTry(true);
            props.msgBox("Veuillez renseigner votre mot de passe actuel", "error", "msg");
            return false;
        }
        setIsSendingRequest(true);
        props
            .request({
                action: "change_user_account_email",
                selected_client: null,
                data: {
                    new_email: newEmail,
                    confirm_new_email: confirmNewEmail,
                    current_password: currentPassword,
                },
            })
            .then(function (response) {
                console.log(response);
                //setUserName(response.data.new_username);
                props.msgBox(`Un email de confirmation a été envoyé à ${response?.data?.new_username}`, "info", "msg").then(function (res) {
                    setShowPopup("");
                    clearPopupFieldStates();
                });
            })
            .catch(function (error) {
                console.error(error.msg);
                props.msgBox(error.msg, "error", "msg");
            })
            .finally(function () {
                setIsSendingRequest(false);
            });
    }
    function deleteAccess() {
        if (currentPassword.length < 1) {
            props.msgBox("Veuillez renseigner votre mot de passe", "error", "msg");
            setDeleteAccountFirstTry(true);
            return false;
        }
        let warningText = "Etes-vous vraiment sûr(e) de vouloir supprimer votre compte ? ";
        props
            .msgBox(warningText, "warning", "confirm")
            .then(function () {
                setIsSendingRequest(true);
                props
                    .request({
                        selected_client: null,
                        action: "delete_user_account",
                        data: {
                            current_password: currentPassword,
                        },
                    })
                    .then(function (response) {
                        console.log(response);
                        props.msgBox("Compte supprimé avec succès", "info", "msg").then(function (res) {
                            setShowPopup("");
                            clearPopupFieldStates();
                            props.logout();
                        });
                    })
                    .catch(function (error) {
                        console.error(error.msg);
                        props.msgBox(error.msg, "error", "msg");
                    })
                    .finally(() => setIsSendingRequest(false));
            })
            .catch(function () {});
    }
    // Pre-rendering treatments
    // Popup building
    let headerPopup = (
        <Row className="popupFormDescrRow">
            <Col xs={12} className="popupFormDescrTxt">
                Merci de renseigner les éléments suivants
            </Col>
        </Row>
    );
    let popupVisible = false,
        popupContent = "",
        popupTitle = "",
        popupSubmit = "";
    let newEmailSection = (
        <>
            <Form.Group controlId="newEmail">
                {!!props.i18n("label.field.new_username") && (
                    <Form.Label>
                        {props.i18n("label.field.new_username")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                )}
                <PortalText
                    id="newEmail"
                    ref={changeLoginFocusRef}
                    onChange={(event) => setNewEmail(event.target.value)}
                    type="email"
                    autoComplete="email"
                    name="newEmail"
                    placeholder={props.i18n("placeholder.field.new_username")}
                    tooltip={props.i18n("info.field.new_username")}
                    value={newEmail}
                    required={true}
                    aria-required={true}
                    isInvalid={(newEmail.length < 1 || !isEmailType(newEmail)) && changeLoginFirstTry}
                    aria-invalid={(newEmail.length < 1 || !isEmailType(newEmail)) && changeLoginFirstTry}
                    aria-describedby="newEmail-1 newEmail-2"
                />
                {buildFeedBack(
                    "newEmail",
                    ["Champ obligatoire", "Doit respecter le format d'un email"],
                    [newEmail.length < 1, !isEmailType(newEmail)],
                    changeLoginFirstTry
                )}
            </Form.Group>
            <Form.Group controlId="confirmNewEmail">
                {!!props.i18n("label.field.new_username_confirm") && (
                    <Form.Label>
                        {props.i18n("label.field.new_username_confirm")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                )}
                <PortalText
                    id="confirmNewEmail"
                    onChange={(event) => setConfirmNewEmail(event.target.value)}
                    type="email"
                    autoComplete="email"
                    name="confirmNewEmail"
                    placeholder={props.i18n("placeholder.field.new_username_confirm")}
                    value={confirmNewEmail}
                    required={true}
                    aria-required={true}
                    isInvalid={(!isEmailType(confirmNewEmail) || !areEquivalent(newEmail, confirmNewEmail)) && changeLoginFirstTry}
                    aria-invalid={(!isEmailType(confirmNewEmail) || !areEquivalent(newEmail, confirmNewEmail)) && changeLoginFirstTry}
                    aria-describedby="confirmNewEmail-1 confirmNewEmail-2"
                />
                {buildFeedBack(
                    "confirmNewEmail",
                    ["Doit respecter le format d'un email", "Doit être identique au nouvel identifiant"],
                    [!isEmailType(confirmNewEmail), !areEquivalent(newEmail, confirmNewEmail)],
                    changeLoginFirstTry
                )}
            </Form.Group>
        </>
    );
    let currentPasswordSection = (
        <>
            <Form.Group controlId="currentPassword">
                {!!props.i18n("label.field.current_password") && (
                    <Form.Label>
                        {props.i18n("label.field.current_password")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                )}
                <PortalPassword
                    id="currentPassword"
                    ref={currentPasswordFocusRef}
                    onChange={handleCurrentPasswordChange}
                    autoComplete="current-password"
                    name="currentPassword"
                    placeholder={props.i18n("placeholder.field.current_password")}
                    value={currentPassword}
                    required={true}
                    aria-required={true}
                    isInvalid={currentPassword.length < 1 && (changePasswordFirstTry || changeLoginFirstTry || deleteAccountFirstTry)}
                    aria-invalid={currentPassword.length < 1 && (changePasswordFirstTry || changeLoginFirstTry || deleteAccountFirstTry)}
                    aria-describedby="currentPassword-1"
                />
                {buildFeedBack(
                    "currentPassword",
                    ["Champ obligatoire"],
                    [currentPassword.length < 1],
                    changePasswordFirstTry || changeLoginFirstTry || deleteAccountFirstTry
                )}
            </Form.Group>
        </>
    );
    let newPasswordSection = (
        <>
            <Form.Group controlId="newPassword">
                {!!props.i18n("label.field.new_password") && (
                    <Form.Label>
                        {props.i18n("label.field.new_password")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                )}
                <PortalPassword
                    id="newPassword"
                    onChange={handleNewPasswordChange}
                    autoComplete="new-password"
                    name="newPassword"
                    placeholder={props.i18n("placeholder.field.new_password")}
                    value={newPassword}
                    required={true}
                    aria-required={true}
                    tooltip={props.i18n("info.field.new_password")}
                    isInvalid={
                        (newPassword.length < 1 ||
                            areEquivalent(currentPassword, newPassword) ||
                            !Password.isLongEnough(newPassword)["status"] ||
                            !Password.hasAtLeastOneNumber(newPassword)["status"] ||
                            !Password.hasLowerCase(newPassword)["status"] ||
                            !Password.hasUpperCase(newPassword)["status"] ||
                            !Password.hasAtLeastOneSpecialCharacter(newPassword)["status"]) &&
                        changePasswordFirstTry
                    }
                    aria-invalid={
                        (newPassword.length < 1 ||
                            areEquivalent(currentPassword, newPassword) ||
                            !Password.isLongEnough(newPassword)["status"] ||
                            !Password.hasAtLeastOneNumber(newPassword)["status"] ||
                            !Password.hasLowerCase(newPassword)["status"] ||
                            !Password.hasUpperCase(newPassword)["status"] ||
                            !Password.hasAtLeastOneSpecialCharacter(newPassword)["status"]) &&
                        changePasswordFirstTry
                    }
                    aria-describedby="newPassword-1 newPassword-2 newPassword-3 newPassword-4 newPassword-5 newPassword-6"
                />
                {buildFeedBack(
                    "newPassword",
                    [
                        "Champ obligatoire",
                        "Différent de l'ancien mot de passe",
                        Password.isLongEnough(newPassword)["condition"],
                        Password.hasAtLeastOneNumber(newPassword)["condition"],
                        Password.hasLowerCase(newPassword)["condition"],
                        Password.hasUpperCase(newPassword)["condition"],
                        Password.hasAtLeastOneSpecialCharacter(newPassword)["condition"],
                    ],
                    [
                        newPassword.length < 1,
                        areEquivalent(currentPassword, newPassword),
                        !Password.isLongEnough(newPassword)["status"],
                        !Password.hasAtLeastOneNumber(newPassword)["status"],
                        !Password.hasLowerCase(newPassword)["status"],
                        !Password.hasUpperCase(newPassword)["status"],
                        !Password.hasAtLeastOneSpecialCharacter(newPassword)["status"],
                    ],
                    changePasswordFirstTry
                )}
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
                {!!props.i18n("label.field.password_confirm") && (
                    <Form.Label>
                        {props.i18n("label.field.password_confirm")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                )}
                <PortalPassword
                    id="confirmNewPassword"
                    onChange={handleConfirmNewPasswordChange}
                    type="password"
                    autoComplete="new-password"
                    name="confirmNewPassword"
                    placeholder={props.i18n("placeholder.field.password_confirm")}
                    value={confirmNewPassword}
                    required={true}
                    aria-required={true}
                    isInvalid={(confirmNewPassword.length < 1 || !areEquivalent(newPassword, confirmNewPassword)) && changePasswordFirstTry}
                    aria-invalid={
                        (confirmNewPassword.length < 1 || !areEquivalent(newPassword, confirmNewPassword)) && changePasswordFirstTry
                    }
                    aria-describedby="confirmNewPassword-1 confirmNewPassword-2"
                />
                {buildFeedBack(
                    "confirmNewPassword",
                    ["Champ obligatoire", "Identique au nouveau mot de passe"],
                    [confirmNewPassword.length < 1, !areEquivalent(newPassword, confirmNewPassword)],
                    changePasswordFirstTry
                )}
            </Form.Group>
        </>
    );

    switch (true) {
        case showPopup === "delete_account":
            popupTitle = "Supprimer cet accès";
            popupVisible = true;
            popupContent = (
                <>
                    {headerPopup}
                    <Form className="popupFormContainer">
                        {!!props.i18n("label.field.mandatory_fields") && (
                            <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                        )}
                        {currentPasswordSection}
                    </Form>
                </>
            );
            popupSubmit = deleteAccess;
            break;
        case showPopup === "change_email":
            popupTitle = "Changer d'identifiant";
            popupVisible = true;
            popupContent = (
                <>
                    {headerPopup}
                    <Form className="popupFormContainer">
                        {!!props.i18n("label.field.mandatory_fields") && (
                            <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                        )}
                        {newEmailSection}
                        {currentPasswordSection}
                    </Form>
                </>
            );
            popupSubmit = postNewEmail;
            break;
        case showPopup === "change_password":
            popupTitle = "Changer le mot de passe";
            popupVisible = true;
            popupContent = (
                <>
                    {headerPopup}
                    <Form className="popupFormContainer">
                        {!!props.i18n("label.field.mandatory_fields") && (
                            <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                        )}
                        {currentPasswordSection}
                        {newPasswordSection}
                        <div className="passwordWarningMsg">
                            Attention, ce mot de passe est celui de votre compte local et en aucun cas celui du compte que vous utilisez au
                            travers de FranceConnect. Il vous servira uniquement lorsque vous vous connecterez avec votre adresse mail
                            plutôt que via FranceConnect
                        </div>
                    </Form>
                </>
            );
            popupSubmit = postNewPassword;
            break;
        default:
            popupVisible = false;
            popupContent = "";
            popupTitle = "";
            popupSubmit = "";
            break;
    }
    // Rendering part
    return (
        <div className="contentContainer">
            {props.setStyle("./normal")}
            <PopupView
                onHiding={onHidePopup}
                showCloseButton={true}
                title={popupTitle}
                visible={popupVisible}
                submitSection={true}
                submitBtnState={isSendingRequest ? "loading" : ""}
                onSubmit={popupSubmit}
            >
                {<div className="popupFormBlock">{popupContent}</div>}
            </PopupView>
            <Container className="profile-section-container">
                <div className="profile-section-content">
                    <h4>{"Paramètres de sécurité" + ` (${userName})`}</h4>
                    {!props.ssoDisconnect ? (
                        <Row>
                            <Col xs={12} md={4} className="btnCol">
                                <PortalBtn className="stretch" onClick={() => setShowPopup("change_password")} autoFocus>
                                    Changer de mot de passe
                                </PortalBtn>
                            </Col>
                            <Col xs={12} md={4} className="btnCol">
                                <PortalBtn className="stretch" onClick={() => setShowPopup("change_email")}>
                                    Changer d'identitfiant
                                </PortalBtn>
                            </Col>

                            <Col xs={12} md={4} className="btnCol">
                                <PortalBtn className="stretch" onClick={() => setShowPopup("delete_account")}>
                                    Supprimer mon compte
                                </PortalBtn>
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                </div>
            </Container>
        </div>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default UserAccount;
