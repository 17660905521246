import React from "react";
import BarMenu from "./components/barMenu/barMenu.js";
import FloatMenu from "./components/floatMenu/floatMenu.js";
import "./portalMenu.css";

export default function PortalMenu(props) {
    //TODO: Améliorer la sémantique avec des ul, li, nav, ...
    /**
     * @param props {Object} [{}]: {
     *       @param {Object} data [{}] : {
     *          @param {String} direction [undefined] : Le hash de la vue sur laquelle l'item pointe
     *
     *          @param {Object[]} items [null] : les sous-items de menus possibles que cet item contient
     *              @example [{direction: '', items: [{direction:'', items:[...]}, ...]}]
     *      }
     *      @param {Function} i18n [emptyFctn] : La fonction pour traduire les champs 'direction' en des noms de vues lisibles
     *
     *      @param {Function} redirectTo [emptyFctn] : La fonction pour rediriger vers les vues quand on clique sur un lien du menu
     *
     *      @param {Boolean} usePlug [false] : Pour activer ou non le mode bouchon pour faire des développements/tests sur le composant
     *
     *      @param {Boolean} desktopDisplay [false] : Pour afficher le menu en mode horizontal (desktop) ou en mode flottant (phone)
     *
     *      @param {Boolean} triggerOpen [false] : Pour déclencher l'ouverture du menu en mode "phone"
     *          @descr Le Bouton d'ouverture/fermeture du menu n'est pas dans le menu lui-même
     *
     *      @param {Boolean} triggerCollapse [false] : Pour déclencher la fermeture du menu en mode "phone"
     *          @descr Le Bouton d'ouverture/fermeture du menu n'est pas dans le menu lui-même
     *
     *  }
     */

    const data = props.data || [];
    const usePlugMenu = !!props.usePlug;
    const desktopDisplay = !!props.desktopDisplay;
    const triggerOpen = !!props.triggerOpen;
    const triggerCollapse = !!props.triggerCollapse;
    const plugMenu = [
        {
            direction: "home_communication",
            items: [
                {
                    direction: "home_client_link",
                    items: [
                        { direction: "user_note", items: null },
                        { direction: "home_user", items: [{ direction: "user_contact", items: null }] },
                    ],
                },
                { direction: "user_file", items: null },
            ],
        },
        {
            direction: "home_demandes",
            items: [
                { direction: "user_subscription", items: null },
                { direction: "user_monitoring", items: null },
            ],
        },
    ];

    function menuRender() {
        if (desktopDisplay) {
            return <BarMenu data={usePlugMenu ? plugMenu : data} redirectTo={props.redirectTo} i18n={props.i18n} />;
        }
        return <FloatMenu data={usePlugMenu ? plugMenu : data} redirectTo={props.redirectTo} i18n={props.i18n} />;
    }

    return <div className="portalMenuContainer">{menuRender()}</div>;
}
