import React from "react";
import { Form } from "react-bootstrap";
import { isArray } from "../../../lib/js/globalFunctions";
/*
Install : - La bilbiothèque bootstrap doit être installée
Configs :
{
    msgList = STRING[] [[]] (req) => La liste des messages de feedback à afficher

    fieldId = STRING (req) ['unknownId'] => L'id du champ d'input auquel les feedbacks font référence
        # Chaque message de feeback aura un id de la forme '${fieldID}-1', '${fieldID}-2', ...
        # Ces listes d'Id sont à renseigner dans le champ 'aria-describedby' de l'input correspondante

    i18n = FUNCTION [emptyFctn] (opt) => La fonction i18n pour transformer les codes feedback en erreurs lisibles
        # Si elle n'est pas settée, on affiche les messages tels quels
}
*/

export default function PortalFeedback(props) {
    let msgList = props.msgList;
    if (!isArray(msgList)) {
        msgList = typeof msgList === "string" ? [msgList] : [];
    }
    const fieldId = props.fieldId || "unknownId";
    const feedbackList = [];
    msgList.forEach(function (msg, index) {
        feedbackList.push(
            <Form.Control.Feedback type={props.type || "invalid"} id={`${fieldId}-${index + 1}`} key={`${fieldId}-${index + 1}`}>
                {typeof props.i18n === "function" ? props.i18n("feedback." + msg) : msg}
            </Form.Control.Feedback>
        );
    });

    return feedbackList;
}
