//######################################## Imports ########################################//

/********** Public Views **********/
import ContactPlatform from "./application/views/public/contact_platform/main.js";
import ConfirmContactPermission from "./application/views/public/confirm_contact_permission/main.js";
import Faq from "./application/views/public/faq/main.js";
import ForgottenPassword from "./application/views/public/forgotten_password/main.js";
import Login from "./application/views/public/login/main.js";
import MfaLogin from "./application/views/public/mfa_login/mfa_login.js";
import Register from "./application/views/public/register/main.js";
import RegisterPersonnalContact from "./application/views/public/register_personnal_contact/main.js";
import SetPassword from "./application/views/public/set_password/main.js";
import ShowcaseView from "./application/views/public/showcase_view/main.js";
/**********************************/

/********** Private Views **********/
import Compass from "./application/views/private/compass/mains.js";
import Contact from "./application/views/private/contact/main.js";
import ClientDmp from "./application/views/private/dmp/view.js";
import Evaluation from "./application/views/private/evaluation/mains.js";
import File from "./application/views/private/file/main.js";
import Invoice from "./application/views/private/invoice/main.js";
import ClientMail from "./application/views/private/mail/view.js";
import ClientMeal from "./application/views/private/meal/view.js";
import ClientMonitoring from "./application/views/private/monitoring/view.js";
import Note from "./application/views/private/note/main.js";
import ClientSubscription from "./application/views/private/subscription/view.js";
import Health from "./application/views/private/health/mains.js";
import Help from "./application/views/private/help/mains.js";
import Home from "./application/views/private/home/mains.js";
import UserAccount from "./application/views/private/account/view.js";
import UserMss from "./application/views/private/mss/view.js";
import Msc from "./application/views/private/msc/view.js";
import ProDictionnary from "./application/views/private/pro_dictionary/mains.js";
import UserNews from "./application/views/private/news/view.js";
import UserPlanning2 from "./application/views/private/planning/view.js";
import ToBuild from "./application/views/public/toBuild.js";
/***********************************/
//#########################################################################################//

//######################################## Dictionnary filling ########################################//
const viewDictionnary = {
    public: {
        confirm_contact_permission: ConfirmContactPermission,
        contact_platform: ContactPlatform,
        faq: Faq,
        forgotten_password: ForgottenPassword,
        login: Login,
        mfa_login: MfaLogin,
        register: Register,
        register_personnal_contact: RegisterPersonnalContact,
        set_password: SetPassword,
        showcase_view: ShowcaseView,
        to_build_public: ToBuild,
    },
    private: {
        client_compass: Compass,
        client_contact: Contact,
        client_dmp: ClientDmp,
        client_evaluation: Evaluation,
        client_file: File,
        client_help: Help,
        client_invoice: Invoice,
        client_note: Note,
        client_mail: ClientMail,
        client_meal: ClientMeal,
        client_monitoring: ClientMonitoring,
        client_planning: UserPlanning2,
        client_subscription: ClientSubscription,
        client_transmission: ToBuild,
        home: Home,
        home_client: Home,
        home_client_communication: Home,
        home_client_demandes: Home,
        home_client_intervention: Home,
        home_client_link: Home,
        home_user: Home,
        home_user_communication: Home,
        home_user_demandes: Home,
        home_user_help: Home,
        home_user_intervention: Home,
        home_user_link: Home,
        user_account: UserAccount,
        user_compass: Compass,
        user_contact: Contact,
        user_dmp: ClientDmp,
        user_evaluation: Evaluation,
        user_file: File,
        user_invoice: Invoice,
        user_health: Health,
        user_help: Help,
        user_mail: ClientMail,
        user_meal: ClientMeal,
        user_monitoring: ClientMonitoring,
        user_msc: Msc,
        user_mss: UserMss,
        user_news: UserNews,
        user_note: Note,
        user_planning: UserPlanning2,
        user_pro_dictionary: ProDictionnary,
        user_subscription: ClientSubscription,
        user_transmission: ToBuild,
        to_build_private: ToBuild,
    },
};
//#####################################################################################################//

export function getViewComponent(viewType, hash) {
    if (!viewDictionnary[viewType][hash]) {
        console.error(
            'The view "' +
                hash +
                '" was not found in viewDictionnary.\nPlease make sure to add the view at the right place into the viewDictionnary'
        );
        return false;
    }
    return {
        component: viewDictionnary[viewType][hash],
    };
}

export function getViewDirectory(viewName) {
    //Transforme les vues "user_name" et "client_name" en name => nom du dossier dans lequel se trouve la vue
    viewName = typeof viewName === "string" ? viewName : "";
    const prefixList = ["user_", "client_"];
    let prefixFound = false;
    prefixList.forEach(function (prefix) {
        if (!prefixFound) {
            prefixFound = viewName.indexOf(prefix) === 0;
            if (prefixFound) {
                viewName = viewName.slice(prefix.length);
            }
        }
    });
    return viewName;
}
