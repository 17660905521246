import React from "react";
import { Modal } from "react-bootstrap";
import ItemRow from "../itemRow/itemRow.js";
import PortalBtn from "../portalBtn/portalBtn.js";
import "./popupView.css";
import ImgBackground from "../imgBackground/imgBackground.js";
/*
Install : - Les styles css associés (paragraphe Popup) doivent être chargés dans le projet

Configs : 
{
    visible : BOOLEAN (required) [false] => Permet de contrôler l'affichage/masquage du popup

    footerRender : FUCNTION (opt) [''] => Fonction qui doit retouner le contenu (string/html/JSX) du footer du Popup

    submitSection : BOOLEAN (opt) [false] => Permet d'afficher un footer standard de validation de la vue 
        # Affichera une ligne supplémentaire au dessus du footer spécifié dans la fonction 'footerRender' 

    submitBtnState : STRING (opt) [''] => Permet de gérer l'état de chargement du bouton validation de la 'submitSection'
        # Valeurs possibles : '' || 'loading' || 'error'
        
    onSubmit : FUNCTION (opt) [''] => Callback déclenchée quand on clique sur le bouton de validation de la 'submitSection'

    onCancel : FUNCTION (opt) [onHiding()] => Callback déclenchée quand on clique sur le bouton d'annulation de la 'submitSection'
        # Si on ne spécifie rien, c'est la fonction spécifiée dans la propriété 'onHiding' qui est exécutée
        # Cela assure d'avoir le même comportement que si l'on clique sur le bouton 'close' du popup 

    className : STRING (opt) [''] => Permet de rajouter une classe alternative au conteneur de la boîte de dialogue du popup
    
    backdropClassName : STRING (opt) [''] => Permet de rajouter une classe alternative au background du popup
        # Pratique pour changer son z-index quand on superpose plusieurs popups les uns sur les autres

    title : STRING (opt) [''] => Permet d'afficher un header en haut du popup avec un titre à l'intérieur

    showCloseButton : BOOLEAN (opt) [false] => Permet d'afficher un bouton pour fermer le popup dans le header
        # !! Pour qu'il s'affiche, il faut qu'il y ait le champ 'title' de setté

    onHiding : FUNCTION (opt) [emptyFctn] => CallBack exécutée lorsque l'on déclenche la demande de "masquer" le popup   
        # Ce déclenchement peut avoir lieu si l'on clique sur le 'closeButton' (Si celui-ci est affiché)
}
*/
function Popup(props) {
    //TODO: Comprendre pourquoi ce composant se "mount/unmount" constamment quand on resize
    function emtpyFcnt() {}
    function onSubmit() {
        if (typeof props.onSubmit === "function") {
            props.onSubmit();
        }
    }
    function onCancel() {
        if (typeof props.onCancel === "function") {
            props.onCancel();
        } else if (typeof props.onHiding === "function") {
            props.onHiding();
        }
    }

    function SubmitSection(props) {
        return (
            <ItemRow fill="stretch" minElWidth={200} maxElWidth={450}>
                <PortalBtn className="validate" state={props.submitBtnState} onClick={onSubmit} disabled={props.submitBtnState}>
                    Valider
                </PortalBtn>
                <PortalBtn onClick={onCancel}>Annuler</PortalBtn>
            </ItemRow>
        );
    }
    function headerRender() {
        return (
            <Modal.Header closeButton={!!props.showCloseButton}>
                <div className="headerContent">
                    {!!props.logo ? <ImgBackground className="companyLogo" /> : ""}
                    {!!props.title ? (
                        <Modal.Title as="h1">
                            <>{props.title}</>
                        </Modal.Title>
                    ) : (
                        ""
                    )}
                </div>
            </Modal.Header>
        );
    }
    return (
        <Modal
            animation={true}
            backdrop="static"
            backdropClassName={props.backdropClassName || ""}
            className={`popupView ${props.className || ""}`}
            show={!!props.visible}
            onHide={typeof props.onHiding === "function" ? props.onHiding : emtpyFcnt}
            scrollable={true}
            centered={true}
        >
            {headerRender()}
            <Modal.Body>
                {typeof props.children === "string" ? <div dangerouslySetInnerHTML={{ __html: props.children }}></div> : props.children}
            </Modal.Body>
            {typeof props.footerRender === "function" || props.submitSection ? (
                <Modal.Footer>
                    {
                        <>
                            {props.submitSection ? <SubmitSection submitBtnState={props.submitBtnState} /> : ""}
                            {typeof props.footerRender === "function" ? props.footerRender() : ""}
                        </>
                    }
                </Modal.Footer>
            ) : (
                ""
            )}
        </Modal>
    );
}

export default Popup;
