import { LocaleManager } from "@bryntum/calendar/calendar.umd.js";
LocaleManager.registerLocale("Fr", {
    desc: "French",
    locale: {
        //Core Locale
        localeName: "Fr",
        localeDesc: "French",
        Object: { Yes: "Oui", No: "Non", Cancel: "Annuler", Ok: "OK" }, //region Widgets
        Combo: {
            noResults: "Aucun résultat",
            recordNotCommitted: "L'enregistrement n'a pas pu être ajouté",
            addNewValue: function addNewValue(value) {
                return "Ajouter ".concat(value);
            },
        },
        FilePicker: { file: "Fichier" },
        Field: {
            badInput: "Valeur du champ invalide",
            patternMismatch: "La valeur ne correspond pas au motif spécifié",
            rangeOverflow: function rangeOverflow(value) {
                return "Value must be less than or equal to ".concat(value.max);
            },
            rangeUnderflow: function rangeUnderflow(value) {
                return "Value must be greater than or equal to ".concat(value.min);
            },
            stepMismatch: "Value should fit the step",
            tooLong: "Value should be shorter",
            tooShort: "Value should be longer",
            typeMismatch: "Value is required to be in a special format",
            valueMissing: "This field is required",
            invalidValue: "Invalid field value",
            minimumValueViolation: "Minimum value violation",
            maximumValueViolation: "Maximum value violation",
            fieldRequired: "This field is required",
            validateFilter: "Value must be selected from the list",
        },
        DateField: { invalidDate: "Date non valide" },
        NumberFormat: { locale: "fr-FR", currency: "EUR" },
        DurationField: { invalidUnit: "Unité non valide" },
        TimeField: { invalidTime: "Invalid time input" },
        List: { loading: "Chargement..." }, // needed here due to LoadMaskable
        GridBase: { loadMask: "Chargement...", syncMask: "Saving changes, please wait..." },
        PagingToolbar: {
            firstPage: "Go to first page",
            prevPage: "Go to previous page",
            page: "Page",
            nextPage: "Go to next page",
            lastPage: "Go to last page",
            reload: "Reload current page",
            noRecords: "No records to display",
            pageCountTemplate: function pageCountTemplate(data) {
                return "of ".concat(data.lastPage);
            },
            summaryTemplate: function summaryTemplate(data) {
                return "Displaying records ".concat(data.start, " - ").concat(data.end, " of ").concat(data.allCount);
            },
        },
        PanelCollapser: { Collapse: "Collapse", Expand: "Expand" },
        UndoRedo: { Undo: "Undo", Redo: "Redo", UndoLastAction: "Undo last action", RedoLastAction: "Redo last undone action" },
        DateHelper: {
            locale: "fr-FR",
            weekStartDay: 1, // Non-working days which match weekends by default, but can be changed according to schedule needs
            nonWorkingDays: { 0: true, 6: true }, // Days considered as weekends by the selected country, but could be working days in the schedule
            weekends: { 0: true, 6: true },
            unitNames: [
                { single: "milliseconde", plural: "millisecondes", abbrev: "ms" },
                { single: "seconde", plural: "secondes", abbrev: "s" },
                { single: "minute", plural: "minutes", abbrev: "min" },
                { single: "heure", plural: "heures", abbrev: "h" },
                { single: "jour", plural: "jours", abbrev: "j" },
                { single: "semaine", plural: "semaines", abbrev: "sem" },
                { single: "mois", plural: "mois", abbrev: "mois" },
                { single: "trimestre", plural: "trimestres", abbrev: "trim" },
                { single: "année", plural: "années", abbrev: "an" },
                { single: "décennie", plural: "décennies", abbrev: "dec" },
            ],
            // Used to build a RegExp for parsing time units. The full names from above are added into the generated Regexp.
            //So you may type \"2 w\" or \"2 wk\" or \"2 week\" or \"2 weeks\" into a DurationField.
            //When generating its display value though, it uses the full localized names above.
            unitAbbreviations: [
                ["mil"],
                ["s", "sec"],
                ["m", "min"],
                ["h", "hr"],
                ["d"],
                ["w", "wk"],
                ["mo", "mon", "mnt"],
                ["q", "quar", "qrt"],
                ["y", "yr"],
                ["dec"],
            ],
            parsers: { L: "MM/DD/YYYY", LT: "HH:mm A" },
            ordinalSuffix: function ordinalSuffix(number) {
                var hasSpecialCase = ["11", "12", "13"].find(function (n) {
                    return number.endsWith(n);
                });
                var suffix = "th";
                if (!hasSpecialCase) {
                    var lastDigit = number[number.length - 1];
                    suffix = { 1: "st", 2: "nd", 3: "rd" }[lastDigit] || "th";
                }
                //return number + suffix;
                return number;
            },
        },
        TrialButton: { downloadTrial: "Download trial" },
        TrialPanel: {
            title: "Please complete fields",
            name: "Nom",
            email: "Email",
            company: "Entreprise",
            product: "Produit",
            cancel: "Annuler",
            submit: "Valider",
            downloadStarting: "Téléchargement en cours...",
        },
        // Calendar Locale
        EventEdit: {
            "All day": "Toute la journée",
            Calendar: "Calendrier",
            Cancel: "Annuler",
            Delete: "Supprimer",
            "Edit event": "Détails de l'évènement",
            End: "Fin",
            Name: "Nom",
            None: "Aucun",
            Repeat: "Période",
            Resource: "Ressource",
            Save: "Enregistrer",
            Start: "Début",
        },
        Calendar: {
            Today: "Aujourd'hui",
            next: function next(unit) {
                return "Suivant ".concat(unit);
            },
            previous: function previous(unit) {
                return "Précédent ".concat(unit);
            },
            plusMore: function plusMore(value) {
                return "+".concat(value, " more");
            },
        },
        CalendarDrag: { holdCtrlForRecurrence: "Hold CTRL for a recurring event" },
        EventTip: { "Edit event": "Edit event" },
        AgendaView: { Agenda: "Agenda" },
        MonthView: { Month: "Mois", monthUnit: "month" },
        WeekView: { Week: "Semaine", weekUnit: "week" },
        YearView: { Year: "Année", yearUnit: "year" },
        EventList: { List: "Liste", Start: "Début", Finish: "Terme" },
        DayView: {
            Day: "Jour",
            dayUnit: "day",
            daysUnit: "days",
            expandAllDayRow: "Expand all-day section",
            collapseAllDayRow: "Collapse all-day section",
        },
        Sidebar: { "Filter events": "Types d'intervention" },
        WeekExpander: { expandTip: "Click to expand row", collapseTip: "Click to collapse row" },
    },
});
LocaleManager.applyLocale("Fr");
