import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData } from "../../../reducers/private/private.js";

import PortalText from "../../../../ressources/components/portalFields/portalText/portalText";
import PortalBtn from "../../../../ressources/components/portalBtn/portalBtn";

export default function MfaLogin({ getViewContext, setStyle, request, msgBox, redirectTo }) {
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        const data_user_mfa = getViewContext();
        const formData = new FormData(e.target);
        const dataMfa = {
            ...data_user_mfa,
            mfa_code: formData.get("mfa_code"),
        };
        request({
            view: "private",
            adm_view_id: null,
            action: "post_check_mfa",
            token: dataMfa.token,
            data: dataMfa,
        })
            .then((response) => {
                //We are logged
                dispatch(setLoginData(response.data));
                document.Wrapper.request({
                    type: "sessionStorage",
                    module: "session",
                    view: "all",
                    action: "set_session",
                    data: response.data,
                })
                    .then(function (response) {})
                    .catch(function (error) {
                        console.error(error.msg);
                    });
                redirectTo(response.data.default_view);
            })
            .catch((error) => {
                msgBox(error.msg, "error", "msg");
            });
    };

    return (
        <>
            {setStyle()}
            <div className="card-mfa-login">
                <section>
                    <h1 className="title-mfa-login">Authentification à deux facteurs </h1>
                    <p className="description-mfa-login">
                        Accédez à votre boîte e-mail entrer sur le site ou vos SMS et saisissez le code à 6 chiffres fourni pour finaliser
                        la procédure de connexion.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <PortalText
                            className="input-mfa-login"
                            name="mfa_code"
                            type="text"
                            autoComplete="off"
                            placeholder="Code d'authentification"
                            required={true}
                            aria-required={true}
                        />
                        <div className="test-yvanne">
                            <PortalBtn className="btn-mfa-login" type="submit">
                                Vérifier
                            </PortalBtn>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
}
