import React, { useEffect, useState } from "react";
import { BryntumCombo } from "@bryntum/calendar-react";
import TooltipWrapper from "../tooltipWrapper/tooltipWrapper";
import { crudDisplay, crudDisabled } from "../../../lib/js/globalFunctions.js";
import "./portalCombo.css";

/*Install : - La bibliothèque font-awesome doit être intallée dans le projet
            - La bilbiothèque Bryntum Calendar React doit être installée
Configs (En plus de celles de Bryntum) :
{
    Tous les paramètres disponibles dans un Core.widget.Combo de Bryntum Calendar-react sauf les paramètres suivants :
        # 'required', 'label', 'cls', 'inputAttributes', 'ref', 'value'

    inputName = STRING (!!REQ) [defaultBryntumValue] => Le nom de l'input qui est associée à ce composant (Obligatoire pour la bonne sémantique d'accessibilité)

    aria-label = STRING (!!REQ) [''] => Texte qui va représenter le label de ce champ pour les lecteurs d'écran
        # Le label par défaut est désactivé pour raison de cohérence de graphisme avec les autres composants
        # On affecte un label 'visuel' à l'extérieur de ce composant et on met la propriété 'aria-label' pour l'accessibilité 

    items = ARRAY (!!REQ) [[]] => Lire la documentation du BryntumCombo (event 'change')  (https://www.bryntum.com/products/calendar/docs/api/Core/widget/Combo) 

    defaultValue (opt) [null] => Valeur initiale de l'input
        # Permet aussi de modifier la valeur interne de l'input par un composant extérieur
        # L'évènement 'onChange' est également déclenché lorsque 'defaultValue' est modifiée depuis un composant extérieur

    onChange = CALLBACK FCTN (opt) [emptyFctn] => Lire la documentation du BryntumCombo  (https://www.bryntum.com/products/calendar/docs/api/Core/widget/Combo) 

    invalid = BOOLEAN (opt) [false] => Pour spécifier que ce champ est invalide

    className : STRING (opt) [''] => Classe que l'on peut ajouter au conteneur du Composant
        # Remplace la propriété 'cls' de Bryntum pour pour 'coller' avec la sémantique React

    onChange : Function (opt) [emptyFctn] => Callback exécutée lorsque l'utilisateur change la valeur stockée par le Composant
        # Cet évènement est également déclenché lorsque la valeur est modifiée par un composant extérieur

    required : BOOLEAN (opt) [false] => Indiquer que ce champ est obligatoire
        # Différent du champ 'required' de Bryntum qui est désactivé sur ce composant

    aria-describedby : STRING (opt) [''] => Indiquer l'ID d'une balise externe qui donne des informations sur ce champ (elles seront lues par le lecteur d'écran après ce qui est écrit dans le label)

    tooltip = STRING (opt) [''] => Le contenu du tooltip associé au champ
        # Pour afficher une brève description du contenu du champ    
    
    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input

    displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
        # !! Si on affecte d'autres droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

    activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'actif'
        # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

    actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
        # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
        # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
        # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'
*/
export default function PortalCombo(props) {
    //Pre-rendering treatments
    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    const filteredProps = deleteProps(
        "inputName",
        "invalid",
        "aria-label",
        "aria-describedby",
        "label",
        "tooltip",
        "tooltipAlign",
        "displayActions",
        "activeActions",
        "actionAllowed",
        "className",
        "cls",
        "required",
        "ref",
        "inputAttributes",
        "value",
        "readOnly",
        "editable"
    );
    return crudDisplay(props.displayActions, props.actionAllowed) ? (
        <TooltipWrapper tooltip={props.tooltip} tooltipAlign={props.tooltipAlign}>
            <BryntumCombo
                value={props.defaultValue}
                cls={`portalCombo-container${props.invalid ? " invalid" : ""}${props.className ? " " + props.className : ""}`}
                listCls="portalComboPicker-container"
                editable={!!props.editable ? true : false}
                labelPosition="above"
                inputAttributes={{
                    id: props?.inputName || null,
                    name: props?.inputName || null,
                    role: "combobox",
                    "aria-label": props["aria-label"],
                    "aria-invalid": !!props.invalid,
                    "aria-describedby": props["aria-describedby"],
                    required: !!props.required,
                    invalid: !!props.invalid,
                    placeholder: props.placeholder,
                }}
                readOnly={props.readOnly || crudDisabled(props.activeActions, props.actionAllowed)}
                triggers={{
                    error: props.invalid
                        ? {
                              cls: "errorIcon",
                              handler(e) {
                                  /*console.log("hello");
                                  console.log(e);*/
                              },
                          }
                        : null,
                }}
                {...filteredProps}
            />
        </TooltipWrapper>
    ) : (
        ""
    );
}
