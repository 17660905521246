import React from "react";
import { formatName } from "../../../../../../../ressources/lib/js/globalFunctions";
import ViewItemGroup from "../components/viewItemGroup/viewItemGroup";
export default function Screen(props) {
    const selectedClient = props.selectedClient || {};
    const loggedUser = props.loggedUser || {};
    const menu = props?.menu?.menu_home || {};
    function welcomeTxtRender() {
        if (selectedClient.ent_entity_id && selectedClient.ent_entity_id !== loggedUser.ent_entity_id) {
            return (
                "Bienvenue sur l'espace personnel de " +
                formatName({
                    first_name: selectedClient.first_name,
                    last_name: selectedClient.last_name,
                })
            );
        }
        console.log(loggedUser);
        return props.i18n(`info.welcome_home_${loggedUser?.rol_type_name || "client"}`);
    }
    function viewItemRender() {
        const itemList = [];
        Object.keys(menu).forEach(function (headerName) {
            if (headerName !== "home") {
                //On n'envoie pas la home
                itemList.push(
                    <ViewItemGroup
                        key={`${headerName}_group`}
                        headerName={headerName}
                        items={menu[headerName]}
                        i18n={props.i18n}
                        redirectTo={props.redirectTo}
                    />
                );
            }
        });
        return itemList;
    }
    return (
        <>
            {props.setStyle("./v_yvelines/desktop")}
            <div className="coverBanner">
                <div className="coverImg"></div>
            </div>
            <div className="homeContentContainer">
                <h1 className="welcomeTitle">{welcomeTxtRender()}</h1>
                <div className="viewGroupContainer">
                    <div className="viewGroupContent">{viewItemRender()}</div>
                </div>
            </div>
        </>
    );
}
