import React from "react";
import PortalTitle from "../../../../../../../../ressources/components/portalTitle/portalTitle.js";
import ImgBackground from "../../../../../../../../ressources/components/imgBackground/imgBackground";
export default function SuccessCreation(props) {
    const registeredUsername = props.registeredUsername;
    return (
        <>
            {/*<PortalTitle title={props.i18n("title.local_creation")} />*/}
            <div className="successContent">
                <ImgBackground className="successRegisterLogo" />
                <p className="successTxt">
                    <p className="sentMailBlock">
                        Nous vous avons envoyé un message dans votre boite e-mail :&nbsp;
                        <span className="emailSent">{registeredUsername || "email inconnu"}</span>
                    </p>
                    <br />
                    <p className="mainInstruction">Cliquez sur le lien de confirmation pour valider votre compte.</p>
                    <p>Cela peut prendre quelques minutes, regardez dans vos courriers indésirables en cas de non-réception.</p>
                </p>
            </div>
        </>
    );
}
