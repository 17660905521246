import React from "react";
import { useState, useEffect, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import BtnToolTip from "../../btnToolTip/btnToolTip.js";
import "./tooltipWrapper.css";
/*
Install : - Les styles css associés (paragraphe TooltipWrapper) doivent être chargés dans le projet
Configs :
{
    tooltip = STRING (opt) [''] => Le contenu du tooltip
    
    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input
}
*/
export default function TooltipWrapper(props) {
    const align = props?.tooltipAlign === "left" ? "left" : "right";
    //Pre-rendering
    const popover = (
        <Popover>
            {/*<Popover.Title as="h3">Popover right</Popover.Title>*/}
            <Popover.Content dangerouslySetInnerHTML={{ __html: props?.tooltip }}></Popover.Content>
        </Popover>
    );
    const tooltipShowBtn = (
        <OverlayTrigger
            trigger="click"
            placement={"top" /*align === "left" ? "auto-start" : "auto-end"*/}
            overlay={popover}
            rootClose={true}
        >
            <BtnToolTip iconCls="fas fa-question" className={`tooltipShowBtn ${align}`}></BtnToolTip>
        </OverlayTrigger>
    );

    return props?.tooltip ? (
        <div className="tooltipWrapper">
            {align === "left" ? tooltipShowBtn : ""}
            {props.children}
            {align === "right" ? tooltipShowBtn : ""}
        </div>
    ) : (
        props.children
    );
}
