//######################################## Imports ########################################//
/**********Logic Components and modules**********/
//External libraries
//React Hooks
import React from "react";
import { useState, useEffect } from "react";

//React Redux Hooks (https://react-redux.js.org/) => Global store API

//Custom library
import { isEmailType, isPhoneType, buildFeedBack } from "../../../../ressources/lib/js/globalFunctions.js";
/************************************************/
/**********UI Components**********/
//External Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom UI Components
import InitView from "../../../../ressources/components/initView/initView";
import PortalCheck from "../../../../ressources/components/portalFields/portalCheck/portalCheck.js";
import PortalPhone from "../../../../ressources/components/portalFields/portalPhone/portalPhone.js";
import PortalText from "../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalBtn from "../../../../ressources/components/portalBtn/portalBtn.js";
/*********************************/
//#########################################################################################//

//######################################## Component definition ########################################//
function UserNews(props) {
    /********** Variables definition **********/
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [viewAction, setViewAction] = useState([]);
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [activeNews, setActiveNews] = useState(false);
    const [registerNewsFirstTry, setRegisterNewsFirstTry] = useState(false);
    const [newsModified, setNewsModified] = useState(false);
    const [formEditNews, setFormEditNews] = useState({
        email: "",
        email_libre: "",
        mobile_phone: "",
    });
    /******************************************/
    //Functions used by effects
    function loadNews(event) {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        props
            .request({
                selected_client: null,
            })
            .then(function (response) {
                const news = response.data;
                handleResponseHelp(response);
                setActiveNews(news.toggled);
                setFormEditNews({
                    email: news.email,
                    email_libre: news.email_libre,
                    mobile_phone: news.mobile_phone,
                });
                setViewData(news);
                setViewAction(response?.action_allowed);
                setIsloading(false);
            })
            .catch(function (error) {
                setErrorLoading(true);
                console.error(error);
            });
    }

    /********** Effects treatments **********/
    useEffect(function () {
        props.updateUserJourney(["home", "home_communication", "user_news"]);
        loadNews();
    }, []);

    /****************************************/
    // Init render treatments
    if (isLoading && !errorLoading) {
        return <InitView type="loading" />;
    }
    if (errorLoading) {
        return <InitView type="errorLoading" />;
    }
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)
    // Global functions
    function validateFormEditNews() {
        if (formEditNews.email.length < 1 && formEditNews.email_libre.length < 1 && formEditNews.mobile_phone.length < 1 && activeNews) {
            props.msgBox("Veuillez renseigner au moins un moyen de contact valide (email ou sms)", "error", "msg");
            return false;
        }
        if (formEditNews.email.length < 1 && formEditNews.email_libre.length > 0) {
            props.msgBox("Veuillez d'abord renseigner le champ E-mail principal (champ précédent)", "error", "msg");
            return false;
        }
        if (formEditNews.email.length > 0 && !isEmailType(formEditNews.email)) {
            props.msgBox("Veuillez renseigner un email valide", "error", "msg");
            return false;
        }
        if (formEditNews.email_libre.length > 0 && !isEmailType(formEditNews.email_libre)) {
            props.msgBox("Veuillez renseigner un second email valide", "error", "msg");
            return false;
        }
        if (formEditNews.mobile_phone.length > 0 && !isPhoneType(formEditNews.mobile_phone)) {
            props.msgBox("Veuillez renseigner un numéro de téléphone mobile valide", "error", "msg");
            return false;
        }
        return true;
    }
    // Treatment functions
    function resetViewStates() {
        setFormEditNews({
            email: viewData.email,
            email_libre: viewData.email_libre,
            mobile_phone: viewData.mobile_phone,
        });
        setActiveNews(viewData.toggled);
    }
    function synchronizeViewData() {
        let newViewData = { ...viewData };
        newViewData.email = formEditNews.email;
        newViewData.email_libre = formEditNews.email_libre;
        newViewData.mobile_phone = formEditNews.mobile_phone;
        newViewData.toggled = activeNews;
        setViewData(newViewData);
    }
    //Request functions
    function postEditNews(event) {
        event.preventDefault();
        if (!validateFormEditNews()) {
            setRegisterNewsFirstTry(true);
            return;
        }
        setIsSendingRequest(true);
        if (activeNews) {
            props
                .request({
                    selected_client: null,
                    action: "post_user_news_data",
                    data: formEditNews,
                })
                .then(function (response) {
                    props.msgBox(
                        "Votre demande a bien été prise en compte. Consultez votre messagerie pour retrouver les détails de votre demande",
                        "info",
                        "msg"
                    );
                    synchronizeViewData(); //On actualise viewData dans le nouvel état serveur (car la demande a aboutie)
                })
                .catch(function (error) {
                    console.error("Erreur lors de l'enregistrement aux actualités (post_user_news_data)");
                    console.error(error.msg);
                    props.msgBox("Échec de la mise à jour de vos informations", "error", "msg");
                    resetViewStates(); //On remet la vue dans l'état serveur intial (car la demande a échoué)
                })
                .finally(function () {
                    setIsSendingRequest(false);
                });
        } else {
            props
                .request({
                    selected_client: null,
                    action: "post_user_news_delete",
                    data: {},
                })
                .then(function (response) {
                    props.msgBox("Votre demande d'annulation a été prise en compte", "info", "msg");
                    synchronizeViewData(); //On actualise viewData dans le nouvel état serveur (car la demande a aboutie)
                })
                .catch(function (error) {
                    console.error("Erreur lors du désabonnement aux actualités (post_user_news_delete)");
                    console.error(error.msg);
                    props.msgBox("Échec de la mise à jour de vos informations", "error", "msg");
                    resetViewStates(); //On remet la vue dans l'état serveur intial (car la demande a échoué)
                })
                .finally(function () {
                    setIsSendingRequest(false);
                });
        }
    }
    //UI functions
    function onSwitchNewsAction(value) {
        setActiveNews(value);
        setNewsModified(true);
        if (!value) {
            setFormEditNews({
                email: "",
                email_libre: "",
                mobile_phone: "",
            });
            setRegisterNewsFirstTry(false);
        } else {
            setFormEditNews({
                email: viewData.email,
                email_libre: viewData.email_libre,
                mobile_phone: viewData.mobile_phone,
            });
        }
    }

    function setEditNewsFormField(field, value) {
        // Exemple d'utilisation : setEditNewsFormField('email', 'email@domain.com') => form['email'] = "email@domain.com";
        setNewsModified(true);
        let newFormEditNews = { ...formEditNews };
        newFormEditNews[field] = value;
        setFormEditNews(newFormEditNews);
    }
    return (
        <div className="user_news">
            {props.setStyle("./normal")}
            <Form className="user-news-section">
                <fieldset className="news-activation-section">
                    <legend>Gestion de l'abonnement aux actualités</legend>
                    <PortalCheck
                        actionAllowed={viewAction}
                        inputName="disable-actuality"
                        activeIcon="far fa-dot-circle"
                        baseIcon="far fa-circle"
                        text="Je ne souhaite pas recevoir d'actualités"
                        textAlign="right"
                        active={!activeNews}
                        onChange={(e) => onSwitchNewsAction(false)}
                        className="check-disable-news"
                        tabIndex={0}
                        autoFocus={true}
                    />
                    <PortalCheck
                        actionAllowed={viewAction}
                        inputName="enable-actuality"
                        activeIcon="far fa-dot-circle"
                        baseIcon="far fa-circle"
                        text="Je souhaite recevoir des actualités"
                        textAlign="right"
                        active={activeNews}
                        onChange={(e) => onSwitchNewsAction(true)}
                        tabIndex={0}
                    />
                </fieldset>
                <Row className={`user-news-form ${!activeNews ? "disabled" : ""}`}>
                    <Form.Group as={Col} xs={12} md={6} controlId="mainEmail">
                        <Form.Label>E-mail principal</Form.Label>
                        <PortalText
                            id="mainEmail"
                            actionAllowed={viewAction}
                            onChange={(e) => setEditNewsFormField("email", e.target.value)}
                            type="email"
                            autocomplete="email"
                            name="mainEmail"
                            value={formEditNews["email"]}
                            isInvalid={!isEmailType(formEditNews.email) && activeNews && registerNewsFirstTry}
                            aria-invalid={!isEmailType(formEditNews.email) && activeNews && registerNewsFirstTry}
                            disabled={!activeNews}
                            aria-describedby="mainEmail-1 mainEmail-2"
                        />
                        {buildFeedBack(
                            "mainEmail",
                            [
                                "Renseigner au moins un moyen de contact valide (email ou sms)",
                                "Doit respecter le format d'un email (exemple@domain.com)",
                            ],
                            [
                                formEditNews.email.length < 1 &&
                                    formEditNews.email_libre.length < 1 &&
                                    formEditNews.mobile_phone.length < 1 &&
                                    activeNews,
                                !isEmailType(formEditNews.email) && activeNews,
                            ],
                            registerNewsFirstTry
                        )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="emailLibre">
                        <Form.Label>E-mail secondaire</Form.Label>
                        <PortalText
                            id="emailLibre"
                            actionAllowed={viewAction}
                            onChange={(e) => setEditNewsFormField("email_libre", e.target.value)}
                            type="email"
                            autocomplete="email"
                            name="emailLibre"
                            value={formEditNews["email_libre"]}
                            disabled={!activeNews}
                            isInvalid={
                                (!isEmailType(formEditNews.email_libre) || formEditNews.email.length < 1) &&
                                formEditNews.email_libre.length > 0 &&
                                registerNewsFirstTry
                            }
                            aria-describedby="emailLibre-1 emailLibre-2"
                        />
                        {buildFeedBack(
                            "emailLibre",
                            ["Renseigner le champ E-mail principal", "Doit respecter le format d'un email (exemple@domain.com)"],
                            [
                                formEditNews.email.length < 1 && formEditNews.email_libre.length > 0,
                                formEditNews.email_libre.length > 0 && !isEmailType(formEditNews.email_libre),
                            ],
                            registerNewsFirstTry
                        )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="mobilePhone">
                        <Form.Label>Numéro de mobile (sms)</Form.Label>
                        <PortalPhone
                            id="mobilePhone"
                            actionAllowed={viewAction}
                            separator=" "
                            groupSize={2}
                            onChange={(e, newValue) => setEditNewsFormField("mobile_phone", newValue)}
                            autocomplete="tel-local"
                            name="mobilePhone"
                            defaultValue={formEditNews["mobile_phone"]}
                            disabled={!activeNews}
                            isInvalid={!isPhoneType(formEditNews.mobile_phone) && activeNews && registerNewsFirstTry}
                            aria-invalid={!isPhoneType(formEditNews.mobile_phone) && activeNews && registerNewsFirstTry}
                            aria-describedby="mobilePhone-1 mobilePhone-2"
                            aria-required={false}
                        />
                        {buildFeedBack(
                            "mobilePhone",
                            [
                                "Renseigner au moins un moyen de contact valide (email ou sms)",
                                "Doit respecter le format d'un numéro de mobile",
                            ],
                            [
                                formEditNews.email.length < 1 &&
                                    formEditNews.email_libre.length < 1 &&
                                    formEditNews.mobile_phone.length < 1 &&
                                    activeNews,
                                !isPhoneType(formEditNews.mobile_phone) && activeNews,
                            ],
                            registerNewsFirstTry
                        )}
                    </Form.Group>
                </Row>
                {newsModified ? (
                    <div className="news-validation-section">
                        <PortalBtn
                            type="submit"
                            onClick={postEditNews}
                            className="validate"
                            state={isSendingRequest ? "loading" : ""}
                            loadingTxt="Enregistrement de la demande"
                        >
                            Valider
                        </PortalBtn>
                    </div>
                ) : (
                    ""
                )}
            </Form>
        </div>
    );
}

export default UserNews;
