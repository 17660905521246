import React from "react";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../../application/reducers/private/private.js";
import { selectDefaultView } from "../../../application/reducers/public/public.js";
import { useEffect } from "react";
import InitView from "../../../ressources/components/initView/initView";
function NotFound(props) {
    const loggedUser = useSelector(selectLoggedUser);
    const defaultView = useSelector(selectDefaultView);
    function userIsLogged() {
        return !!loggedUser?.ent_entity_id;
    }
    useEffect(function () {
        const viewName = window.location.hash.slice(2);
        const redirectionHash = defaultView || "login";
        console.error("VUE " + viewName + " NOT FOUND");
        if (!userIsLogged()) {
            //On retourne au login si on arrive sur une vue not_found quand on n'est pas connecté
            console.error("REDIRECTION TO " + redirectionHash);
            props.redirectTo(redirectionHash, null, true);
        }
    }, []);
    return <InitView type="noData" noDataMsg={props.i18n("error.view_not_found")} />;
}
export default NotFound;
