import React from "react";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import ImgBackground from "../../../../../../../../ressources/components/imgBackground/imgBackground";
export default function SuccessPwdSet(props) {
    const actionType = props.actionType;
    const successMsg = props.successMsg;
    function onClickFollowBtn() {
        props.redirectTo("login");
    }
    return (
        <div className="successContainer">
            <div className="leftPart">
                <h2 className="mainSuccessTxt">{successMsg}</h2>
                <PortalBtn onClick={onClickFollowBtn} className="followBtn" iconCls="fas fa-arrow-right" iconAlign="right">
                    Me connecter
                </PortalBtn>
            </div>
            {actionType === "post_create_username" && (
                <div className="rightPart">
                    <ImgBackground className="successLogo" />
                </div>
            )}
        </div>
    );
}
