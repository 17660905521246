import React from "react";
import { useState, useEffect, useRef } from "react";
import cloneDeep from "clone-deep";
import NavSectionMenu from "../navSectionMenu/navSectionMenu";
import FloatContainer from "../../../floatContainer/floatContainer";
import "./menuItem.css";
export default function MenuItem(props) {
    /**
     * @param {Object} props :{
     *      @param {Object} data [{}] : {
     *          @param {String} direction [undefined] : Le hash de la vue sur laquelle l'item pointe
     *
     *          @param {Object[]} items [null] : les sous-items de menus possibles que cet item contient
     *              @example [{direction: '', items: [{direction:'', items:[...]}, ...]}]
     *      }
     *
     *      @param {Function} i18n [emptyFctn] : La fonction pour traduire les champs 'direction' en des noms de vues lisibles
     *
     *      @param {String} subNavType ['float'] : Indique (quand l'item de menu est une branche de navigation d'un sous-menu), le type d'affichage de ce sous-menu
     *          @descr Types possibles : 'float', 'navSection', ('tree') => Not available yet
     *
     *      @param {Function} onClick [emptyFctn] : Callback qui est déclenchée quand un utilisateur clique sur un item de menu
     *          @param {Object} event : {
     *              @param {String} type : Le type d'événement déclenché au clic sur l'item
     *                  @desc Types possibles : 'toggleFloat', 'goToDirection', 'goToNavSection'
     *              @param {Object} data : Les données de l'item de Menu cliqué
     *          }
     *
     *      @param {Boolean} triggerCollapse [false] : Propriété qui permet de déclencher la fermeture du sous-menu flotant (subNavType = 'float') depuis un composant supérieur
     * }
     */

    function initData(rawData) {
        const data = cloneDeep(rawData);
        if (typeof props.i18n === "function") {
            //console.log(props.i18n("label.view." + data.direction));
            data.label = props.i18n("label.view." + data.direction);
            data.icon = props.i18n("icon.view." + data.direction + "_icon");
        } else {
            data.label = data.direction;
        }
        return data || {};
    }
    const data = initData(props.data);
    const label = data.label || "unknown";
    const items = data.items || [];
    const subNavType = props.subNavType || "float"; //Values : 'float' || 'navSection' || 'tree'
    const className = props.className;
    const triggerCollapse = !!props.triggerCollapse;

    const [floatContainerOpened, setFloatContainerOpened] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const toggleFloatBtnRef = useRef();
    const subnavLogoSpanRef = useRef();
    const itemLabelRef = useRef();

    useEffect(
        function () {
            setFloatContainerOpened(false);
        },
        [triggerCollapse]
    );

    //Event Handlers
    function toggleFloat(event) {
        const toggleFloatBtn = toggleFloatBtnRef.current;
        const subnavLogoSpan = subnavLogoSpanRef.current;
        const itemLabel = itemLabelRef.current;
        //console.log(subnavLogoSpan);
        if (event.target === toggleFloatBtn || event.target === subnavLogoSpan || event.target === itemLabel)
            setFloatContainerOpened(!floatContainerOpened);
        if (typeof props.onClick === "function") props.onClick({ type: "toggleFloat", data: data });
    }
    function goToDirection() {
        if (typeof props.redirectTo === "function") props.redirectTo(data.direction);
        if (typeof props.onClick === "function") props.onClick({ type: "goToDirection", data: data });
    }
    function goToNavSection() {
        if (typeof props.onClick === "function") props.onClick({ type: "goToNavSection", data: data });
    }
    function onFocusMenuItem() {
        setHasFocus(true);
    }
    function onBlurMenuItem(event) {
        setTimeout(function () {
            //Timeout because onClick events inside menu are not triggered on safari (fuck you apple) if onBlur runs first...
            const targetElClick = event?.relatedTarget;
            const toggleFloatBtn = toggleFloatBtnRef.current;
            const canFindInnerComponent = typeof toggleFloatBtn?.contains === "function";
            const clickIsOutsideMenuItem = canFindInnerComponent && toggleFloatBtn && !toggleFloatBtn.contains(targetElClick);
            if (clickIsOutsideMenuItem) {
                setHasFocus(false);
                setFloatContainerOpened(false);
            }
        }, 0);
    }
    function render() {
        switch (true) {
            case items.length > 0 && subNavType === "navSection":
                return (
                    <li
                        className={
                            `portalMenuItem navSection ${data.direction}${hasFocus ? " focused" : ""}` + (className ? " " + className : "")
                        }
                        onClick={goToNavSection}
                        onFocus={onFocusMenuItem}
                        onBlur={onBlurMenuItem}
                        tabIndex={0}
                    >
                        <span className="itemLabel" ref={itemLabelRef}>
                            {label}
                        </span>
                        <span className="subnavLogo fas fa-chevron-right" ref={subnavLogoSpanRef}></span>
                    </li>
                );
            case items.length > 0 && subNavType === "float":
                return (
                    <li
                        className={
                            `portalMenuItem float ${data.direction}${hasFocus ? " focused" : ""}` + (className ? " " + className : "")
                        }
                        onClick={toggleFloat}
                        ref={toggleFloatBtnRef}
                        onFocus={onFocusMenuItem}
                        onBlur={onBlurMenuItem}
                        tabIndex={0}
                    >
                        <span className="itemLabel" ref={itemLabelRef}>
                            {label}
                        </span>
                        <span className="subnavLogo fas fa-chevron-down" ref={subnavLogoSpanRef}></span>
                        <FloatContainer className="floatSubnavContainer" opened={floatContainerOpened}>
                            {floatContainerOpened ? (
                                <NavSectionMenu data={items} redirectTo={props.redirectTo} i18n={props.i18n} /*title="Test"*/ />
                            ) : (
                                ""
                            )}
                        </FloatContainer>
                    </li>
                );
            default:
                return (
                    <li
                        className={
                            `portalMenuItem goToDirection ${data.direction}${hasFocus ? " focused" : ""}` +
                            (className ? " " + className : "")
                        }
                        onClick={goToDirection}
                        onFocus={onFocusMenuItem}
                        onBlur={onBlurMenuItem}
                        tabIndex={0}
                    >
                        <span className="itemLabel colorTextBase">
                            {data.icon ? <span className={"icon " + data.icon}></span> : ""}
                            <span className="label">{label}</span>
                        </span>
                    </li>
                );
        }
    }
    return render();
}
