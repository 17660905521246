import { isObject, uniqueId } from "../ressources/lib/js/globalFunctions";

const Paho = require("paho-mqtt");

export default class WebSocketMQTT {
    static reject(code, msg) {
        return new Promise(function (rs, rj) {
            rj({
                success: false,
                code: code,
                msg: msg,
                data: [],
            });
        });
    }
    //---------- PUBLIC FUNCTIONS ----------//
    static bindAction(input) {
        /*
            # Usecase : Permet de déclencher (ou non) un traitement sur la box et de s'abonner à ses canaux
                      d'avancement et de réponse

            input : {
                 endPoint : STRING (REQ) => URI du broker MQTT de la box (ex: "ws://51.258.1.1:80")
                ,action: {
                     publish : STRING (opt) [''] => PATH vers le topic pour lancer le traitement à réaliser
                    ,subscribe : {
                         response : STRING (opt) [''] => PATH vers le topic pour récupérer la réponse à un traitement en cours d'exécution
                        ,progress : STRING (opt) [''] => PATH vers le topic pour récupérer des messages sur l'état d'avancement d'un traitement en cours d'exécution
                    }
                } 
                ,token: STRING (REQ) => Token pour vérifier que l'on a bien le droit d'accéder à la session 
                ,selected_client : INT (REQ) => ent_entity_id du client 
                ,data: OBJECT (opt) [{}] => Données métier à fournir au traitement pour qu'il s'exécute correctement
            }
    
            # return Promise : resolve({
                 onResponse(data) : CALLBACK_FUNCTION (opt) [emptyFctn] => Callback qui sera exécutée quand le traitement sera terminé avec la réponse en 1er paramètre
                    # Override avec la fonction que l'on souhaite être exécutée
                    # N'est censée se déclencher qu'UNE seule fois par publish envoyé
                    # data : OBJECT => Données métier renvoyées quand les traitements de l'action de l'instance du WebSocket sont terminés
                    
                ,onProgress(data) : CALLBACK_FUNCTION (opt) [emptyFctn] => Callback qui sera exécutée quand le traitement enverra un message (log) sur l'état d'avancement de l'action
                    # Override avec la fonction que l'on souhaite être exécutée
                    # Peut se déclencher PLUSIEURS fois par publish envoyé pour envoyer des messages d'état
                    # data : OBJECT => Données intermédiaires du traitement en cours d'exécution

                ,onError(error) : CALLBACK_FUNCTION (opt) [emptyFctn] => Callback qui sera exécutée quand une erreur altérera le bon fonctionnement du canal WebSocket
                    # Override avec la fonction que l'on souhaite être exécutée
                    # error : OBJECT => Erreur au format d'une token request Asapro standard {success: false, msg:...}
                
                ,disconnect() : FUNCTION (callable) => Permet de déconnecter le WebSocket

                ,publish(data) : FUNCTION (callable) => Permet de publier un message (pour lancer un traitement) sur l'action à laquelle le WebSocket est connecté
                    data: OBJECT (opt) [{}] => Données métier à fournir au traitement pour qu'il s'exécute correctement
            }),
            reject({
                success: false,
                code: <ErrorCode>
                msg: <ErrorMsg>
            })
        */
        function reject(code, msg) {
            return new Promise(function (rs, rj) {
                rj({
                    success: false,
                    code: code,
                    msg: msg,
                    data: [],
                });
            });
        }
        console.log("WebSocketMQTT.bindAction() input : ", input);
        let errorCode = 4004;
        if (!isObject(input)) {
            return reject(errorCode, `[${errorCode}] - ERROR BIND ACTION - <Input> is not an OBJECT : ${input}`);
        }
        const requiredKeys = ["endPoint", "action", "selected_client", "token"];
        let requiredKey = "";
        for (let i = 0; i < requiredKeys.length; i++) {
            requiredKey = requiredKeys[i];
            if (!input[requiredKey]) {
                errorCode = 4005;
                return reject(errorCode, `[${errorCode}] - ERROR BIND ACTION - Paramètre obligatoire "${requiredKey}" incorrect`);
            }
        }
        const clientID = "asareact_ws_id_" + uniqueId();
        const CONNECT_TIMEOUT = 60; //seconds
        const SUBSCRIBE_TIMEOUT = 60; //seconds
        let client;
        try {
            client = new Paho.Client(input.endPoint, clientID);
        } catch (error) {
            errorCode = 4006;
            return reject(errorCode, `[${errorCode}] - ERROR BIND ACTION - Echec lors de l'instanciation du Websocket :\n${error}`);
        }
        return new Promise(function (rs, rj) {
            const ApiInstance = {
                isConnected: false,
                action: {
                    name: null,
                    publish: input?.action?.publish || null,
                    publishCount: 0,
                    subscribe: {
                        response: {
                            path: input?.action?.subscribe?.response || null,
                            available: false,
                        },
                        progress: {
                            path: input?.action?.subscribe?.progress || null,
                            available: false,
                        },
                    },
                },
                onConnected: function () {},
                onProgress: function () {},
                onResponse: function () {},
                onError: function () {},
                disconnect: function () {
                    console.log("Disconnecting WebSocket");
                    if (ApiInstance.isConnected) {
                        client.disconnect();
                        ApiInstance.isConnected = false;
                    } else {
                        console.error("WebSocket déjà déconnecté");
                    }
                },
                publish: function (data) {
                    let errorMsg = "";
                    return new Promise(function (rs, rj) {
                        if (!ApiInstance.isConnected) {
                            errorCode = 5005;
                            errorMsg = `[${errorCode}] - ERROR BIND ACTION - Erreur lors de la publication sur le topic "${ApiInstance.action.publish}" :\nWebSocket déconnecté`;
                            rj({
                                success: false,
                                code: errorCode,
                                msg: errorMsg,
                                data: [],
                            });
                        } else if (ApiInstance.action.publish) {
                            console.log(`Publish_posting on topic "${ApiInstance.action.publish}" :\n`, data);
                            try {
                                client.publish(ApiInstance.action.publish, JSON.stringify(data));
                            } catch (error) {
                                errorCode = 5006;
                                errorMsg = `[${errorCode}] - ERROR BIND ACTION - Erreur lors de la publication sur le topic "${ApiInstance.action.publish}" :\n${error}`;
                                console.error(errorMsg);
                                rj({
                                    success: false,
                                    code: errorCode,
                                    msg: errorMsg,
                                    cata: [],
                                });
                                return;
                            }
                        } else {
                            errorCode = 5004;
                            errorMsg = `[${errorCode}] - ERROR BIND ACTION - L'instance WebSocket à laquelle vous êtes connecté ne contient aucun topic de publication`;
                            console.error(errorMsg);
                            rj({
                                success: false,
                                code: errorCode,
                                msg: errorMsg,
                                data: [],
                            });
                            return;
                        }
                        ApiInstance.publishCount++;
                        rs();
                    });
                },
            };
            client.onConnectionLost = function (error) {
                ApiInstance.isConnected = false;
                errorCode = 4100;
                let errorMsg = `[${errorCode}/${error.errorCode}] Connexion to WebSocket "${input.endPoint}" ${
                    error.errorCode == 0 ? "closed" : "lost"
                }\n${error.errorMessage}`;
                error.errorCode == 0 ? console.log(errorMsg) : console.error(errorMsg);
                const standardError = {
                    success: false,
                    code: errorCode,
                    msg: errorMsg,
                    data: [],
                };
                ApiInstance.onError(standardError);
                rj(standardError);
            };
            client.onMessageDelivered = function (message) {
                console.log(`Publish_delivered on topic "${message?.destinationName}" :\n`, JSON.parse(message.payloadString || "{}"));
            };
            client.onMessageArrived = function (message) {
                const decodedPayload = JSON.parse(message.payloadString || "{}");
                console.log(`Message arrived on topic "${message?.destinationName}" :\n`, decodedPayload);
                if (message?.destinationName === ApiInstance.action.subscribe.response.path) {
                    ApiInstance.onResponse(decodedPayload);
                }
                if (message?.destinationName === ApiInstance.action.subscribe.progress.path) {
                    ApiInstance.onProgress(decodedPayload);
                }
            };
            client.onConnected = function (reconnect, uri) {
                errorCode = 5001;
                //console.log("Connected to MQTT");
                ApiInstance.isConnected = true;
            };
            client.disconnectedPublishing = false;
            client.disconnectedBufferSize = 5000;
            const connectOptions = {
                timeout: CONNECT_TIMEOUT,
                userName: "guest",
                password: "guest",
                //willMessage: null, //RTFM
                keepAliveInterval: 60, //seconds (will disconnect after x seconds of inactivity)
                cleanSession: true, //RTFM
                useSSL: false, //RTFM
                invocationContext: input,
                onSuccess: function (context) {
                    errorCode = 5000;
                    console.log(`[${errorCode}] - Connection to MQTT succeeded`);
                    ApiInstance.isConnected = true;
                    function checkPendingSubscription() {
                        for (let key in ApiInstance.action.subscribe) {
                            if (ApiInstance.action.subscribe[key]["available"] == "pending") return true;
                        }
                        return false;
                    }
                    function afterAllSubscriptionDone() {
                        if (ApiInstance.action.publish && ApiInstance.action.publishCount < 1) {
                            setTimeout(function () {
                                //To be sure this is executed after handling customization is done by caller
                                ApiInstance.publish(input.data).catch(function (error) {
                                    ApiInstance.onError(error);
                                    rj(error);
                                });
                            }, 0);
                        }
                        rs(ApiInstance);
                    }
                    if (ApiInstance.action.subscribe.response.path) {
                        console.log(`Initiating subscription to "${ApiInstance.action.subscribe.response.path}" topic...`);
                        ApiInstance.action.subscribe.response.available = "pending";
                        client.subscribe(ApiInstance.action.subscribe.response.path, {
                            timeout: SUBSCRIBE_TIMEOUT,
                            onFailure: function (error) {
                                errorCode = 4009;
                                ApiInstance.action.subscribe.response.available = false;
                                rj({
                                    success: false,
                                    code: errorCode,
                                    msg: `[${errorCode}/${error.errorCode}] - ERROR BIND ACTION - Echec lors de l'abonnement au topic : "${ApiInstance.action.subscribe.response.path}"\n${error.errorMessage}`,
                                    data: [],
                                });
                            },
                            onSuccess: function (context) {
                                errorCode = 5002;
                                ApiInstance.action.subscribe.response.available = true;
                                console.log(`[${errorCode}] - Subscription to "${ApiInstance.action.subscribe.response.path}" available`);
                                if (!checkPendingSubscription()) {
                                    afterAllSubscriptionDone();
                                }
                            },
                        });
                    }
                    if (ApiInstance.action.subscribe.progress.path) {
                        console.log(`Initiating subscription to "${ApiInstance.action.subscribe.progress.path}" topic...`);
                        ApiInstance.action.subscribe.progress.available = "pending";
                        client.subscribe(ApiInstance.action.subscribe.progress.path, {
                            timeout: SUBSCRIBE_TIMEOUT,
                            onFailure: function (error) {
                                errorCode = 4010;
                                ApiInstance.action.subscribe.progress.available = false;
                                rj({
                                    success: false,
                                    code: errorCode,
                                    msg: `[${errorCode}/${error.errorCode}] - ERROR BIND ACTION - Echec lors de l'abonnement au topic : "${ApiInstance.action.subscribe.progress.path}"\n${error.errorMessage}`,
                                    data: [],
                                });
                            },
                            onSuccess: function (context) {
                                errorCode = 5003;
                                ApiInstance.action.subscribe.progress.available = true;
                                console.log(`[${errorCode}] - Subscription to "${ApiInstance.action.subscribe.progress.path}" available`);
                                if (!checkPendingSubscription()) {
                                    afterAllSubscriptionDone();
                                }
                            },
                        });
                    }
                    if (!checkPendingSubscription()) {
                        errorCode = 4011;
                        rj({
                            success: false,
                            code: errorCode,
                            msg: `[${errorCode}] - ERROR BIND ACTION - Aucun topic de souscription renseigné`,
                        });
                    }
                },
                onFailure: function (error) {
                    ApiInstance.isConnected = false;
                    errorCode = 4008;
                    rj({
                        success: false,
                        code: errorCode,
                        msg: `[${errorCode}/${error.errorCode}] - ERROR BIND ACTION - Echec lors de la connexion au Websocket :\n${error.errorMessage}`,
                        data: [],
                    });
                },
                hosts: null, //To override instance host with a list of hosts to try connexion
                ports: null, //To override instance port with a list of ports to try connexion
                reconnect: false, //Try to reconnect if connexion is lost
                mqttVersion: 4, //4 or 3.1 or 3.1.1
                mqttVersionExplicit: false,
                uris: null, //To override instance host with a list of "VALID URIs" to try connexion (Use in place of "this.hosts" and "this.ports")
            };
            try {
                client.connect(connectOptions);
            } catch (error) {
                errorCode = 4007;
                rj({
                    success: false,
                    code: errorCode,
                    msg: `[${errorCode}] - ERROR BIND ACTION - Echec lors de la connexion au Websocket :\n${error}`,
                    data: [],
                });
                return;
            }
        });
    }
    //--------------------------------------//
}
