import React from "react";
import "./screen.css";
import { Nav } from "react-bootstrap";
export default function Screen(props) {
    function buildNavLinks() {
        let navListDisplay = [];
        if (props.showcaseList && props.showcaseList.length > 0) {
            const showcaseList = props.showcaseList;
            function onClickNavLink(showcaseParam) {
                let url;
                switch (true) {
                    case !!showcaseParam.internal_hash:
                        //It's not a showcase view, but still an internal view
                        url = window.location.origin + "/#/" + showcaseParam.internal_hash;
                        break;
                    default:
                        url =
                            window.location.origin +
                            `/#/showcase_view?admParameterId=${showcaseParam?.content_param_id}&viewTitle=${showcaseParam?.title}`;
                }
                props
                    .openExternal({
                        url: url,
                        force: true,
                    })
                    .then(function () {})
                    .catch(function () {});
            }
            showcaseList.forEach(function (showcaseParam) {
                navListDisplay.push(
                    <Nav.Link
                        key={`${showcaseParam?.title}_link`}
                        onClick={function (e) {
                            onClickNavLink(showcaseParam);
                        }}
                        role="navigation"
                    >
                        {showcaseParam.title}
                    </Nav.Link>
                );
            });
        }
        return navListDisplay;
    }
    const navListDisplay = buildNavLinks();
    return (
        <div className="publicViewContainer">
            <div className="publicViewContentBlock">{props.children}</div>
            {navListDisplay.length > 0 ? (
                <div className="publicViewFooter">
                    <Nav className="showcaseNav">{navListDisplay}</Nav>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
