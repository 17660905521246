//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect, useRef } from "react";
//Custom Library
import { isEmailType, buildFeedBack } from "../../../../../../../ressources/lib/js/globalFunctions.js";
import PortalFeedback from "../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom UI Components
import PortalTitle from "../../../../../../../ressources/components/portalTitle/portalTitle.js";
import PopupView from "../../../../../../../ressources/components/popupView/popupView.js";
import ItemRow from "../../../../../../../ressources/components/itemRow/itemRow.js";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalPassword from "../../../../../../../ressources/components/portalFields/portalPassword/portalPassword.js";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import Fconnect from "../../../../../../../ressources/components/fconnect/fconnect.js";
import PscConnect from "../../../../../../../ressources/components/pscConnect/pscConnect.js";
import PortalList from "../../../../../../../ressources/components/portalList/portalList.js";
import ImgBackground from "../../../../../../../ressources/components/imgBackground/imgBackground.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//

export default function Screen(props) {
    //Controller params
    const portalType = props.portalType;
    const contactAssist = props.contactAssist;
    const contactUrl = props.contactUrl;
    const pscConnect = props.pscConnect;
    const fConnect = props.fConnect;
    const loginForm = props.loginForm;
    const editLoginForm = props.editLoginForm;
    const recoverPasswordForm = props.recoverPasswordForm;
    const editRecoverPasswordForm = props.editRecoverPasswordForm;
    const clearRecoverPasswordForm = props.clearRecoverPasswordForm;
    const recoverLoginForm = props.recoverLoginForm;
    const editRecoverLoginForm = props.editRecoverLoginForm;
    const clearRecoverLoginForm = props.clearRecoverLoginForm;
    const recoveredLogin = props.recoveredLogin;
    const loginFirstTry = props.loginFirstTry;
    const setLoginFirstTry = props.setLoginFirstTry;
    const recoverPasswordFirstTry = props.recoverPasswordFirstTry;
    const setRecoverPasswordFirstTry = props.setRecoverPasswordFirstTry;
    const recoverLoginFirstTry = props.recoverLoginFirstTry;
    const setRecoverLoginFirstTry = props.setRecoverLoginFirstTry;
    const loginState = props.loginState;
    const recoverPassState = props.recoverPassState;
    const recoverLoginState = props.recoverLoginState;
    const postLogin = props.postLogin;
    const postRecoverPassword = props.postRecoverPassword;
    const postRecoverLogin = props.postRecoverLogin;
    const loginFormValidator = props.loginFormValidator;
    const recoverPasswordFormValidator = props.recoverPasswordFormValidator;
    const recoverLoginFormValidator = props.recoverLoginFormValidator;
    const myPersonalSpaceUrl = props.i18n("external_ref.my_personal_space_url");
    //States
    const [showPopup, setShowPopup] = useState("");

    //Refs
    const autoFocusRef = useRef();
    const issueOptionsFocusRef = useRef();
    const recoverPasswordFocusRef = useRef();
    const recoverLoginFocusRef = useRef();
    const contactAssistanceFocusRef = useRef();
    function contactAssistDisplay() {
        return contactAssist || "<Moyen de contact à renseigner>";
    }

    //Effects treatments
    useEffect(
        function () {
            switch (showPopup) {
                case "issue_options":
                    if (issueOptionsFocusRef.current) issueOptionsFocusRef.current.focus();
                    break;
                case "recover_password":
                    if (recoverPasswordFocusRef.current) recoverPasswordFocusRef.current.focus();
                    break;
                case "recover_login":
                    if (recoverLoginFocusRef.current) recoverLoginFocusRef.current.focus();
                    break;
                case "contact_assistance":
                    if (contactAssistanceFocusRef.current) contactAssistanceFocusRef.current.focus();
                    break;
                default:
                    if (autoFocusRef.current) autoFocusRef.current.focus();
                    break;
            }
        },
        [showPopup]
    );
    useEffect(
        //Screen specific
        function () {
            const specialAction = props.getViewContext();
            if (specialAction?.open) {
                setShowPopup(specialAction?.open);
                props.redirectTo(props.viewName);
                //To force tooltip Popover to close
                const body = document.body;
                if (body) body.click();
            }
        },
        [props.viewHash]
    );

    //View global functions
    function clearPopupFieldStates() {
        clearRecoverPasswordForm();
        clearRecoverLoginForm();
        setRecoverPasswordFirstTry(false);
        setRecoverLoginFirstTry(false);
    }

    //UI event handlers
    function onHidePopup() {
        setShowPopup("");
        clearPopupFieldStates();
    }
    function onReturnPopup() {
        setShowPopup("issue_options");
        clearPopupFieldStates();
    }
    function onSubmitLogin(event) {
        event.preventDefault();
        postLogin().catch(function (err) {
            console.error(err);
            let errorMsg = props.i18n("error." + err.code);
            if (err.code === "wrong_pwd" && !!err.data && typeof err.data === "object" && err.data.remaining_try) {
                const plural = +err.data.remaining_try > 1 ? "s" : "";
                errorMsg += " " + props.i18n("error.pwd_remaining_try", [+err.data.remaining_try, plural]);
            }
            props.msgBox(errorMsg, "error", "msg");
        });
    }
    function onSubmitForgottenPassword(event) {
        event.preventDefault();
        postRecoverPassword()
            .then(function (res) {
                props
                    .msgBox(
                        "Votre demande a bien été prise en compte.\nUn lien de ré-initialisation a été envoyé dans votre boîte mail",
                        "info",
                        "msg"
                    )
                    .then(function (res) {
                        setShowPopup("");
                        clearPopupFieldStates();
                    });
            })
            .catch(function (err) {
                console.error(err);
                props.msgBox(err.msg, "error", "msg");
            });
    }
    function onSubmitForgottenLogin(event) {
        event.preventDefault();
        postRecoverLogin().catch(function (err) {
            console.error(err);
            props.msgBox(err.msg, "error", "msg");
        });
    }
    function onClickConnectIssues(e) {
        e.preventDefault();
        setShowPopup("issue_options");
    }
    function onClickPwdIssues(e) {
        e.preventDefault();
        props.redirectTo("forgotten_password");
    }
    function redirectToMyPersonalSpace() {
        if (myPersonalSpaceUrl) {
            window.open(myPersonalSpaceUrl);
        }
    }
    // Pre-rendering treatments
    //loginFormValidation
    const loginUsernameValidationResult = loginFormValidator.fields.login.validate();
    const loginPasswordValidationResult = loginFormValidator.fields.password.validate();
    //recoverPasswordFormValidation
    const recovPasswordUsernameResult = recoverPasswordFormValidator.fields.username.validate();
    const recovPasswordExternalRefResult = recoverPasswordFormValidator.fields.external_ref.validate();
    //recoverLoginFormValidation
    const recovLoginFirstNameResult = recoverLoginFormValidator.fields.first_name.validate();
    const recovLoginLastNameResult = recoverLoginFormValidator.fields.last_name.validate();
    const recovLoginBirthdateResult = recoverLoginFormValidator.fields.birth_date.validate();
    const recovLoginPostalCodeResult = recoverLoginFormValidator.fields.postal_code.validate();

    function contactAssistDisplay() {
        return contactAssist || "<Moyen de contact à renseigner>";
    }
    function getLoginHeaderTitle() {
        return props.i18n("title.login_section_" + portalType);
    }
    function thirdAuthRender() {
        let content = "";
        switch (portalType) {
            case "employee":
                content = "";
                break;

            case "pro":
                if (!pscConnect) {
                    content = "";
                    break;
                }
                content = (
                    <>
                        <PscConnect txtInfos={props.i18n("info.pscConnect_desc")} msgBox={props.msgBox} />
                    </>
                );
                break;
            default: //client
                if (!fConnect) {
                    content = "";
                    break;
                }
                content = (
                    <>
                        <Fconnect txtInfos={props.i18n("info.fconnect_desc")} msgBox={props.msgBox} />
                    </>
                );
                break;
        }
        return (
            !!content && (
                <>
                    <div className="subSectionContainer thirdAuth">
                        <h3 className="subSectionTitle">{props.i18n("title.third_auth_client")}</h3>
                        {content}
                    </div>
                    <span className="lineSeparator text bold">OU</span>
                </>
            )
        );
    }
    // Popup building
    function popupFooterRender() {
        let popupFooter;
        switch (true) {
            case showPopup === "issue_options":
                popupFooter = (
                    <ItemRow>
                        <PortalBtn onClick={onHidePopup}>Annuler</PortalBtn>
                    </ItemRow>
                );
                break;
            default:
                popupFooter = (
                    <ItemRow>
                        <PortalBtn onClick={onReturnPopup}>Précédent</PortalBtn>
                        <PortalBtn onClick={onHidePopup}>Annuler</PortalBtn>
                    </ItemRow>
                );
                break;
        }
        return popupFooter;
    }
    let popupVisible = false,
        popupContent = "",
        popupTitle = "";
    switch (true) {
        case showPopup === "issue_options":
            popupVisible = true;
            popupTitle = "Quel problème rencontrez-vous ?";
            popupContent = (
                <>
                    <Container className="popupFormContainer">
                        <Row as={"ul"}>
                            <Col xs={12} as={"li"} className="issueOption">
                                <button
                                    className="portalLinkBtn"
                                    onClick={() => setShowPopup("recover_password")}
                                    ref={issueOptionsFocusRef}
                                >
                                    J'ai oublié mon mot de passe
                                </button>
                            </Col>
                            <Col xs={12} as={"li"} className="issueOption">
                                <button className="portalLinkBtn" onClick={() => setShowPopup("recover_login")}>
                                    J'ai oublié mon identifiant
                                </button>
                            </Col>
                            {contactAssist ? (
                                <Col xs={12} as={"li"} className="issueOption">
                                    <button className="portalLinkBtn" onClick={() => setShowPopup("contact_assistance")}>
                                        {props.i18n("label.contact_assist")}
                                    </button>
                                </Col>
                            ) : (
                                ""
                            )}
                        </Row>
                    </Container>
                </>
            );
            break;
        case showPopup === "recover_password":
            popupVisible = true;
            popupTitle = "J'ai oublié mon mot de passe";
            popupContent = (
                <>
                    <Row className="popupFormDescrRow">
                        <Col xs={12} className="popupFormDescrTxt">
                            Veuillez renseigner l'identifiant de votre compte ainsi que votre numéro de dossier
                        </Col>
                    </Row>
                    <Form className="popupFormContainer" onSubmit={onSubmitForgottenPassword} noValidate>
                        {!!props.i18n("label.field.mandatory_fields") && (
                            <Form.Group className="smallFont">{props.i18n("label.field.mandatory_fields")}</Form.Group>
                        )}
                        <Form.Group controlId="currentMail">
                            {!!props.i18n("label.field.username") && (
                                <Form.Label>
                                    {props.i18n("label.field.username")}&nbsp;
                                    <span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="currentMail"
                                onChange={(event) => editRecoverPasswordForm("username", event.target.value)}
                                type="email"
                                iconsLeft="far fa-envelope"
                                name="currentMail"
                                autoComplete="email"
                                placeholder={props.i18n("placeholder.field.username")}
                                value={recoverPasswordForm.username}
                                ref={recoverPasswordFocusRef}
                                required={true}
                                aria-required={true}
                                aria-invalid={!recovPasswordUsernameResult.success && recoverPasswordFirstTry}
                                isInvalid={!recovPasswordUsernameResult.success && recoverPasswordFirstTry}
                                aria-describedby="currentMail-1 currentMail-2"
                                tooltip={props.i18n("info.field.new_username")}
                            />
                            {recoverPasswordFirstTry && (
                                <PortalFeedback fieldId="currentMail" msgList={recovPasswordUsernameResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <Form.Group controlId="fileNumber">
                            {!!props.i18n("label.field.file_number") && (
                                <Form.Label>
                                    {props.i18n("label.field.file_number")}
                                    <span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            {/*<PortalText
                                id="fileNumber"
                                onChange={(event) => editRecoverPasswordForm("external_ref", event.target.value)}
                                type="text"
                                value={recoverPasswordForm.external_ref}
                                name="fileNumber"
                                placeholder={props.i18n("placeholder.field.file_number")}
                                aria-required={true}
                                required={true}
                                isInvalid={!recovPasswordExternalRefResult.success && recoverPasswordFirstTry}
                                aria-invalid={!recovPasswordExternalRefResult.success && recoverPasswordFirstTry}
                                aria-describedby="fileNumber-1"
                                tooltip={props.i18n("info.field.file_number")}
                            />*/}
                            {recoverPasswordFirstTry && (
                                <PortalFeedback fieldId="fileNumber" msgList={recovPasswordExternalRefResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <PortalBtn className="validate centered" state={recoverPassState} type="submit">
                            Valider pour ré-initialiser votre mot de passe
                        </PortalBtn>
                        <div className="passwordWarningMsg">
                            Attention, ce mot de passe est celui de votre compte local et en aucun cas celui du compte que vous utilisez au
                            travers de FranceConnect. Il vous servira uniquement lorsque vous vous connecterez avec votre adresse mail
                            plutôt que via FranceConnect
                        </div>
                    </Form>
                </>
            );
            break;
        case showPopup === "recover_login":
            popupVisible = true;
            popupTitle = "J'ai oublié mon identifiant";
            popupContent = (
                <>
                    <Row className="popupFormDescrRow">
                        <Col xs={12} className="popupFormDescrTxt">
                            Merci de renseigner les éléments suivants
                        </Col>
                    </Row>
                    <Form className="popupFormContainer" onSubmit={onSubmitForgottenLogin} noValidate>
                        {!!props.i18n("label.field.mandatory_fields") && (
                            <Form.Group className="smallFont">{props.i18n("label.field.mandatory_fields")}</Form.Group>
                        )}
                        <Form.Group controlId="lastName">
                            {!!props.i18n("label.field.last_name") && (
                                <Form.Label>
                                    {props.i18n("label.field.last_name")}
                                    <span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="lastName"
                                ref={recoverLoginFocusRef}
                                onChange={(event) => editRecoverLoginForm("last_name", event.target.value)}
                                type="text"
                                autoComplete="family-name"
                                value={recoverLoginForm.last_name}
                                name="lastName"
                                placeholder={props.i18n("placeholder.field.last_name")}
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginLastNameResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginLastNameResult.success && recoverLoginFirstTry}
                                aria-describedby="lastName-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="lastName" msgList={recovLoginLastNameResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            {!!props.i18n("label.field.first_name") && (
                                <Form.Label>
                                    {props.i18n("label.field.first_name")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="firstName"
                                onChange={(event) => editRecoverLoginForm("first_name", event.target.value)}
                                type="text"
                                autoComplete="given-name"
                                value={recoverLoginForm.first_name}
                                name="firstName"
                                placeholder={props.i18n("placeholder.field.first_name")}
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginFirstNameResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginFirstNameResult.success && recoverLoginFirstTry}
                                aria-describedby="firstName-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="firstName" msgList={recovLoginFirstNameResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <Form.Group controlId="birthDate">
                            {!!props.i18n("label.field.birth_datetime") && (
                                <Form.Label>
                                    {props.i18n("label.field.birth_datetime")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="birthDate"
                                onChange={(event) => editRecoverLoginForm("birth_date", event.target.value)}
                                type="date"
                                autoComplete="bday"
                                name="birthDate"
                                placeholder={props.i18n("placeholder.field.birth_datetime")}
                                value={recoverLoginForm.birth_date}
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginBirthdateResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginBirthdateResult.success && recoverLoginFirstTry}
                                aria-describedby="birthDate-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="birthDate" msgList={recovLoginBirthdateResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        {/*<Form.Group controlId="postalCode">
                            <Form.Label>
                                Code postal <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="postalCode"
                                onChange={(event) => editRecoverLoginForm("postal_code", event.target.value)}
                                type="text"
                                value={recoverLoginForm.postal_code}
                                name="postalCode"
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginPostalCodeResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginPostalCodeResult.success && recoverLoginFirstTry}
                                aria-describedby="postalCode-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="postalCode" msgList={recovLoginPostalCodeResult.errors} i18n={props.i18n} />
                            )}
                            </Form.Group>*/}
                        {recoveredLogin ? (
                            <div className="recoveredLogin">
                                <span className="content">{recoveredLogin}</span>
                            </div>
                        ) : (
                            ""
                        )}
                        <PortalBtn
                            className="validate centered"
                            state={recoverLoginState}
                            type="submit"
                            //aria-label="Récupérer mon identifiant"
                        >
                            Récupérer mon identifiant
                        </PortalBtn>
                    </Form>
                </>
            );
            break;
        case showPopup === "contact_assistance":
            popupVisible = true;
            popupTitle = "Autres problèmes de connexion";
            popupContent = (
                <>
                    <Container className="popupFormContainer">
                        <Row className="popupFormDescrRow">
                            <Col xs={12} className="popupFormDescrTxt">
                                Si vous rencontrez des difficultés pour vous connecter, veuillez contacter l'assistance :
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="phoneContact" id="phoneContact">
                                <span className="sr-only">
                                    Si vous rencontrez des difficultés pour vous connecter, veuillez contacter l'assistance :
                                </span>
                                <address>{contactAssistDisplay()}</address>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
            break;
        default:
            popupVisible = false;
            popupContent = "";
            popupTitle = "";
            break;
    }
    // Rendering part
    return (
        <>
            {props.setStyle("./v_yvelines/desktop")}
            <PopupView
                onHiding={onHidePopup}
                showCloseButton={true}
                title={popupTitle}
                visible={popupVisible}
                footerRender={popupFooterRender}
            >
                {<div className="popupFormBlock">{popupContent}</div>}
            </PopupView>
            <div className="loginViewContainer">
                <div className="loginViewContent left">
                    <PortalTitle className="loginTitle">{props.i18n("title.main")}</PortalTitle>
                    <div className="loginContainer">
                        <div className="sectionContainer login">
                            <div className="sectionTitleContainer">
                                <span className={`titleIcon ${props.i18n("icon.login_section_icon")}`}></span>
                                <h1 className="sectionTitle">{getLoginHeaderTitle()}</h1>
                            </div>
                            {thirdAuthRender()}
                            <div className="subSectionContainer localAuth">
                                <h3 className="subSectionTitle">{props.i18n("title.local_auth")}</h3>
                                <Form className="noMargin row" onSubmit={onSubmitLogin} noValidate>
                                    {!!props.i18n("label.field.mandatory_fields") && (
                                        <div className="descr smallFont col-12">{props.i18n("label.field.mandatory_fields")}</div>
                                    )}
                                    <Form.Group controlId="formBasicEmail" as={Col} xs={12}>
                                        {!!props.i18n("label.field.username") && (
                                            <Form.Label>
                                                {props.i18n("label.field.username")}
                                                &nbsp;<span aria-hidden={true}>*</span>
                                            </Form.Label>
                                        )}
                                        <PortalText
                                            id="formBasicEmail"
                                            value={loginForm.login}
                                            type="email"
                                            placeholder={props.i18n("placeholder.field.username")}
                                            autoComplete="email"
                                            onChange={(e) => {
                                                editLoginForm("login", e.target.value);
                                            }}
                                            ref={autoFocusRef}
                                            iconsLeft="far fa-envelope"
                                            tooltip={props.i18n("info.field.username", [props.i18n("label.issue_login")])}
                                            aria-required={true}
                                            isInvalid={!loginUsernameValidationResult.success && loginFirstTry}
                                            aria-invalid={!loginUsernameValidationResult.success && loginFirstTry}
                                            aria-describedby="formBasicEmail-1 formBasicEmail-2"
                                        />
                                        {loginFirstTry && (
                                            <PortalFeedback
                                                fieldId="formBasicEmail"
                                                msgList={loginUsernameValidationResult.errors}
                                                i18n={props.i18n}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword" as={Col} xs={12}>
                                        {!!props.i18n("label.field.current_password") && (
                                            <Form.Label>
                                                {props.i18n("label.field.current_password")}
                                                &nbsp;<span aria-hidden={true}>*</span>
                                            </Form.Label>
                                        )}
                                        <PortalPassword
                                            id="formBasicPassword"
                                            type="text"
                                            placeholder={props.i18n("placeholder.field.current_password")}
                                            value={loginForm.password}
                                            iconsLeft="far fa-lock-alt"
                                            tooltip={props.i18n("info.field.current_password", [props.i18n("label.issue_login")])}
                                            autoComplete="current-password"
                                            onChange={(e) => {
                                                editLoginForm("password", e.target.value);
                                            }}
                                            aria-required={true}
                                            aria-describedby="passwordError-1"
                                            isInvalid={!loginPasswordValidationResult.success && loginFirstTry}
                                            aria-invalid={!loginPasswordValidationResult.success && loginFirstTry}
                                        />
                                        {loginFirstTry && (
                                            <PortalFeedback
                                                fieldId="passwordError"
                                                msgList={loginPasswordValidationResult.errors}
                                                i18n={props.i18n}
                                            />
                                        )}
                                        <div className="connectIssuesContainer">
                                            {/*<button type="button" className="connectIssues portalLinkBtn" onClick={onClickConnectIssues}>
                                                {props.i18n("label.issue_global")}
                                            </button>*/}
                                            <button type="button" className="connectIssues portalLinkBtn" onClick={onClickPwdIssues}>
                                                {props.i18n("label.issue_pwd")}
                                            </button>
                                        </div>
                                    </Form.Group>
                                    <PortalBtn className="sectionBtn" state={loginState} loadingTxt="Connexion en cours" type="submit">
                                        {props.i18n("label.action.send_login")}
                                    </PortalBtn>
                                </Form>
                            </div>
                        </div>
                        <div className="sectionContainer toRegister">
                            <div className="sectionTitleContainer">
                                <span className={`titleIcon ${props.i18n("icon.register_section_icon")}`}></span>
                                <h2 className="sectionTitle">{props.i18n("title.register_section")}</h2>
                            </div>
                            <p className="descr">{props.i18n("info.to_register_" + portalType)}</p>
                            <PortalBtn
                                className="sectionBtn"
                                onClick={() => props.redirectTo("register")}
                                iconCls={props.i18n("icon.view.register_icon")}
                            >
                                {props.i18n("label.view.register")}
                            </PortalBtn>
                        </div>
                    </div>
                </div>
                {!!myPersonalSpaceUrl && (
                    <div className="loginViewContent right">
                        <div className="myPersonalSpaceContent">
                            <img className="flower" src="./ressources/_shared/images/flower.svg" />
                            <PortalTitle className="myPersonnalSpaceTitle">Mon espace personnel qu'est-ce que c'est ?</PortalTitle>
                            <p>
                                Un compte sécurisé pour regrouper vos données et les partager avec les aidants de votre choix.
                                <br />
                                <br />
                                Un site dédié pour maitriser vos informations et superviser les interventions à votre domicile.
                            </p>
                            <PortalBtn className="myPersonnalSpaceBtn reverse" onClick={redirectToMyPersonalSpace}>
                                EN SAVOIR PLUS
                            </PortalBtn>
                            <ImgBackground className="myPersonalSpaceImg" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
//###########################################################################################//
