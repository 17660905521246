import React from "react";
import { useState, useEffect, useRef } from "react";
import "./contextMenu.css";

export default function ContextMenu(props) {
    /*  Configs : 
        {
            openTrigger : BOOLEAN (!!REQ) [false] => Booléen qui, à chaque fois qu'il 'switch', fait apparaitre le contextMenu

            xPos : INT (!!REQ) [0] => Position x à l'écran de l'endroit où on a fait le clic droit (en px)

            yPos : INT (!!REQ) [0] => Position y à l'écran de l'endroit où on a fait le clic droit (en px)

            optionConfigs : ARRAY OF CONFIGs (!!REQ) [[]] => Liste des options du contextMenu
                # Format d'une liste d'options => [{
                    text : STRING (opt) [''] => Texte qui définit l'option à cliquer

                    iconCls : STRING (opt) [''] => Classe fontAwesome de l'icône de l'option

                    onClick : FUNCTION (opt) [emptyFctn] => Fonction callback qui sera exécutée quand on clique sur l'option correspondante
                        # Paramètres => onClick(
                            optionConfig : OBJECT [{}] => La configuration de l'option correspondante définie dans 'optionConfigs'
                                # On peut ainsi personnaliser son rendu et utiliser des 'optionConfigs' différentes de la configuration standard
                        ) 
                }, {...}, ...]

            autoClose : BOOLEAN (opt) [true] => Activer ou non la fermeture automatique du contextMenu
                # Si on désactive l'autoClose, il faut gérer la fermeture du menu via le paramètre 'closeTrigger'

            closeTrigger : BOOLEAN (opt) [false] => Booléen qui, à chaque fois qu'il 'switch', fait disparaître le contextMenu
                # !! Si on gère le masquage avec cette propriété, bien penser à désactiver l'autoClose pour éviter les effets de bords

            maxHeight : INT (opt) [10000000000] => Hauteur max que l'on souhaite donner au contextMenu
                # Cette hauteur peut être forcée à une valeur plus petite si le menu est sur le point de dépasser de l'écran
                # Cela peut arriver quand on ouvre le menu proche d'une extrémité de l'écran

            maxWidth : INT (opt) [10000000000] => Largeur max que l'on souhaite donner au contextMenu
                # Cette largeur peut être forcée à une valeur plus petite si le menu est sur le point de dépasser de l'écran
                # Cela peut arriver quand on ouvre le menu proche d'une extrémité de l'écran

            headerTxt : STRING (opt) [''] => Texte pour donner un titre au contextMenu
                # Si il est vide, le header n'est pas affiché
            
            itemRender : FUNCTION (opt) [emptyFctn] => Fonction qui remplace le rendu par défaut du contextMenu
                # Fctn(optionConfig){} => Les params passés dans la Callback si elle existe
                    optionConfig : OBJECT [{}] => La configuration de l'option correspondante définie dans 'optionConfigs'
                        # On peut ainsi personnaliser son rendu et utiliser des 'optionConfigs' différentes de la configuration standard
                )
                # Return => JSX (req) => Le contenu JSX du nouveau rendu de l'option 
        }
    */

    const [init, setInit] = useState(true);
    const [visibility, setVisibility] = useState("");
    const x = +props.xPos;
    const y = +props.yPos;
    const autoClose = props?.autoClose !== false ? true : false;
    let headerTxt = typeof props.headerTxt === "string" ? props.headerTxt : "";
    let optionConfigs = typeof props?.optionConfigs?.forEach === "function" ? props.optionConfigs : [];
    const borderFromWindow = 30; //px
    const firstOptionRef = useRef();
    const contextMenuContainerRef = useRef();
    useEffect(
        function () {
            if (!init) {
                setVisibility(" visible");
            }
            if (init) setInit(false);
        },
        [props.openTrigger]
    );
    useEffect(
        function () {
            if (!init) {
                setVisibility("");
            }
            if (init) setInit(false);
        },
        [props.closeTrigger]
    );
    useEffect(
        function () {
            if (visibility) {
                const tagToFocus = firstOptionRef.current ? firstOptionRef.current : contextMenuContainerRef.current;
                if (tagToFocus) {
                    tagToFocus.focus();
                }
            }
        },
        [visibility]
    );
    //UX event handlers
    function handleClickItem(optionConfig) {
        if (typeof optionConfig?.onClick === "function") optionConfig.onClick(optionConfig);
    }

    function onBlurMenu(e) {
        const targetElClick = e?.relatedTarget;
        const comboContainEl = contextMenuContainerRef.current;
        if (comboContainEl && !comboContainEl.contains(targetElClick) && comboContainEl !== targetElClick && autoClose) {
            setVisibility("");
        }
    }
    //UI rendering functions
    function defaultItemRender(optionConfig) {
        return (
            <div className="contextMenu-item-content">
                {optionConfig?.iconCls ? <span className={`icon ${optionConfig?.iconCls}`}></span> : ""}
                <div className="text">{optionConfig?.text}</div>
            </div>
        );
    }
    function buildItems() {
        const menuItemDisplay = [];
        optionConfigs.forEach(function (optionConfig, index) {
            menuItemDisplay.push(
                <li key={index}>
                    <button
                        className="contextMenu-item-container"
                        role="menuitem"
                        aria-disabled={false}
                        tabIndex={0}
                        ref={index == 0 ? firstOptionRef : null}
                        onClick={() => handleClickItem(optionConfig)}
                    >
                        {typeof props.itemRender === "function" ? props.itemRender(optionConfig) : defaultItemRender(optionConfig)}
                    </button>
                </li>
            );
        });
        return menuItemDisplay;
    }
    function calcMaxHeight() {
        const userMaxHeight = typeof props.maxHeight === "number" ? props.maxHeight : 10000000000;
        const borderMaxHeight = y <= window.innerHeight / 2 ? window.innerHeight - y - borderFromWindow : y - borderFromWindow;
        return Math.min(borderMaxHeight, userMaxHeight);
    }
    function calcMaxWidth() {
        const userMaxWidth = typeof props.maxWidth === "number" ? props.maxWidth : 10000000000;
        const borderMaxWidth = x <= window.innerWidth / 2 ? window.innerWidth - x - borderFromWindow : x - borderFromWindow;
        return Math.min(borderMaxWidth, userMaxWidth);
    }
    function buildStyle() {
        return {
            top: y <= window.innerHeight / 2 ? y : null,
            right: x <= window.innerWidth / 2 ? null : window.innerWidth - x,
            bottom: y <= window.innerHeight / 2 ? null : window.innerHeight - y,
            left: x <= window.innerWidth / 2 ? x : null,
            maxHeight: calcMaxHeight(),
            maxWidth: calcMaxWidth(),
        };
    }

    return (
        <div
            className={`contextMenu-container${visibility}`}
            onBlur={onBlurMenu}
            style={buildStyle()}
            tabIndex={optionConfigs.length > 0 ? -1 : 0}
            ref={contextMenuContainerRef}
        >
            {headerTxt ? <div className="contextMenu-header">{headerTxt}</div> : ""}
            <ul className="contextMenu-content" tabIndex={-1} role="menu">
                {buildItems()}
            </ul>
        </div>
    );
}
