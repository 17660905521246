//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect } from "react";
import cloneDeep from "clone-deep";
//Custom Library
import { isArray, editFormState, uniqueId } from "../../../../ressources/lib/js/globalFunctions.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//Custom UI Components
import BaseDesktopScreen from "./screens/base/desktop/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";
//-----------------------------------//
export default function Controller(props) {
    const useServerPlug = props.useServerPlug;
    const refreshState = props.refreshState;
    const loggedUser = props.loggedUser;
    const store = props.store;
    //Local States definition and managing
    const [storeSortingType, setStoreSortingType] = useState("lastMsgDate"); //Values : 'lastMsgDate' / 'imp'
    function sortStore(store) {
        store = cloneDeep(store);
        if (isArray(store?.msg_messenger)) {
            store.msg_messenger.sort(function (topicItem1, topicItem2) {
                return new Date(topicItem2.send_at) - new Date(topicItem1.send_at);
            });
            if (storeSortingType == "imp") {
                store.msg_messenger.sort(function (topicItem1, topicItem2) {
                    return topicItem2.msg_importance_id - topicItem1.msg_importance_id;
                });
            }
        }
        return store;
    }
    const sortedStore = sortStore(store);
    const [initTopicForm, _setInitTopicForm] = useState(null);
    function setInitTopicForm(form) {
        const defaultTopicForm = {
            id: -1,
            client_ent_entity_id: "" + props?.selectedClient?.ent_entity_id,
            subject: "",
            msg_messenger_group_id: "",
            msg_classification_id: "1",
            msg_importance_id: "1",
            msg_category_id: [""],
            read_only: false,
            is_public: "1",
            inside: true,
            first_message: "",
        };
        if (!!form) {
            Object.keys(form).forEach(function (formKey) {
                defaultTopicForm[formKey] = form[formKey];
            });
            _setInitTopicForm(defaultTopicForm);
            return;
        }
        _setInitTopicForm(null);
    }
    const [topicForm, _setTopicForm] = useState(initTopicForm);
    const [topicFormModifCount, setTopicFormModifCount] = useState(0);
    const [topicFormSubmitCount, setTopicFormSubmitCount] = useState(0);
    function setTopicForm(field, value) {
        editFormState(field, value, topicForm, _setTopicForm, true);
        setTopicFormModifCount(topicFormModifCount + 1);
    }
    const [openedTopic, setOpenedTopic] = useState(null);
    //MsgList managing
    const [loadTopicContentState, setLoadTopicContentState] = useState(""); //Values: 'loading' || 'error'
    const defaultTopicContent = {
        header: {},
        user: [],
        content: [],
    };
    const [topicContent, setTopicContent] = useState(defaultTopicContent);
    const [pendingTopicContent, setPendingTopicContent] = useState([]);
    const [newNoteContent, setNewNoteContent] = useState("");

    //Communication functions
    function postEditTopic() {
        console.log(topicForm);
        props.setRequestState("loading");
        setTopicFormSubmitCount(topicFormSubmitCount + 1);
        return props
            .request({
                action: "post_edit_forum",
                ask_notify: true,
                data: {
                    header: topicForm,
                    previous_header: initTopicForm,
                    content: {
                        first_message: topicForm.first_message || "",
                    },
                },
            })
            .then(function (res) {
                props
                    .refresh()
                    .catch(function (err) {})
                    .finally(function () {
                        props.setRequestState("");
                    });
                return Promise.resolve(res);
            })
            .catch(function (err) {
                props.setRequestState("");
                props.msgBox(err.msg, "error", "msg", "");
                return Promise.reject(err);
            });
    }
    function postDeleteTopic(topicData) {
        props
            .msgBox(
                "Êtes-vous sûrs de vouloir clôtuer ce forum ?",
                "warning",
                "confirm",
                "Clôture du forum" + (topicData?.title ? ` - '${topicData.title}'` : "")
            )
            .then(function (res) {
                props.setRequestState("loading");
                props
                    .request({
                        action: "post_close_forum",
                        ask_notify: true,
                        data: {
                            header: {
                                id: [topicData?.id],
                            },
                        },
                    })
                    .then(function (res) {
                        props
                            .refresh()
                            .catch(function (error) {})
                            .finally(function () {
                                props.setRequestState("");
                            });
                    })
                    .catch(function (err) {
                        props.setRequestState("");
                        props.msgBox(err.msg, "error", "msg", "");
                    });
            })
            .catch(function (rej) {});
    }
    function loadTopicContent(invisibleRefresh) {
        if (!openedTopic || !openedTopic?.id) {
            if (!invisibleRefresh) {
                props.msgBox("Aucun topic sélectionné", "error", "msg");
                setOpenedTopic();
            }
            return Promise.reject({
                code: "CLI-note-200",
                msg: "Aucun topic sélectionné",
            });
        }
        if (!invisibleRefresh) setLoadTopicContentState("loading");
        return props
            .request({
                action: "get_client_note_content",
                data: {
                    id: openedTopic.id,
                    get_plug_data: useServerPlug === true,
                },
            })
            .then(function (res) {
                setTopicContent(res?.data);
                setLoadTopicContentState("");
                return Promise.resolve(res);
            })
            .catch(function (err) {
                console.error(err.msg);
                if (!invisibleRefresh) setLoadTopicContentState("error");
                return Promise.reject(err);
            });
    }
    function postNote(pendingId) {
        const clonedPendingTopicContent = cloneDeep(pendingTopicContent);
        return new Promise(function (rs, rj) {
            const topicId = !!openedTopic && !!openedTopic?.id ? openedTopic.id : null;
            if (!topicId) {
                return rj({
                    code: "CLI-note-100",
                    msg: "Topic de la note inconnu",
                });
            }
            if (!pendingId) {
                return rj({
                    code: "CLI-note-101",
                    msg: "Id temporaire de la note inconnu",
                });
            }
            const noteToSend = clonedPendingTopicContent.filter(function (currentNote, id) {
                return currentNote.pending_id === pendingId;
            })[0];
            if (!noteToSend) {
                return rj({
                    code: "CLI-note-102",
                    msg: "Note (Id temporaire : " + pendingId + ") introuvable dans la liste des notes à envoyer",
                });
            }
            if (noteToSend.posting_state === "loading") {
                return rj({
                    code: "CLI-note-103",
                    msg: "Note déjà en cours d'envoi",
                });
            }
            noteToSend.posting_state = "loading";
            noteToSend.post_try++;
            setPendingTopicContent(clonedPendingTopicContent);
            props
                .request({
                    action: "post_client_note_content",
                    ask_notify: false,
                    data: {
                        header: {
                            php_action: "add_msg",
                            id: [topicId],
                        },
                        message: noteToSend.message,
                    },
                })
                .then(function (res) {
                    loadTopicContent(true).finally(function () {
                        setPendingTopicContent(function (pendingTopicContent) {
                            const newPendingTopicContent = cloneDeep(pendingTopicContent);
                            let pendingNotePosition = null;
                            const pendingNoteToDelete = newPendingTopicContent.filter(function (pendingNote, id) {
                                if (pendingNote.pending_id === pendingId) {
                                    pendingNotePosition = id;
                                    return true;
                                }
                            })[0];
                            if (typeof pendingNotePosition === "number") newPendingTopicContent.splice(pendingNotePosition, 1);
                            return newPendingTopicContent;
                        });
                    });
                    rs(res);
                })
                .catch(function (err) {
                    setPendingTopicContent(function (pendingTopicContent) {
                        const newPendingTopicContent = cloneDeep(pendingTopicContent);
                        let failedNoteId;
                        const failedNote = newPendingTopicContent.filter(function (pendingNote, id) {
                            if (pendingNote.pending_id === pendingId) {
                                failedNoteId = id;
                                return true;
                            }
                        })[0];
                        if (!!failedNote) {
                            failedNote.posting_state = "error";
                        }
                        return newPendingTopicContent;
                    });
                    rj(err);
                });
        });
    }

    //Effect treatments
    useEffect(
        function () {
            _setTopicForm(initTopicForm);
            setTopicFormModifCount(0);
            setTopicFormSubmitCount(0);
        },
        [initTopicForm]
    );
    useEffect(
        function () {
            if (!!store && !!store?.msg_messenger && !!openedTopic && !!openedTopic?.id) {
                const updatedOpenedTopic = store.msg_messenger.filter(function (topic) {
                    return topic.id == openedTopic.id;
                })[0];
                if (!!updatedOpenedTopic) {
                    const clonedUpdatedOpenedTopic = cloneDeep(updatedOpenedTopic);
                    setOpenedTopic(clonedUpdatedOpenedTopic);
                } else {
                    //In case the current opened topic disappeared from database
                    setOpenedTopic();
                }
            }
        },
        [store]
    );
    useEffect(
        function () {
            if (!!openedTopic) {
                loadTopicContent();
            } else {
                //We clean all states related to current topic manipulation
                setTopicContent(defaultTopicContent);
                setPendingTopicContent([]);
                setNewNoteContent("");
            }
        },
        [openedTopic]
    );
    useEffect(
        function () {
            if (pendingTopicContent.length > 0) {
                pendingTopicContent.forEach(function (pendingNote) {
                    if (!!pendingNote.pending_id && !pendingNote.posting_state) {
                        postNote(pendingNote.pending_id).catch(function (err) {
                            console.error(err);
                        });
                    }
                });
            }
        },
        [pendingTopicContent]
    );

    //Action functions
    function addNote() {
        if (!newNoteContent) return;
        const defaultNoteFormat = {
            //Pending fields
            post_try: 0,
            pending_id: uniqueId(),
            posting_state: "",
            //Standard fields
            created_by: loggedUser?.adm_user_id,
            created_by_username: null,
            deleted_at: null,
            id: null,
            message: "",
            par_statuscode_id: null,
            send_at: null,
        };
        const newNote = { ...defaultNoteFormat };
        newNote.message = newNoteContent;
        setPendingTopicContent(function (pendingTopicContent) {
            const newPendingTopicContent = cloneDeep(pendingTopicContent);
            newPendingTopicContent.push(newNote);
            return newPendingTopicContent;
        });
        setNewNoteContent("");
    }
    function cancelNote(pendingId) {
        setPendingTopicContent(function (pendingTopicContent) {
            //console.log(pendingTopicContent);
            const clonedPendingTopicContent = cloneDeep(pendingTopicContent);
            let pendingNoteId = null;
            const noteToCancel = clonedPendingTopicContent.filter(function (pendingNote, id) {
                if (pendingNote.pending_id === pendingId) {
                    pendingNoteId = id;
                    return true;
                }
            })[0];
            //console.log(pendingNoteId);
            if (typeof pendingNoteId === "number") clonedPendingTopicContent.splice(pendingNoteId, 1);
            //console.log(clonedPendingTopicContent);
            return clonedPendingTopicContent;
        });
    }
    const viewControllerProps = {
        storeSortingType: storeSortingType,
        setStoreSortingType: setStoreSortingType,
        store: sortedStore,
        initTopicForm: initTopicForm,
        setInitTopicForm: setInitTopicForm,
        topicForm: topicForm,
        setTopicForm: setTopicForm,
        topicFormModifCount: topicFormModifCount,
        topicFormSubmitCount: topicFormSubmitCount,
        openedTopic: openedTopic,
        setOpenedTopic: setOpenedTopic,
        postEditTopic: postEditTopic,
        postDeleteTopic: postDeleteTopic,
        loadTopicContentState: loadTopicContentState,
        newNoteContent: newNoteContent,
        setNewNoteContent: setNewNoteContent,
        addNote: addNote,
        postNote: postNote,
        cancelNote: cancelNote,
        topicContent: topicContent,
        pendingTopicContent: pendingTopicContent,
    };
    switch (props.appTheme) {
        case "yvelines":
            return <YvelinesDesktopScreen {...props} {...viewControllerProps} />;
        default:
            return <BaseDesktopScreen {...props} {...viewControllerProps} />;
    }
}
