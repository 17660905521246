import React from "react";
import { useState, useEffect, useRef } from "react";
import "./itemRow.css";
export default function ItemRow(props) {
    /*  Configs : 
    {
        fill : STRING (opt) ['stretch'] => Valeurs possibles : ['stretch', 'start', 'center', 'end']
            # stretch : Les éléments remplissent toute la largeur du conteneur et font tous la même largeur
            # start : Les éléments font la largeur de leur contenu et sont placés au début du conteneur
            # center : Les éléments font la largeur de leur contenu et sont placés au centre du conteneur
            # end : Les éléments font la largeur de leur contenu et sont placés à la fin du conteneur

        minElWidth : NUMBER (opt) [200] => Unité en px
            # Lorsqu'on est en "fill='stretch'", taille des éléments à partir de laquelle l'agencement va passer en 'column' lorsqu'on réduit l'écran

        maxElWidth : NUMBER (opt) [450] => Unité en px
            # Lorsqu'on est en "fill='stretch'", taille des éléments à partir de laquelle l'agencement va passer en 'row' lorsqu'on agrandit l'écran
            # Si minWidth > maxWidth, c'est minWidth qui sera considéré comme breakpoint
    
        className : STRING (opt) [''] => Classe additionnelle à affecter au conteneur 
    }
*/
    const fillList = ["stretch", "start", "center", "end"];
    const defaultElWidth = { min: 200, max: 450 };
    const fill = fillList.includes(props.fill) ? props.fill : "stretch";
    const [column, setColumn] = useState(false);
    const [childWidth, setChildWidth] = useState(null);
    const myRef = useRef();

    function getFirstChild() {
        let firstChildNode;
        if (myRef.current && myRef.current.childNodes.length > 0) {
            firstChildNode = myRef.current.childNodes[0];
        }
        return firstChildNode;
    }
    function resizeListener() {
        const firstChildNode = getFirstChild();
        if (firstChildNode) {
            const nodeWidth = firstChildNode.clientWidth;
            setChildWidth(nodeWidth);
        }
    }
    useEffect(function () {
        if (fill === "stretch") {
            const firstChildNode = getFirstChild();
            if (firstChildNode) {
                const nodeWidth = firstChildNode.clientWidth;
                setChildWidth(nodeWidth);
                window.addEventListener("resize", resizeListener);
            }
        }
        return function () {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    useEffect(
        function () {
            if (childWidth !== null) {
                const minWidth = typeof props.minElWidth === "number" ? props.minElWidth : defaultElWidth.min;
                const maxWidth = typeof props.maxElWidth === "number" ? props.maxElWidth : defaultElWidth.max;
                if (childWidth <= minWidth) {
                    setColumn(true);
                } else {
                    const breakPoint = Math.max(minWidth, maxWidth);
                    if (childWidth > breakPoint) {
                        setColumn(false);
                    }
                }
            }
        },
        [childWidth, props.children]
    );

    return (
        <div ref={myRef} className={`itemRowContainer ${fill}${column ? " column" : ""}${props.className ? " " + props.className : ""}`}>
            {props.children}
        </div>
    );
}
