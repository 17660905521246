import {configureStore} from '@reduxjs/toolkit';
import privateReducer from './application/reducers/private/private.js';
import publicReducer from './application/reducers/public/public.js';

const appStore = configureStore({
    reducer: {
        public: publicReducer,
        private: privateReducer
    }
})

export default appStore;