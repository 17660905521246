import React from "react";
import { useEffect } from "react";
export default function Screen(props) {
    const store = props.store || {};
    console.log(store);
    const rootId = "faqContainer";
    useEffect(function () {
        if (!!window.HippoStlManager) {
            window.HippoStlManager.setView({
                rootId: rootId,
                url: store.standalone_url,
                data: store.data.json_data,
            })
                .then(function (res) {})
                .catch(function (err) {
                    console.error(err.msg);
                });
        }
    }, []);
    return (
        <>
            {props.setStyle("./base/desktop")}
            <div id={rootId} className="faqContainer"></div>
        </>
    );
}
