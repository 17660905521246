import React from "react";
function ToBuild(props) {
    var style = {
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
    };
    return (
        <div style={style}>
            <div style={{ margin: "auto" }}>{props.i18n("error.view_to_build")}</div>
        </div>
    );
}
export default ToBuild;
