import React from "react";
import PortalController from "../../../../ressources/components/portalController/portalController.js";
import Controller from "./controller.js";

export default function Contact(props) {
    return (
        <PortalController {...props} usePlug={false} storePlug={{}} customInit={false}>
            <Controller />
        </PortalController>
    );
}
