export default class UserSession {
    constructor() {
        this.get_session = function (input) {
            return new Promise(function (rs, rj) {
                var privateData = sessionStorage.getItem("private");
                rs({
                    success: true,
                    code: 300,
                    msg: "",
                    data: JSON.parse(privateData),
                });
            });
        };
        this.set_session = function (input) {
            return new Promise(function (rs, rj) {
                sessionStorage.setItem("private", JSON.stringify(input.data));
                rs();
            });
        };
        this.clear_session = function (input) {
            return new Promise(function (rs, rj) {
                sessionStorage.clear();
                rs();
            });
        };
        this.update_selected_client = function (input) {
            return new Promise(function (rs, rj) {
                var privateData = JSON.parse(sessionStorage.getItem("private"));
                privateData.selected_client = input.data;
                sessionStorage.setItem("private", JSON.stringify(privateData));
                rs();
            });
        };
        this.get_token = function () {
            if (sessionStorage.getItem("private") === null) {
                return false;
            }
            var privateData = JSON.parse(sessionStorage.getItem("private"));
            return privateData.logged_user.token;
        };
    }
}
