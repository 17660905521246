//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect } from "react";
//Redux library (https://react-redux.js.org/) => Global store API
import { useDispatch, useSelector } from "react-redux";
//Reducers and Selectors for asaStore connexion
import { selectContactAssist, selectContactUrl, selectPscConnect, selectFconnect } from "../../../reducers/public/public.js";
import { setLoginData } from "../../../reducers/private/private.js";
//Custom Library
import { editFormState, formValidator, isEmailType } from "../../../../ressources/lib/js/globalFunctions.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//Custom UI Components
import BaseDesktopScreen from "./screens/base/desktop/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";
//-----------------------------------//
export default function Controller(props) {
    //Global constants
    //Redux bound state variables
    const contactAssist = useSelector(selectContactAssist);
    const contactUrl = useSelector(selectContactUrl);
    const pscConnect = useSelector(selectPscConnect);
    const fConnect = useSelector(selectFconnect);
    //Specific view states
    const dispatch = useDispatch();
    //NewViewStates
    function initLoginForm() {
        return {
            login: "",
            password: "",
        };
    }
    const [loginForm, setLoginForm] = useState(initLoginForm());
    const loginFormValidator = new formValidator({
        login: [
            { conditionCode: "required_username", validate: () => !!loginForm.login },
            { conditionCode: "username_is_email_type", validate: () => isEmailType(loginForm.login) },
        ],
        password: [{ conditionCode: "required_password", validate: () => !!loginForm.password }],
    });
    function initRecoverPasswordForm() {
        return {
            username: "",
            //external_ref: "",
        };
    }
    const [recoverPasswordForm, setRecoverPasswordForm] = useState(initRecoverPasswordForm());
    const recoverPasswordFormValidator = new formValidator({
        username: [
            { conditionCode: "required_username", validate: () => !!recoverPasswordForm.username },
            { conditionCode: "username_is_email_type", validate: () => isEmailType(recoverPasswordForm.username) },
        ],
        external_ref: [{ conditionCode: "required_file_number", validate: () => true /*!!recoverPasswordForm.external_ref*/ }], //On désactive le numéro de dossier car pas de numéro pour les entourages proches
    });
    function initRecoverLoginForm() {
        return {
            first_name: "",
            last_name: "",
            birth_date: "",
            postal_code: "",
        };
    }
    const [recoverLoginForm, setRecoverLoginForm] = useState(initRecoverLoginForm());
    const recoverLoginFormValidator = new formValidator({
        last_name: [{ conditionCode: "required_last_name", validate: () => !!recoverLoginForm.last_name }],
        first_name: [{ conditionCode: "required_first_name", validate: () => !!recoverLoginForm.first_name }],
        birth_date: [{ conditionCode: "required_birth_datetime", validate: () => !!recoverLoginForm.birth_date }],
        postal_code: [{ conditionCode: "required_postal_code", validate: () => true }],
    });
    const [recoveredLogin, setRecoveredLogin] = useState("");
    const [loginFirstTry, setLoginFirstTry] = useState(false);
    const [recoverPasswordFirstTry, setRecoverPasswordFirstTry] = useState(false); //Controller shared
    const [recoverLoginFirstTry, setRecoverLoginFirstTry] = useState(false); //Controller shared
    //Requesting states
    const [sessionId, setSessionId] = useState(""); //To enable server persistance when trying multiple login attempts //Controller shared
    const [loginState, setLoginState] = useState(""); //Values: 'loading' || 'error'
    const [recoverPassState, setRecoverPassState] = useState(""); //Values: 'loading' || 'error'
    const [recoverLoginState, setRecoverLoginState] = useState(""); //Values: 'loading' || 'error'

    //Specific view effects
    useEffect(function () {
        //props.init();//Infinite loop
    }, []);

    //Here to write views communication actions (post, ...) and post data sates (editForm ...)
    //They are common to every screen
    //Formular Functions
    function editLoginForm(field, value) {
        editFormState(field, value, loginForm, setLoginForm);
    }
    function clearLoginForm() {
        setLoginForm(initLoginForm());
    }
    function editRecoverPasswordForm(field, value) {
        editFormState(field, value, recoverPasswordForm, setRecoverPasswordForm);
    }
    function clearRecoverPasswordForm() {
        setRecoverPasswordForm(initRecoverPasswordForm());
    }
    function editRecoverLoginForm(field, value) {
        editFormState(field, value, recoverLoginForm, setRecoverLoginForm);
    }
    function clearRecoverLoginForm() {
        setRecoverLoginForm(initRecoverLoginForm());
    }

    //Posting functions
    function postLogin(event) {
        return new Promise(function (rs, rj) {
            if (typeof event?.preventDefault === "function") event.preventDefault();
            setLoginFirstTry(true);
            const validationRes = loginFormValidator.validate();
            //if (!validationRes.success) return rj({code: validationRes.errors[0], msg: props.i18n("error." + validationRes.errors[0]) });
            if (!validationRes.success) return rj({ code: "invalid_form", msg: props.i18n("error.invalid_form") });
            setLoginState("loading");
            function requestLogin(sessionId) {
                document.Wrapper.request({
                    type: "ajax",
                    module: "view",
                    view: "login",
                    action: "post_login",
                    data: {
                        login: loginForm.login,
                        password: loginForm.password,
                        session_id: sessionId,
                    },
                })
                    .then(function (response) {
                        console.log("response : ", response);
                        console.log("response data : ", response.data);
                        if (response.data.required_mfa) {
                            //We need to do MFA
                            console.log("response Controller : ", response);
                            props.redirectTo("mfa_login", response.data);
                        } else {
                            //We are logged
                            dispatch(setLoginData(response.data));
                            document.Wrapper.request({
                                type: "sessionStorage",
                                module: "session",
                                view: "all",
                                action: "set_session",
                                data: response.data,
                            })
                                .then(function (response) {
                                    return rs(response);
                                })
                                .catch(function (error) {
                                    console.error(error.msg);
                                });
                            props.redirectTo(response.data.default_view);
                        }
                    })
                    .catch(function (error) {
                        clearLoginForm();
                        return rj(error);
                    })
                    .finally(function () {
                        setLoginState("");
                    });
            }
            if (!sessionId) {
                document.Wrapper.request({
                    type: "ajax",
                    view: "login",
                    module: "view",
                    action: "get_session",
                    data: [],
                })
                    .then(function (res) {
                        let sessionId = res.data.session_id;
                        setSessionId(sessionId);
                        requestLogin(sessionId);
                    })
                    .catch(function (err) {
                        requestLogin("");
                    });
            } else {
                requestLogin(sessionId);
            }
        });
    }
    function postRecoverPassword(event) {
        return new Promise(function (rs, rj) {
            if (typeof event?.preventDefault === "function") event.preventDefault();
            setRecoverPasswordFirstTry(true);
            const validationRes = recoverPasswordFormValidator.validate();
            if (!validationRes.success) return rj({ msg: props.i18n("error." + validationRes.errors[0]) });
            setRecoverPassState("loading");
            document.Wrapper.request({
                type: "ajax",
                module: "view",
                view: "login",
                action: "ask_reset_pass",
                data: recoverPasswordForm,
            })
                .then(function (response) {
                    console.log(response);
                    return rs(response);
                })
                .catch(function (error) {
                    return rj(error);
                })
                .finally(function () {
                    setRecoverPassState("");
                });
        });
    }
    function postRecoverLogin(event) {
        return new Promise(function (rs, rj) {
            if (typeof event?.preventDefault === "function") event.preventDefault();
            setRecoverLoginFirstTry(true);
            const validationRes = recoverLoginFormValidator.validate();
            if (!validationRes.success) return rj({ msg: props.i18n("error." + validationRes.errors[0]) });
            setRecoverLoginState("loading");
            document.Wrapper.request({
                type: "ajax",
                module: "view",
                view: "login",
                action: "ask_reset_login",
                data: recoverLoginForm,
            })
                .then(function (response) {
                    console.log(response);
                    setRecoveredLogin(response?.data?.username);
                    return rs(response);
                })
                .catch(function (error) {
                    setRecoveredLogin("");
                    return rj(error);
                })
                .finally(function () {
                    setRecoverLoginState("");
                });
        });
    }
    //Rendering part
    const localControllerProps = {
        contactAssist: contactAssist,
        contactUrl: contactUrl,
        pscConnect: pscConnect,
        fConnect: fConnect,
        loginForm: loginForm,
        editLoginForm: editLoginForm,
        recoverPasswordForm: recoverPasswordForm,
        editRecoverPasswordForm: editRecoverPasswordForm,
        clearRecoverPasswordForm: clearRecoverPasswordForm,
        recoverLoginForm: recoverLoginForm,
        editRecoverLoginForm: editRecoverLoginForm,
        clearRecoverLoginForm: clearRecoverLoginForm,
        recoveredLogin: recoveredLogin,
        loginFirstTry: loginFirstTry,
        setLoginFirstTry: setLoginFirstTry,
        recoverPasswordFirstTry: recoverPasswordFirstTry,
        setRecoverPasswordFirstTry: setRecoverPasswordFirstTry,
        recoverLoginFirstTry: recoverLoginFirstTry,
        setRecoverLoginFirstTry: setRecoverLoginFirstTry,
        loginState: loginState,
        recoverPassState: recoverPassState,
        recoverLoginState: recoverLoginState,
        postLogin: postLogin,
        postRecoverPassword: postRecoverPassword,
        postRecoverLogin: postRecoverLogin,
        loginFormValidator: loginFormValidator,
        recoverPasswordFormValidator: recoverPasswordFormValidator,
        recoverLoginFormValidator: recoverLoginFormValidator,
    };
    switch (props.appTheme) {
        case "yvelines":
            return <YvelinesDesktopScreen {...props} {...localControllerProps} />;
        default:
            return <BaseDesktopScreen {...props} {...localControllerProps} />;
    }
}
