import React from "react";
import { useState, useEffect, useRef } from "react";
import "./portalToolbar.css";

/*
Configs : 
{
    breakPoint : INT (!!REQ) [500] => Largeur à partir de laquelle la 'toolbar' passe d'une affichage horizontal à un affichage vertical

    title : STRING || JSX (opt) [''] => Titre de la 'toolbar'
        # Il sera au milieu sur l'affichage horizontal et en haut sur l'affichage vertical
    
    startToolsRender : FUNCTION (opt) [emptyFctn] => Fonction qui doit retourner le JSX des items au début de la 'toolbar'
        # Ils seront sur l'extrémité gauche dans l'affichage horizontal et en premier sur l'affichage vertical (juste en dessous du 'title')

    endToolsRender : FUNCTION (opt) [emptyFctn] => Fonction qui doit retourner le JSX des items à la fin de la 'toolbar'
        # Ils seront sur l'extrémité droite dans l'affichage horizontal et en dernier sur l'affichage vertical

    className : STRING (opt) [''] => Classe supplémentaire affectée au conteneur de la 'toolbar'

    noBorder : BOOLEAN (opt) [false] => Pour enlever les border autour de la toolbar
}
*/
export default function PortalToolbar(props) {
    const breakPoint = typeof props.breakPoint !== "number" ? 500 : props.breakPoint;
    const [toolbarWidth, setToolbarWidth] = useState(1);
    const noBorder = !!props.noBorder;
    const toolbarRef = useRef();
    useEffect(function () {
        function onResizeToolbar() {
            if (toolbarRef.current) {
                setToolbarWidth(toolbarRef.current.clientWidth);
            }
        }
        window.addEventListener("resize", onResizeToolbar);
        setTimeout(onResizeToolbar, 1);
    }, []);

    useEffect(
        function () {
            if (!!toolbarRef.current) {
                setTimeout(function () {
                    const toolbarTag = toolbarRef.current;
                    if (!!toolbarTag) {
                        const toolbarTagWidth = toolbarRef.current.clientWidth;
                        if (toolbarTagWidth !== toolbarWidth) {
                            //console.log(toolbarTagWidth);
                            setToolbarWidth(toolbarTagWidth);
                        }
                    }
                }, 1);
            }
        },
        [toolbarWidth]
    );

    function isWideScreen() {
        //console.log(toolbarWidth);
        return toolbarWidth > breakPoint;
    }
    function renderStart() {
        switch (true) {
            case typeof props.startToolsRender === "function" && !isWideScreen():
                return props.startToolsRender();

            case typeof props.startToolsRender === "function" && isWideScreen():
                return (
                    <ul key="toolsStart" className="portalToolbar-tools start">
                        {props.startToolsRender()}
                    </ul>
                );
            default:
                return "";
        }
    }
    function renderEnd() {
        switch (true) {
            case typeof props.endToolsRender === "function" && !isWideScreen():
                return props.endToolsRender();

            case typeof props.endToolsRender === "function" && isWideScreen():
                return (
                    <ul key="toolsEnd" className="portalToolbar-tools end">
                        {props.endToolsRender()}
                    </ul>
                );
            default:
                return "";
        }
    }
    function renderTitle() {
        if (!props.title) return "";
        return (
            <h1 key="toolsTitle" className="portalToolbar-title">
                {props.title}
            </h1>
        );
    }

    function renderContent() {
        const start = renderStart();
        const end = renderEnd();
        const title = renderTitle();
        if (isWideScreen()) {
            //Big screens
            return [start, title, end];
        } else {
            return (
                <>
                    {title}
                    <div className="portalToolbar-tools">
                        {start}
                        {end}
                    </div>
                </>
            );
        }
    }

    return (
        <nav
            ref={toolbarRef}
            className={
                "portalToolbar-container" +
                (isWideScreen() ? " large" : "") +
                (!!noBorder ? " noBorder" : "") +
                (props.className ? " " + props.className : "")
            }
            aria-current="page"
        >
            {renderContent()}
        </nav>
    );
}
