import { phone } from "phone";
import Form from "react-bootstrap/Form";
import cloneDeep from "clone-deep";
export function checkFieldPaste(formerValue, newValue) {
    //Retourne un booléen qui indique si un copier/coller a été effectué entre 2 strings
    let longestValue = formerValue.length > newValue.length ? formerValue : newValue;
    let shortestValue = formerValue.length > newValue.length ? newValue : formerValue;
    let result = "";
    for (let i = 0; i < longestValue.length; i++) {
        if (shortestValue[i]) {
            if (shortestValue[i] !== longestValue[i]) {
                result += shortestValue[i];
            }
        } else {
            result += longestValue[i];
        }
    }
    if (result.length !== 1) {
        return true;
    } else {
        return false;
    }
}

export function isEmailType(str) {
    //Retourne un booléen qui indique si la string en entrée est de type email
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
}

export function isPhoneType(str) {
    const analisis = phone(str, { country: "FRA" });
    return analisis.isValid;
}

export function areEquivalent(str1, str2) {
    return str1.length !== 0 && str1 === str2;
}

export function isDate(str) {
    const date = new Date(str);
    return date instanceof Date && !isNaN(date.valueOf());
}

export function isArray(variable) {
    return Array.isArray(variable);
}

export function isObject(variable) {
    return typeof variable === "object" && variable !== null && !Array.isArray(variable);
}

export function buildFeedBack(fieldId, errorMsgList, conditionList, activate) {
    //DEPRECATED => Now we use <PortalFeedback /> component
    fieldId = fieldId || "unknownId";
    let displayFeedBack = [];
    let feedBack = [];
    if (typeof errorMsgList !== "object" || typeof conditionList !== "object") return "";
    conditionList.forEach(function (condition, index) {
        if (typeof condition !== "boolean") return "";
        if (condition && errorMsgList[index]) {
            feedBack.push(errorMsgList[index]);
        }
    });
    feedBack.forEach(function (txt, index) {
        displayFeedBack.push(
            <Form.Control.Feedback type="invalid" id={`${fieldId}-${index + 1}`} key={`${fieldId}-${index + 1}`}>
                {txt}
            </Form.Control.Feedback>
        );
    });
    return activate ? displayFeedBack : "";
}
export function getObjectValue(data, path) {
    /*
    data : l'objet dans lequel on map
    path : ['key1', 'key2'] => La chemin vers l'élément de l'objet que l'on cherche
    */
    if (!data || typeof data !== "object") {
        return null;
    }
    var i,
        len = path.length;
    for (i = 0; path[i] && typeof data === "object" && data && i < len; ++i) {
        //typeof null => "object" => Holy shit !!!!!
        data = data[path[i]];
    }
    return data;
}

export function buildUncompleteDate(rawStr) {
    if (typeof rawStr !== "string") rawStr = "";
    const cleanStr = rawStr.replace(/\D/g, "");
    const dayStr = cleanStr.slice(0, 2);
    const monthStr = cleanStr.slice(2, 4);
    const yearStr = cleanStr.slice(4);
    const myDate = new Date();
    if (+dayStr > 0) myDate.setDate(+dayStr);
    if (+monthStr > 0) myDate.setMonth(+monthStr - 1);
    if (yearStr) myDate.setFullYear(+yearStr);
    return myDate;
}

export function formatAsaproDate(date, isDatetime) {
    if (!date) return "";
    if (typeof date === "string") date = buildUncompleteDate(date);
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = "" + d.getFullYear(),
        hours = "" + d.getHours(),
        minutes = "" + d.getMinutes(),
        seconds = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;

    return [year, month, day].join("-") + (isDatetime ? " " + [hours, minutes, seconds].join(":") : "");
}
export function parseIntWithoutNaN(value) {
    value = +value;
    return isNaN(value) ? 0 : value;
}
const shortMonthLabelList = ["Janv.", "Févr.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."];
const monthLabelList = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];
const weekLabelList = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

export function formatDayTitleDisplay(startDate) {
    const date = new Date(startDate);
    const dateTxt = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return `${weekLabelList[date.getDay()]} ${dateTxt} ${monthLabelList[date.getMonth()]} ${date.getFullYear()}`;
}
export function formatWeekTitleDisplay(startDate, weekLength) {
    weekLength = +weekLength;
    const startWeekDate = new Date(startDate);
    const endWeekDate = new Date(startDate);
    endWeekDate.setDate(endWeekDate.getDate() + weekLength - 1);
    const startDateTxt = startWeekDate.getDate() < 10 ? "0" + startWeekDate.getDate() : startWeekDate.getDate();
    const endDateTxt = endWeekDate.getDate() < 10 ? "0" + endWeekDate.getDate() : endWeekDate.getDate();
    const startMonthTxt = endWeekDate.getMonth() !== startWeekDate.getMonth() ? monthLabelList[startWeekDate.getMonth()] : "";
    const startYearTxt = startWeekDate.getFullYear() !== endWeekDate.getFullYear() ? startWeekDate.getFullYear() : "";
    return `Du ${startDateTxt} ${startMonthTxt} ${startYearTxt} au ${endDateTxt} ${
        monthLabelList[endWeekDate.getMonth()]
    } ${endWeekDate.getFullYear()}`;
}
export function formatMonthTitleDisplay(startDate) {
    const monthDate = new Date(startDate);
    if (monthDate.getDate() !== 1) {
        monthDate.setDate(1);
        monthDate.setMonth(monthDate.getMonth() + 1);
    }
    return monthLabelList[monthDate.getMonth()] + " " + monthDate.getFullYear();
}
export function formatYearTitleDisplay(startDate) {
    return "Année " + startDate.getFullYear();
}

export function formatDateDisplay(date, displayTime, locale) {
    if (!date) return "";
    locale = locale || "fr-FR";
    const myDate = new Date(date);
    return myDate.toLocaleDateString(locale) + (displayTime ? " à " + myDate.toLocaleTimeString(locale) : "");
}

export function findAbsolutePosition(target, startCoord) {
    if (typeof startCoord?.x !== "number" || typeof startCoord?.y !== "number") startCoord = { x: 0, y: 0 };
    startCoord.x += +target?.offsetLeft;
    startCoord.y += +target?.offsetTop;
    if (target?.offsetParent) {
        return findAbsolutePosition(target.offsetParent, startCoord);
    } else {
        return startCoord;
    }
}
export function setRightCase(nameStr) {
    nameStr = nameStr || "";
    const nameSeparatorList = [" ", "-", "'", '"', "_"];
    const nameArray = nameStr.split("");
    let previousLetterIsSeparator = false;
    nameArray.forEach(function (letter, index) {
        previousLetterIsSeparator = !!nameSeparatorList.filter(function (separator) {
            return separator === nameArray[index - 1];
        })[0];
        letter = index < 1 || previousLetterIsSeparator ? letter.toUpperCase() : letter.toLowerCase();
        //console.warn(letter, previousLetterIsSeparator);
        nameArray[index] = letter;
    });

    return nameArray.join("");
}
export function formatName(input) {
    input = isObject(input) ? input : {};
    const firstName = setRightCase(input.first_name);
    const lastName = setRightCase(input.last_name);
    return (firstName || "") + (lastName ? " " + lastName : "") || "Inconnu";
}

export function formatString(string, fGroupSize, separator, cursorIndex) {
    string = string || "";
    fGroupSize = typeof fGroupSize !== "number" ? 2 : fGroupSize;
    separator = separator || " ";
    cursorIndex = typeof cursorIndex !== "number" ? string.length : cursorIndex;
    fGroupSize = fGroupSize || 2;
    separator = separator || " ";
    const groupedCharList = [],
        groupList = [];
    const groupCount = Math.ceil(string.length / fGroupSize);
    for (let i = 0; i < fGroupSize; i++) {
        groupList.push("");
    }
    for (let i = 0; i < groupCount; i++) {
        groupedCharList.push(groupList);
    }
    let finalCursorIndex = cursorIndex;
    //console.log("Former cursor index : ", cursorIndex);
    let charIndex = 0;
    let formattedString = "";
    for (let groupIndex = 0; groupIndex < groupedCharList.length; groupIndex++) {
        for (let groupCharIndex = 0; groupCharIndex < groupedCharList[groupIndex].length; groupCharIndex++) {
            formattedString += string[charIndex] || "";
            charIndex++;
        }
        if (groupIndex !== groupedCharList.length - 1) {
            //We don't add separator at the end of the string, only between 2 groups
            formattedString += separator;
            if (charIndex < cursorIndex) finalCursorIndex += separator.length; //We don't move cursor when separators are added after the initial position
        }
    }
    //console.log("New cursor Index : ", finalCursorIndex);
    return { formattedString: formattedString, cursorIndex: finalCursorIndex };
}

export function editFormState(fieldList, valueList, state, setter) {
    const traceTpl = "editFormState() : ";
    if (!isArray(fieldList)) fieldList = fieldList ? [fieldList] : [];
    if (fieldList.length < 1) {
        console.error(traceTpl + `No fields specified to update this state`);
        return;
    }
    if (!isArray(valueList)) valueList = [valueList];
    if (!state || typeof state !== "object") {
        console.error(traceTpl + `No React State found to update '${fieldList}' field`);
        return;
    }
    if (typeof setter !== "function") {
        console.error(traceTpl + `No React Setter found to update '${fieldList}' field`);
        return;
    }
    function setClonedStateField(field, value, clonedState) {
        let currentLevel = clonedState;
        const keyList = field.split(".");
        let keyIndex = 0;
        let currentKey = "";
        let fieldTried = "";
        while (keyIndex < keyList.length) {
            currentKey = keyList[keyIndex];
            fieldTried += keyIndex > 0 ? "." + currentKey : currentKey;
            if (!currentKey) {
                console.error(traceTpl + `Undefined key to access '${fieldTried}' in '${field}'`);
                break;
            }
            if (!isObject(currentLevel)) {
                console.error(traceTpl + `Error trying to access '${fieldTried}' in : `, clonedState);
                break;
            }
            if (keyIndex === keyList.length - 1) {
                currentLevel[currentKey] = value;
                //setter(clonedState);
                break;
            } else {
                currentLevel = currentLevel[currentKey];
            }
            keyIndex++;
        }
    }
    const clonedState = cloneDeep(state);
    fieldList.forEach(function (field, index) {
        if (!field || typeof field !== "string") {
            console.error(traceTpl + `Unknown field to update into ${fieldList} at index ${index}`);
            return;
        }
        let value = valueList[index];
        setClonedStateField(field, value, clonedState);
    });
    setter(clonedState);
    return;
}

export function crudDisplay(displayActions, actionAllowed) {
    /*
        displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
            # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!

        actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
            # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
            # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
            # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'

        Return : BOOLEAN => Indique si les droits autorisent l'affichage du composant ou non
    */
    let display = true;
    const requiredDisplayActions = ["dis"];
    if (typeof displayActions?.forEach === "function") {
        displayActions.forEach(function (action) {
            requiredDisplayActions.push(action);
        });
    }
    if (typeof actionAllowed?.forEach !== "function") return display;
    requiredDisplayActions.forEach(function (requiredAction) {
        if (
            actionAllowed.findIndex(function (viewAction) {
                return requiredAction === viewAction;
            }) < 0
        ) {
            display = false;
        }
    });
    return display;
}
export function crudDisabled(activeActions, actionAllowed) {
    /*
        activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'modifiable' (non disabled)
            # Par défaut on considère que c'est le droit 'upd' mais on peut customiser

        actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
            # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
            # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
            # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'

        Return : BOOLEAN => Indique si les droits nécessitent une désactivation du composant ou non
    */
    let disabled = false;
    const requiredActiveActions = typeof activeActions?.forEach === "function" ? activeActions : ["upd"];
    if (typeof actionAllowed?.forEach !== "function") return disabled;
    requiredActiveActions.forEach(function (requiredAction) {
        if (actionAllowed.findIndex((viewAction) => viewAction === requiredAction) < 0) disabled = true;
    });
    return disabled;
}
export class Password {
    //DEPRECATED => Use PasswordFieldValidator
    constructor() {}

    static isLongEnough(str) {
        let minLength = 12;
        return { status: str.length >= minLength, condition: `Au moins ${minLength} caractères` };
    }
    static hasAtLeastOneNumber(str) {
        return { status: /[0-9]/.test(str), condition: "Au moins 1 chiffre" };
    }
    static hasLowerCase(str) {
        return { status: /[a-z]/.test(str), condition: "Au moins 1 caractère en minuscule" };
    }
    static hasUpperCase(str) {
        return { status: /[A-Z]/.test(str), condition: "Au moins 1 caractère en majuscule" };
    }
    static hasAtLeastOneSpecialCharacter(str) {
        return { status: /[@$_!%*#?&.\/]/.test(str), condition: "Au moins 1 caractère spécial" };
    }
    static checkPassword(password) {
        if (password.length < 1) {
            return { status: false, msg: "Veuillez renseigner votre mot de passe" };
        }
        let isLong = this.isLongEnough(password);
        if (!isLong.status) {
            return { status: false, msg: `Votre mot de passe doit contenir ${isLong.condition.toLowerCase()}` };
        }
        let hasOneNumber = this.hasAtLeastOneNumber(password);
        if (!hasOneNumber.status) {
            return { status: false, msg: `Votre mot de passe doit contenir ${hasOneNumber.condition.toLowerCase()}` };
        }
        let hasLower = this.hasLowerCase(password);
        if (!hasLower.status) {
            return { status: false, msg: `Votre mot de passe doit contenir ${hasLower.condition.toLowerCase()}` };
        }
        let hasUpper = this.hasUpperCase(password);
        if (!hasUpper.status) {
            return { status: false, msg: `Votre mot de passe doit contenir ${hasUpper.condition.toLowerCase()}` };
        }
        let hasSpecial = this.hasAtLeastOneSpecialCharacter(password);
        if (!hasSpecial.status) {
            return { status: false, msg: `Votre mot de passe doit contenir ${hasSpecial.condition.toLowerCase()}` };
        }
        return { status: true, msg: "" };
    }
    static checkConfirmation(password, passwordConfirm) {
        if (passwordConfirm.length < 1) {
            return { status: false, msg: "Veuillez confirmer votre mot de passe" };
        }
        if (!areEquivalent(password, passwordConfirm)) {
            return { status: false, msg: "Votre confirmation de mot de passe n'est pas correcte" };
        }
        return { status: true, msg: "" };
    }
}
export class formValidator {
    constructor(fieldValidators) {
        /**
         * @param {Object} fieldValidators = {
         *      @param {Object[]} ${formField_name} : [{
         *          @descr Ex : password || username || email
         *          @descr On rentre la liste de toutes les contraintes de validité pour chaque champ spécifié
         *
         *          @param {String} conditionCode : Code de la contrainte de validité du champ ${formField_name}
         *              @descr Ex : 'required_password' || 'has_at_least_one_number' ...
         *
         *          @param {Function} validate : Fonction qui doit retourner un Booléen qui matérialise la contrainte logique de validité
         *       }, {}, ...]
         * }
         * @return {InstanceObject} : {
         *      @descr Une instance de formValidator qui permet de valider tous les champs de formulaire en une seule fois
         *
         *      @property {Object} fields : {
         *          @descr Le dictionnaire de tous les champs à valider
         *
         *          @property {Object} ${formField_name} : {
         *              @descr Le nom d'un des champs à valider
         *
         *              @function validate()
         *                  @descr Permet de valider toutes les contraintes d'UN champ de formulaire en une seule fois
         *
         *                  @return {Object} : {
         *                      @property {Boolean} success : true si toutes les contraintes sont valides, false si au moins une ne l'est pas
         *
         *                      @property {String[]} errors : La liste des 'conditionCode' erronés du champ en question
         *                          @descr On peut ensuite transformer dans la vue ces codes d'erreurs en textes personnalisés via le i18n
         *                  }
         *              @property {Object} validationState : {
         *                  @descr C'est là qu'on stocke le statut de validité d'UN champ de formulaire après l'appel de la fonction 'this.validate()'
         *
         *                  @property {Boolean} success : true si toutes les contraintes sont valides, false si au moins une ne l'est pas
         *
         *                  @property {String[]} errors : La liste des 'conditionCode' erronés du champ en question
         *                      @descr On peut ensuite transformer dans la vue ces codes d'erreurs en textes personnalisés via le i18n
         *              }
         *          }
         *      }
         *      @method validate(customList)
         *          @descr Permet de valider TOUS LES champs de formulaire (OU une liste donnée en paramètre) en une seule fois
         *
         *          @param {String[]} customList : La liste des champs de formulaire à valider (Si on ne veut pas tous les valider d'un coup)
         *              @descr Si aucun paramètre n'est passé tous les champs seront validés
         *              @descr Seuls les champs réellement présents dans le validateur seront testés
         *
         *          @return {Object} : {
         *              @property {Boolean} success : true si tous les champs sont valides, false si au moins 1 ne l'est pas
         *
         *              @property {String[]} errors : La liste des 'conditionCode' erronés du 1er champ en erreur dans le formulaire
         *                  @descr On peut ensuite transformer dans la vue ces codes d'erreurs en textes personnalisés via le i18n
         *          }
         *
         *       @property {Object} validationState : {
         *          @descr C'est là qu'on stocke le statut de validité de tous les champs du formulaire après l'appel de la fonction 'this.validate()'
         *
         *          @property {Boolean} success : true si tous les champs sont valides, false si au moins 1 ne l'est pas
         *
         *          @property {String[]} errors : La liste des 'conditionCode' erronés du 1er champ en erreur dans le formulaire
         *              @descr On peut ensuite transformer dans la vue ces codes d'erreurs en textes personnalisés via le i18n
         *       }
         * }
         */
        this.fields = {};
        const me = this;
        Object.keys(fieldValidators).forEach(function (fieldName) {
            me.fields[fieldName] = {
                _validators: fieldValidators[fieldName],
                validate: function () {
                    let errors = [];
                    let fieldValidatorList = this._validators;
                    fieldValidatorList.forEach(function (validator) {
                        if (typeof validator.validate === "function") {
                            if (!validator.validate()) {
                                //console.log(validator);
                                errors.push(validator.conditionCode || "condition non décrite");
                            }
                        }
                    });
                    this.validationState = { success: errors.length < 1, errors: errors };
                    return cloneDeep(this.validationState);
                },
            };
        });
    }
    validate(customList) {
        const me = this;
        let res = { success: false, errors: [] };
        if (!isArray(customList)) {
            customList = typeof customList === "string" ? [customList] : [];
        }
        const realFieldList = Object.keys(this.fields);
        const customToCheckList = [];
        let customFieldIsIntoRealList = false;
        let hasAlreadyBeenPushed = false;
        customList.forEach(function (customField) {
            customFieldIsIntoRealList = !!realFieldList.filter((realField) => realField === customField)[0];
            hasAlreadyBeenPushed = !!customToCheckList.filter((fieldIntoCustom) => fieldIntoCustom === customField)[0];
            if (customFieldIsIntoRealList && !hasAlreadyBeenPushed) customToCheckList.push(customField);
        });
        realFieldList.forEach(function (field) {
            me["fields"][field]["validate"]();
        });
        const fieldList = customToCheckList.length > 0 ? customToCheckList : realFieldList;
        let fieldIndex = 0;
        let currentField = "";
        while (fieldIndex < fieldList.length) {
            currentField = fieldList[fieldIndex];
            res = me["fields"][currentField]["validationState"];
            if (!res["success"]) break;
            fieldIndex++;
        }
        me.validationState = res;
        return res;
    }
}

export class PasswordFieldsValidator {
    constructor(formData) {
        return {
            password: [
                { conditionCode: "required_password", validate: () => !!formData.password },
                { conditionCode: "password_is_long_enough", validate: () => formData.password.length >= 12 },
                { conditionCode: "password_has_one_number", validate: () => /[0-9]/.test(formData.password) },
                { conditionCode: "password_has_lower_case", validate: () => /[a-z]/.test(formData.password) },
                { conditionCode: "password_has_upper_case", validate: () => /[A-Z]/.test(formData.password) },
                { conditionCode: "password_has_special_character", validate: () => /[@$_!%*#?&.\/]/.test(formData.password) },
            ],
            password_confirm: [
                { conditionCode: "required_password_confirm", validate: () => !!formData.password_confirm },
                {
                    conditionCode: "password_confirm_equal_password",
                    validate: () => areEquivalent(formData.password, formData.password_confirm),
                },
            ],
        };
    }
}
document._uniqueId = 0;
export function uniqueId() {
    document._uniqueId++;
    return document._uniqueId;
}
