import React from "react";
import { useState, useEffect } from "react";
import BaseDesktopScreen from "./screens/base/desktop/screen.js";

export default function Controller(props) {
    //Communication functions
    function downloadDetail(itemData) {
        props
            .request({
                type: "ajaxGetFile",
                action: "download_eval",
                data: itemData,
            })
            .then(function (res) {
                //Normally nothing here
            })
            .catch(function (err) {
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            });
    }
    const localControllerProps = {
        downloadDetail: downloadDetail,
    };
    return <BaseDesktopScreen {...props} {...localControllerProps} />;
}
