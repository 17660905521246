import Ajax from "./Ajax.js";
import WebSocketMQTT from "./WebSocketMQTT.js";
import UserSession from "./UserSession.js";
import deepClone from "clone-deep";
export default class WrapperClass {
    constructor(appConfig, appEnvironnement, serviceWorker) {
        function buildAppInfo() {
            const defaultConfigList = {
                appCode: "hippocad",
                appTheme: "hippocad",
                dnsFront: "portal-v2dev.hippocad.eu",
                endPoint: "https://asapro.hippocad.eu",
                i18n: "fr_fr",
            };
            const appInfo = appConfig;
            let errorMsg;
            for (const defaultConfig in defaultConfigList) {
                if (!appConfig[defaultConfig]) {
                    errorMsg = `${defaultConfig} is not configured for this Environnement => "HOST_CONFIGS" : "${appEnvironnement}"\nDefault value "${defaultConfigList[defaultConfig]}" will be set`;
                    console.error(errorMsg);
                    alert(errorMsg);
                    appInfo[defaultConfig] = defaultConfigList[defaultConfig];
                } else {
                    appInfo[defaultConfig] = appConfig[defaultConfig];
                }
            }
            appInfo.serviceWorker = serviceWorker;
            return appInfo;
        }
        const appInfo = buildAppInfo();
        this.name = "Wrapper";
        this.getInfo = function () {
            return deepClone(appInfo); //breaking the reference
        };
        this.getServiceWorker = function () {
            return deepClone(appInfo.serviceWorker);
        };
        this._getToken = function () {
            const session = new UserSession();
            return session.get_token();
        };
        this._ajax = function (asaproDns, asareactDns, input, WrapperInstance) {
            //DEPRECATED
            return Ajax.request(asaproDns, asareactDns, input, WrapperInstance);
        };
        this._getFile = function (asaproDns, asareactDns, input) {
            //DEPRECATED
            return Ajax.getFile(asaproDns, asareactDns, input);
        };
        this._postFile = function (asaproDns, asareactDns, input) {
            //DEPRECATED
            return Ajax.postFile(asaproDns, asareactDns, input);
        };
        this._bindAction = function (input) {
            return WebSocketMQTT.bindAction(input);
        };
        this._ajaxWebSocket = function (input) {
            return Ajax.requestWebSocket(input);
        };
        this._session = function (input) {
            const session = new UserSession();
            const action = session[input?.action];
            if (typeof action !== "function") {
                return this.reject(404, `[404] - ERROR SESSION STORAGE - input.action "${input.action}" not found\n Input : ${input}`);
            }
            return session[input.action](input);
        };
    }

    reject(code, msg) {
        return new Promise(function (rs, rj) {
            rj({
                success: false,
                code: code || 404,
                msg: `[${code}] ${msg}`,
                data: [],
            });
        });
    }

    /**
     *
     * Exemple requête :
     * Wrapper.request({
     *     type:'ajax',
     *     module:'adm',
     *     view: 'login',
     *     action:'login',
     *     data:{
     *         email: email,
     *         password: password
     *     }
     * })
     * @param input
     * @returns {Promise<unknown>|*}
     */
    request(input) {
        //TODO: REFACTOR "REQUEST" FUNCTION
        console.log("WRAPPER REQUEST", input);
        if (typeof input !== "object" || input === null) {
            console.error("Wrong Wrapper request input format (check the documentation)");
            return this.reject(422, "Wrong Wrapper request input format (check the documentation)");
        }
        const dnsFront = this.getInfo()["dnsFront"];
        const endPoint = input.endPoint || this.getInfo()["endPoint"];
        //console.log("ENDPOINT : ", endPoint);
        const sentData = {
            // POUR AJAX
            type: input.type, // 'ajax' le plus souvent
            dns: dnsFront,
            endpoint: endPoint,
            application: "application_asareact",
            token: input.token || this._getToken() || "",
            action: input.action,
            module: input.module, // toujours 'view'
            view: input.view ? input.view : "", //
            adm_view_id: input.adm_view_id || "",
            php_action: input.action,
            print: input.print,
            notify: input.notify || false,
            // Si on est connecté en tant que tuteur pour requêter sur les infos du bénéficiaire
            selected_client: input.selected_client ? input.selected_client.ent_entity_id : "",
            data: input.data,
            file: input.file || null,
        };
        const boxInput = {
            type: input.type,
            endPoint: input.endPoint,
            action: input.action,
            token: "default_token",
            selected_client: input.selected_client,
            notify: input.notify || false,
            data: input.data,
        };
        switch (input.type) {
            case "ajax":
            //return this._ajax(endPoint, window.location.origin, sentData, this);
            case "ajaxGetFile":
                //return this._getFile(endPoint, window.location.origin, sentData);
                const hippoRequestInstance = new window.HippoRequest();
                return hippoRequestInstance.request(sentData).catch(function (err) {
                    const formattedCode = err.code || "undef";
                    const formattedMsg = `[${formattedCode}] ${err.msg || "undef"}`;
                    err.msg = formattedMsg;
                    if (err.code !== 4221) return Promise.reject(err);
                    const msg = `[${formattedCode}] Votre session a expiré veuillez vous reconnecter`;
                    if (document.React && typeof document.React.msgBox === "function") {
                        return document.React.msgBox(msg, "error", "msg", "Session expirée").then(function () {
                            sessionStorage.clear();
                            window.location.replace(window.location.origin);
                            return Promise.reject(err);
                        });
                    } else {
                        alert(msg);
                        sessionStorage.clear();
                        window.location.replace(window.location.origin);
                        return Promise.reject(err);
                    }
                });
            case "ajaxBox":
                return this._ajaxWebSocket(boxInput);
            case "wsBox":
                return this._bindAction(boxInput);
            case "sessionStorage":
                return this._session(sentData);

            default:
                return this.reject(404, `[404] - ERROR WRAPPER REQUEST - input.type "${input.type}" unknown\nInput: ${input}`);
        }
    }
}
