import React from "react";
import { useRef, useEffect } from "react";
import { TreeView } from "devextreme-react/tree-view";
import deepClone from "clone-deep";
//import { selectSSoDisconnect } from "../../../../../../../../reducers/private/private.js";
//import { useSelector } from "react-redux";
import ImgBackground from "../../../../../../../ressources/components/imgBackground/imgBackground.js";
import PortalCombo from "../../../../../../../ressources/components/portalFields/portalCombo/portalCombo.js";
function NavBarV2(props) {
    const ssoDisconnect = props.ssoDisconnect;
    const screenType = props.screenWidth < 650 ? "small" : "normal";
    const menuContainerLeftRef = useRef(null);
    const menuContainerRightRef = useRef(null);
    const menuItemSelectedLeft = useRef(null);
    const menuItemSelectedRight = useRef(null);
    const menuLeftBtnRef = useRef(null);
    const menuRightBtnRef = useRef(null);
    //Tool functions
    function displayFullName(firstName, lastName) {
        firstName = firstName || "";
        lastName = lastName || "";
        let separator = firstName && lastName ? " " : "";
        return firstName + separator + lastName || "Inconnu";
    }
    function displayInitials(firstName, lastName) {
        firstName = firstName ? firstName.trim()[0].toUpperCase() : "";
        lastName = lastName ? " " + lastName.trim()[0].toUpperCase() : "";
        return firstName + lastName;
    }
    function autoFocusContent() {
        if (menuItemSelectedLeft?.current && props.menuStateLeft === " open") {
            //console.log(menuItemSelectedLeft.current);
            menuItemSelectedLeft.current.focus();
        }
        if (menuItemSelectedRight?.current && props.menuStateRight === " open") {
            //console.log(menuItemSelectedRight.current);
            menuItemSelectedRight.current.focus();
        }
    }
    function navLinksLeftBuild() {
        return deepClone(props.menuConfig.menu_left);
    }
    function navLinksRightBuild() {
        let navLinksRight = deepClone(props.menuConfig.menu_right);
        if (props.attachedClient && typeof (props.attachedClient === "object") && props.attachedClient.length > 0) {
            let treeItem,
                treeItemList = [
                    {
                        id: "2_1",
                        text: "VOTRE ESPACE",
                        selected: props.loggedUser.ent_entity_id === props.selectedClient.ent_entity_id,
                        client: props.loggedUser,
                        action: "changeClient",
                    },
                ],
                selfClientTxt;
            props.attachedClient.forEach(function (client, index) {
                selfClientTxt = client.ent_entity_id === props.loggedUser.ent_entity_id ? " (Moi)" : "";
                treeItem = {
                    id: `2_${index + 2}`,
                    text: displayFullName(client.first_name, client.last_name) + selfClientTxt,
                    selected: client.ent_entity_id === props.selectedClient.ent_entity_id,
                    client: client,
                    action: "changeClient",
                };
                treeItemList.push(treeItem);
            });
            navLinksRight.push({
                id: "2",
                text: "Choix du bénéficiaire",
                expanded: true,
                items: treeItemList,
            });
        }
        navLinksRight.push({
            id: "3",
            text: props.i18n("label.action.send_logout"),
            action: "logout",
        });
        return navLinksRight;
    }
    function buildSpaceSelectionList() {
        let spaceSelectionList = [];
        let ownSpace = "Votre Espace Personnel"; //TODO: Le ramener du i18n
        if (props.attachedClient && typeof (props.attachedClient === "object") && props.attachedClient.length > 0) {
            spaceSelectionList.push({
                value: props.loggedUser.ent_entity_id,
                text: ownSpace.toUpperCase(),
                client: props.loggedUser,
                action: "changeClient",
            });
            props.attachedClient.forEach(function (client) {
                spaceSelectionList.push({
                    value: client.ent_entity_id,
                    text: displayFullName(client.first_name, client.last_name),
                    client: client,
                    action: "changeClient",
                });
            });
        }
        return spaceSelectionList;
    }
    useEffect(
        function () {
            setTimeout(autoFocusContent, 400); //Time of transition duration to prevent graphic bugs
        },
        [props.menuStateLeft, props.menuStateRight]
    );
    //Event functions
    function onItemClick(itemData) {
        //console.log(itemData);
        props.handleMenuStateRight("close");
        props.handleMenuStateLeft("close");
        const direction = itemData.direction;
        switch (itemData.action) {
            case "logout":
                props.logout();
                break;
            case "changeClient":
                props.updateSelectedClient(itemData.client);
                break;
            default:
                if (direction) {
                    props.redirectTo(direction);
                    //if (direction !== props.viewHash) props.handleMenuStateLeft("close");
                }
                break;
        }
    }
    function onBlurMenu(event, rawMenuType) {
        setTimeout(function () {
            //Timeout because onClick events inside menu are not triggered on safari (fuck you apple) if onBlur runs first...
            const targetElClick = event?.relatedTarget;
            console.log("event : ", event);
            console.log("target : ", targetElClick);
            const container = rawMenuType === "left" ? menuContainerLeftRef.current : menuContainerRightRef.current;
            const menuPosition = rawMenuType === "left" ? "left" : "right";
            const canFindInnerComponent = typeof container?.contains === "function";
            const clickIsOutsideMenu = targetElClick && canFindInnerComponent && container && !container.contains(targetElClick);
            if (clickIsOutsideMenu || false) {
                if (menuPosition === "left" && menuLeftBtnRef?.current !== targetElClick) props.handleMenuStateLeft("close");
                if (menuPosition === "right" && menuRightBtnRef?.current !== targetElClick) props.handleMenuStateRight("close");
            }
        }, 0);
    }
    //Rendering functions
    function menuItemRender(menuPosition, itemData) {
        //console.log("itemRender : ", itemData);
        const openingStatus = menuPosition === "right" ? props.menuStateRight : props.menuStateLeft; // Prend les valeurs 'open' ou ''
        return (
            <button
                className="asaMenuItem"
                onClick={() => onItemClick(itemData)}
                tabIndex={openingStatus ? 0 : -1}
                onBlur={(e) => onBlurMenu(e, menuPosition)}
                ref={
                    menuPosition === "right"
                        ? +itemData?.id === 1
                            ? menuItemSelectedRight
                            : null
                        : +itemData?.id === 1
                        ? menuItemSelectedLeft
                        : null
                }
                role="treeitem"
                aria-label={itemData.text || ""}
                aria-selected={itemData.selected || false}
                aria-expanded={itemData.expanded || false}
                aria-level={itemData.id.split("_").length}
            >
                <>
                    {itemData.icon ? <span className={`icon ${itemData.icon}`} aria-hidden={true}></span> : ""}
                    <span className="text">{itemData.text}</span>
                </>
            </button>
        );
    }
    function spaceSelectorRender(itemList) {
        function onChangeSpaceSelection(e) {
            const itemData = e?.source?.record?.data;
            //console.log(itemData);
            if (itemData) {
                onItemClick(itemData);
            }
        }
        return (
            <PortalCombo
                inputName="spaceSelector"
                aria-label="Sélecteur d'espace"
                className="spaceSelector"
                items={itemList}
                defaultValue={props.selectedClient.ent_entity_id}
                onChange={onChangeSpaceSelection}
                editable={false}
            />
        );
    }
    function navOptionsRender() {
        let navOptionsText = screenType !== "small" ? <h1 className="navOptionsText">Menu</h1> : "";
        return (
            <button
                ref={menuLeftBtnRef}
                className={"navOptionsPart" + props.menuStateLeft}
                onClick={() => {
                    props.handleMenuStateLeft("toggle");
                }}
            >
                <i className="navOptionsIcon fas fa-bars"></i>
                {navOptionsText}
            </button>
        );
    }
    function txtInfosRender() {
        if (screenType === "small") return "";
        const comboItemList = buildSpaceSelectionList();
        const spaceSelector =
            comboItemList.length > 1 ? spaceSelectorRender(comboItemList) : <h1 className="txtInfosContent">Votre Espace personnel</h1>;
        return (
            <div
                className="txtInfosPart"
                onClick={function (e) {
                    if (e.target.id !== "spaceSelector") props.redirectTo("home");
                }}
            >
                {spaceSelector}
            </div>
        );
    }

    function companyInfosRender() {
        if (screenType === "small") return "";
        return (
            <div
                className="companyInfosPart"
                onClick={function (e) {
                    props.redirectTo("home");
                }}
            >
                <ImgBackground className="companyLogo" />
            </div>
        );
    }
    function userInfoRender() {
        const userBtn = (
            <button
                className={`userInfosIcon${ssoDisconnect ? " fconnect" : ""}`}
                onClick={() => props.handleMenuStateRight("toggle")}
                tabIndex={0}
                ref={menuRightBtnRef}
            >
                <div className="txtContent">
                    {ssoDisconnect ? "" : displayInitials(props.loggedUser.first_name, props.loggedUser.last_name)}
                </div>
            </button>
        );
        let loggedUserDisplay = "Inconnu",
            selectedClientDisplay = <p className="txtClientPart">(Inconnu)</p>;
        if (props.loggedUser) {
            loggedUserDisplay = displayFullName(props.loggedUser.first_name, props.loggedUser.last_name);
            if (props.selectedClient) {
                if (props.loggedUser.ent_entity_id !== props.selectedClient.ent_entity_id) {
                    selectedClientDisplay = (
                        <p className="txtClientPart">{`(${displayFullName(
                            props.selectedClient.first_name,
                            props.selectedClient.last_name
                        )})`}</p>
                    );
                } else {
                    selectedClientDisplay = "";
                }
            }
        }

        if (screenType === "small") {
            const comboItemList = buildSpaceSelectionList();
            return (
                <div className="userInfosPart">
                    <div className="txtUserInfosPart">
                        {/*<p className="txtWelcomePart">Bienvenue</p>*/}
                        {comboItemList.length > 1 ? (
                            spaceSelectorRender(comboItemList)
                        ) : (
                            <p className="txtUserNamePart">{loggedUserDisplay}</p>
                        )}
                        {userBtn}
                    </div>
                    {/*selectedClientDisplay*/}
                </div>
            );
        }
        return (
            <div className="userInfosPart">
                {/*<p className="txtWelcomePart">Bienvenue</p>*/}
                <div className="txtUserInfosPart">
                    <p className="txtUserNamePart">{loggedUserDisplay}</p>
                    {userBtn}
                </div>
                {selectedClientDisplay}
            </div>
        );
    }
    /*rendering part*/
    return (
        <div className="navBarContainer">
            <header className="navBarRow1">
                {navOptionsRender()}
                {companyInfosRender()}
                {txtInfosRender()}
                {userInfoRender()}
            </header>
            <nav className={"navOptionsPanel left" + props.menuStateLeft} ref={menuContainerLeftRef}>
                <TreeView
                    items={navLinksLeftBuild()}
                    focusStateEnabled={false}
                    tabIndex={-1}
                    selectNodesRecursive={false}
                    itemRender={function (itemData) {
                        return menuItemRender("left", itemData);
                    }}
                />
            </nav>
            <nav className={"navOptionsPanel right" + props.menuStateRight} ref={menuContainerRightRef}>
                <TreeView
                    items={navLinksRightBuild()}
                    focusStateEnabled={false}
                    tabIndex={-1}
                    selectNodesRecursive={false}
                    itemRender={function (itemData) {
                        return menuItemRender("right", itemData);
                    }}
                />
            </nav>
        </div>
    );
}
export default NavBarV2;
