//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState } from "react";
//External libraries
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components

//Custom UI Components
//import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn";
import ListContactBlock from "./components/listContactBlock";
import DetailContactForm from "./components/detailContactForm";
import ProContactSelector from "./components/proContactSelector";
import SuccessContactAdd from "./components/successContactAdd";
//-----------------------------------//
//#########################################################################################//
export default function Screen(props) {
    const store = props.store || {};
    const contactForm = props.contactForm;
    const initContactForm = props.initContactForm;
    const setInitContactForm = props.setInitContactForm;
    const formatContactName = props.formatContactName;
    const refreshState = props.refreshState;
    const [successAdd, setSuccessAdd] = useState(false);
    function returnHome() {
        setInitContactForm();
        setSuccessAdd(false);
    }
    function postContact() {
        props
            .postContact()
            .then(function (res) {
                if (contactForm.php_action === "add") {
                    setSuccessAdd(true);
                } else {
                    setInitContactForm();
                }
            })
            .catch(function (err) {
                console.error(err);
            });
    }
    //Rendering functions
    function formatComboItems(rawComboItems) {
        rawComboItems = rawComboItems || [];
        const formattedComboItems = [{ text: "", value: 0 }];
        rawComboItems.forEach(function (rawItem) {
            formattedComboItems.push({
                text: rawItem?.name,
                value: rawItem?.id,
            });
        });
        return formattedComboItems;
    }
    function chooseDisplay() {
        let contentTitle = "";
        let content = "";
        let subnavContent = [];
        /*function onReturnClick(e) {
            setInitContactForm();
        }
        const returnBtn = (
            <PortalBtn className="reverse" onClick={onReturnClick}>
                Retour
            </PortalBtn>
        );*/
        switch (true) {
            case !!contactForm:
                const formAction = contactForm.php_action;
                const contactType = !contactForm.rol_type_rol_id || contactForm.rol_type_rol_id == 4 ? "personal" : "professional";
                if (formAction === "add") {
                    contentTitle = props.i18n("label.action.add_" + contactType + "_contact");
                    if (successAdd) {
                        content = (
                            <SuccessContactAdd
                                {...props}
                                actionAllowed={props.concatActionAllowed(props.actionAllowed, props?.contactForm?.action_allowed)}
                                formAction={formAction}
                                contactType={contactType}
                                formatComboItems={formatComboItems}
                                returnHome={returnHome}
                                postContact={postContact}
                            />
                        );
                    } else if (contactType === "personal") {
                        content = (
                            <DetailContactForm
                                {...props}
                                actionAllowed={props.concatActionAllowed(props.actionAllowed, props?.contactForm?.action_allowed)}
                                formAction={formAction}
                                contactType={contactType}
                                formatComboItems={formatComboItems}
                                returnHome={returnHome}
                                postContact={postContact}
                            />
                        );
                    } else {
                        content = (
                            <ProContactSelector
                                {...props}
                                actionAllowed={props.concatActionAllowed(props.actionAllowed, props?.contactForm?.action_allowed)}
                                formAction={formAction}
                                contactType={contactType}
                                formatComboItems={formatComboItems}
                                returnHome={returnHome}
                                postContact={postContact}
                            />
                        );
                    }
                } else {
                    contentTitle = `${formatContactName(initContactForm || {})} (${props.i18n("title." + contactType + "_contact")})`;
                    content = (
                        <DetailContactForm
                            {...props}
                            actionAllowed={props.concatActionAllowed(props.actionAllowed, props?.contactForm?.action_allowed)}
                            formAction={formAction}
                            contactType={contactType}
                            formatComboItems={formatComboItems}
                            returnHome={returnHome}
                            postContact={postContact}
                        />
                    );
                }
                //subnavContent.push(returnBtn);
                break;
            default: //List display
                content = (
                    <>
                        <ListContactBlock
                            data={store.contact_list}
                            staticStore={store.static_store}
                            persoContactActivation={store.perso_contact_activation}
                            type="personal"
                            i18n={props.i18n}
                            actionAllowed={props.actionAllowed}
                            concatActionAllowed={props.concatActionAllowed}
                            postDeleteContact={props.postDeleteContact}
                            setInitContactForm={props.setInitContactForm}
                            msgBox={props.msgBox}
                            formatContactName={props.formatContactName}
                            refreshState={refreshState}
                            returnHome={returnHome}
                            scrollLayout={props.scrollLayout}
                        />
                        <ListContactBlock
                            data={store.pro_contact_list}
                            staticStore={store.static_store}
                            type="professional"
                            i18n={props.i18n}
                            actionAllowed={props.actionAllowed}
                            concatActionAllowed={props.concatActionAllowed}
                            postDeleteContact={props.postDeleteContact}
                            setInitContactForm={props.setInitContactForm}
                            msgBox={props.msgBox}
                            formatContactName={props.formatContactName}
                            refreshState={refreshState}
                            returnHome={returnHome}
                            scrollLayout={props.scrollLayout}
                        />
                    </>
                );
                break;
        }
        return (
            <>
                <div className="subnav_container">{subnavContent}</div>
                {contentTitle ? <h1 className="contentTitle">{contentTitle}</h1> : ""}
                {content}
            </>
        );
    }
    return (
        <>
            {props.setStyle("./v_yvelines/desktop")}
            {chooseDisplay()}
        </>
    );
}
