import React from "react";
import BaseScreen from "./screens/base/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";
export default function Home(props) {
    switch (props.appTheme) {
        case "yvelines":
            return <YvelinesDesktopScreen {...props} />;
        default:
            return <BaseScreen {...props} />;
    }
}
