//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect, useRef } from "react";
//External library
//import ReactToPrint from "react-to-print";
//import ReactToPdf from "react-to-pdf";
import cloneDeep from "clone-deep";
//Custom library
import {
    formatDayTitleDisplay,
    formatWeekTitleDisplay,
    formatMonthTitleDisplay,
    formatYearTitleDisplay,
    formatAsaproDate,
} from "../../../../ressources/lib/js/globalFunctions.js";
/*
On utilise 'react-to-print' pour transférer certains éléments de la page dans une vue d'impression gérée par le navigateur via "window.print()"
    # !!! Window.print n'est pas géré par certaines webViews mobiles (firefox android) mais par contre est supporté par IE5...
    # Et en plus c'est galère à mettre en forme...

On peut utiliser 'react-to-pdf' pour générer directement un PDF à télécharger en insérant des éléments de la page via les modules "jsPDF" et "html2canvas"
    # !!! Peut-être plus stable sur les webViews mobiles mais nécessité d'implémenter des polyfills pour IE et pas de prévisualisation ni d'option pour imprimer directement

Si l'on veut générer un PDF totalement différent de la page web avec les données fournies, utiliser 'react-pdf/renderer' ou 'pdfmake' (choix à définir)
*/
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import { BryntumCalendar } from "@bryntum/calendar-react";
import { EventModel, ResourceModel } from "@bryntum/calendar/calendar.umd.js";
import "@bryntum/calendar/calendar.material.css";
//Custom UI Components
import InitView from "../../../../ressources/components/initView/initView";
import "./localeFr"; //Automatically set french local inside this module (modify inside the module)
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//

function UserPlanning2(props) {
    // A personnaliser en fonction de la vue
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const plugLoadingDelay = 0; //Pour simuler un temps de chargement
    const plugLoadingError = false; //Pour simuler une erreur de chargement
    const defaultMode = "week";
    const screenType = props.screenWidth < 650 ? "small" : "normal";
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        event: [
            {
                id: 1352347,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-08 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-08 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 5152307,
                adm_company_ent_entity_id: null,
                adm_company_id: "17",
                cid: "81",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "Orthomicile",
                duration: "210.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-08 17:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-08 14:00:00",
                title: "Intervention dentaire",
            },
            {
                id: 3618341,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-08 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-08 20:00:00",
                title: "Aide à domicile",
            },
            {
                id: 3852341,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-09 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-09 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 2414378,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-09 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-09 20:00:00",
                title: "Aide à domicile",
            },
            {
                id: 5130479,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-10 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-10 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 3940621,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-10 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-10 20:00:00",
                title: "Aide à domicile",
            },
            {
                id: 3714972,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-10 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-10 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 3752464,
                adm_company_ent_entity_id: null,
                adm_company_id: "5",
                cid: "81",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "Medisoins",
                duration: "150.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-10 11:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-10 9:00:00",
                title: "Visite de routine",
            },
            {
                id: 7183466,
                adm_company_ent_entity_id: null,
                adm_company_id: "14",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "Bionmanger",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-10 12:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-10 12:00:00",
                title: "Livraison de repas",
            },
            {
                id: 4255414,
                adm_company_ent_entity_id: null,
                adm_company_id: "30",
                cid: "81",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "Boston Kinétics",
                duration: "90.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-11 15:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-11 14:00:00",
                title: "Intervention kinésithérapie",
            },
            {
                id: 1259467,
                adm_company_ent_entity_id: null,
                adm_company_id: "1",
                cid: "12",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "Propre et Sec",
                duration: "150.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-11 17:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-11 14:00:00",
                title: "Intervention Femme de Ménage",
            },
            {
                id: 4253064,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-11 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-11 20:00:00",
                title: "Aide à domicile",
            },
            {
                id: 1536497,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-12 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-12 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 2784310,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-12 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-12 20:00:00",
                title: "Aide à domicile",
            },
            {
                id: 4380601,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-13 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-13 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 6429051,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-13 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-13 20:00:00",
                title: "Aide à domicile",
            },
            {
                id: 1382111,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "60.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-14 9:00:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-14 8:00:00",
                title: "Aide à domicile",
            },
            {
                id: 4050619,
                adm_company_ent_entity_id: null,
                adm_company_id: "04",
                cid: "11",
                client_ent_entity_name: "Abrial Louisette-Marie",
                company_name: "SAD de Niort",
                duration: "30.00", //minutes
                employee_ent_entity_id: "-1",
                end_datetime: "2021-11-14 20:30:00",
                is_all_day: "0",
                origin_end_datetime: "",
                origin_start_datetime: "",
                par_status_treatment_id: "1",
                start_datetime: "2021-11-14 20:00:00",
                title: "Aide à domicile",
            },
        ],
    };
    const colorCode = [
        { id: -1, name: "Inconnu", color: "#C0C0C0" },
        { id: 8, name: "Date et heure de fin non renseignée", color: "#ffa200" },
        { id: 11, name: "Mandataire - Demande récurr", color: "#045c1a" },
        { id: 12, name: "Demande Client Prestataire", color: "#004884" },
        { id: 13, name: "Hors intervention - Récurr.", color: "#770d77" },
        { id: 14, name: "Abs. intervenant - Récurr.", color: "#8c433f" },
        { id: 15, name: "Abs. Client - Récurr.", color: "#9e0012" },
        { id: 16, name: "Complément planning APOLOGIC", color: "#BF3FBF" },
        { id: 21, name: "Intervention - en cours -", color: "#19ff19" },
        { id: 31, name: "Intervention réalisée", color: "#6bd571" },
        { id: 32, name: "Intervention réalisée Prestataire", color: "" },
        { id: 41, name: "Mandataire - Intervention planif.", color: "#25882a" },
        { id: 42, name: "Planning client Prestataire", color: "#0075d6" },
        { id: 43, name: "Hors intervention - Planifié", color: "#bf3fbf" },
        { id: 44, name: "Abs. intervenant - Planifié", color: "#b45e59" },
        { id: 45, name: "Abs. Client - Planifié", color: "#e62239" },
        { id: 51, name: "Plan de soins", color: "#004884" },
        { id: 80, name: "Intervention - non clôturée", color: "#ffa200" },
        { id: 81, name: "Planning Plan de soin", color: "#0075d6" },
        { id: 500, name: "Planifié", color: "#0075d6" },
        { id: 600, name: "Réalisé", color: "#6bd571" },
    ];
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [isReloading, setIsReloading] = useState(false);
    const [errorReloading, setErrorReloading] = useState(false);
    const [mode, setMode] = useState("month");
    const [calendarVisibility, setCalendarVisibility] = useState(true);
    const [loadedStartDate, setLoadedStartDate] = useState(getDateFromReference(-2)); //Number of months we load before today
    const [loadedEndDate, setLoadedEndDate] = useState(getDateFromReference(2)); //Number of months we load after today
    const [displayedEvents, setDisplayedEvents] = useState([]);
    const [displayStartDatetime, setDisplayStartDatetime] = useState(null);
    const [displayEndDatetime, setDisplayEndDatetime] = useState(null);
    //const printPdfRef = useRef();
    // Init functions
    function formatDuration(duration) {
        let time = duration; //Duration in minutes
        let years = Math.floor(time / (60 * 24 * 364.25));
        let days = 0,
            hours = 0,
            minutes = 0,
            pluralMinutes = "",
            pluralHours = "",
            pluralDays = "",
            pluralYears = years > 1 ? "s" : "";
        time -= years * 60 * 24 * 364.25;
        if (time > 0) {
            days = Math.floor(time / (60 * 24));
            pluralDays = days > 1 ? "s" : "";
            time -= days * 60 * 24;
            if (time > 0) {
                hours = Math.floor(time / 60);
                pluralHours = hours > 1 ? "s" : "";
                time -= hours * 60;
            }
        }
        minutes = Math.round(time);
        pluralMinutes = minutes > 1 ? "s" : "";
        return `${years ? `${years} an${pluralYears} ` : ""}${days ? `${days} jour${pluralDays} ` : ""}${
            hours ? `${hours} heure${pluralHours} ` : ""
        }${minutes ? `${minutes} minute${pluralMinutes} ` : ""}`;
    }
    function getDateFromReference(monthNumber, referenceDate) {
        let targetDate = referenceDate ? new Date(referenceDate) : new Date();
        targetDate.setMonth(targetDate.getMonth() + monthNumber);
        return `${targetDate.getFullYear()}-${
            targetDate.getMonth() + 1 < 10 ? `0${targetDate.getMonth() + 1}` : targetDate.getMonth() + 1
        }-01`;
    }
    function patchViewData(data) {
        const events = data.event;
        if (events && typeof events === "object") {
            events.forEach(function (event) {
                event.durationDisplay = formatDuration(event.duration);

                //To pass all 'unknown' event types under the same 'asa_type_event_id' (-1) which is the 'unknown' id reference in colorCode
                if (colorCode.findIndex((eventType) => +eventType?.id === +event?.asa_type_event_id) === -1) event.asa_type_event_id = -1;
            });
        }
        return data;
    }
    function loadViewData() {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        setIsloading(true);
        setErrorLoading(false);
        if (dataPlug && useDataPlug) {
            setTimeout(function () {
                if (plugLoadingError) {
                    setErrorLoading(true);
                    setIsloading(false);
                } else {
                    setViewData(patchViewData(dataPlug));
                    setIsloading(false);
                }
            }, plugLoadingDelay);
            return;
        }
        props
            .request({
                data: {
                    start_date: loadedStartDate,
                    end_date: loadedEndDate,
                },
            })
            .then(function (response) {
                console.log(response.data);
                console.warn(patchViewData(response.data));
                setViewData(patchViewData(response.data));
                handleResponseHelp(response);
                setIsloading(false);
            })
            .catch(function (error) {
                setErrorLoading(true);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            });
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        //Mise à jour du fil d'ariane
        props.updateUserJourney(["home", "home_client", "user_planning"]);
    }, []);
    let ent_entity_id = props.selectedClient.ent_entity_id;
    useEffect(
        function () {
            //Chargement des données de la vue (Bouchon ou non)
            loadViewData();
        },
        [ent_entity_id]
    );
    //useEffect(buildLegend, [displayStartDatetime, displayEndDatetime]);//DEPRECATED
    // Lorsqu'on istancie le calendrier pour la 1ère fois directement sur les vues "week" ou "day", la props "visibleStartTime" de ces vues n'est pas settée correctement (Le scroller est en haut à minuit quoi que l'on fasse...)
    // Pour corriger cette erreur, je décale le passage d'une vue "month" à une vue "week" et je rends le calendrier visible après cette manip grâce à l'effet ci-dessous (j'ai pas trouvé mieux pour l'instant comme solution...)
    // Si l'on décide d'instancier le calendrier sur les vues "month" ou "agenda", il faut commenter l'effet ci-dessous car il n'est pas utile et c'est plus agréable visuellement
    ///*
    useEffect(
        function () {
            if (!isLoading && !errorLoading) {
                setTimeout(function () {
                    setMode(defaultMode);
                    setTimeout(function () {
                        setCalendarVisibility(true);
                    }, 400);
                }, 400);
            } else {
                setCalendarVisibility(false);
            }
        },
        [isLoading]
    ); //*/
    // Init render treatments
    if (isLoading && !errorLoading) {
        return <InitView type="loading" />;
    }
    if (errorLoading) {
        return <InitView type="errorLoading" />;
    }
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)

    // View constants

    // View global functions
    function formatEventNumberDisplay(eventCount) {
        return ` (${eventCount > 0 ? eventCount : "Aucun"} évènement${eventCount > 1 ? "s" : ""})`;
    }
    function buildLegend(rawStartDate, rawEndDate, newMode, range, legendInstance, callbackViewData) {
        if (!legendInstance) {
            console.error("legendInstance is missing, legend is unavailable");
            return;
        }
        //HELPER FUNCTIONS
        function refreshDisplayDates(rawStartDate, rawEndDate, newMode) {
            const displayStartDate = rawStartDate ? new Date(rawStartDate) : null;
            let displayEndDate;
            switch (newMode) {
                case "month":
                    if (displayStartDate.getDate() !== 1) {
                        displayStartDate.setDate(1);
                        displayStartDate.setMonth(displayStartDate.getMonth() + 1);
                    }
                    displayEndDate = displayStartDate ? new Date(displayStartDate) : null;
                    if (displayEndDate) displayEndDate.setMonth(displayEndDate.getMonth() + 1);
                    break;
                default:
                    displayEndDate = rawEndDate ? new Date(rawEndDate) : null;
                    break;
            }
            if (displayEndDate) displayEndDate.setMilliseconds(displayEndDate.getMilliseconds() - 1);
            setDisplayStartDatetime(displayStartDate);
            setDisplayEndDatetime(displayEndDate);
            return { startDatetime: displayStartDate, endDatetime: displayEndDate };
        }
        function selectEvents(startDatetime, endDatetime, callbackViewData) {
            function calcLegendDuration(event) {
                //return duration en s
                switch (true) {
                    case event.all_day:
                        return 0;

                    case +event.duration > 0:
                        return +event.duration * 60;

                    default:
                        return (event.legendEndDatetime - event.legendStartDatetime) / 1000;
                }
            }
            const newDisplayedEvents =
                callbackViewData?.event && +callbackViewData?.event?.length > 0
                    ? callbackViewData.event.filter(function (event) {
                          const startDateTimeEvent = new Date(event?.start_datetime);
                          const endDateTimeEvent = new Date(event?.end_datetime);
                          if (startDateTimeEvent <= endDatetime && endDateTimeEvent >= startDatetime) {
                              event.legendStartDatetime = startDateTimeEvent < startDatetime ? startDatetime : startDateTimeEvent;
                              event.legendEndDatetime = endDateTimeEvent > endDatetime ? endDatetime : endDateTimeEvent;
                              /*
                                ,IF(asa_act.duration IS NOT NULL AND asa_act.duration >0
                                ,asa_act.duration
                                ,IF (asa_act.all_day=1
                                    ,0
                                    ,TIMESTAMPDIFF(MINUTE,CONCAT(asa_act.start_date, ' ', IFNULL(asa_act.start_time, '00:00:00')),CONCAT(asa_act.end_date, ' ', IFNULL(asa_act.end_time, '23:59:59')))
                                    ) )AS duration


                                */
                              event.legendDuration = calcLegendDuration(event); //(event.legendEndDatetime - event.legendStartDatetime) / 1000; //duration in seconds
                              return true;
                          }
                      })
                    : [];
            setDisplayedEvents(newDisplayedEvents);
            return newDisplayedEvents;
        }
        function buildLegendStatistics(eventList, mode, range, startDatetime) {
            const legendMetadata = { title: "Agenda Bénéficiaire", items: [] };
            function getTitle(startDatetime, mode, range, eventList) {
                let dateDisplay = "";
                switch (mode) {
                    case "day":
                        dateDisplay = formatDayTitleDisplay(startDatetime);
                        break;
                    case "week":
                        dateDisplay = formatWeekTitleDisplay(startDatetime, 7);
                        break;
                    case "month":
                        dateDisplay = formatMonthTitleDisplay(startDatetime);
                        break;
                    case "year":
                        dateDisplay = formatYearTitleDisplay(startDatetime);
                        break;
                    case "agenda":
                        return getTitle(startDatetime, range, range, eventList);
                    default:
                        return null;
                }
                return dateDisplay + formatEventNumberDisplay(eventList.length);
            }
            legendMetadata.title = getTitle(startDatetime, mode, range, eventList);
            eventList.forEach(function (event) {
                const currentTypeEventId = event?.asa_type_event_id;
                let eventTypeInLegend = legendMetadata.items.find((item) => item.id == currentTypeEventId);
                if (!eventTypeInLegend) {
                    const eventTypeData = colorCode.find((eventType) => eventType.id == currentTypeEventId);
                    if (eventTypeData) {
                        eventTypeInLegend = {
                            id: eventTypeData.id,
                            name: eventTypeData.name,
                            displayName: eventTypeData.name,
                            color: eventTypeData.color,
                            totalDuration: 0,
                            totalDurationDecimal: 0,
                            displayDuration: "",
                            displayDurationDecimal: "",
                        };
                        legendMetadata.items.push(eventTypeInLegend);
                    } else {
                        //The event is unknown
                        eventTypeInLegend = legendMetadata.items.find((item) => item.id == -1);
                        if (!eventTypeInLegend) {
                            const unknownEventTypeData = colorCode.find((item) => item.id == -1);
                            eventTypeInLegend = {
                                id: unknownEventTypeData.id,
                                name: unknownEventTypeData.name,
                                displayName: unknownEventTypeData.name,
                                color: unknownEventTypeData.color,
                                totalDuration: 0,
                                totalDurationDecimal: 0,
                                displayDuration: "",
                                displayDurationDecimal: "",
                            };
                            legendMetadata.items.push(eventTypeInLegend);
                        }
                    }
                }
                eventTypeInLegend.totalDuration += event.legendDuration;
            });
            legendMetadata.items.forEach(function (legendItem) {
                const totalDurationDecimal = legendItem.totalDuration / (60 * 60);
                const hourDurationInt = Math.floor(totalDurationDecimal);
                const hourDurationDisplay = hourDurationInt < 10 ? "0" + hourDurationInt : hourDurationInt;
                const minuteDurationInt = Math.round((totalDurationDecimal % 1) * 60);
                const minuteDurationDisplay = minuteDurationInt < 10 ? "0" + minuteDurationInt : minuteDurationInt;
                legendItem.totalDurationDecimal = totalDurationDecimal;
                legendItem.displayDuration = `${hourDurationDisplay}h${minuteDurationDisplay}`;
                legendItem.displayDurationDecimal = totalDurationDecimal.toFixed(2) + "h";
            });
            legendMetadata.items.sort(function (legendItem1, legendItem2) {
                return legendItem1.id - legendItem2.id;
            });
            return legendMetadata;
        }
        function printLegend(legendData, legendInstance) {
            //console.log(legendData);
            console.log(viewData?.no_prev);
            legendInstance.removeAll();
            let displayLegendItems = viewData?.no_prev
                ? `<div class="calendarLegendItem">
                        <div class=upperPart>
                            <div class=displayDuration>Merci de vous tourner vers votre SAAD pour connaître votre planning</div>
                        </div>
                    </div>`
                : "";
            legendData.items.forEach(function (legendItem, index) {
                displayLegendItems += `
                    <div class="calendarLegendItem">
                        <div class=upperPart>
                            ${legendItem?.color ? `<div class=colorIndicator style="background-color:${legendItem?.color};"></div>` : ""}
                            <div class=displayDuration>${legendItem?.name}&nbsp;:&nbsp;${legendItem?.displayDuration}</div>
                        </div>
                        ${
                            legendItem?.displayDurationDecimal
                                ? `<div class=lowerPart>
                                    ${props.i18n("label.decimal_duration")}&nbsp;:&nbsp;${legendItem.displayDurationDecimal}
                                </div>`
                                : ""
                        }
                    </div>`;
            });
            const html = `
                <div class=calendarLegendTitle>${legendData.title}</div>
                <div class=calendarLegendContent>
                    ${displayLegendItems}
                </div>
            `;
            legendInstance.content = html;
        }
        //LEGEND BUILDING PROCESS
        const displayDates = refreshDisplayDates(rawStartDate, rawEndDate, newMode);
        const newDisplayedEvents = selectEvents(displayDates.startDatetime, displayDates.endDatetime, callbackViewData);
        const legendStatistics = buildLegendStatistics(newDisplayedEvents, newMode, range, displayDates.startDatetime);
        printLegend(legendStatistics, legendInstance);
    }
    function manageViewDataLoading(viewStartDate, viewEndDate, calendarInstance) {
        //Helper functions
        function getDateRange(viewStartDate, viewEndDate, loadedStartDate, loadedEndDate, monthBefore, monthAfter) {
            viewStartDate = new Date(viewStartDate);
            viewEndDate = new Date(viewEndDate);
            loadedStartDate = new Date(loadedStartDate);
            loadedEndDate = new Date(loadedEndDate);
            let queryStartDate, queryEndDate, newLoadedStartDate, newLoadedEndDate;
            let change = true;
            switch (true) {
                case viewStartDate <= loadedStartDate:
                    queryStartDate = new Date(viewStartDate);
                    queryStartDate.setMonth(queryStartDate.getMonth() - monthBefore);
                    newLoadedStartDate = new Date(queryStartDate);
                    if (viewEndDate <= loadedEndDate) {
                        newLoadedEndDate = new Date(loadedEndDate);
                        queryEndDate = new Date(loadedStartDate);
                    } else {
                        queryEndDate = new Date(viewEndDate);
                        queryEndDate.setMonth(queryEndDate.getMonth() + monthAfter);
                        newLoadedEndDate = new Date(queryEndDate);
                    }
                    break;
                case viewEndDate >= loadedEndDate:
                    queryEndDate = new Date(viewEndDate);
                    queryEndDate.setMonth(queryEndDate.getMonth() + monthAfter);
                    newLoadedEndDate = new Date(queryEndDate);
                    if (viewStartDate >= loadedStartDate) {
                        newLoadedStartDate = new Date(loadedStartDate);
                        queryStartDate = new Date(loadedEndDate);
                    } else {
                        queryStartDate = new Date(viewStartDate);
                        queryStartDate.setMonth(queryStartDate.getMonth() - monthBefore);
                        newLoadedStartDate = new Date(queryStartDate);
                    }
                    break;
                default:
                    change = false;
                    break;
            }
            return {
                change: change,
                viewStartDate: formatAsaproDate(viewStartDate),
                viewEndDate: formatAsaproDate(viewEndDate),
                queryStartDate: formatAsaproDate(queryStartDate),
                queryEndDate: formatAsaproDate(queryEndDate),
                loadedStartDate: formatAsaproDate(newLoadedStartDate),
                loadedEndDate: formatAsaproDate(newLoadedEndDate),
                _formerLoadedStartDate: formatAsaproDate(loadedStartDate),
                _formerLoadedEndDate: formatAsaproDate(loadedEndDate),
            };
        }
        function concatViewData(newViewData, oldViewData) {
            const concatViewData = cloneDeep(oldViewData); //To break the reference with the push
            newViewData.event.forEach(function (newEvent) {
                if (
                    oldViewData.event.findIndex(function (oldEvent) {
                        return oldEvent.id == newEvent.id;
                    }) < 0
                ) {
                    concatViewData.event.push(newEvent);
                }
            });
            return cloneDeep(concatViewData); //To break all the references for further viewData state update
        }
        return new Promise(function (rs, rj) {
            const newDateRange = getDateRange(viewStartDate, viewEndDate, loadedStartDate, loadedEndDate, 2, 2);
            if (newDateRange.change) {
                //alert(loadedStartDate + "/" + loadedEndDate);
                setIsReloading(true);
                setCalendarVisibility(false);
                props
                    .request({
                        data: {
                            start_date: newDateRange.queryStartDate,
                            end_date: newDateRange.queryEndDate,
                        },
                    })
                    .then(function (response) {
                        const resViewData = patchViewData(response.data);
                        const newViewData = concatViewData(resViewData, viewData);
                        setViewData(newViewData);
                        setLoadedStartDate(newDateRange.loadedStartDate);
                        setLoadedEndDate(newDateRange.loadedEndDate);
                        //console.log(calendarInstance);
                        setErrorReloading(false);
                        setIsReloading(false);
                        setCalendarVisibility(true);
                        rs(newViewData);
                    })
                    .catch(function (error) {
                        setErrorReloading(true);
                        if (error.msg) {
                            console.error(error.msg);
                        }
                        console.error(error);
                    });
            } else {
                rs(viewData);
            }
        });
    }
    function customizeEventField(fieldInstance, fieldKey, eventData) {
        //!!! This fieldInstance is reused when another eventEdit is displayed (hidden property is not reset with 'config' value for example)
        //console.log(fieldInstance);
        //console.log(fieldInstance.name);
        fieldInstance.hidden = !fieldInstance.value && fieldInstance.value !== 0 ? true : false;
        switch (fieldKey) {
            case "startTimeField":
                fieldInstance.hidden = eventData?.is_all_day ? true : false;
                break;
            case "endTimeField":
                fieldInstance.hidden = eventData?.is_all_day || eventData.punctual;
                break;
            case "endDateField":
                fieldInstance.hidden = eventData.punctual;
                break;
            default:
                break;
        }
    }
    function displayEventMeta(eventData, rowDisplay) {
        let firstPart = eventData.pro_product_name || eventData.employee_ent_entity_name;
        let secondPart = eventData.supplier_organization_name;
        let separator = firstPart && secondPart ? "<br/>" : "";
        if (rowDisplay && separator) separator = " ";
        return (firstPart ? `<span>${firstPart}</span>` : "") + separator + (secondPart ? `<span>(${secondPart})</span>` : "");
    }
    // UI event handlers

    // Pre-rendering treatments
    class ViewDataModel extends EventModel {
        static get fields() {
            return [
                { name: "startDate", dataSource: "start_datetime", type: "date", format: "YYYY-MM-DD HH:mm:ss", nullable: false },
                { name: "endDate", dataSource: "end_datetime", type: "date", format: "YYYY-MM-DD HH:mm:ss", defaultValue: null },
                { name: "punctual", dataSource: "punctual", type: "boolean" },
                { name: "resourceId", dataSource: "asa_type_event_id", type: "int" },
                { name: "allDay", dataSource: "all_day", type: "boolean" },
                { name: "durationUnit", type: "string", defaultValue: "m" }, //Mandatory to correctly set events inside calendar
                { name: "client_ent_entity_name", type: "string" },
                { name: "durationDisplay", type: "string", defaultValue: "Inconnue" },
                { name: "payer_organization_name", type: "string", defaultValue: "" },
                { name: "supplier_organization_name", type: "string", defaultValue: "" },
                { name: "pro_product_name", type: "string", defaultValue: "" },
                { name: "ord_type_cadre_intervention_name", type: "string", defaultValue: "" },
                { name: "employee_ent_entity_name", type: "string", defaultValue: "" },
            ];
        }
    }
    class EventTypeModel extends ResourceModel {
        static get fields() {
            return [{ name: "eventColor", dataSource: "color", type: "string" }];
        }
    }
    // Rendering part
    let viewContent;
    //let toPdfFct;
    viewContent = (
        <div className="user-planning-container">
            {isReloading || errorReloading ? <InitView type={isReloading ? "loading" : "errorLoading"} /> : ""}
            {/*<ReactToPrint
                    trigger={() => (
                        <Button className="btnTooltip" disabled={!viewData}>
                            <span className="fas fa-print"></span>
                            Imprimer
                        </Button>
                    )}
                    content={() => printPdfRef.current}
                    onBeforeGetContent={function (rawContent) {
                        console.log(content);
                    }}
                    documentTitle="Agenda"
                />*/}
            {/*<ReactToPdf
                    targetRef={printPdfRef}
                    filename="Agenda.pdf"
                    x={10}
                    y={10}
                    options={{
                        orientation: "landscape",
                        unit: "px",
                        format: [window.innerWidth, window.innerHeight],
                        hotfixes: ["px_scaling"],
                    }}
                    scale={1}
                >
                    {({ toPdf }) => (toPdfFct = toPdf)}
                </ReactToPdf>*/}
            <div className="planningContainer" /*ref={printPdfRef}*/>
                <BryntumCalendar
                    mode={mode}
                    readOnly={true}
                    modes={{
                        agenda: {
                            title: "Liste",
                            eventRenderer: function ({ renderData }) {
                                const eventData = renderData.eventRecord.data;
                                let startTime = new Date(eventData.start_datetime).toLocaleTimeString("fr-FR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                let endTime = new Date(eventData.end_datetime).toLocaleTimeString("fr-FR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                let timePart = `<span>${startTime} - ${endTime}</span><br/>`;
                                let metaPart = displayEventMeta(eventData);
                                //let companyPart = `<br/><span>(${renderData.eventRecord.data.supplier_organization_name})</span>`;
                                return screenType === "small" ? timePart + metaPart : metaPart; //Only played at the init of the view (not played when screen is resized)
                            },
                            /*descriptionRenderer: function (view) {
                                //Useless if viewDescription Widget is disabled inside tbar
                                let daysWithEvents = view.store.records;
                                let eventCount = 0;
                                daysWithEvents.forEach(function (day) {
                                    eventCount += day.data.events.length;
                                });
                                return formatAgendaTitleDisplay(view?._startDate, eventCount, view?._range?.unit);
                            },*/
                            emptyText: "Aucun évènement",
                            eventHeight: "auto",
                            range: "week",
                            listeners: {
                                beforeShow: function (event) {
                                    let rangeMenu = event.source.listRangeMenu;
                                    let rangeMenuItems = rangeMenu.items;
                                    rangeMenu.title = "Visibilité des évènements";
                                    rangeMenuItems.listRangeDayItem.text = "Jour";
                                    rangeMenuItems.listRangeMonthItem.text = "Mois";
                                    rangeMenuItems.listRangeWeekItem.text = "Semaine";
                                    rangeMenuItems.listRangeYearItem.text = "Année";
                                    rangeMenuItems.listRangeDecadeItem = null;
                                },
                                renderRows: function (event) {
                                    const view = event?.source;
                                    const today = new Date();
                                    setTimeout(function () {
                                        view.scrollTo(today);
                                    }, 1);
                                },
                            },
                        },
                        day: {
                            //title: "Titre de la vue Jour",
                            eventSpacing: 2,
                            eventRenderer: function ({ renderData }) {
                                const eventData = renderData.eventRecord.data;
                                let startTime = new Date(eventData.start_datetime).toLocaleTimeString("fr-FR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                let endTime = new Date(eventData.end_datetime).toLocaleTimeString("fr-FR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                return `<span>${startTime} - ${endTime}</span><br/>${displayEventMeta(eventData)}`;
                            },
                            hourHeight: 100,
                            showTime: false,
                            visibleStartTime: 7,
                            listeners: {
                                beforeShow: function ({ source }) {
                                    //console.log(source);
                                    source._dateFormat = "dddd DD MMMM YYYY";
                                },
                            },
                            /*descriptionRenderer: function (view) {
                                //Useless if viewDescription Widget is disabled inside tbar
                                return formatDayTitleDisplay(view?.date);
                            },*/
                        },
                        week: {
                            //title: "Titre de la vue Semaine",
                            //dateFormat: "YYYY",
                            eventSpacing: 2,
                            eventRenderer: function ({ renderData }) {
                                const eventData = renderData.eventRecord.data;
                                //if (eventData) console.log(eventData);
                                let startTime = new Date(eventData.start_datetime).toLocaleTimeString("fr-FR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                let endTime = new Date(eventData.end_datetime).toLocaleTimeString("fr-FR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                                return `<span>${startTime} - ${endTime}</span><br/>${displayEventMeta(eventData)}`;
                            },
                            /*descriptionRenderer: function (view) {
                                //console.log("Description week : ", view);
                                //Useless if viewDescription Widget is disabled inside tbar
                                const cellMonth = view?.cellMonth || {};
                                const weekLength = cellMonth?.weekLength;
                                return formatWeekTitleDisplay(cellMonth?.date, weekLength);
                            },*/
                            hourHeight: 100,
                            showTime: false,
                            visibleStartTime: 7,
                        },
                        month: {
                            //title: "Titre de la vue Mois",
                            eventRenderer: function ({ renderData }) {
                                const eventData = renderData.eventRecord.data;
                                //return `<span>${renderData.eventRecord.data.pro_product_name}</span> <span>(${renderData.eventRecord.data.supplier_organization_name})</span>`;
                                return displayEventMeta(eventData, true);
                            },
                            minRowHeight: 150,
                            /*descriptionRenderer: function (view) {
                                //Useless if viewDescription Widget is disabled inside tbar
                                return formatMonthTitleDisplay(view?.cellMonth?._date);
                            },*/
                            sixWeeks: false,
                            disableOtherMonthCells: true,
                            hideOtherMonthCells: true,
                        },
                        year: null,
                    }}
                    nonWorkingDays={{
                        0: true,
                        1: false,
                        2: false,
                        3: false,
                        4: false,
                        5: false,
                        6: true,
                    }}
                    sidebar={{
                        floating: true,
                        collapsed: true,
                        scrollable: true,
                        items: {
                            datePicker: {
                                listeners: {
                                    readOnly: function (event) {
                                        event.source.readOnly = false;
                                    },
                                },
                            },
                            eventFilter: false,
                            resourceFilter: false,
                        },
                        listeners: {
                            focusOut: function (event) {
                                const target = event?.explicitOriginalTarget;
                                if (target?.dataset?.ref !== "toggleSideBar") this.element.style = "margin-left: -360px;";
                            },
                        },
                    }}
                    tbar={{
                        //Top bar (common config for all viewTypes)
                        items: {
                            //Can be personnalized using an array of widget configs (erase all default widgets below)
                            toggleSideBar: {
                                icon: "far fa-calendar-alt",
                                listeners: {
                                    action: function (event) {
                                        const sidebar = event?.source?.owner?.owner?.sidebar;
                                        const calendarPanel = sidebar?.items[0];
                                        const btnNextMonth = calendarPanel?.tbar?.items[2];
                                        if (btnNextMonth) btnNextMonth.focus();
                                    },
                                },
                            },
                            todayButton: false,
                            //prevButton: false
                            //nextButton: false
                            viewDescription: false,
                            spacer: false,
                            modeSelector: {
                                listeners: {
                                    beforewidgetcreate: function (e) {
                                        //To force the focus at the init of the view
                                        //console.log(e);
                                        const modeSelectorItems = e.source.items;
                                        if (modeSelectorItems[1]) {
                                            //weekButtonSelector
                                            const weekButtonTag = modeSelectorItems[1]["currentElement"];
                                            if (weekButtonTag) weekButtonTag.focus();
                                        }
                                    },
                                },
                            },
                            legend: {
                                type: "container",
                                weight: 550,
                                id: "calendarLegendContainer",
                            },
                        },
                    }}
                    /*bbar={{
                        type: "button",
                        text: "Hello bottom",
                    }}*/
                    weekStartDay={1}
                    eventStore={{
                        modelClass: ViewDataModel,
                    }}
                    events={viewData?.event || []}
                    resourceStore={{
                        modelClass: EventTypeModel,
                    }}
                    resources={colorCode}
                    destroyStores={true}
                    scrollable={true} //can't set an object param ({overflowX: 'hidden', overflowY: 'auto'}) because it makes a cyclic object error
                    //This config syntax is working if setted directly inside each viewType ('week', 'agenda', 'sidebar' ...)
                    eventSelectionDisabled={false} //Don't seem to have any effect (try to put it inside a viewType config)
                    height="700px"
                    minHeight="100%"
                    //width="100%"
                    monitorResize={true}
                    hidden={!calendarVisibility}
                    //Direct REACT Wrapper listeners (We can use React states inside)
                    // Instead of using the 'listeners' config which doesn't allow React states to be used inside eventHandler functions
                    onBeforeEventEditShow={function (event) {
                        //Setting the header color with the same color as the event selected
                        let colorId = +event.eventRecord.data.asa_type_event_id;
                        let typeEvent = colorCode.find(function (element) {
                            return element.id == colorId;
                        });
                        if (!typeEvent) typeEvent = colorCode[0];
                        let eventColor = typeEvent.color;
                        let headerPopupTag = event.editor.byRef.headerElement;
                        headerPopupTag.style.backgroundColor = eventColor;

                        //Fields customization
                        const eventEdit = event.eventEdit;
                        //console.log(event);
                        if (eventEdit) {
                            const eventEditItems = eventEdit.items || {};
                            const eventEditFields = Object.keys(eventEditItems);
                            eventEditFields.forEach(function (fieldKey) {
                                if (eventEdit[fieldKey]) {
                                    customizeEventField(eventEdit[fieldKey], fieldKey, event?.eventRecord?.data);
                                }
                            });
                        }
                    }}
                    onActiveItemChange={function (event) {
                        //Fired even at init
                        //Override "dateRangeChange" event (which is wonderful...)
                        //console.log(event);
                        const viewStartDate = event?.activeItem?.startDate;
                        const viewEndDate = event?.activeItem?.endDate;
                        const newMode = event?.activeItem?.modeName;
                        const range = event?.activeItem?._range?.unit;
                        const legendInstance = this?.tbar?._widgetMap?.legend;
                        setMode(newMode);
                        buildLegend(viewStartDate, viewEndDate, newMode, range, legendInstance, viewData);
                    }}
                    onDateRangeChange={function (event) {
                        //Pas top comme event, il se déclenche quand on change de vue mais pas tout le temps... (uniquement la 1ère fois qu'on le fait)
                        const viewStartDate = event?.new?.startDate;
                        const viewEndDate = event?.new?.endDate;
                        const newMode = this.mode;
                        const range = this?.activeView?._range?.unit;
                        const calendarInstance = this;
                        const legendInstance = this?.tbar?._widgetMap?.legend;
                        manageViewDataLoading(viewStartDate, viewEndDate, calendarInstance).then(function (newViewData) {
                            buildLegend(viewStartDate, viewEndDate, newMode, range, legendInstance, newViewData);
                        });
                    }}
                    eventTooltipFeature={{
                        disabled: false,
                        items: {},
                        showOn: "hover",
                        titleRenderer: function (event) {
                            const eventData = event.data;
                            return `<div style='text-align: center'>${displayEventMeta(eventData)}<div>`;
                        },
                        tools: {
                            edit: {
                                cls: "fas fa-calendar",
                            },
                            delete: null,
                        },
                        listeners: {
                            beforeShow: function (event) {
                                let colorId = +event.source._eventRecord.data.asa_type_event_id;
                                let typeEvent = colorCode.find(function (element) {
                                    return element.id == colorId;
                                });
                                if (!typeEvent) typeEvent = colorCode[0]; //The unknown type Event
                                let eventColor = typeEvent.color;
                                let headerPopupTag = event.source.byRef.headerElement;
                                headerPopupTag.style.backgroundColor = eventColor;
                            },
                        },
                    }}
                    eventEditFeature={{
                        disabled: false,
                        triggerEvent: "eventclick",
                        items: {
                            nameField: {
                                weight: 0,
                                //label: "Intervenant",
                                type: "textfield",
                                clearable: false,
                            },
                            serviceField: {
                                type: "textfield",
                                label: "Service",
                                name: "pro_product_name",
                                weight: 1,
                            },
                            resourceField: {
                                weight: 2,
                                label: "Type d'intervention",
                            },
                            payerField: {
                                type: "textfield",
                                label: "Financeur",
                                name: "payer_organization_name",
                                weight: 3,
                            },
                            supplierField: {
                                type: "textfield",
                                label: "Prestataire",
                                name: "supplier_organization_name",
                                weight: 4,
                            },

                            cadreField: {
                                type: "textfield",
                                label: "Cadre d'intervention",
                                name: "ord_type_cadre_intervention_name",
                                weight: 5,
                            },
                            employeeField: {
                                type: "textfield",
                                label: "Intervenants",
                                name: "employee_ent_entity_name",
                                weight: 6,
                            },
                            startDateField: {
                                type: "datefield",
                                weight: 7,
                            },
                            startTimeField: { weight: 8 },
                            endDateField: { weight: 9 },
                            endTimeField: { weight: 10 },
                            durationField: {
                                type: "textfield",
                                label: "Durée",
                                name: "durationDisplay",
                                weight: 11,
                            },
                            allDay: {
                                name: "allDay",
                                label: "Toute la journée",
                                weight: 12,
                            },
                            recurrenceCombo: null,
                            editRecurrenceCombo: null,
                        },
                    }}
                />
            </div>
        </div>
    );
    return (
        <>
            {props.setStyle("./normal")}
            {viewContent}
        </>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default UserPlanning2; // A personnaliser en fonction de la vue
