import React from "react";
import ViewItem from "../viewItem/viewItem.js";
import "./viewItemGroup.css";
export default function ViewItemGroup(props) {
    /**
     * @param {Object} props :{
     *      @param {Object[]} items : [{
     *          @descr La liste des données des vues contenues dan ce groupe (Pour l'instant seulement 'direction')
     *          @param {String} direction : Le hash de la vue comprise dans ce groupe
     *      }, {...}, ...]
     *
     *      @param {String} headerName : Le hash de la vue 'home_$header'
     *          @example 'home_communication' || 'home_demandes'
     *              TODO: Renommer en 'home_1', 'home_2', 'home_1_2', ...
     *
     *      @param {Function} i18n : La fonction i18n pour récupérer les textes et icônes
     *
     *      @param {Function} redirectTo : La fonction pour rediriger vers la vue spécifiée dans @param direction
     *
     * }
     */

    const headerName = props.headerName || "";
    const items = props.items || [];

    //Rendering functions
    function headerRender() {
        if (headerName) {
            return <h2 className="groupName">{props.i18n("label.view." + headerName)}</h2>;
        }
        return "";
    }
    function itemRender() {
        const itemDisplayList = [];
        items.forEach(function (item) {
            const direction = item.direction;
            itemDisplayList.push(
                <ViewItem key={`${headerName}_item_${direction}`} direction={direction} i18n={props.i18n} redirectTo={props.redirectTo} />
            );
        });
        return itemDisplayList;
    }
    return (
        <div className={"viewItemGroupContainer" + (headerName ? " " + headerName : "")}>
            {headerRender()}
            <div className="groupContent">{itemRender()}</div>
        </div>
    );
}
