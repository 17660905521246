import React from "react";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom
import PortalCheck from "../../../../../../../../ressources/components/portalFields/portalCheck/portalCheck";
import PortalText from "../../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalDate from "../../../../../../../../ressources/components/portalFields/portalDate/portalDate.js";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalFeedback from "../../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
import PortalTitle from "../../../../../../../../ressources/components/portalTitle/portalTitle";
import PopupView from "../../../../../../../../ressources/components/popupView/popupView.js";
export default function LocalCreationForm(props) {
    const formData = props.formData;
    const formValidator = props.formValidator;
    const formValidationCount = props.formValidationCount;
    const setFormData = props.setFormData;
    const postRegister = props.postRegister;
    const requestState = props.requestState;
    const setStep = props.setStep;
    const portalType = props.portalType;
    const pscConnect = props.pscConnect;
    const cguData = props.cguData || {};
    const rgpdData = props.rgpdData || {};
    const openCgu = props.openCgu;
    const legalContent = props.store.legal_content || "";
    const setViewLayoutData = props.setViewLayoutData;
    const [rawDate, setRawDate] = useState(formData.birth_datetime);
    /*useEffect(
        function () {
            if (rawDate) {
                setFormData("birth_datetime", rawDate);
            }
        },
        [rawDate]
    );*/

    const lastNameValidationResult = formValidator.fields.last_name.validate();
    const firstNameValidationResult = formValidator.fields.first_name.validate();
    const usernameValidationResult = formValidator.fields.username.validate();
    const dossierValidationResult = formValidator.fields.referencedossier.validate();
    const ssnNumberValidationResult = formValidator.fields.ssn_number_patient.validate();
    const rppsValidationResult = formValidator.fields.rpps.validate();
    const birthValidationResult = formValidator.fields.birth_datetime.validate();
    const cguValidationResult = formValidator.fields.cgu.validate();
    const consentValidationResult = formValidator.fields.consent.validate();
    const rgpdValidationResult = formValidator.fields.rgpd.validate();
    //Effect treatments
    useEffect(function () {
        setViewLayoutData(["title", "disclaimer"], [props.i18n("title.local_creation"), ""]);
    }, []);
    //UX event handlers
    function onSubmitForm(event) {
        event.preventDefault();
        if (typeof postRegister === "function") postRegister();
    }
    return (
        <>
            <Form id="registerForm" onSubmit={onSubmitForm} noValidate>
                {/*<PortalTitle title={props.i18n("title.local_creation")} />*/}
                {!!props.i18n("label.field.form_filling_instructions") && (
                    <Form.Group>
                        <h4>{props.i18n("label.field.form_filling_instructions")}</h4>
                    </Form.Group>
                )}
                {!!props.i18n("label.field.mandatory_fields") && (
                    <Form.Group className="smallFont">{props.i18n("label.field.mandatory_fields")}</Form.Group>
                )}
                <Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="lastName">
                        {!!props.i18n("label.field.last_name") && (
                            <Form.Label>
                                {props.i18n("label.field.last_name")}&nbsp;<span aria-hidden={true}>*</span>
                            </Form.Label>
                        )}
                        <PortalText
                            id="lastName"
                            onChange={(e) => setFormData("last_name", e.target.value)}
                            type="text"
                            autoComplete="family-name"
                            name="lastName"
                            placeholder={props.i18n("placeholder.field.last_name")}
                            value={formData.last_name}
                            autoFocus
                            required={true}
                            aria-required={true}
                            isInvalid={!lastNameValidationResult.success && formValidationCount > 0}
                            aria-invalid={!lastNameValidationResult.success && formValidationCount > 0}
                            aria-describedby="lastName-1"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="lastName" msgList={lastNameValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="firstName">
                        {!!props.i18n("label.field.first_name") && (
                            <Form.Label>
                                {props.i18n("label.field.first_name")}&nbsp;<span aria-hidden={true}>*</span>
                            </Form.Label>
                        )}
                        <PortalText
                            id="firstName"
                            onChange={(e) => setFormData("first_name", e.target.value)}
                            type="text"
                            autoComplete="given-name"
                            name="firstName"
                            placeholder={props.i18n("placeholder.field.first_name")}
                            value={formData.first_name}
                            required={true}
                            aria-required={true}
                            isInvalid={!firstNameValidationResult.success && formValidationCount > 0}
                            aria-invalid={!firstNameValidationResult.success && formValidationCount > 0}
                            aria-describedby="firstName-1"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="firstName" msgList={firstNameValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="birthDate">
                        {!!props.i18n("label.field.birth_datetime") && (
                            <Form.Label>
                                {props.i18n("label.field.birth_datetime")}&nbsp;<span aria-hidden={true}>*</span>
                            </Form.Label>
                        )}
                        <PortalDate
                            id="birthDate"
                            onChange={(e) => setFormData("birth_datetime", e.target.value)}
                            autoComplete="bday"
                            name="birthDate"
                            placeholder={props.i18n("placeholder.field.birth_datetime")}
                            value={formData.birth_datetime}
                            required={true}
                            aria-required={true}
                            isInvalid={!birthValidationResult.success && formValidationCount > 0}
                            aria-invalid={!birthValidationResult.success && formValidationCount > 0}
                            aria-describedby="birthDate-1"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="birthDate" msgList={birthValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                    {portalType !== "pro" && (
                        <Form.Group as={Col} xs={12} md={6} controlId="fileNumber">
                            {!!props.i18n("label.field.file_number") && (
                                <Form.Label>
                                    {props.i18n("label.field.file_number")} <span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="fileNumber"
                                onChange={(e) => setFormData("referencedossier", e.target.value)}
                                type="text"
                                tooltip={props.i18n("info.field.file_number")}
                                name="fileNumber"
                                placeholder={props.i18n("placeholder.field.file_number")}
                                value={formData.referencedossier}
                                aria-required={true}
                                required={true}
                                isInvalid={!dossierValidationResult.success && formValidationCount > 0}
                                aria-invalid={!dossierValidationResult.success && formValidationCount > 0}
                                aria-describedby="fileNumber-1"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback fieldId="fileNumber" msgList={dossierValidationResult.errors} i18n={props.i18n} />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                    )}
                    {portalType == "pro" && (
                        <Form.Group as={Col} xs={12} md={6} controlId="rpps">
                            {!!props.i18n("label.field.rpps") && (
                                <Form.Label>
                                    {props.i18n("label.field.rpps")} <span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="rpps"
                                onChange={(e) => setFormData("rpps", e.target.value)}
                                type="text"
                                //tooltip={props.i18n("info.field.rpps")}
                                name="rpps"
                                placeholder={props.i18n("placeholder.field.rpps")}
                                value={formData.rpps}
                                aria-required={true}
                                required={true}
                                isInvalid={!rppsValidationResult.success && formValidationCount > 0}
                                aria-invalid={!rppsValidationResult.success && formValidationCount > 0}
                                aria-describedby="rpps-1"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback fieldId="rpps" msgList={rppsValidationResult.errors} i18n={props.i18n} />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                    )}
                </Row>
                <Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="username">
                        {!!props.i18n("label.field.username") && (
                            <Form.Label>
                                {props.i18n("label.field.username")}&nbsp;<span aria-hidden={true}>*</span>
                            </Form.Label>
                        )}
                        <PortalText
                            id="username"
                            onChange={(e) => setFormData("username", e.target.value)}
                            type="email"
                            iconsLeft="far fa-envelope"
                            autoComplete="email"
                            name="username"
                            placeholder={props.i18n("placeholder.field.username")}
                            value={formData.username}
                            tooltip={props.i18n("info.field.new_username")}
                            required={true}
                            aria-required={true}
                            isInvalid={!usernameValidationResult.success && formValidationCount > 0}
                            aria-invalid={!usernameValidationResult.success && formValidationCount > 0}
                            aria-describedby="username-1 username-2"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="username" msgList={usernameValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                    {/*portalType !== "pro" && (
                        <Form.Group as={Col} xs={12} md={6} controlId="ssnNumber">
                            {!!props.i18n("label.field.ssn_number_patient") && (
                                <Form.Label>
                                    {props.i18n("label.field.ssn_number_patient")} <span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="ssnNumber"
                                onChange={(e) => setFormData("ssn_number_patient", e.target.value)}
                                type="text"
                                tooltip={props.i18n("info.field.ssn_number_patient")}
                                name="fileNumber"
                                placeholder={props.i18n("placeholder.field.ssn_number_patient")}
                                value={formData.ssn_number_patient}
                                aria-required={true}
                                required={true}
                                isInvalid={!ssnNumberValidationResult.success && formValidationCount > 0}
                                aria-invalid={!ssnNumberValidationResult.success && formValidationCount > 0}
                                aria-describedby="ssnNumber-1"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback fieldId="ssnNumber" msgList={ssnNumberValidationResult.errors} i18n={props.i18n} />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                    )*/}
                </Row>
                {/* Ligne qui sépare les deux sections */}
                {/*<Row className="line-space-row">
                <hr id="line-space" />
            </Row>
            <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="password">
                    <Form.Label>
                        {props.i18n("label.field.current_password")} <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalPassword
                        id="password"
                        onChange={(e) => setFormData("password", e.target.value)}
                        autoComplete="new-password"
                        name="password"
                        value={formData.password}
                        tooltip={props.i18n("info.field.new_password")}
                        required={true}
                        aria-required={true}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} controlId="confirmPassword">
                    <Form.Label>
                        Confirmation du mot de passe <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalPassword
                        id="confirmPassword"
                        onChange={(e) => setFormData("password_confirm", e.target.value)}
                        autoComplete="new-password"
                        name="confirmPassword"
                        value={formData.password_confirm}
                        required={true}
                        aria-required={true}
                    />
                </Form.Group>
                    </Row>*/}
                <Row className="checkRow">
                    <Form.Group className="checkGroup">
                        <PortalCheck
                            inputName="checkCGU"
                            active={formData.cgu}
                            onChange={() => setFormData("cgu", !formData.cgu)}
                            text={
                                //TODO: Changer ce paramètre en 'label' = car on peut y insérer du JSX'
                                /*<>
                                    {props.i18n("label.field.cgu_agreement_1")}&nbsp;
                                    <button type="button" className="portalLinkBtn cguLink" onClick={openCgu}>
                                        {props.i18n("label.field.cgu_agreement_2")}
                                    </button>
                                    &nbsp;
                                    <span aria-hidden={true}>*</span>
                                </>*/
                                props.i18n("label.field.cgu_agreement", [cguData.url])
                            }
                            textAlign="right"
                            required={true}
                            invalid={!cguValidationResult.success && formValidationCount > 0}
                            aria-describedby="checkCgu-1"
                        />
                        {/*TODO: Mettre en place un système de feedback accessible comme pour les inputs texte de bootstrap*/}
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="checkCgu" msgList={cguValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                    <Form.Group className="checkGroup">
                        <PortalCheck
                            inputName="checkConsent"
                            active={formData.consent}
                            onChange={() => setFormData("consent", !formData.consent)}
                            text={props.i18n("label.field.consent")}
                            textAlign="right"
                            required={true}
                            invalid={!consentValidationResult.success && formValidationCount > 0}
                            aria-describedby="checkConsent-1"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="checkConsent" msgList={consentValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                    <Form.Group className="checkGroup">
                        <PortalCheck
                            inputName="checkRgpd"
                            active={formData.rgpd}
                            onChange={() => setFormData("rgpd", !formData.rgpd)}
                            text={props.i18n("label.field.rgpd_agreement", [rgpdData.url])}
                            textAlign="right"
                            required={true}
                            invalid={!rgpdValidationResult.success && formValidationCount > 0}
                            aria-describedby="checkRgpd-1"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback fieldId="checkRgpd" msgList={rgpdValidationResult.errors} i18n={props.i18n} />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                </Row>
                <Row className="validationRow">
                    <PortalBtn
                        state={requestState}
                        className="validate"
                        type="submit"
                        style={{ marginRight: "20px" }}
                        iconCls="fas fa-arrow-right"
                        iconAlign="right"
                    >
                        {props.i18n("label.action.send_signup")}
                    </PortalBtn>
                    {(portalType !== "pro" || (portalType === "pro" && pscConnect)) && (
                        <PortalBtn className="backBtn" onClick={() => setStep("modeChoice")}>
                            Retour
                        </PortalBtn>
                    )}
                </Row>
            </Form>
            <div className="legalInfoContainer" dangerouslySetInnerHTML={{ __html: legalContent }}></div>
        </>
    );
}
