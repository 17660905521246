//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect } from "react";
//Custom Library
import PortalScript from "../../../../ressources/components/portalScript/portalScript";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
//Custom UI Components
import InitView from "../../../../ressources/components/initView/initView";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//
function ClientDmp(props) {
    // A personnaliser en fonction de la vue
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const plugLoadingDelay = 2000; //Pour simuler un temps de chargement
    const plugLoadingError = false; //Pour simuler une erreur de chargement
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        viewData1: "Donnée n°1",
        viewData2: "Donnée n°2",
    };
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [errorLoadingMsg, setErrorLoadingMsg] = useState("");
    // Init functions

    function loadViewData() {
        setIsloading(true);
        setErrorLoading(false);
        setTimeout(function () {
            console.log(props.viewHash);
            console.log(window.location.hash.slice(2));
        }, 2000);
        if (dataPlug && useDataPlug) {
            setTimeout(function () {
                if (plugLoadingError) {
                    setErrorLoading(true);
                    setIsloading(false);
                } else {
                    setViewData(dataPlug);
                    setIsloading(false);
                }
            }, plugLoadingDelay);
            return;
        }
        props
            .request()
            .then(function (response) {
                setViewData(response.data);
                setIsloading(false);
                props
                    .openExternal({
                        url: "https://www.monespacesante.fr/ssov2/authentification.html?domain=patient&client_id=103-9b2244c2-c776-4566-8853-277d16e2a0a7&tab_id=MhuBwxLuDx4",
                        msgBoxConfig: {
                            className: "monespacesante",
                        },
                    })
                    .finally(function () {
                        props.redirectTo("home", null, true);
                    });
            })
            .catch(function (error) {
                setErrorLoading(true);
                setErrorLoadingMsg(error.msg);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
                props
                    .openExternal({
                        url: "https://www.monespacesante.fr/",
                        msgBoxConfig: {
                            className: "monespacesante",
                        },
                    })
                    .finally(function () {
                        props.redirectTo("home", null, true);
                    });
            });
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        //Mise à jour du fil d'ariane
        props.updateUserJourney(["home", "home_client", "client_dmp"]); // A personnaliser en fonction de la vue
        return function () {};
    }, []);
    let ent_entity_id = props.selectedClient.ent_entity_id;
    useEffect(
        function () {
            //Chargement des données de la vue (Bouchon ou non)
            loadViewData();
        },
        [ent_entity_id]
    );

    // Init render treatments
    if (isLoading && !errorLoading) {
        return <InitView type="loading" />;
    }
    if (errorLoading) {
        return <InitView type="errorLoading" errorMsg={"DMP Indisponible" || errorLoadingMsg} />;
    }
    if (!viewData?.iframe || typeof viewData?.iframe !== "string" || typeof viewData?.iframe !== "string")
        return <InitView type="errorLoading" errorMsg="Instanciation du DMP erronnée" />;
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)

    // View constants

    // View global functions

    // UI event handlers
    // Pre-rendering treatments

    // Rendering part
    return (
        <>
            {props.setStyle("./normal")}
            {viewData?.script ? <PortalScript>{viewData.script}</PortalScript> : ""}
            <div id="dmp_container" className="iframe_dmp_container" dangerouslySetInnerHTML={{ __html: viewData.iframe }}></div>
        </>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default ClientDmp; // A personnaliser en fonction de la vue
