import React from "react";
import { useState } from "react";
//Custom UI Components
import PortalToolbar from "../../../../../../../ressources/components/portalToolbar/portalToolbar.js";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalCombo from "../../../../../../../ressources/components/portalFields/portalCombo/portalCombo.js";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText.js";
export default function FilterToolbar(props) {
    //Local constants
    const setFilterFields = props.setFilterFields;
    const setFilterValue = props.setFilterValue;
    const filterFieldsComboData = props.filterFieldsComboData || [];
    const realTimeFilter = !!props.realTimeFilter;
    //Local states
    const [localFilterFields, setLocalFilterFields] = useState([]);
    const [localFilterValue, setLocalFilterValue] = useState("");
    //UX handlers
    function onChangeComboOption(e) {
        if (!realTimeFilter) {
            setLocalFilterFields(e.value);
            return;
        }
        setFilterFields(e.value);
    }
    function onChangeSearchValue(e) {
        if (!realTimeFilter) {
            setLocalFilterValue(e.target.value);
            return;
        }
        setFilterValue(e.target.value);
    }
    function onClickSearchBtn() {
        if (!realTimeFilter) {
            setFilterFields(localFilterFields);
            setFilterValue(localFilterValue);
        }
    }
    //Pre-rendering treatments
    function buildComboItems() {
        let comboOptions = [];
        filterFieldsComboData.forEach(function (comboOption) {
            comboOptions.push({
                text: comboOption.label,
                value: comboOption.value,
            });
        });
        return comboOptions;
    }
    function startToolsRender() {
        const comboItems = buildComboItems();
        return (
            <>
                <PortalCombo
                    inputName="filterField"
                    items={comboItems}
                    onChange={onChangeComboOption}
                    placeholder="Rechercher dans ..."
                    aria-label="Rechercher dans ..."
                />
                <PortalText placeHolder="Votre recherche" onChange={onChangeSearchValue} aria-label="Valeur recherchée" />
                {!realTimeFilter && (
                    <PortalBtn iconCls="fal fa-search" onClick={onClickSearchBtn}>
                        Rechercher
                    </PortalBtn>
                )}
            </>
        );
    }

    return <PortalToolbar breakPoint={600} startToolsRender={startToolsRender} className="filterToolbar" />;
}
