//######################################## Imports ########################################//
//External libraries
import React from "react";
import { useState, useEffect } from "react";
//Custom libraries
import InitView from "../../../../ressources/components/initView/initView.js";
import BaseScreen from "./screens/base/screen.js";
import YvelineDesktopScreen from "./screens/v_yvelines/screen.js";
//#########################################################################################//
export default function Controller(props) {
    const store = props.store;
    const updateStore = props.updateStore;
    const request = props.request;
    const getViewContext = props.getViewContext;
    const viewHash = props.viewHash;
    const appTheme = props.appTheme;
    //local Controller states
    const [initState, setInitState] = useState(""); //'loading' || 'error'
    //Communication functions
    function loadData() {
        console.warn("play");
        setInitState("loading");
        const context = getViewContext();
        const viewTitle = context.viewTitle;
        request({
            view: "public",
            action: "get_showcase_content",
            data: {
                adm_parameter_id: context.admParameterId || 0,
            },
        })
            .then(function (response) {
                console.log(response);
                updateStore({
                    viewTitle: viewTitle,
                    viewContent: response.data || "",
                });
                setInitState("");
            })
            .catch(function (error) {
                console.error(error.msg);
                setInitState("error");
            });
    }
    //Effect treatments
    useEffect(
        function () {
            loadData();
        },
        [viewHash]
    );

    //Rendering part
    if (!!initState) {
        return <InitView type={initState === "error" ? "errorLoading" : initState} />;
    }

    switch (appTheme) {
        case "yvelines":
            return <YvelineDesktopScreen {...props} />;
        default:
            return <BaseScreen {...props} />;
    }
}
