//######################################## Imports ########################################//
//External libraries
import React from "react";
import { useState, useEffect } from "react";
//Custom libraries
import InitView from "../../../../ressources/components/initView/initView";
import { formValidator, editFormState, formatName, PasswordFieldsValidator } from "../../../../ressources/lib/js/globalFunctions";
import BaseScreen from "./screens/base/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";
//#########################################################################################//
export default function Controller(props) {
    //Url format to access this view : 'https://${portalDns}#set_password?token='xxx'
    //LocalHost ex : http://localhost:3000/#set_password?token='xxx'

    const store = props.store;
    const [actionType, setActionType] = useState(""); //'post_create_username' || 'post_update_username' || 'post_reset_password'
    //Is equal to php_action bound to the token
    const [initState, setInitState] = useState(""); //'loading' || 'error'
    const defaultFormData = {
        token: "",
        password: "",
        password_confirm: "",
    };
    const [formData, _setFormData] = useState(defaultFormData);
    function setFormData(field, value) {
        editFormState(field, value, formData, _setFormData);
    }
    const [formValidationCount, setFormValidationCount] = useState(0);
    const formDataValidator = new formValidator({
        token: [{ conditionCode: "required_token", validate: () => !!formData.token }],
        password: new PasswordFieldsValidator(formData)["password"],
        password_confirm: new PasswordFieldsValidator(formData)["password_confirm"],
    });
    const usernameFormValidator = new formValidator({
        token: [{ conditionCode: "required_token", validate: () => !!formData.token }],
        password: [{ conditionCode: "required_password", validate: () => !!formData.password }],
        password_confirm: [{ conditionCode: "always_valid", validate: () => true }],
    });
    const usedFormValidator = actionType === "post_update_username" ? usernameFormValidator : formDataValidator;

    //Communication functions
    function loadTokenData(token) {
        setInitState("loading");
        props.setRequestState("loading");
        props
            .request({
                action: "get_token_data",
                data: {
                    token: token,
                },
            })
            .then(function (res) {
                props.updateStore(res.data);
                if (!res?.data?.php_action) {
                    props
                        .msgBox("Action inconnue", "error", "msg")
                        .then(function () {
                            window.history.back();
                        })
                        .catch(function () {});
                }
                setInitState("");
            })
            .catch(function (err) {
                setInitState("error");
                console.error(err.msg);
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {
                        window.history.back();
                    })
                    .catch(function () {});
            })
            .finally(function () {
                props.setRequestState("");
            });
    }

    function postData() {
        return new Promise(function (rs, rj) {
            setFormValidationCount(formValidationCount + 1);
            if (!actionType) {
                return rj({ msg: "Type d'action inconnue" });
            }
            const validationRes = usedFormValidator.validate();
            if (!validationRes.success) {
                //return rj({ msg: props.i18n("error." + validationRes.errors[0]) });
                return rj({ msg: props.i18n("error.invalid_form") });
            }
            props.setRequestState("loading");
            props
                .request({
                    action: actionType,
                    data: formData,
                })
                .then(function (res) {
                    return rs(res);
                })
                .catch(function (err) {
                    return rj(err);
                })
                .finally(function () {
                    props.setRequestState("");
                });
        });
    }

    //Effects treatments
    useEffect(function () {
        setInitState("loading");
        let queryParams = props.getViewContext();
        if (!queryParams.token) {
            setInitState("error");
            return props
                .msgBox(props.i18n("error.forbidden_view"), "error", "msg")
                .then(function () {
                    window.history.back();
                })
                .catch(function () {});
        }
        setFormData("token", queryParams.token);
        loadTokenData(queryParams.token);
    }, []);

    useEffect(
        //To set actionType once token data is loaded into viewStore
        function () {
            setActionType(store.php_action || "");
        },
        [store]
    );

    if (initState === "loading") {
        return <InitView type="loading" />;
    }
    if (initState === "error") {
        return <InitView type="errorLoading" />;
    }

    //Data managing functions
    function getViewTitle() {
        return props.i18n("title." + actionType);
    }
    function getPasswordLabel() {
        switch (actionType) {
            case "post_create_username":
                return props.i18n("label.field.current_password");
            case "post_update_username":
                return props.i18n("label.field.confirm_current_password");
            default: //case reset_password
                return props.i18n("label.field.new_password");
        }
    }
    function getPasswordPlaceholder() {
        switch (actionType) {
            case "post_create_username":
                return props.i18n("placeholder.field.current_password");
            case "post_update_username":
                return props.i18n("placeholder.field.confirm_current_password");
            default: //case reset_password
                return props.i18n("placeholder.field.new_password");
        }
    }
    function getPasswordConfirmLabel() {
        return props.i18n("label.field.password_confirm");
    }
    function getPasswordConfirmPlaceholder() {
        return props.i18n("placeholder.field.password_confirm");
    }
    function getPasswordInfo() {
        switch (actionType) {
            case "post_update_username":
                return props.i18n("info.field.current_password");
            default:
                return props.i18n("info.field.new_password");
        }
    }
    function getPostBtnLabel() {
        return props.i18n("label.action." + actionType);
    }
    function getSuccessMsg() {
        return props.i18n("success." + actionType);
    }
    function getUsername() {
        switch (actionType) {
            case "post_update_username":
                return store.new_username || "Inconnu";
            default:
                return store.username || "Inconnu";
        }
    }

    const controllerProps = {
        formData: formData,
        setFormData: setFormData,
        formValidator: usedFormValidator,
        formValidationCount: formValidationCount,
        viewTitle: getViewTitle(),
        passwordLabel: getPasswordLabel(),
        postBtnLabel: getPostBtnLabel(),
        passwordPlaceholder: getPasswordPlaceholder(),
        passwordInfo: getPasswordInfo(),
        passwordConfirmLabel: getPasswordConfirmLabel(),
        passwordConfirmPlaceholder: getPasswordConfirmPlaceholder(),
        successMsg: getSuccessMsg(),
        postData: postData,
        actionType: actionType,
        username: getUsername(),
    };
    switch (true) {
        case props.appTheme === "yvelines":
            return <YvelinesDesktopScreen {...props} {...controllerProps} />;
        default:
            return <BaseScreen {...props} {...controllerProps} />;
    }
}
