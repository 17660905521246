import React from "react";
function ArianWire(props) {
    function handlePathClick(hash) {
        if (hash) {
            props.redirectTo(hash);
        }
    }

    let userJourney = props.userJourney || [];
    let viewIconCls,
        viewTxt,
        arianItem,
        arianContent = [];
    userJourney.map(function (userPath, index) {
        viewIconCls = props.i18n("icon.view." + userPath + "_icon");
        viewTxt = props.i18n("label.view." + userPath);
        arianItem = (
            <button
                className={"arianItem " + (userPath ? "clickable" : "")}
                onClick={() => handlePathClick(userPath)}
                key={index}
                tabIndex={userPath ? 0 : -1}
            >
                {viewIconCls ? <span className={"iconArianItem " + viewIconCls}></span> : ""}
                <span className="txtArianItem">{viewTxt}</span>
            </button>
        );
        arianContent.push(arianItem);
        if (index + 1 !== userJourney.length) {
            arianContent.push(
                <span className="arianItemSeparator" key={"sep-" + index}>
                    /
                </span>
            );
        }
        viewIconCls = "";
        viewTxt = "";
        return null;
    });
    return <>{arianContent.length > 0 ? <div className="arianContainer">{arianContent}</div> : ""}</>;
}

export default ArianWire;
