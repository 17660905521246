import React from "react";
import { Alert, Col, Container, Row, Table } from "react-bootstrap";
import PortalBtn from "../../../../../../ressources/components/portalBtn/portalBtn";
import PropTypes from "prop-types";

List.propTypes = {
    listData: PropTypes.array.isRequired,
    setFormData: PropTypes.func.isRequired,
    headerData: PropTypes.object,
    downloadFile: PropTypes.func.isRequired,
};

function List({ listData, setFormData, headerData, downloadFile, isInDeclarationRange, declarationIsOpened }) {
    if (headerData == null) {
        headerData = {
            start_date: new Date().toISOString().slice(0, 10),
            end_date: new Date().toISOString().slice(0, 10),
            quantity_left: 1,
        };
    }
    //console.log('List', headerData);
    /* const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url_file_base = "../react.php?page=plan_aide&a=get_file&s=" + urlParams.get("s") + "&file_id=";*/

    //console.info(listData);

    function onClickAddBtn() {
        setFormData({
            id: -1,
            periode: null,
            declare: null,
            //quantity_left: headerData.quantity_left,
            file_id: null,
            filename: "",
        });
    }
    function render_periode(periode) {
        periode = new Date(periode + "-01");
        let str_dt = periode.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
        });
        return str_dt[0].toUpperCase() + str_dt.substring(1);
    }
    function render_quantity(quantity) {
        return (+quantity).toFixed(0);
    }

    function render_icon(data, headerData, setFormData) {
        // icon status
        let icon = "";

        switch (+data.par_status_treatment_id) {
            case 650:
                icon = (
                    <a href={null} className="not_a_link" title="En cours d’instruction">
                        <span className="fas fa-hourglass-half icon_bleu"></span>
                    </a>
                );
                break;
            case 651:
                icon = (
                    <a href={null} className="not_a_link" title="Traité">
                        <span className="fas fa-check-square icon_vert"></span>
                    </a>
                );
                break;
            case 652:
                icon = (
                    <a href={null} className="not_a_link" title={`Rejeté : ${data.inv_type_motif_name}`}>
                        <span className="fas fa-times icon_rouge"></span>
                    </a>
                );
                break;
            case "":
            default:
                if (isInDeclarationRange(data.periode)) {
                    icon = (
                        <a
                            href={null}
                            className="link_file"
                            title="Modifier"
                            onClick={() => {
                                //console.log('clic', data);
                                setFormData({
                                    id: data.id,
                                    periode: data.periode,
                                    quantity: (+data.quantity).toFixed(0),
                                    //quantity_left: headerData.quantity_left,
                                    file_id: data.file_id,
                                    filename: data.filename,
                                });
                            }}
                        >
                            <span className="fas fa-edit"></span>
                        </a>
                    );
                } else {
                    icon = (
                        <a
                            href={null}
                            className="not_a_link"
                            title="Enregistré : ne peut pas être modifié car ne fait pas partie des périodes ouvertes"
                        >
                            <span className="fas fa-save icon_dark"></span>
                        </a>
                    );
                }
                break;
        }
        return icon;
    }
    return (
        <div>
            <Container className="btn_top_grid" fluid>
                <Row>
                    {declarationIsOpened() ? (
                        <Col md="auto">
                            <PortalBtn iconCls="fas fa-plus" onClick={onClickAddBtn}>
                                Ajouter
                            </PortalBtn>
                        </Col>
                    ) : (
                        ""
                    )}
                    <Col>
                        <Alert key="info_grid_block" variant="primary" className="info_grid_block">
                            Légende : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="fas fa-edit"></span> Ouvert (Modifiable)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="fas fa-save icon_dark"></span> Enregistré&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="fas fa-hourglass-half icon_bleu"></span> En cours d’instruction &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="fas fa-check-square icon_vert"></span> Traité &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="fas fa-times icon_rouge"></span> Rejeté
                        </Alert>
                    </Col>
                </Row>
            </Container>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Période</th>
                        <th>Heures effectuées</th>
                        <th>Pièce jointe</th>
                    </tr>
                </thead>
                <tbody>
                    {listData &&
                        listData.map((data) => (
                            <tr key={data.id}>
                                <td>{render_icon(data, headerData, setFormData)}</td>
                                <td>{render_periode(data.periode)}</td>
                                <td style={{ textAlign: "right" }}>{render_quantity(data.quantity)}</td>
                                <td>
                                    <a href={null} rel="noreferrer" className="link_file" onClick={() => downloadFile(data.file_id)}>
                                        <span className="fas fa-file-download"></span>
                                        &nbsp;{data.filename}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    {(!listData || listData.length == 0) && (
                        <tr key="0">
                            <td colSpan="5" className="no_data">
                                Aucun enregistrement
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
}
export default List;
