import React from "react";
import PopupView from "../popupView/popupView";
import { useEffect, useRef } from "react";
import ItemRow from "../itemRow/itemRow.js";
import PortalBtn from "../portalBtn/portalBtn";
import "./msgBox.css";
/*
Configs : 
{
    level: STRING (opt) ['info'] => Définit le niveau d'importance 
        # Niveaux possibles : 'info', 'warning', 'error'
        
    type: STRING (opt) ['msg'] => Définit le type d'interaction de la boîte de dialogue
        # Types possibles : 'msg', 'confirm', 'process'
        # Type 'msg' : Affichage d'un message avec le niveau souhaité et d'un bouton pour fermer la boîte de dialogue
        # Type 'confirm' : Affichage d'un message avec le niveau souhaité et de 2 boutons pour confirmer ou non ce qui est demandé
        # Type 'process' : Affichage d'un message avec le niveau souhaité, accompagné d'un loader à côté du texte (pas de bouton pour fermer la boîte de dialogue => Géré par élément externe)

    title : STRING (opt) ['${levelDefault}'] => Permet d'afficher un header en haut de la boîte de dialogue
        # Si on ne met rien, un titre par défaut s'affiche en fonction du niveau d'importance ('level') de la boîte de dialogue

    className: STRING (opt) [''] => Classe additionnelle que l'on peut ajouter au conteneur de la msgBox

    btnConfirmTxt : STRING (opt) ['Oui' || 'OK'] => Texte de contenu du bouton de confirmation de la box
        # S'affiche uniquement pour les types 'msg' et 'confirm'

    btnRefuseTxt : STRING (opt) ['Non'] => Texte de contenu du bouton de refus de la box
        # S'affiche uniquement pour le type 'confirm'

    resolve : FUNCTION (opt) [''] => Fonction qui permet de déclencher la résolution la Promesse générée lorsqu'on clique sur le bouton de confirmation de la box 
        # Mécanique un peu complexe, voir la fonction 'msgBox()' dans App.js pour comprendre son implémentation

    reject : FUNCTION (opt) [''] => Fonction qui permet dedéclencher le rejet de la Promesse générée lorsqu'on clique sur le bouton de refus de la box 
        # Mécanique un peu complexe, voir la fonction 'msgBox()' dans App.js pour comprendre son implémentation
}
*/
export default function MsgBox(props) {
    const visible = !!props.children;
    const levelList = ["info", "warning", "error"];
    const typeList = ["msg", "confirm", "process"];
    const defaultLevelData = {
        info: { title: "Information", icon: "" },
        warning: { title: "Attention", icon: "fas fa-exclamation-triangle" },
        error: { title: "Erreur", icon: "far fa-times-circle" },
    };
    const level = levelList.includes(props.level) ? props.level : "info";
    const type = typeList.includes(props.type) ? props.type : "msg";
    const btnConfirmTxt = !!props.btnConfirmTxt ? props.btnConfirmTxt : type === "confirm" ? "Oui" : "OK";
    const btnRefuseTxt = !!props.btnRefuseTxt ? props.btnRefuseTxt : "Non";
    const focusRef = useRef();

    useEffect(
        function () {
            //console.log(props.children);
            if (focusRef.current) {
                focusRef.current.focus();
            }
        },
        [props.children]
    );

    function onRefuse() {
        if (typeof props.reject === "function") {
            props.reject();
        }
    }
    function onAccept() {
        if (typeof props.resolve === "function") {
            props.resolve();
        }
    }
    function buildFooter() {
        return type !== "process" ? (
            <ItemRow fill="stretch">
                {type === "confirm" ? (
                    <PortalBtn className="reverse" onClick={onRefuse} ref={type === "confirm" ? focusRef : null}>
                        {btnRefuseTxt}
                    </PortalBtn>
                ) : (
                    ""
                )}
                <PortalBtn onClick={onAccept} ref={type === "confirm" ? null : focusRef}>
                    {btnConfirmTxt}
                </PortalBtn>
            </ItemRow>
        ) : (
            ""
        );
    }
    function buildTitle() {
        let titleDisplay = (
            <>
                <span className={`icon ${defaultLevelData[level]["icon"]}`}></span>
                {props.title || defaultLevelData[level]["title"]}
            </>
        );
        return titleDisplay;
    }
    function displayLoader() {
        return type === "process" ? "icon fas fa-spinner rotate" : "";
    }
    function contentRender() {
        if (typeof props.children === "string") return <div dangerouslySetInnerHTML={{ __html: props.children }}></div>;
        return <div>{props.children}</div>;
    }
    return (
        <PopupView
            className={`msgBox ${level}${!!props.className ? " " + props.className : ""}`}
            visible={visible}
            showCloseButton={false}
            title={buildTitle()}
            footerRender={buildFooter}
            backdropClassName="msgBox"
            logo
        >
            <div className="body-content">
                <span className={displayLoader()}></span>
                {contentRender()}
            </div>
        </PopupView>
    );
}
