import React from "react";
import "./initView.css";
/*
Install : - Les styles css associés (paragraphe initView) doivent être chargés dans le projet
Configs :
{
    type = STRING (!!REQ) ['default'] => Le type d'instance de la vue 
        # Valeurs possibles : 'loading' / 'errorLoading' / 'noData'
    
    loadingMsg = STRING (opt) ['Chargement des données de la vue'] => Le message affiché lorsque la vue est en chargement (type = 'loading')

    errorMsg = STRING (opt) ['Les données n'ont pas pu être chargées'] => Le message affiché lorsque la vue est en erreur (type = 'errorLoading')    

    noDataMsg = STRING (opt) ['Aucune donnée n'a été chargée'] => Le message affiché lorsque la vue n'a ramené aucune donnée (type = 'noData')
}
*/
function InitView(props) {
    const viewTypes = {
        loading: "Chargement en cours ...",
        errorLoading: "Les données n'ont pas pu être chargées",
        noData: "Aucune donnée n'a été chargée",
        default: "Affichage par défaut du composant InitView",
    };
    const iconStates = {
        loading: "fas fa-medkit", //fas fa-heartbeat
        errorLoading: "fas fa-times-circle",
        noData: "fas fa-empty-set",
    };
    function buildDisplayMsg(type) {
        let displayMsg;
        switch (type) {
            case "loading":
                displayMsg = props.loadingMsg || viewTypes.loading;
                break;
            case "errorLoading":
            case "error":
                displayMsg = props.errorMsg || viewTypes.errorLoading;
                break;
            case "noData":
                displayMsg = props.noDataMsg || viewTypes.noData;
                break;
            default:
                displayMsg = viewTypes.default;
                break;
        }
        return displayMsg;
    }
    return (
        <div className={`initViewContainer ${props.type}`}>
            <span className={`stateIcon ${iconStates[props.type] || ""}`}></span>
            <div dangerouslySetInnerHTML={{ __html: buildDisplayMsg(props.type) }}></div>
        </div>
    );
}
export default InitView;
