//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect } from "react";
//Custom Library
import { isEmailType, buildFeedBack } from "../../../../../../../ressources/lib/js/globalFunctions.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom UI Components
import PortalCheck from "../../../../../../../ressources/components/portalFields/portalCheck/portalCheck.js";
import PortalPhone from "../../../../../../../ressources/components/portalFields/portalPhone/portalPhone.js";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalCombo from "../../../../../../../ressources/components/portalFields/portalCombo/portalCombo.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//

export default function Screen(props) {
    const store = props.store;
    const actionAllowed = props.actionAllowed;
    const postUpdFileData = props.postUpdFileData;
    const isEdited = props.isEdited;
    const editProfile = props.editProfile;
    const requestState = props.requestState;
    // View global functions
    function formatDate(asaproDate) {
        if (typeof asaproDate !== "undefined" && asaproDate != null) {
            let formattedDate = asaproDate.split("-");
            // Conversion affichage 1939-11-13 => 13/11/1929
            formattedDate = formattedDate[2] + "/" + formattedDate[1] + "/" + formattedDate[0];
            return formattedDate;
        }
        return asaproDate;
    }
    // Rendering functions
    function renderCountryComboOptions() {
        var countryComboOptionsDisplay = [],
            countryComboOptionsData = [];
        for (let key in store?.par_country || {}) {
            //Putting combo values into a real list for sorting
            countryComboOptionsData.push(store.par_country[key]);
        }
        //console.warn(countryComboOptionsData);
        countryComboOptionsData.sort();
        //console.warn(countryComboOptionsData);
        countryComboOptionsData.forEach(function (countryName, id) {
            // Building combo options elements
            countryComboOptionsDisplay.push(
                /*DEPRECATED
                <option key={id} value={countryName}>
                    {countryName}
                </option>//*/
                {
                    id: countryName + id,
                    text: countryName,
                    value: countryName,
                }
            );
        });
        return countryComboOptionsDisplay;
    }
    // Rendering part
    //return JSON.stringify(props.store) || null;
    return (
        <div id="client_file">
            {props.setStyle("./base/desktop")}
            <Form className="profile-section-container">
                <Row id="la-fameuse" className="profile-display-row">
                    <Col id="profile-display-identity-container" className="profile-display-section" xs={12} md={6}>
                        <h4>Identité</h4>
                        <fieldset className="form-group">
                            <legend className="form-label">Civilité</legend>
                            <Row className="radio-row">
                                <PortalCheck
                                    inputName="womanRadio"
                                    activeIcon="far fa-dot-circle"
                                    baseIcon="far fa-circle"
                                    active={store?.profile?.ent_type_civility_id == 2}
                                    text="Madame"
                                    disabled={true}
                                    textAlign="right"
                                />
                                <PortalCheck
                                    inputName="manRadio"
                                    activeIcon="far fa-dot-circle"
                                    baseIcon="far fa-circle"
                                    active={store?.profile?.ent_type_civility_id == 1}
                                    text="Monsieur"
                                    disabled={true}
                                    textAlign="right"
                                />
                            </Row>
                        </fieldset>
                        <Form.Group controlId="lastName">
                            <Form.Label>{props.i18n("label.field.last_name")}</Form.Label>
                            <PortalText
                                id="lastName"
                                type="text"
                                name="lastName"
                                actionAllowed={actionAllowed}
                                autoComplete="family-name"
                                onChange={(e) => editProfile("coordinates", "last_name", e.target.value)}
                                defaultValue={store?.profile?.last_name}
                            />
                        </Form.Group>
                        <Form.Group controlId="mothersMaidenName">
                            <Form.Label>{props.i18n("label.field.mothers_maiden_name")}</Form.Label>
                            <PortalText
                                id="mothersMaidenName"
                                type="text"
                                name="mothersMaidenName"
                                actionAllowed={actionAllowed}
                                autoComplete="family-name"
                                onChange={(e) => editProfile("coordinates", "mothers_maiden_name", e.target.value)}
                                defaultValue={store?.profile?.mothers_maiden_name}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            <Form.Label>{props.i18n("label.field.first_name")}</Form.Label>
                            <PortalText
                                id="firstName"
                                type="text"
                                name="firstName"
                                autoComplete="given-name"
                                readOnly
                                defaultValue={store?.profile?.first_name}
                            />
                        </Form.Group>
                        <Form.Group controlId="birthDate">
                            <Form.Label>Date de naissance</Form.Label>
                            <PortalText
                                id="birthDate"
                                type="text"
                                name="birthDate"
                                autoComplete="bday"
                                readOnly
                                defaultValue={formatDate(store?.profile?.birth_datetime)}
                            />
                        </Form.Group>
                        {/*<Form.Group controlId="fileNumber">
                            <Form.Label>Numéro de dossier</Form.Label>
                            <PortalText id="fileNumber" type="text" name="fileNumber" readOnly defaultValue={store?.profile?.referencedossier} />
                        </Form.Group>*/}
                    </Col>
                    <Col id="profile-display-coordinates-container" className="profile-display-section" xs={12} md={6}>
                        <h4>Coordonnées</h4>
                        <Form.Group controlId="phone">
                            <Form.Label>Téléphone fixe</Form.Label>
                            <PortalPhone
                                id="phone"
                                separator=" "
                                groupSize={2}
                                actionAllowed={actionAllowed}
                                onChange={(e, newValue) => editProfile("coordinates", "phone", newValue)}
                                autoComplete="tel-local"
                                name="phone"
                                defaultValue={store?.profile?.phone}
                                autoFocus
                                aria-required={false}
                                aria-invalid={false}
                            />
                        </Form.Group>
                        <Form.Group controlId="mobilePhone">
                            <Form.Label>Téléphone mobile</Form.Label>
                            <PortalPhone
                                id="mobilePhone"
                                actionAllowed={actionAllowed}
                                separator=" "
                                groupSize={2}
                                onChange={(e, newValue) => editProfile("coordinates", "mobile_phone", newValue)}
                                autoComplete="tel-local"
                                name="mobilePhone"
                                defaultValue={store?.profile?.mobile_phone}
                                aria-required={false}
                                aria-invalid={false}
                            />
                        </Form.Group>
                        <Form.Group controlId="contactEmail">
                            <Form.Label>E-mail de contact</Form.Label>
                            <PortalText
                                id="contactEmail"
                                onChange={(e) => editProfile("coordinates", "email", e.target.value)}
                                actionAllowed={actionAllowed}
                                type="email"
                                autoComplete="email"
                                name="contactEmail"
                                defaultValue={store?.profile?.email}
                                aria-required={false}
                                isInvalid={!isEmailType(store?.profile?.email) && store?.profile?.email?.length > 0}
                                aria-invalid={!isEmailType(store?.profile?.email) && store?.profile?.email?.length > 0}
                                aria-describedby="contactEmail-1"
                            />
                            {buildFeedBack(
                                "contactEmail",
                                ["Doit respecter le format d'un email (exemple@domain.com)"],
                                [!isEmailType(store?.profile?.email) && store?.profile?.email?.length > 0]
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row id="profile-display-address-container" className="profile-display-section profile-display-row">
                    <Col xs={12}>
                        <h4>Adresse</h4>
                    </Col>
                    <Form.Group as={Col} xs={12} md={6} controlId="labelAddress">
                        <Form.Label>Destinataire</Form.Label>
                        <PortalText
                            id="labelAddress"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "label", e.target.value)}
                            type="text"
                            name="labelAddress"
                            defaultValue={store?.profile?.label}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="secondLabelAddress">
                        <Form.Label>Complément destinataire</Form.Label>
                        <PortalText
                            id="secondLabelAddress"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "agent", e.target.value)}
                            type="text"
                            name="secondLabelAddress"
                            defaultValue={store?.profile?.agent}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="neighborhood">
                        <Form.Label>Bâtiment, Résidence</Form.Label>
                        <PortalText
                            id="neighborhood"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "neighborhood", e.target.value)}
                            type="text"
                            name="neighborhood"
                            defaultValue={store?.profile?.neighborhood}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="pobox">
                        <Form.Label>Appartement, escalier, étage</Form.Label>
                        <PortalText
                            id="pobox"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "pobox", e.target.value)}
                            type="text"
                            name="pobox"
                            defaultValue={store?.profile?.pobox}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <fieldset className="col-md-6 col-12">
                        <legend className="form-label">Numéro et voie</legend>
                        <Row>
                            <Form.Group as={Col} xs={4} controlId="streetNumber">
                                <Form.Label className="sr-only">Numéro de rue</Form.Label>
                                <PortalText
                                    id="streetNumber"
                                    actionAllowed={actionAllowed}
                                    onChange={(e) => editProfile("address", "number", e.target.value)}
                                    type="text"
                                    name="streetNumber"
                                    defaultValue={store?.profile?.number}
                                    aria-required={false}
                                    aria-invalid={false}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={8} controlId="streetAddress">
                                <Form.Label className="sr-only">Nom de rue</Form.Label>
                                <PortalText
                                    id="streetAddress"
                                    actionAllowed={actionAllowed}
                                    onChange={(e) => editProfile("address", "street_address", e.target.value)}
                                    type="text"
                                    name="streetAddress"
                                    defaultValue={store?.profile?.street_address}
                                    aria-required={false}
                                    aria-invalid={false}
                                />
                            </Form.Group>
                        </Row>
                    </fieldset>
                    <Form.Group as={Col} xs={12} md={6} controlId="placeName">
                        <Form.Label>Lieu-dit</Form.Label>
                        <PortalText
                            id="placeName"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "place_name", e.target.value)}
                            type="text"
                            name="placeName"
                            defaultValue={store?.profile?.place_name}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="houseName">
                        <Form.Label>Complément d'adresse</Form.Label>
                        <PortalText
                            id="houseName"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "housename", e.target.value)}
                            type="text"
                            name="houseName"
                            defaultValue={store?.profile?.housename}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="postCode">
                        <Form.Label>Code postal</Form.Label>
                        <PortalText
                            id="postCode"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "postcode", e.target.value)}
                            type="text"
                            autoComplete="postal-code"
                            name="postCode"
                            defaultValue={store?.profile?.postcode}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="city">
                        <Form.Label>Ville CEDEX</Form.Label>
                        <PortalText
                            id="city"
                            actionAllowed={actionAllowed}
                            onChange={(e) => editProfile("address", "city", e.target.value)}
                            type="text"
                            name="city"
                            defaultValue={store?.profile?.city}
                            aria-required={false}
                            aria-invalid={false}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="parCountry">
                        <Form.Label>Pays</Form.Label>
                        <PortalCombo
                            inputName="parCountry"
                            //items={renderCountryComboOptions()}
                            store={{
                                fields: ["text", "id", "value"],
                                data: renderCountryComboOptions(),
                            }}
                            displayField="text"
                            valueField="value"
                            actionAllowed={actionAllowed}
                            activeAction={["upd"]}
                            defaultValue={store?.profile?.par_country}
                            onChange={(e) => editProfile("address", "par_country", e.value)}
                        />
                    </Form.Group>
                </Row>
                <Row className="justify-content-center profile-display-row">
                    {typeof actionAllowed?.findIndex !== "function" || actionAllowed.findIndex((action) => action === "upd") >= 0 ? (
                        <PortalBtn
                            type="submit"
                            state={requestState}
                            loadingTxt="Envoi de la demande"
                            onClick={postUpdFileData}
                            className="validate"
                            disabled={!isEdited}
                        >
                            Demander une modification
                        </PortalBtn>
                    ) : (
                        ""
                    )}
                </Row>
            </Form>
        </div>
    );
}
//###########################################################################################//
