import React from "react";
import { useEffect } from "react";
export default function Help(props) {
    const url = "https://www.maboussoleaidants.fr/mes-aides";
    //URL: https://develop.d1zewbh793lo4s.amplifyapp.com/

    useEffect(function () {
        props
            .openExternal({
                url: url,
                //force: true,
                msgBoxConfig: {
                    //className: "monespacesante",
                },
            })
            .catch(function (e) {})
            .finally(function () {
                props.redirectTo("home", null, true);
            });
    }, []);

    return "";
}
