import React from "react";
import { useState, useEffect } from "react";
import "./floatContainer.css";
export default function FloatContainer(props) {
    const [opened, setOpened] = useState(!!props.isOpened);
    useEffect(
        function () {
            setOpened(!!props.opened);
        },
        [props.opened]
    );
    return (
        <div className={`floatContainer${props.className ? " " + props.className : ""}${opened ? " opened" : ""}`}>
            {opened ? props.children : ""}
        </div>
    );
}
