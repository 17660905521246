import React from "react";
import { crudDisplay, crudDisabled } from "../../lib/js/globalFunctions";
import "./portalBtn.css";
export default function 
 PortalBtn(props) {
    /*  Configs : 
    {
        state : STRING (opt) [''] => Etat du bouton
            # Valeurs possibles : '' || 'loading' || 'error'
            # Les styles des états 'loading' et 'error' sont prioritaires

        loadingTxt : STRING (opt) ['Traitement en cours'] => Texte du bouton quand il est dans un état 'login'

        errorTxt : STRING (opt) ['Erreur'] => Texte du bouton quand il est dans un état 'error'

        iconCls : STRING (opt) [''] => Icône à l'intérieur du bouton
            # Si on rajoute du contenu au bouton, l'icône sera placé à gauche de ce contenu

        iconAlign : STRING (opt) ['left'] => Alignement de l'icône à l'intérieur du bouton
            # Valeurs possibles : 'left' || 'right' 

        className : STRING (opt) [''] => Classe additionnelle à ajouter au bouton
            # Valeurs pré-stylées : 'validate' || 'danger' || 'reverse'

        displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
            # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

        activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'modifiable'
            # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

        actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
            # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
            # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
            # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'
        }
    }
*/
    const iconAlign = props.iconAlign === "right" ? "right" : "left";
    function deleteProps(propsToDelete) {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    function contentRender() {
        switch (props.state) {
            case "loading":
                return props.loadingTxt || "Traitement en cours";
            case "error":
                return props.errorTxt || "Erreur";
            default:
                return props.children;
        }
    }
    function iconContentRender() {
        const baseCls = "icon " + iconAlign + (!!contentRender() ? " withMargin" : "");
        switch (props.state) {
            case "loading":
                return <span className={`${baseCls} fas fa-spinner rotate`}></span>;
            case "error":
                return <span className={`${baseCls} fas fa-exclamation-triangle`}></span>;
            default:
                return props.iconCls ? <span className={baseCls + " " + props.iconCls}></span> : "";
        }
    }
    function isDisabled() {
        return !!props.state || crudDisabled(props.activeActions, props.actionAllowed) || props.disabled;
    }
    function className() {
        const errorClass = props.state === "error" ? " on error" : "";
        const propsCls = props.className ? " " + props.className : "";
        return "portalBtn" + errorClass + propsCls;
    }

    const filteredProps = deleteProps(
        "iconCls",
        "disabled",
        "className",
        "loadingTxt",
        "errorTxt",
        "displayActions",
        "activeActions",
        "actionAllowed"
    );

    return crudDisplay(props.displayActions, props.actionAllowed) ? (
        <button {...filteredProps} disabled={isDisabled()} className={className()}>
            {iconAlign === "left" ? iconContentRender() : ""}
            {contentRender()}
            {iconAlign === "right" ? iconContentRender() : ""}
        </button>
    ) : (
        ""
    );
}
