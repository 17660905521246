import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";
//import CesuService from '../services/Cesu';
import ItemRow from "../../../../../../ressources/components/itemRow/itemRow.js";
import PortalText from "../../../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalBtn from "../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalCombo from "../../../../../../ressources/components/portalFields/portalCombo/portalCombo.js";
FormCesu.propTypes = {
    formData: PropTypes.object.isRequired,
    onHide: PropTypes.func.isRequired,
    setFormData: PropTypes.func.isRequired,
    setSuccessPost: PropTypes.func.isRequired,
    refresh_list: PropTypes.func.isRequired,
    headerData: PropTypes.object.isRequired,
    downloadFile: PropTypes.func.isRequired,
    request: PropTypes.func.isRequired,
};

function FormCesu({
    formData,
    setFormData,
    onHide,
    setSuccessPost,
    refresh_list,
    downloadFile,
    request,
    invPecId,
    cesuDescription,
    cesuOpenPeriod,
    displayMonth,
    getDateValue,
}) {
    const ref_input_file = useRef();
    useEffect(() => {
        setErrorMessage("");
        setSuccessPost("");
    }, [formData]);

    const [errorMessage, setErrorMessage] = useState(null);

    //const [fld_periode, set_fld_periode] = useState( true );
    const [fld_valid_quantity, set_fld_valid_quantity] = useState(null);
    const [fld_valid_file, set_fld_valid_file] = useState(null);
    const [has_file, set_has_file] = useState(false);
    const [save_in_progress, set_save_in_progress] = useState(false);
    const maxHour = 500;
    /*const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url_file_base = "../react.php?page=plan_aide&a=get_file&s=" + urlParams.get("s") + "&file_id=";*/
    function onSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (save_in_progress) return;

        setErrorMessage("");
        setSuccessPost("");

        //console.log(formData);
        let tmp_valid_state = {
            //periode: fld_periode,
            quantity: fld_valid_quantity,
            file: fld_valid_file,
        };

        /*if (tmp_valid_state.periode == null) {
            tmp_valid_state.periode = formData.id > 0;
            set_fld_periode(formData.id > 0);
        }*/
        if (tmp_valid_state.quantity == null) {
            tmp_valid_state.quantity = formData.id > 0;
            set_fld_valid_quantity(formData.id > 0);
        }
        if (tmp_valid_state.file == null) {
            tmp_valid_state.file = formData.id > 0;
            set_fld_valid_file(formData.id > 0);
        }
        if (!invPecId) {
            //Ceci ne devrait jamais arriver
            alert("Prise en charge inconnue");
            return;
        }
        if (
            // !tmp_valid_state.periode ||
            !tmp_valid_state.quantity ||
            !tmp_valid_state.file
        ) {
            return;
        } else {
            set_save_in_progress(true);
            let post_data = {};
            post_data["row_id"] = formData.id;
            post_data["periode"] = formData.periode;
            post_data["quantity"] = formData.quantity;
            post_data["file_id"] = formData.file_id;
            post_data["inv_pec_id"] = invPecId;
            //console.log(formData);
            const fileToTransfer = formData.file;
            request({
                type: "ajax",
                action: "post_cesu_data",
                data: post_data,
                file: fileToTransfer,
            })
                .then(function (response) {
                    ref_input_file.current.value = "";
                    set_has_file(false);
                    set_fld_valid_file(null);
                    setSuccessPost(response.msg);
                    refresh_list();
                    onHide();
                })
                .catch(function (error) {
                    setErrorMessage(error.msg);
                })
                .finally(function () {
                    set_save_in_progress(false);
                });
        }
    }

    function onChange(event, fld, val = null) {
        if (event != null) {
            val = event.target.value;
        }

        let new_formData = Object.assign({}, formData);

        //console.info(event, fld, val);
        if (fld == "periode") {
            new_formData[fld] = val;
        } else if (fld == "quantity") {
            if (isNaN(+val)) {
                set_fld_valid_quantity(false);
            } else {
                val = (+val).toFixed(0);
                if (val == 0) {
                    new_formData[fld] = val;
                    set_fld_valid_quantity(false);
                } else if (val >= 1 && val <= maxHour) {
                    new_formData[fld] = val;
                    set_fld_valid_quantity(true);
                } else {
                    set_fld_valid_quantity(false);
                }
            }
        } else if (fld == "file") {
            if (val || formData.file_id) {
                if (event && val) {
                    new_formData[fld] = event.target.files[0];
                }
                set_fld_valid_file(true);

                set_has_file(true);
            } else {
                set_fld_valid_file(false);
                set_has_file(false);
            }
        }
        setFormData(new_formData);
    }
    function onChangeCombo(event) {
        //console.log(event.value);
        onChange({ target: { value: event.value } }, "periode");
    }
    function onDeleteCesu(cesuId) {
        set_save_in_progress(true);
        request({
            action: "del_cesu_data",
            data: {
                cesu_id: cesuId,
                inv_pec_id: invPecId,
            },
        })
            .then(function (response) {
                ref_input_file.current.value = "";
                set_has_file(false);
                set_fld_valid_file(null);
                setSuccessPost(response.msg);
                refresh_list();
                onHide();
            })
            .catch(function (error) {
                setErrorMessage(error.msg);
            })
            .finally(function () {
                set_save_in_progress(false);
            });
    }
    function buildPeriodItems() {
        const items = [];
        function buildDatePeriodList(startDate, endDate) {
            const startDateJS = new Date(startDate);
            startDateJS.setDate(1);
            startDateJS.setHours(0);
            startDateJS.setMinutes(0);
            startDateJS.setSeconds(0);
            startDateJS.setMilliseconds(0);
            const endDateJS = new Date(endDate);
            endDateJS.setDate(1);
            endDateJS.setMonth(endDateJS.getMonth() + 1);
            endDateJS.setHours(0);
            endDateJS.setMinutes(0);
            endDateJS.setSeconds(0);
            endDateJS.setMilliseconds(0);
            let cursorDateJS = new Date(startDateJS);
            const datePeriodList = [];
            while (cursorDateJS < endDateJS) {
                datePeriodList.push(cursorDateJS);
                cursorDateJS = new Date(cursorDateJS);
                cursorDateJS.setMonth(cursorDateJS.getMonth() + 1);
            }
            return datePeriodList;
        }
        const datePeriodList = buildDatePeriodList(cesuOpenPeriod.start_date, cesuOpenPeriod.end_date);
        datePeriodList.forEach(function (dateJS) {
            items.push({
                value: getDateValue(dateJS),
                text: displayMonth(dateJS),
            });
        });
        const postedPeriod = formData.periode;
        if (postedPeriod) {
            const postedPeriodIsSelectable = !!items.filter(function (item) {
                return item.value === postedPeriod;
            })[0];
            if (!postedPeriodIsSelectable) {
                //When a period is already posted (Form modification case), this period has to be in list
                //This scenario is not supposed to happen because people can only within the opening period ranges
                let dateJS = new Date(postedPeriod);
                const itemToAdd = {
                    value: postedPeriod,
                    text: displayMonth(dateJS),
                };
                if (!items[0] || dateJS <= new Date(items[0]["value"])) {
                    items.unshift(itemToAdd);
                } else {
                    items.push(itemToAdd);
                }
            }
        }
        return items;
    }
    return (
        <div>
            <Form onSubmit={onSubmit} noValidate>
                {errorMessage && (
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        <Alert.Heading>Attention !</Alert.Heading>
                        <p>{errorMessage}</p>
                    </Alert>
                )}
                {/*Static version of alert below
                    <Alert variant="info">
                        Pour percevoir votre aide du Conseil départemental sur le mois en cours, vous devez saisir les informations et
                        transmettre le bulletin de salaire&nbsp;
                        <span style={{ color: "#FF0000", fontWeight: "bold" }}>au plus tard le 8 de chaque mois</span>. Toute information
                        transmise au-delà du 8 sera prise en compte dans le paiement du mois suivant.
                    </Alert>
                */}
                {cesuDescription && (
                    <Alert variant="info" className="cesuDescription">
                        <span dangerouslySetInnerHTML={{ __html: cesuDescription }}></span>
                    </Alert>
                )}
                <div className="formulaire">
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="frm.periode">
                                <Form.Label>Période d'emploi :</Form.Label>
                                <PortalCombo
                                    inputName="frm.periode"
                                    defaultValue={formData.periode || (buildPeriodItems()[0] ? buildPeriodItems()[0]["value"] : null)}
                                    items={buildPeriodItems()}
                                    onChange={onChangeCombo}
                                ></PortalCombo>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="frm.quantity">
                                <Form.Label>Heures effectuées :</Form.Label>
                                <PortalText
                                    id="frm.quantity"
                                    type="number"
                                    min="1"
                                    max={maxHour}
                                    value={formData.quantity || 0}
                                    onChange={(event) => onChange(event, "quantity")}
                                    required
                                    isInvalid={!fld_valid_quantity && fld_valid_quantity != null}
                                />
                                {!fld_valid_quantity && fld_valid_quantity != null && (
                                    <div className="msg_invalid">{`Vous pouvez déclarer entre 1 et ${maxHour}h.`}</div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="frm.file">
                        <Form.Label>Joindre le bulletin de salaire CESU (PDF) :</Form.Label>
                        <Row>
                            <Col>
                                <PortalText
                                    id="frm.file"
                                    type="file"
                                    onChange={(event) => onChange(event, "file")}
                                    required
                                    ref={ref_input_file}
                                    isInvalid={!fld_valid_file && fld_valid_file != null}
                                    className={`form-control ${has_file ? "" : "file_empty"}`}
                                    tooltip='Les bulletins de salaire sont disponibles sur le site du CESU : <a href="https://www.cesu.urssaf.fr" target="_blank" rel="noreferrer">www.cesu.urssaf.fr</a> dans "Mes déclarations".'
                                />

                                {!fld_valid_file && fld_valid_file != null && (
                                    <div className="msg_invalid">{`Une pièce jointe est obligatoire pour valider votre déclaration`}</div>
                                )}

                                <Form.Text className="help_block" muted>
                                    {formData.file_id ? (
                                        <>
                                            <span>Bulletin de salaire CESU (PDF) actuel :</span>&nbsp;
                                            <a
                                                href={null}
                                                onClick={() => downloadFile(formData.file_id)}
                                                rel="noreferrer"
                                                className="link_file"
                                            >
                                                <span className="fas fa-file-download"></span>
                                                &nbsp;{formData.filename}
                                            </a>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
                <ItemRow className="validateCesu">
                    <PortalBtn className="validate" state={save_in_progress ? "loading" : ""} type="submit">
                        Valider
                    </PortalBtn>
                    {formData.id > 0 && (
                        <PortalBtn className="danger" state={save_in_progress ? "loading" : ""} onClick={() => onDeleteCesu(formData.id)}>
                            Supprimer
                        </PortalBtn>
                    )}
                    <PortalBtn className="portalBtn base" onClick={onHide}>
                        Annuler
                    </PortalBtn>
                </ItemRow>
            </Form>
        </div>
    );
}

export default FormCesu;
