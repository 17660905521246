import React from "react";
import { Row, Col } from "react-bootstrap";
import PortalSection from "../../../../../../../../ressources/components/portalPage/portalSection/portalSection";
import PortalCheck from "../../../../../../../../ressources/components/portalFields/portalCheck/portalCheck";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalFeedback from "../../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
import { formatDateDisplay } from "../../../../../../../../ressources/lib/js/globalFunctions";
export default function ContactPermissionSection(props) {
    const contactForm = props.contactForm;
    const rolTypeContactCombo = props?.store?.static_store?.rol_type_contact_id || [];
    const setContactForm = props.setContactForm;
    const formValidationCount = props.formValidationCount;
    const actionAllowed = props.actionAllowed;
    const msgBox = props.msgBox;
    const contactFormValidator = props.contactFormValidator;
    const proContactFormValidator = props.proContactFormValidator;
    const formValidator = contactForm.rol_type_rol_id == 4 ? contactFormValidator : proContactFormValidator;
    const permissionStatusTreatmentId = contactForm.rol_contact_permission_status_treatment_id;
    const permissionUpdatedAt = formatDateDisplay(contactForm.rol_contact_permission_updated_at || "", true);
    React.useEffect(function () {
        props.scrollLayout(0);
    }, []);

    //UX event handlers

    //Rendering functions
    function dynamicRender() {
        function askConsentRender() {
            const add_rol_contact_permission_validation = formValidator.fields.add_rol_contact_permission.validate();
            return (
                <Row>
                    <Col xs={12}>
                        <PortalCheck
                            inputName="add_rol_contact_permission"
                            text={props.i18n("label.field.add_rol_contact_permission", [props.appName])}
                            textAlign="right"
                            active={contactForm.add_rol_contact_permission}
                            activeAction={["upd"]}
                            actionAllowed={actionAllowed}
                            onChange={(active, e) => {
                                setContactForm("add_rol_contact_permission", active);
                            }}
                            required
                            invalid={!add_rol_contact_permission_validation.success && formValidationCount > 0}
                            aria-describedby="add_rol_contact_permission-1"
                        />
                        {formValidationCount > 0 && add_rol_contact_permission_validation.errors.length > 0 && (
                            <PortalFeedback
                                i18n={props.i18n}
                                msgList={add_rol_contact_permission_validation.errors}
                                fieldId="add_rol_contact_permission"
                            />
                        )}
                    </Col>
                </Row>
            );
        }

        switch (permissionStatusTreatmentId) {
            case "901":
                //TODO: L'email utilisé ici n'est pas forcément corrélé à celui à laquelle l'invitation a été envoyée
                return (
                    <p className="permissionStatusInfo">
                        En attente d'acceptation du consentement...
                        <br />
                        Invitation envoyée à {contactForm.email + (permissionUpdatedAt ? " le " + permissionUpdatedAt : "")}
                    </p>
                );

            case "902":
                return (
                    <div>
                        <p className="permissionStatusInfo">Consentement refusé{permissionUpdatedAt ? " le " + permissionUpdatedAt : ""}</p>
                    </div>
                );

            case "903":
                return (
                    <div>
                        <p className="permissionStatusInfo">
                            Consentement accepté{permissionUpdatedAt ? " le " + permissionUpdatedAt : ""}
                        </p>
                    </div>
                );

            default:
                return askConsentRender();
        }
    }
    return (
        <PortalSection title={props.i18n("title.permission_section")} titleAlign="left" xs={props.xs || 12} md={props.md || 6}>
            {dynamicRender()}
        </PortalSection>
    );
}
