//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux"; //React Redux Hooks (https://react-redux.js.org/) => Global store API

//Custom modules
import { resetPublicSlice } from "./reducers/public/public.js"; //Reducers and Selectors for asaStore connexion
import WrapperClass from "../coredata/Wrapper.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import "bootstrap/dist/css/bootstrap.min.css";
import "devextreme/dist/css/dx.light.css";
import "../ressources/lib/font_awesome/5.13/css/all.min.css";
//Custom UI Components
import "../ressources/hippo_icons/hippo_icons.css";
import App from "./App.js";
import InitView from "../ressources/components/initView/initView.js";
//-----------------------------------//
const config = require("../local_config.json");
//#########################################################################################//

//######################################## Component definition ########################################//

function InitApp(props) {
    /********** Variables definition **********/
    const [processState, setProcessState] = useState("Instanciation de votre application");
    const [appThemeState, setAppThemeState] = useState(null);
    const [appIsReady, setAppIsReady] = useState(false);
    const [errorApp, setErrorApp] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    /******************************************/
    //Redux dispatcher
    const dispatch = useDispatch();

    //ACTION FUNCTIONS
    function handleErrorProcess(errorMsg, level) {
        /*
            Levels <=1 => console.warn + userNonInformed
            Levels > 1 => console.error + userInformed
        */
        level = typeof level === "number" ? level : 1;
        switch (true) {
            case level > 1:
                console.error(errorMsg);
                alert(errorMsg);
                break;
            default:
                console.warn(errorMsg);
                break;
        }
        console.error(errorMsg);
        alert(errorMsg);
    }

    function customizeApp(appName, appTheme, appEnvironnement) {
        let errorMsg = `is not configured for this Environnement => "HOST_CONFIGS" : ${appEnvironnement}`;
        if (!appName) console.error("appName " + errorMsg);
        if (!appTheme) console.error("appTheme " + errorMsg);
        appName = appName || "Portail bénéficiaire";
        appTheme = appTheme || "hippocad";
        // On modifie le titre de l'application
        let titleHead = document.getElementById("appTitle");
        let iconHead = document.getElementById("appIcon");
        let manifestHead = document.getElementById("appManifest");
        titleHead.innerHTML = appName;
        iconHead.href = "./ressources/" + appTheme + "/images/icon.ico";
        manifestHead.href = `./ressources/${appTheme}/manifest.json`;
    }

    function correctDeviceHeight() {
        //Patch pour régler le problème du 100vh sur les navigateurs des appareils mobiles qui utilisent webkit (Safari/iOS - Chrome/android)
        let deviceHeight = window.innerHeight;
        document.documentElement.style.setProperty("--vh", `${deviceHeight}px`);
    }

    //DATA FETCHING FUNCTIONS
    function getAppContext() {
        let queryParams = window.location.search.slice(1);
        if (queryParams[queryParams.length - 1] === "/") queryParams = queryParams.slice(0, queryParams.length - 1);
        const context = {};
        if (!queryParams) return context;
        queryParams.split("&").forEach(function (paramStr) {
            let paramCouple = paramStr.split("=");
            if (paramCouple[0]) context[decodeURIComponent(paramCouple[0])] = decodeURIComponent(paramCouple[1]);
        });
        const hasSpecialMode = !!Object.keys(context).filter((el) => el === "type")[0];
        if (hasSpecialMode) {
            //france_connect doesn't handle query string hashs inside their callback...
            //We put the "root" query_string into the "hash" query_string => will reload the page
            window.location.hash = "#/" + window.location.search;
            window.location.href = window.location.origin + window.location.pathname + "#/" + window.location.search;
        }
        return context;
    }

    function getAppConfig() {
        //Asynchronous fct
        return new Promise(function (rs, rj) {
            let appConfig = {},
                appEnvironnement = null;
            if (
                window.location.protocol === "file:" ||
                window.location.hostname === "localhost" ||
                window.location.hostname === "192.168.1.6"
            ) {
                // On est en mode APK ou localhost => On prend la config "local" du fichier "config.json"
                appConfig = config?.HOST_CONFIGS?.local || {};
                appEnvironnement = "local";
                appConfig.appEnvironnement = appEnvironnement;
                rs(appConfig);
            } else {
                // On est en mode WEB => L'appName et l'appEnvironnement sont settés "à chaud" en fonction de la dns de déploiement

                /*Méthode avec le config.json importé
                const hostDictionnary = config?.HOST_CONFIGS;
                let currentHost = window.location.hostname;
                if (
                    typeof hostDictionnary[currentHost] == "undefined" ||
                    hostDictionnary[currentHost] == null ||
                    !hostDictionnary[currentHost]
                ) {
                    appEnvironnement = "default";
                    let errorMsg = `You have deployed the project on an unknown DNS (${currentHost}), this Environnement => "HOST_CONFIGS" : "${appEnvironnement}" will be set`;
                    handleErrorProcess(errorMsg, 2);
                    appConfig = hostDictionnary?.default || {};
                } else {
                    appEnvironnement = currentHost;
                    appConfig = hostDictionnary[currentHost] || {};
                    appConfig.dnsFront = currentHost;
                }
                appConfig.appEnvironnement = appEnvironnement;
                rs(appConfig); //*/

                //*Méthode en appelant ./index.php?action=get_config
                const xhr = new XMLHttpRequest();
                const errorTpl = window.location.origin + " : impossible de récupérer les configurations de l'application";
                xhr.open("GET", window.location.origin + "/index.php?action=get_config");
                xhr.onerror = function (event) {
                    console.error(event);
                    rj("[Network-error] " + errorTpl);
                };
                xhr.onload = function () {
                    if (xhr.response.length < 1) {
                        //Le serveur n'a rien renvoyé (certainement une erreur)
                        rj(`[${xhr.status}-${xhr.statusText}] Erreur serveur ${errorTpl}`);
                        return;
                    }
                    let response = "";
                    try {
                        response = decodeURIComponent(atob(xhr.response));
                        response = JSON.parse(response);
                    } catch (error) {
                        console.error(`Erreur décodage réponse serveur ${window.location.origin} :\n\n${response}`);
                        console.error(error);
                        rj("[Decoding-error] " + errorTpl);
                        return;
                    }
                    if (!response.success) {
                        const formatCode = "[" + response.code + "] ";
                        console.error(formatCode + response.msg);
                        rj(formatCode + errorTpl + "<br/><br/>" + response.msg);
                        return;
                    }
                    appEnvironnement = window.location.hostname;
                    appConfig = response.data || {};
                    appConfig.appEnvironnement = appEnvironnement;
                    rs(appConfig);
                };
                xhr.send();
                //*/
            }
        });
    }

    function registerServiceWorker(pwaMode) {
        return new Promise(function (rs, rj) {
            if (pwaMode) {
                if ("serviceWorker" in navigator) {
                    // declaring scope manually
                    navigator.serviceWorker
                        .register("./service-worker.js", { scope: "./" })
                        .then((registration) => {
                            console.log("Service worker registration succeeded:", registration);
                            rs(registration);
                        })
                        .catch((error) => {
                            console.error("Service worker registration failed:", error);
                            rs(null);
                        });
                } else {
                    console.error("Service workers are not supported.");
                    rs(null);
                }
            } else {
                rs(null);
            }
        });
    }

    function loadAppParams(Wrapper) {
        function recursiveCall(Wrapper, rs, rj) {
            Wrapper.request({
                type: "ajax",
                module: "view",
                view: "public",
                action: "get_app_param",
                data: {
                    i18n: Wrapper.getInfo()["i18n"],
                },
            })
                .then(function (response) {
                    rs(response.data);
                })
                .catch(function (error) {
                    //On relance la requête au bout de 5 sec si il s'agit d'une erreur réseau (pas de réseau)
                    if (error.code === 504) {
                        setProcessState("En attente de la connexion avec le serveur");
                        setTimeout(function () {
                            recursiveCall(Wrapper, rs, rj);
                        }, 5000);
                    } else {
                        console.error(error?.msg || error);
                        const userErrorMsg =
                            "Le serveur n'a pas réussi à charger les configurations nécessaires au bon fonctionnement de votre application<br><br>" +
                            error?.msg;
                        rj(userErrorMsg);
                    }
                });
        }
        return new Promise(function (rs, rj) {
            recursiveCall(Wrapper, rs, rj);
        });
    }
    //EFFECT FUNCTIONS
    function instanciateApp() {
        let queryAppContext = getAppContext();
        let appContext = queryAppContext;
        const sessionStorageEntry = "appContext";
        if (window.sessionStorage) {
            const sessionStorage = window.sessionStorage;
            let sessionAppContext = {};
            try {
                sessionAppContext = JSON.parse(sessionStorage.getItem(sessionStorageEntry) || "{}");
            } catch (error) {
                console.error(`Error decoding ${sessionStorageEntry} sessionStorage entry`);
                sessionAppContext = {};
            }
            Object.keys(appContext).forEach(function (queryAppContextKey) {
                //We override former queryString params without erasing the other former params
                sessionAppContext[queryAppContextKey] = queryAppContext[queryAppContextKey];
            });
            let contextStored = true;
            appContext = sessionAppContext;
            try {
                sessionStorage.setItem(sessionStorageEntry, JSON.stringify(sessionAppContext));
            } catch (error) {
                contextStored = false;
                appContext = queryAppContext;
                console.error(`Error storing ${sessionStorageEntry} entry`);
            }
            if (window.location.search && contextStored) {
                //If context is correctly stored, we clean the query string to prettify the url
                window.location.href = window.location.origin + window.location.pathname + window.location.hash;
            }
        }
        correctDeviceHeight();
        getAppConfig()
            .then(function (appConfig) {
                registerServiceWorker(appConfig?.pwa_mode)
                    .then(function (serviceWorker) {
                        setAppThemeState(appConfig?.appTheme || "hippocad");
                        const WrapperInstance = new WrapperClass(appConfig, appConfig?.appEnvironnement, serviceWorker);
                        document.Wrapper = WrapperInstance;
                        setProcessState("Chargement des configurations de votre application");
                        loadAppParams(WrapperInstance)
                            .then(function (response) {
                                const appParams = response;
                                dispatch(
                                    resetPublicSlice({
                                        appTheme: appConfig?.appTheme,
                                        appParams: appParams,
                                        i18nCode: appConfig?.i18n,
                                        appContext: appContext,
                                    })
                                );
                                customizeApp(appParams?.app_name, appConfig?.appTheme, appConfig?.appEnvironnement);
                                setAppIsReady(true);
                            })
                            .catch(function (errorMsg) {
                                //When server is on error except when it's a network error (think about mobile devices)
                                setErrorMsg(errorMsg);
                                setErrorApp(true);
                            });
                    })
                    .catch(function (error) {
                        //This function doesn't reject
                    });
            })
            .catch(function (errorMsg) {
                setErrorMsg(errorMsg);
                setErrorApp(true);
            });
    }

    useEffect(instanciateApp, []);
    return (
        <>
            {appThemeState ? <link key="override_theme" rel="stylesheet" href={`./ressources/${appThemeState}/override_theme.css`} /> : ""}
            {appIsReady ? <App /> : <InitView type={errorApp ? "errorLoading" : "loading"} loadingMsg={processState} errorMsg={errorMsg} />}
        </>
    );
}
//######################################################################################################//
export default InitApp;
