import React from "react";
import { useState, useRef, useEffect } from "react";
import Footer from "../../../../components/footer/footer.js";
import ImgBackground from "../../../../../../ressources/components/imgBackground/imgBackground.js";
import PortalBtn from "../../../../../../ressources/components/portalBtn/portalBtn.js";
export default function Screen(props) {
    //console.warn(props);
    const title = props.viewLayoutData.title || "";
    const disclaimer = props.viewLayoutData.disclaimer || "";
    const viewHash = props.viewHash;
    const [scrollClass, setScrollClass] = useState("");
    const viewContentRef = useRef();
    //Event handlers
    function onClickLoginBtn() {
        if (viewHash === "login") {
            props.scrollLayout(0);
        } else {
            props.redirectTo("login");
        }
    }

    function onClickLogoutBtn() {
        props.logout();
    }

    function onClickLogo() {
        if (typeof props.returnHome === "function") props.returnHome();
    }
    function onLayoutScroll(e) {
        const scrollY = e.target.scrollTop;
        if (scrollY > 0) {
            setScrollClass("scrolled");
        } else {
            setScrollClass("");
        }
    }

    //Rendering functions
    function logBtnRender() {
        if (!props.isConnected) {
            return (
                <PortalBtn className="headerBtn" onClick={onClickLoginBtn} iconCls="hippo-icon user">
                    {props.i18n("label.action.goto_personal_space")}
                </PortalBtn>
            );
        }
        return (
            <PortalBtn className="headerBtn" onClick={onClickLogoutBtn} iconCls="far fa-lock-open">
                Déconnexion
            </PortalBtn>
        );
    }

    return (
        <>
            {props.setStyle("./public/v_yvelines/desktop")}
            <div className={`layoutContainer${props.viewGroup && " " + props.viewGroup}`}>
                <div className={`headerContainer${!!scrollClass ? " " + scrollClass : ""}`}>
                    <div className="headerRow first">
                        <div className="itemContainer left"></div>
                        <div className="itemContainer center">
                            <ImgBackground onClick={onClickLogo} className="company_logo" />
                        </div>
                        <div className="itemContainer right">{logBtnRender()}</div>
                    </div>
                    <div className="headerRow second">
                        <div className="titleContainer">
                            <h1 className="main">{title}</h1>
                            <p className="disclaimer">{disclaimer}</p>
                        </div>
                    </div>
                </div>
                <div
                    id="layoutViewContainer"
                    className="viewContainer layoutViewContainer"
                    tabIndex={1001}
                    onClick={null}
                    onScroll={onLayoutScroll}
                    ref={viewContentRef}
                >
                    <div className="viewBlock">
                        <div className="layoutViewContent">{props.children}</div>
                        <Footer {...props} />
                    </div>
                </div>
            </div>
        </>
    );
}
