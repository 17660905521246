import React from "react";
import ImgBackground from "../../../../../../../../ressources/components/imgBackground/imgBackground";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalList from "../../../../../../../../ressources/components/portalList/portalList";
export default function ListContactBlock(props) {
    /**
     * @param {Object} props [{}]: {
     *      @param {String} type ['personal'] : Le type de bloc de contact 'personal' || 'professional'
     *
     *      @param {Object[]} data [[]] : La liste des données des contacts du bloc d'affichage
     *
     *      @param {Object} static_store [{}] : Les contenus des différents combos de la vue
     * }
     */

    const blocType = props.type === "professional" ? "professional" : "personal";
    const blocTitle = props.i18n("title." + blocType + "_contact");
    const data = props.data || [];
    const staticStore = props.staticStore || {};
    const refreshState = props.refreshState;
    const persoContactActivation = !!props.actionAllowed.filter(function (action) {
        return action === `ena_${blocType === "professional" ? "pro" : "perso"}`;
    })[0]; //props.persoContactActivation;
    const rol_type_rol_id = blocType === "personal" ? 4 : 3;

    React.useEffect(function () {
        props.scrollLayout(0);
    }, []);
    //Event handlers
    function onClickNew() {
        props.setInitContactForm({ rol_type_rol_id: rol_type_rol_id });
    }
    function onClickModify(itemData) {
        props.setInitContactForm(itemData);
    }
    function onClickDelete(itemData) {
        props
            .msgBox(
                `Voulez-vous vraiment supprimer ${props.formatContactName(itemData) || "ce contact"} de votre entourage ?`,
                "warning",
                "confirm",
                "Confirmation"
            )
            .then(function () {
                props.postDeleteContact(itemData);
            })
            .catch(function () {});
    }
    //Rendering functions
    function listItemRender(itemData) {
        // return itemData.id;
        function displayRolTypeContact() {
            const rolTypeContactCombo = staticStore.rol_type_contact_id || [];
            const rolTypeContactId = +itemData.rol_type_contact_id;
            const defaultContactStatus = props.i18n("error.unknown_rol_type_contact");
            if (rolTypeContactCombo.length < 1 || !rolTypeContactId) return defaultContactStatus;
            let contactStatus;
            const foundStatus = rolTypeContactCombo.filter(function (comboItem) {
                return comboItem?.id === rolTypeContactId;
            })[0];
            if (foundStatus) contactStatus = foundStatus?.name;
            return contactStatus || defaultContactStatus;
        }
        function displayRolTypeContact() {
            const combo = staticStore.rol_type_contact_id || [];
            const iheTypeId = +itemData.rol_type_contact_id;
            const defaultDisplay = props.i18n("error.unknown_rol_type_contact");
            if (combo.length < 1 || !iheTypeId) return defaultDisplay;
            let contactIheType;
            const foundIheType = combo.filter(function (comboItem) {
                return comboItem?.id === iheTypeId;
            })[0];
            if (foundIheType) contactIheType = foundIheType?.name;
            return contactIheType || defaultDisplay;
        }
        function findContactProfession() {
            let professionStr = "";
            if (itemData.libelle_profession) professionStr += itemData.libelle_profession;
            return professionStr || props.i18n("error.unknown_profession");
        }
        function setPermissionIconCls() {
            if (itemData.dossier_liaison == 2) {
                return "fas fa-eye";
            }
            return "fas fa-eye-slash";
        }
        const itemActionAllowed = props.concatActionAllowed(props.actionAllowed, itemData.action_allowed || []);
        return (
            <>
                <div className="infoList">
                    <p className="contactName">
                        {props.formatContactName({ first_name: itemData.first_name, last_name: itemData.last_name })}
                        {!!+itemData.main_contact && <sup className="mainContact fas fa-star"></sup>}
                    </p>
                    {/*blocType === "personal" ? <p className="rolTypeContact">{displayRolTypeContact()}</p> : ""*/}
                    {blocType === "personal" ? <p className="iheType">{displayRolTypeContact()}</p> : ""}
                    {blocType === "professional" ? <p className="rolProfession">{findContactProfession()}</p> : ""}
                </div>
                <div className="optionList">
                    <PortalBtn className="reverse" disabled iconCls={setPermissionIconCls()} />
                    <PortalBtn className="reverse" onClick={() => onClickModify(itemData)}>
                        {itemActionAllowed.filter((action) => action === "upd")[0] ? "Modifier" : "Détail"}
                    </PortalBtn>
                    <PortalBtn
                        className="reverse"
                        iconCls="fas fa-trash"
                        displayActions={["dis", "del"]}
                        activeActions={["del"]}
                        actionAllowed={itemActionAllowed}
                        onClick={() => onClickDelete(itemData)}
                    ></PortalBtn>
                </div>
            </>
        );
    }

    function getDescr() {
        switch (blocType) {
            case "professional":
                return "Retrouvez ici les coordonnées des professionnels de santé qui interviennent dans votre prise en charge.";
            default: //personal
                return "Retrouvez ici les coordonnées de vos proches aidants. Vous pouvez à tout moment faire évoluer ou mettre à jour le répertoire de vos contacts.";
        }
    }

    function addBtnRender() {
        if (!persoContactActivation && blocType === "personal") {
            return "";
        }
        const addBtnTxt = props.i18n("label.action.add_" + blocType + "_contact");
        return (
            <PortalBtn
                displayActions={["dis", "add"]}
                activeActions={["dis", "add"]}
                actionAllowed={props.actionAllowed || []}
                className="addBtn"
                iconCls="far fa-plus-circle"
                onClick={onClickNew}
            >
                {addBtnTxt}
            </PortalBtn>
        );
    }

    return (
        <div className={"listContactBlockContainer " + blocType}>
            <h2 className="listContactBlockTitle">{blocTitle}</h2>
            <div className="newContactBlock">
                <ImgBackground />
                <div className="newContactSubBlock">
                    <p className="descr">{getDescr()}</p>
                    {addBtnRender()}
                </div>
            </div>
            <PortalList
                data={data}
                noPagination
                noDataMsg={
                    !persoContactActivation && blocType === "personal"
                        ? props.i18n(`error.${blocType}_contact_disabled`)
                        : props.i18n("error.no_contact")
                }
                itemRender={listItemRender}
                itemSelectable={false}
                isLoading={refreshState === "loading"}
                errorLoading={refreshState === "error"}
            />
        </div>
    );
}
