//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect } from "react";
//External library
import cloneDeep from "clone-deep";
//----------------------------------------------------//
//---------- UI Components ----------//
//Custom UI Components
import InitView from "../initView/initView";
//-----------------------------------//
export default function CommonController(props) {
    /**
     * @param {Object} props : {
     *
     *    @param {Boolean} usePlug : Pour savoir si on utilise des données bouchon
     *       @descr : Ce sont les données dans @param storePlug qui seront instanciées dans le store du controller
     *
     *    @param {Object || Array} storePlug : Le store instancié avec les données bouchon fournies si on active @param usePlug
     *
     *    @param {Boolean} customInit : Si à true => désactive le chargement automatique à l'init du composant
     *       @descr : Si à false => Le chargement automatique se fait à l'init et les données reçues se retrouvent dans le store du composant
     *
     *    @param {Object} loadDataParams (opt) [{}] : Les paramètres qui seront transmis à la requête du chargement automatique (et du refresh) pour charger les données
     *}
     */

    //Constants
    const usePlug = !!props.usePlug;
    const storePlug = props.storePlug || {};
    const customInit = !!props.customInit;
    const loadDataParams = props.loadDataParams || {};

    //States
    const [requestState, setRequestState] = useState(""); //"" || "loading" || "error"
    const [initState, setInitState] = useState(!customInit ? "loading" : ""); //"" || "loading" || "error"
    const [refreshState, setRefreshState] = useState(""); //"" || "loading" || "error"
    const [actionAllowed, setActionAllowed] = useState(null);
    const [store, updateStore] = useState([]);
    function cloneStore() {
        return cloneDeep(store);
    }
    //Communication functions
    function onLoadData(response) {
        return new Promise(function (rs, rj) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
            }
            updateStore(response.data);
            setActionAllowed(response.action_allowed || null);
            if (response?.help?.default_display) props.setHelpDisplay(true);
            setInitState("");
            setRefreshState("");
            return rs(response.data);
        });
    }
    function init() {
        setInitState("loading");
        if (usePlug) {
            updateStore(storePlug);
            setInitState("");
            return Promise.resolve(storePlug);
        }
        return props
            .request({
                data: loadDataParams,
            })
            .then(function (res) {
                return onLoadData(res);
            })
            .catch(function (err) {
                setInitState("error");
                return Promise.reject(err);
            });
    }

    function refresh() {
        setRefreshState("loading");
        if (usePlug) {
            updateStore(storePlug);
            setRefreshState("");
            return Promise.resolve(storePlug);
        }
        return props
            .request({
                data: loadDataParams,
            })
            .then(function (res) {
                return onLoadData(res);
            })
            .catch(function (err) {
                setRefreshState("error");
                console.error("Error refreshing store");
                console.error(err);
                return Promise.reject(err);
            });
    }

    //Effects treatments
    const ent_entity_id = props.selectedClient.ent_entity_id;
    useEffect(
        function () {
            if (!customInit) init().catch(() => "");
        },
        [ent_entity_id]
    );

    useEffect(
        function () {
            switch (true) {
                case initState === "loading":
                case refreshState === "loading":
                    setRequestState("loading");
                    break;
                default:
                    setRequestState("");
                    break;
            }
        },
        [initState, refreshState]
    );

    const parentProps = { ...props };
    parentProps.requestState = requestState;
    parentProps.setRequestState = setRequestState;
    parentProps.init = init;
    parentProps.refreshState = refreshState;
    parentProps.setRefreshState = setRefreshState;
    parentProps.refresh = refresh;
    parentProps.actionAllowed = actionAllowed;
    parentProps.setActionAllowed = setActionAllowed;
    parentProps.store = store;
    parentProps.cloneStore = cloneStore;
    parentProps.updateStore = updateStore;
    const childrenWithProps = React.Children.map(props.children, function (child) {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, parentProps);
        }
        return child;
    });
    //Rendering treatments
    if (initState === "loading") {
        return <InitView type="loading" loadingMsg={props.i18n("info.action.view_loading")} />;
    }
    if (initState === "error") {
        return <InitView type="errorLoading" errorMsg={props.i18n("error.view_loading")} />;
    }
    return <>{childrenWithProps}</>;
}
