import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalPassword from "../../../../../../../../ressources/components/portalFields/portalPassword/portalPassword";
import PortalFeedback from "../../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
export default function SetPasswordForm(props) {
    const onSubmitForm = props.onSubmitForm;
    const formData = props.formData;
    const setFormData = props.setFormData;
    const formValidator = props.formValidator;
    const formValidationCount = props.formValidationCount;
    const requestState = props.requestState;
    const passwordLabel = props.passwordLabel;
    const postBtnLabel = props.postBtnLabel;
    const passwordPlaceholder = props.passwordPlaceholder;
    const passwordConfirmLabel = props.passwordConfirmLabel;
    const passwordConfirmPlaceholder = props.passwordConfirmPlaceholder;
    const passwordInfo = props.passwordInfo;
    const actionType = props.actionType;
    //Validations
    const passwordValidation = formValidator.fields.password.validate();
    console.warn(passwordValidation);
    const passwordConfirmValidation = formValidator.fields.password_confirm.validate();
    function getPasswordValidationClass(conditionCode) {
        if (typeof passwordValidation.errors === "object") {
            const conditionIsError = !!passwordValidation.errors.filter(function (errorCode) {
                return conditionCode === errorCode;
            })[0];
            return !conditionIsError ? "valid" : "";
        }
    }
    //UX handlers
    return (
        <>
            <div>
                <div className="formInstructions">{props.i18n("info.instructions." + actionType)}</div>
                <div className="formSubInstructions">{props.i18n("info.subinstructions." + actionType)}</div>
            </div>
            <Form className="setPasswordFormContainer" onSubmit={onSubmitForm} noValidate>
                <Row>
                    <Form.Group as={Col} xs={12} controlId="password">
                        <PortalPassword
                            id="password"
                            onChange={(e) => setFormData("password", e.target.value)}
                            type="text"
                            iconsLeft="far fa-lock-alt"
                            name="lastName"
                            placeholder={passwordPlaceholder}
                            value={formData.username}
                            autoFocus
                            required={true}
                            aria-required={true}
                            tooltip={passwordInfo}
                            isInvalid={formValidationCount > 0 && !passwordValidation.success}
                            aria-invalid={formValidationCount > 0 && !passwordValidation.success}
                            aria-describedby="password-1 password-2 password-3 password-4 password-5 password-6"
                        />
                        {/*formValidationCount > 0 ? (
                            <PortalFeedback i18n={props.i18n} fieldId="password" msgList={passwordValidation.errors} />
                        ) : (
                            ""
                        )*/}
                        <div className="customFeedbackContainer">
                            <h3 className="feedbackTitle">Votre mot de passe doit contenir&nbsp;:</h3>
                            <ul className="feedbackList">
                                <li className={getPasswordValidationClass("password_is_long_enough")}>
                                    {props.i18n("feedback.password_is_long_enough")}
                                </li>
                                <li className={getPasswordValidationClass("password_has_lower_case")}>
                                    {props.i18n("feedback.password_has_lower_case")}
                                </li>
                                <li className={getPasswordValidationClass("password_has_upper_case")}>
                                    {props.i18n("feedback.password_has_upper_case")}
                                </li>
                                <li className={getPasswordValidationClass("password_has_one_number")}>
                                    {props.i18n("feedback.password_has_one_number")}
                                </li>
                                <li className={getPasswordValidationClass("password_has_special_character")}>
                                    {props.i18n("feedback.password_has_special_character")}
                                </li>
                            </ul>
                        </div>
                    </Form.Group>
                    {actionType !== "post_update_username" && (
                        <Form.Group as={Col} xs={12} controlId="confirmPassword">
                            <PortalPassword
                                id="confirmPassword"
                                onChange={(e) => setFormData("password_confirm", e.target.value)}
                                autoComplete="password"
                                name="confirmPassword"
                                iconsLeft="far fa-lock-alt"
                                placeholder={passwordConfirmPlaceholder}
                                value={formData.password_confirm}
                                required={true}
                                aria-required={true}
                                isInvalid={formValidationCount > 0 && !passwordConfirmValidation.success}
                                aria-invalid={formValidationCount > 0 && !passwordConfirmValidation.success}
                                aria-describedby="confirmPassword-1 confirmPassword-2"
                            />
                            {formValidationCount > 0 && (
                                <PortalFeedback i18n={props.i18n} fieldId="confirmPassword" msgList={passwordConfirmValidation.errors} />
                            )}
                        </Form.Group>
                    )}
                </Row>
                <div className="sendRequestContainer">
                    <PortalBtn state={requestState} className="sendRequestBtn" type="submit" iconCls="fas fa-arrow-right" iconAlign="right">
                        {postBtnLabel}
                    </PortalBtn>
                </div>
            </Form>
        </>
    );
}
