import React from "react";
import { useState, useEffect, useRef } from "react";
import TooltipWrapper from "../tooltipWrapper/tooltipWrapper";
import "./portalCheck.css";

/*
Install : - La bibliothèque font-awesome doit être intallée dans le projet
          - Les styles css associés (paragraphe portalCheck) doivent être chargés dans le projet
Configs :
{
    inputName = STRING (!!REQ) [portalCheck-i] => Le nom de l'input qui est associée à ce composant (Obligatoire pour la bonne sémantique d'accessibilité)

    activeIcon : STRING (opt) ['fas fa-check-square'] => La classe font-awesome de la CheckBox lorsque celle-ci est dans un état 'active'

    baseIcon : STRING (opt) ['far fa-square'] => La classe font-awesome de la CheckBox lorsque celle-ci n'est pas dans un état 'active'
        # Ces 2 propriétés doivent être spécifiées par paire, sinon on ce sont les classes de base qui sont affectées

    text : STRING || JSX (opt) [''] => Texte optionnel que l'on peut adjoindre au composant //TODO: Changer en 'label'

    textAlign: STRING (opt) ['left'] => Pour définir de quel côté se trouve le texte par rapport à l'icône //TODO: Changer en 'labelAlign'

    onChange : FUNCTION (opt) [emptyFctn] => Callback exécuté lorsque l'utilisateur déclenche le changement d'état de la checkBox
        fctn(active, event){} => Les params passés dans la Callback si elle existe
            active : BOOLEAN => Indique  dans quel état devrait se trouver la CheckBox après le changement d'état effectué par l'utilisateur
            event : OBJECT => L'évènement déclenché sur le span de la CheckBox
    
    defaultActive : BOOLEAN (opt) [false] => Permet d'initialiser l'état de la CheckBox par un composant de niveau supérieur

    active : BOOLEAN (opt - not standard) [undefined] => Permet de forcer l'état de la CheckBox par un composant de niveau supérieur
        # Si la checkBox est 'disabled', ce paramètre est le seul moyen de contrôler l'état de la CheckBox après son init
    
    disabled : BOOLEAN (opt) [false] => L'utilisateur ne peut plus modifier directement l'état de la CheckBox
        # L'évènement 'onChange' ne peut plus être déclenché même si le paramètre 'active' est modifié d'une autre manière
    
    className : STRING (opt) [''] => Classe que l'on peut ajouter à l'élément extérieur de la CheckBox
    
    tabIndex : NUMBER (opt) [-1] => Index pour l'ordre de focus à appliquer sur le composant lorsqu'on navigue au clavier 

    required : BOOLEAN (opt) [false] => Indiquer que ce champ est obligatoire

    invalid : BOOLEAN (opt) [false] => Indiquer que ce champ est invalide (pour les lecteurs d'écran)

    aria-describedby : STRING (opt) [''] => Indiquer l'ID d'une balise externe qui donne des informations sur ce champ (elles seront lues par le lecteur d'écran après ce qui est écrit dans le label)

    aria-label : STRING (opt) [''] => Permet de mettre un texte qui sera lu à la place de ce qui se trouve dans le label
        # Ce champ est obligatoire si aucun label n'est spécifié pour des raisons d'accessibilité
    
    autoFocus : BOOLEAN (opt) [false] => Permet au composant de prendre automatiquement le focus lorsqu'il apparaît à l'écran
        # !! Cela ne fonctionne que si le composant n'est pas 'disabled'
    
    tooltip = STRING (opt) [''] => Le contenu du tooltip
    
    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input

    displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
        # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

    activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'modifiable'
        # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

    actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
        # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
        # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
        # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'
}
*/
function PortalCheck(props) {
    const inputName = typeof props.inputName === "string" ? props.inputName : `portalCheck-${Math.floor(Math.random() * 1000000)}`;
    const customIcons = props.activeIcon && props.baseIcon;
    const activeIcon = customIcons ? props.activeIcon : "fas fa-check-square";
    const baseIcon = customIcons ? props.baseIcon : "far fa-square";
    const text = props.text || "";
    const textAlign = props.textAlign === "right" ? "right" : "left";
    const isControlled = props.hasOwnProperty("active");
    const [localActive, setLocalActive] = useState(isControlled ? !!props.active : !!props.defaultActive);
    const [focusClass, setFocusClass] = useState("");
    const inputRef = useRef();
    const displayActions = ["dis"];
    if (typeof props?.displayActions?.forEach === "function") {
        props.displayActions.forEach(function (action) {
            displayActions.push(action);
        });
    }
    const activeActions = typeof props?.activeActions?.forEach === "function" ? props.activeActions : ["upd"];
    function defaultDisplay() {
        let display = true;
        if (typeof props.actionAllowed?.forEach !== "function") return display;
        displayActions.forEach(function (requiredAction) {
            if (
                props.actionAllowed.findIndex(function (viewAction) {
                    return requiredAction === viewAction;
                }) < 0
            ) {
                display = false;
            }
        });
        return display;
    }
    function defaultDisabled() {
        let disabled = false;
        if (typeof props.actionAllowed?.forEach !== "function") return disabled;
        activeActions.forEach(function (requiredAction) {
            if (props.actionAllowed.findIndex((viewAction) => viewAction === requiredAction) < 0) disabled = true;
        });
        return disabled;
    }
    const isDisabled = props.hasOwnProperty("disabled") ? props.disabled : defaultDisabled();
    const tabIndex = typeof props.tabIndex === "number" ? (isDisabled ? -1 : props.tabIndex) : 0;
    useEffect(function () {
        if (!!props.autoFocus && inputRef.current && !isDisabled) {
            inputRef.current.focus();
        }
    }, []);

    function handleClick(event) {
        event.stopPropagation();
        if (!isDisabled) {
            if (isControlled) {
                //Le composant est controllé (via la props "active") => On délègue la demande de changement d'état au composant supérieur via la callback (si elle existe)
                if (typeof props.onChange === "function") {
                    props.onChange(!props.active, event);
                }
            } else {
                //Le composant est 'libre' => On change l'état du composant et on déclenche la callback (si elle existe) pour informer le composant supérieur du changement
                let newActive = !localActive;
                setLocalActive(newActive);
                if (typeof props.onChange === "function") {
                    props.onChange(newActive, event);
                }
            }
        }
    }
    function handleFocus(event) {
        setFocusClass("focused");
        if (typeof props.onFocus === "function") props.onFocus(event);
    }
    function handleBlur(event) {
        setFocusClass("");
        if (typeof props.onBlur === "function") props.onBlur(event);
    }
    function renderLabel() {
        if (typeof text === "string") return <label htmlFor={inputName} dangerouslySetInnerHTML={{ __html: text }}></label>;
        return <label htmlFor={inputName}>{text}</label>;
    }
    return (
        <TooltipWrapper tooltip={props?.tooltip} tooltipAlign={props?.tooltipAlign}>
            {defaultDisplay() ? (
                <div
                    className={`portalCheck ${typeof props.className === "string" ? props.className : ""} ${isDisabled ? "disabled" : ""} ${
                        isControlled ? (props.active ? "active" : "") : localActive ? "active" : ""
                    } ${focusClass}`}
                >
                    <>
                        {text && textAlign === "left" ? renderLabel() : ""}
                        <span
                            className={`icon ${
                                isControlled ? (props.active ? activeIcon : baseIcon) : localActive ? activeIcon : baseIcon
                            } ${text && textAlign === "left" ? "txtLeft" : text && textAlign === "right" ? "txtRight" : ""}`}
                            onClick={handleClick}
                        ></span>
                        <input
                            className="sr-only"
                            type="checkbox"
                            id={inputName}
                            name={inputName}
                            disabled={isDisabled}
                            checked={isControlled ? !!props.active : !!props.defaultActive}
                            value={isControlled ? !!props.active : !!props.defaultActive}
                            required={!!props.required}
                            aria-required={!!props.required}
                            aria-invalid={!!props.invalid}
                            aria-label={props["aria-label"] || null}
                            aria-describedby={props["aria-describedby"] || null}
                            tabIndex={tabIndex}
                            onChange={handleClick}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={inputRef}
                        />
                        {text && textAlign === "right" ? renderLabel() : ""}
                    </>
                </div>
            ) : (
                ""
            )}
        </TooltipWrapper>
    );
}
export default PortalCheck;
