import React from "react";
import { useState, useEffect, useRef } from "react";
import { getObjectValue } from "../../../../../ressources/lib/js/globalFunctions";
import BtnToolTip from "../../../../../ressources/components/btnToolTip/btnToolTip";
export default function DishCell({ data, comboList, statusList, minMax, onChange, repCliMenuDate }) {
    const currentDishId = comboList?.selected || "repDishNull";
    const dishQuantity = +data?.quantity || 0;
    const [comboOpen, setComboOpen] = useState(false);
    const defaultFocus = useRef(null); //First element inside combo options
    const comboContainerEl = useRef(null);
    //Effects treatments
    useEffect(
        function () {
            if (defaultFocus.current) defaultFocus.current.focus();
        },
        [comboOpen]
    );

    //UI event handlers
    function onClickCombo() {
        setComboOpen(!comboOpen);
    }
    function onBlurCombo(e) {
        const targetElClick = e?.relatedTarget;
        const comboContainEl = comboContainerEl.current;
        if (comboContainEl && !comboContainEl.contains(targetElClick) && comboContainEl !== targetElClick) {
            setComboOpen(false);
        }
    }
    function requestIncreaseDishQuantity() {
        const quantityMax = minMax?.quantity_max || 0;
        if (dishQuantity < quantityMax) {
            /*console.log("data : ", data);
            console.log("comboList : ", comboList);
            console.log("statusList : ", statusList);
            console.log("minMax : ", minMax);*/
            repCliMenuDate[data?.date]["section"][comboList?.field]["quantity"]++;
            onChange(repCliMenuDate, data?.date);
        }
    }
    function requestDecreaseDishQuantity() {
        const quantityMin = minMax?.quantity_min || 0;
        if (dishQuantity > quantityMin) {
            repCliMenuDate[data?.date]["section"][comboList?.field]["quantity"]--;
            onChange(repCliMenuDate, data?.date);
        }
    }
    function changeSelectedDish(dishId) {
        const selectedDish = comboList?.list.find((dish) => dish.id == dishId);
        if (selectedDish) {
            repCliMenuDate[data?.date]["section"][comboList?.field]["rep_dish_id"] = selectedDish?.id;
            repCliMenuDate[data?.date]["section"][comboList?.field]["quantity"] = minMax?.quantity_min || 0;
            repCliMenuDate[data?.date]["section"][comboList?.field]["par_status_treatment_id"] = selectedDish?.par_status_treatment_id;
            console.log("selectedDish : ", selectedDish);
        } else {
            repCliMenuDate[data?.date]["section"][comboList?.field]["rep_dish_id"] = null;
            repCliMenuDate[data?.date]["section"][comboList?.field]["quantity"] = null;
            repCliMenuDate[data?.date]["section"][comboList?.field]["par_status_treatment_id"] = null;
        }
        onChange(repCliMenuDate, data?.date);
        setComboOpen(false);
    }
    //Pre-rendering treatments
    const dishList = {};
    if (typeof comboList?.list === "object" && comboList?.list.length > 0) {
        comboList?.list.forEach(function (comboOption) {
            if (getObjectValue(comboOption, ["id"])) {
                dishList[comboOption.id] = comboOption;
            }
        });
    }
    function buildDishCombo() {
        const dishOptionsDisplay = [];
        let optionKey = "";
        let dishOptionDis;
        let parStatusId = "";
        for (let dishOption in dishList) {
            //dishOption = rep_dish_id
            optionKey = `date${data?.date || ""}proProduct${data?.pro_product_id}repMenu${data?.rep_menu_id || ""}repDish${dishOption}`;
            //console.log(dishList[dishOption]);
            parStatusId = getObjectValue(dishList, [dishOption, "par_status_treatment_id"]);
            dishOptionDis = (
                <div
                    key={optionKey}
                    className={`dish-description-container${dishOption == currentDishId ? " selected" : ""}`}
                    onClick={() => changeSelectedDish(dishOption)}
                    tabIndex={0}
                >
                    <div className="layoutBox">
                        <div className="dish-description-content">
                            <div className="title">{getObjectValue(dishList, [dishOption, "name"]) || ""}</div>
                            <div className="text">{getObjectValue(dishList, [dishOption, "detail"]) || ""}</div>
                        </div>
                        <div className="dish-description-status">
                            <span className={`icon fas ${getObjectValue(statusList, ["list", parStatusId, "cls"])}`}></span>
                        </div>
                    </div>
                    <div className="dish-description-info">
                        <span className="descr">{getObjectValue(dishList, [dishOption, "info"]) || ""}</span>
                    </div>
                </div>
            );
            dishOptionsDisplay.push(dishOptionDis);
        }
        dishOptionsDisplay.unshift(
            <div
                ref={defaultFocus}
                value={"repDishNull"}
                key="repDishNull"
                className={`dish-description-container empty${"repDishNull" == currentDishId ? " selected" : ""}`}
                onClick={() => changeSelectedDish("repDishNull")}
                tabIndex={0}
            >
                Rien
            </div>
        );
        return (
            <div className="dish-combo-container" onBlur={onBlurCombo}>
                {dishOptionsDisplay}
            </div>
        );
    }
    function buildSelectedOption() {
        /*console.log(currentDishId);
        console.log(dishList);*/
        const selectedOption = getObjectValue(dishList, [currentDishId]) || {};
        const selectedOptionStatus = Object.keys(selectedOption).length;
        const parStatusId = selectedOption.par_status_treatment_id;
        //console.log(statusList);
        let selectedOptionDisplay;
        if (selectedOptionStatus) {
            selectedOptionDisplay = (
                <>
                    <div className="dish-quantity-container">
                        <BtnToolTip
                            className="quantityBtn"
                            iconCls="fas fa-minus"
                            onClick={requestDecreaseDishQuantity}
                            disabled={false}
                            aria-label=""
                        />
                        <span className="quantity">{dishQuantity}</span>
                        <BtnToolTip
                            className="quantityBtn"
                            iconCls="fas fa-plus"
                            onClick={requestIncreaseDishQuantity}
                            disabled={false}
                            aria-label=""
                        />
                    </div>
                    <div className="dish-description-container" onClick={onClickCombo} ref={comboContainerEl} tabIndex={-1}>
                        <div className="layoutBox">
                            <div className="dish-description-content">
                                <div className="title">{selectedOption.name || ""}</div>
                                <div className="text">{selectedOption.detail || ""}</div>
                            </div>
                            <div className="dish-description-status">
                                <span className={`icon fas ${getObjectValue(statusList, ["list", parStatusId, "cls"])}`}></span>
                            </div>
                        </div>
                        <div className="dish-description-info">
                            <span className="descr fas fa-chevron-down"></span>
                        </div>
                        {comboOpen ? buildDishCombo() : ""}
                    </div>
                </>
            );
        } else {
            selectedOptionDisplay = (
                <>
                    <div className="dish-description-container" onClick={onClickCombo} ref={comboContainerEl} tabIndex={-1}>
                        <div className="layoutBox">
                            <div className="dish-description-content">
                                <div className="title"></div>
                                <div className="text"></div>
                            </div>
                            <div className="dish-description-status">
                                <span className="icon"></span>
                            </div>
                        </div>
                        <div className="dish-description-info">
                            <span className="descr fas fa-chevron-down"></span>
                        </div>
                        {comboOpen ? buildDishCombo() : ""}
                    </div>
                </>
            );
        }
        return selectedOptionDisplay;
    }
    return <div className="mealTable-cell-content dish-cell" /*onBlur={onBlurCombo}*/>{buildSelectedOption()}</div>;
}
