import React from "react";
import { useEffect } from "react";
import { uniqueId } from "../../lib/js/globalFunctions";

export default function PortalScript({ id, src, children }) {
    const scriptId = id ? id : "portalScript" + uniqueId();
    src = typeof src === "string" ? src : "";
    useEffect(function () {
        if (!src) {
            const scriptTag = document.getElementById(scriptId);
            if (!scriptTag) {
                console.error(`ERROR - PortalScript : scriptId (${scriptId}) not found`);
                return;
            }
            const parentTag = scriptTag.parentNode;
            scriptTag.id = "";
            //!!!! Never remove a react tag from browser DOM, it may cause React DOM to loose its reference and then to crash
            //parentTag.removeChild(scriptTag);
            const newScriptTag = document.createElement("script");
            newScriptTag.id = scriptId;
            switch (true) {
                case src:
                    newScriptTag.src = src;
                    break;
                case typeof children === "string":
                    newScriptTag.textContent = children;
                    break;
                default:
                    console.error(`ERROR - PortalScript : No script or src defined for the component`);
                    return;
            }
            parentTag.insertBefore(newScriptTag, scriptTag);
        }
        return function () {
            const scriptTag = document.getElementById(scriptId);
            if (scriptTag) {
                scriptTag.parentNode.removeChild(scriptTag);
            }
        };
    }, []);
    return <noscript id={scriptId} style={{ display: "none" }}></noscript>;
}
