import React from "react";
import PortalList from "../../../../../../../ressources/components/portalList/portalList";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn";
export default function Screen(props) {
    const store = props.store || [];
    const refreshState = props.refreshState;
    const downloadDetail = props.downloadDetail;
    //Rendering functions
    function displayDate(date) {
        if (!date) return "";
        date = new Date(date);
        return date.toLocaleDateString("FR-fr", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    }
    function evalHeaderDisplay() {
        return (
            <>
                <h4 className="colTitle col first">Type d'évaluation</h4>
                <h4 className="colTitle col second">Date</h4>
                <h4 className="colTitle col third">Score</h4>
                <h4 className="colTitle col fourth">Détails</h4>
            </>
        );
    }
    function listItemRender(itemData) {
        function detailRender() {
            if (!itemData.show_detail) return "";
            return (
                <PortalBtn className="reverse" iconCls="fas fa-eye" onClick={() => downloadDetail(itemData)}>
                    Voir le détail
                </PortalBtn>
            );
        }
        return (
            <>
                <p className="col first">{props.i18n("label.evaluation.svy_" + itemData?.svy_code)}</p>
                <p className="col second">{displayDate(itemData.svy_date || itemData.created_at)}</p>
                <p className="col third">{itemData.score !== null ? itemData.score : props.i18n("error.unknown_evaluation")}</p>
                <p className="col fourth">{detailRender()}</p>
            </>
        );
    }
    return (
        <>
            {props.setStyle("./base/desktop")}
            <div className="evalListContainer">
                <h2 className="evalTitle">Historique des évaluations</h2>
                <PortalList
                    header={evalHeaderDisplay()}
                    className="evalList"
                    data={store}
                    noPagination
                    itemRender={listItemRender}
                    noDataMsg="Aucune évaluation disponible"
                    itemSelectable={false}
                    isLoading={refreshState === "loading"}
                    errorLoading={refreshState === "error"}
                />
            </div>
        </>
    );
}
