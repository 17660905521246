import React from "react";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../../application/reducers/private/private.js";
import { Button, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ImgBackground from "../imgBackground/imgBackground.js";
import BtnToolTip from "../btnToolTip/btnToolTip.js";
import "./bubleView.css";
export default function BubbleView(props) {
    const history = useHistory();
    const loggedUser = useSelector(selectLoggedUser);

    function userIsLogged() {
        return !!loggedUser?.ent_entity_id;
    }
    function onBackClick() {
        history.goBack();
    }
    function onHomeClick() {
        if (typeof props.onHomeClick !== "function") {
            if (userIsLogged()) {
                history.push("home");
            } else {
                history.push("login");
            }
            return;
        }
        props.onHomeClick();
    }
    return (
        <div className="bubbleViewContainer">
            <Container className={`bubblePanel ${props.className || ""}`}>
                <BtnToolTip className="bubbleHomeBtn" iconCls="fas fa-home" onClick={onHomeClick}></BtnToolTip>
                <div className="bubblePanelHeader portalContainer">
                    {props.logo ? (
                        <>
                            <ImgBackground className="portalRow bubblePanelLogoContainer companyLogo" />
                            <span className="lineSeparator bold bubbleLogoSeparator"></span>
                        </>
                    ) : (
                        ""
                    )}
                    {props.title ? <h1 className="portalRow bubblePanelTitle">{props.title.toUpperCase()}</h1> : ""}
                </div>
                <div className="portalContainer bubblePanelContent">
                    {props.children}
                    {props.backBtn ? (
                        <Row className="bubbleBackBtn">
                            <Button className="portalBtn base" onClick={onBackClick}>
                                Retour
                            </Button>
                        </Row>
                    ) : (
                        ""
                    )}
                </div>
            </Container>
        </div>
    );
}
