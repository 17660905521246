//######################################## Imports ########################################//
//External libraries
import React from "react";
import { Form } from "react-bootstrap";
//Custom libraries
import PortalFeedback from "../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
import BubbleView from "../../../../../../ressources/components/bubbleView/bubbleView";
import PortalPassword from "../../../../../../ressources/components/portalFields/portalPassword/portalPassword";
import PortalBtn from "../../../../../../ressources/components/portalBtn/portalBtn";
//#########################################################################################//
export default function Screen(props) {
    const viewTitle = props.viewTitle;
    const formData = props.formData;
    const setFormData = props.setFormData;
    const formValidator = props.formValidator;
    const formValidationCount = props.formValidationCount;
    const requestState = props.requestState;
    const passwordLabel = props.passwordLabel;
    const passwordPlaceholder = props.passwordPlaceholder;
    const passwordInfo = props.passwordInfo;
    const passwordConfirmLabel = props.passwordConfirmLabel;
    const passwordConfirmPlaceholder = props.passwordConfirmPlaceholder;
    const successMsg = props.successMsg;
    const postData = props.postData;
    const username = props.username;
    const actionType = props.actionType;
    //UX event handlers
    function onSubmitForm(e) {
        e.preventDefault();
        postData()
            .then(function (res) {
                props.msgBox(successMsg, "info", "msg").then(function (res) {
                    props.redirectTo("login");
                });
            })
            .catch(function (err) {
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            });
    }

    //Validations
    const passwordValidation = formValidator.fields.password.validate();
    const passwordConfirmValidation = formValidator.fields.password_confirm.validate();

    return (
        <BubbleView title={viewTitle} logo={true}>
            {props.setStyle("./base")}
            {!!username && <h2 className="usernameDisplay">{username}</h2>}
            <Form className="portalRow" onSubmit={onSubmitForm} noValidate>
                <Form.Group>
                    <h4>Merci de renseigner les champs ci-dessous :</h4>
                </Form.Group>
                {!!props.i18n("label.field.mandatory_fields") && <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>}
                <Form.Group controlId="password">
                    {!!passwordLabel && (
                        <Form.Label>
                            {passwordLabel}&nbsp;<span aria-hidden={true}>*</span>
                        </Form.Label>
                    )}
                    <PortalPassword
                        id="password"
                        autoFocus
                        onChange={(e) => setFormData("password", e.target.value)}
                        autoComplete="password"
                        name="password"
                        iconsLeft="far fa-lock-alt"
                        placeholder={passwordPlaceholder}
                        value={formData.password}
                        required={true}
                        aria-required={true}
                        tooltip={passwordInfo}
                        isInvalid={formValidationCount > 0 && !passwordValidation.success}
                        aria-invalid={formValidationCount > 0 && !passwordValidation.success}
                        aria-describedby="password-1 password-2 password-3 password-4 password-5 password-6"
                    />
                    {formValidationCount > 0 && <PortalFeedback i18n={props.i18n} fieldId="password" msgList={passwordValidation.errors} />}
                </Form.Group>
                {actionType !== "post_update_username" && (
                    <Form.Group controlId="confirmPassword">
                        {!!passwordConfirmLabel && (
                            <Form.Label>
                                {passwordConfirmLabel}&nbsp;<span aria-hidden={true}>*</span>
                            </Form.Label>
                        )}
                        <PortalPassword
                            id="confirmPassword"
                            onChange={(e) => setFormData("password_confirm", e.target.value)}
                            autoComplete="password"
                            name="confirmPassword"
                            iconsLeft="far fa-lock-alt"
                            placeholder={passwordConfirmPlaceholder}
                            value={formData.password_confirm}
                            required={true}
                            aria-required={true}
                            isInvalid={formValidationCount > 0 && !passwordConfirmValidation.success}
                            aria-invalid={formValidationCount > 0 && !passwordConfirmValidation.success}
                            aria-describedby="confirmPassword-1 confirmPassword-2"
                        />
                        {formValidationCount > 0 && (
                            <PortalFeedback i18n={props.i18n} fieldId="confirmPassword" msgList={passwordConfirmValidation.errors} />
                        )}
                    </Form.Group>
                )}
                <PortalBtn className="validate centered" state={requestState} type="submit">
                    Valider
                </PortalBtn>
            </Form>
        </BubbleView>
    );
}
