import React from "react";
import { useState, useEffect } from "react";
//Custom
import PortalTitle from "../../../../../../../ressources/components/portalTitle/portalTitle.js";
import RecoverForm from "./components/recoverForm.js";
import SuccessMsgSent from "./components/successMsgSent.js";
export default function Screen(props) {
    const postData = props.postData;
    const legalContent = props.store.legal_content;
    const setViewLayoutData = props.setViewLayoutData;
    //Screen states
    const [step, setStep] = useState("recoverForm"); //recoverForm || successMsgSent
    //Effect treatments
    useEffect(function () {
        setViewLayoutData("title", props.i18n("label.view.forgotten_password"));
    }, []);
    //UX event handlers
    function onSubmitForm(e) {
        e.preventDefault();
        postData()
            .then(function (res) {
                setStep("successMsgSent");
            })
            .catch(function (err) {
                props
                    .msgBox(props.i18n(err.msg), "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            });
    }
    //Pre-rendering treatments
    function renderScreen() {
        switch (step) {
            case "successMsgSent":
                return <SuccessMsgSent {...props} />;
            default:
                return <RecoverForm onSubmitForm={onSubmitForm} {...props} />;
        }
    }
    //console.log(formValidation);
    return (
        <>
            {props.setStyle("./base/desktop")}
            <div className="contentContainer">
                {/*<div className="titlePart">
                    <PortalTitle
                        title={props.i18n("label.view.forgotten_password")}
                        subtitle={"" /*props.i18n("info.process_description")}
                    />
                </div>*/}
                {renderScreen()}
            </div>
            <div className="legalInfoContainer" dangerouslySetInnerHTML={{ __html: legalContent }}></div>
        </>
    );
}
