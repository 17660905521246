import React from "react";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn.js";
export default function SuccessMsgSent(props) {
    function onClickBtnLink() {
        props.redirectTo("register");
    }

    function onClickFollowBtn() {
        props.redirectTo("login");
    }
    return (
        <div className="successContainer">
            <p className="mainSuccessTxt">
                Vous devriez recevoir un e-mail dans les prochaines minutes avec un lien pour réinitialiser votre mot de passe. Pour
                protéger votre compte, ce lien ne sera valide que pendant 24 heures.
            </p>
            <h2 className="listTitle">Si vous n'avez rien reçu :</h2>
            <ol className="listContainer">
                <li>Consultez votre dossier spams ou courrier indésirable</li>
                <li>L'adresse e-mail que vous avez saisie est peut-être incorrecte</li>
                <li>
                    L'adresse e-mail saisie n'est peut-être pas enregistrée chez nous. Si vous n'avez pas encore de compte, vous pouvez{" "}
                    <button className="portalLinkBtn" onClick={onClickBtnLink}>
                        vous inscrire ici.
                    </button>
                </li>
            </ol>
            <PortalBtn onClick={onClickFollowBtn} className="followBtn" iconCls="fas fa-arrow-right" iconAlign="right">
                Continuer
            </PortalBtn>
        </div>
    );
}
