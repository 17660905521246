import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalText from "../../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalFeedback from "../../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback.js";
export default function RecoverForm(props) {
    const formData = props.formData;
    const setFormData = props.setFormData;
    const formValidator = props.formValidator;
    const formValidationCount = props.formValidationCount;
    const requestState = props.requestState;
    const onSubmitForm = props.onSubmitForm;

    //Pre-rendering treatments
    const formValidation = formValidator.validate();
    return (
        <>
            <div>
                <div className="formInstructions">Veuillez saisir l'adresse email associée à votre compte </div>
                <div className="formSubInstructions">
                    Nous vous enverrons un e-mail avec plus d'informations pour réinitialiser votre mot de passe.
                </div>
            </div>
            <Form className="contactFormContainer" onSubmit={onSubmitForm} noValidate>
                {!!props.i18n("label.field.mandatory_fields") && <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>}
                <Row>
                    <Form.Group as={Col} xs={12} controlId="username">
                        {!!props.i18n("label.field.username") && (
                            <Form.Label>
                                {props.i18n("label.field.username")}&nbsp;<span aria-hidden={true}>*</span>
                            </Form.Label>
                        )}
                        <PortalText
                            id="username"
                            onChange={(e) => setFormData("username", e.target.value)}
                            type="text"
                            iconsLeft="far fa-envelope"
                            name="lastName"
                            placeholder={props.i18n("placeholder.field.username")}
                            value={formData.username}
                            autoFocus
                            required={true}
                            aria-required={true}
                            isInvalid={!formValidator.fields.username.validationState.success && formValidationCount > 0}
                            aria-invalid={!formValidator.fields.username.validationState.success && formValidationCount > 0}
                            aria-describedby="username-1"
                        />
                        {formValidationCount > 0 ? (
                            <PortalFeedback
                                fieldId="username"
                                msgList={formValidator.fields.username.validationState.errors}
                                i18n={props.i18n}
                            />
                        ) : (
                            ""
                        )}
                    </Form.Group>
                    <div className="sendRequestContainer">
                        <PortalBtn
                            state={requestState}
                            className="sendRequestBtn"
                            type="submit"
                            iconCls="fas fa-arrow-right"
                            iconAlign="right"
                        >
                            Continuer
                        </PortalBtn>
                    </div>
                </Row>
            </Form>
        </>
    );
}
