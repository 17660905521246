import React from "react";
import { useEffect } from "react";
//Custom
import PortalTitle from "../../../../../../../../ressources/components/portalTitle/portalTitle.js";
import Fconnect from "../../../../../../../../ressources/components/fconnect/fconnect.js";
import PscConnect from "../../../../../../../../ressources/components/pscConnect/pscConnect.js";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn.js";
export default function ModeChoice(props) {
    const setStep = props.setStep;
    const portalType = props.portalType;
    const setViewLayoutData = props.setViewLayoutData;
    function getSubtitle() {
        if (portalType === "pro") {
            return props.i18n("info.pro_creation_subtitle");
        }
        return props.i18n("info.client_creation_subtitle");
    }
    useEffect(function () {
        if (portalType == "pro" && !props.pscConnect) {
            setStep("localCreation");
        } else {
            setViewLayoutData(["title", "disclaimer"], [props.i18n("title.mode_choice"), getSubtitle()]);
        }
    }, []);
    function onClickLocalChoice() {
        setStep("localCreation");
    }

    function getDescription() {
        switch (portalType) {
            case "pro":
                if (!props.pscConnect) return "";
                return props.i18n("info.pscConnect_register");
            default:
                if (!props.fConnect) return "";
                return props.i18n("info.fconnect_register");
        }
    }
    return (
        <>
            {/*<PortalTitle title={props.i18n("title.mode_choice")} subtitle={getSubtitle()} />*/}
            <h2 className="choiceDescription">{getDescription()}</h2>
            <div className="choiceGroupContainer">
                {portalType !== "pro" && !!props.fConnect && (
                    <div className="choiceItemContainer fcChoice">
                        <Fconnect txtInfos={props.i18n("info.fconnect_desc")} msgBox={props.msgBox} />
                    </div>
                )}
                {portalType === "pro" && !!props.pscConnect && (
                    <div className="choiceItemContainer pscChoice">
                        <PscConnect txtInfos={props.i18n("info.pscConnect_desc")} msgBox={props.msgBox} />
                    </div>
                )}
                <div className="choiceItemContainer localChoice">
                    <span className="icon fas fa-envelope-open"></span>
                    <PortalBtn onClick={onClickLocalChoice}>{props.i18n("label.action.select_local_creation")}</PortalBtn>
                </div>
            </div>
        </>
    );
}
