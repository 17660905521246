import React from "react";
//Custom library
import { parseIntWithoutNaN } from "../../../../../../../../ressources/lib/js/globalFunctions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import PortalSection from "../../../../../../../../ressources/components/portalPage/portalSection/portalSection";
import PortalText from "../../../../../../../../ressources/components/portalFields/portalText/portalText";
import PortalPhone from "../../../../../../../../ressources/components/portalFields/portalPhone/portalPhone";
import PortalCheck from "../../../../../../../../ressources/components/portalFields/portalCheck/portalCheck";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalCombo from "../../../../../../../../ressources/components/portalFields/portalCombo/portalCombo";
import PortalFeedback from "../../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
import ItemRow from "../../../../../../../../ressources/components/itemRow/itemRow";
import ContactPermissionSection from "./contactPermissionSection";
import ContactAccessSection from "./contactAccessSection";
export default function DetailContactForm(props) {
    /**
     * @param {Object} props [{}]: {
     *      @param {String} type ['personal'] : Le type de bloc de contact 'personal' || 'professional'
     *
     *      @param {Object} contactForm [{}] : Les données du contact sélectionné (ou du futur contact à ajouter)
     *
     *
     * }
     */

    const contactForm = props.contactForm || {};
    const contactType = props.contactType || "personal";
    const postalAddressKey = contactType === "personal" ? "json_ent_entity_postal_address_1" : "json_ent_entity_postal_address_2";
    const postalAddressData = contactForm[postalAddressKey] || {};
    const contactFormValidator = props.contactFormValidator;
    const setContactForm = props.setContactForm;
    const formModificationCount = props.formModificationCount;
    const formValidationCount = props.formValidationCount;
    const staticStore = props.store.static_store;
    const requestState = props.requestState;
    const postContact = props.postContact;
    const actionAllowed = props.actionAllowed;
    const formatComboItems = props.formatComboItems;
    const returnHome = props.returnHome;

    React.useEffect(function () {
        props.scrollLayout(0);
    }, []);
    //Event handlers
    function onSubmitForm(event) {
        event.preventDefault();
        if (typeof postContact === "function") postContact();
    }
    function onChangeMainContact(newValue) {
        const msg = "Voulez-vous que ce contact soit votre contact principal ?";
        if (!!newValue && !+contactForm.main_contact) {
            props
                .msgBox(msg, "info", "confirm")
                .then(function () {
                    setContactForm("main_contact", newValue);
                })
                .catch(function () {});
            return;
        }
        setContactForm("main_contact", newValue);
    }

    function onSelectRolTypeContact(event) {
        const dataStore = event?.source?.record?.data || {};
        const authorizeDossierLiaison = !!dataStore.authorize_access_dossier_liaison;
        const requireRolContactPermission = !!dataStore.required_rol_contact_permission;
        const addRolContactPermission = false; //Retrait automatique de la demande de consentement à chaque changement de type
        setContactForm(
            ["rol_type_contact_id", "authorize_access_dossier_liaison", "required_rol_contact_permission", "add_rol_contact_permission"],
            [event.value, authorizeDossierLiaison, requireRolContactPermission, addRolContactPermission]
        );
    }

    function onChangeMobilePhone(e, newValue) {
        const keyList = ["mobile_phone"];
        const valueList = [newValue];
        if (!contactForm.phone) {
            //We fill used_phone with mobile_phone
            keyList.push("used_phone");
            valueList.push(newValue);
        }
        setContactForm(keyList, valueList);
    }

    const lastNameValidationResult = contactFormValidator.fields.last_name.validate();
    const firstNameValidationResult = contactFormValidator.fields.first_name.validate();
    const emailValidationResult = contactFormValidator.fields.email.validate();
    const phoneValidationResult = contactFormValidator.fields.phone.validate();
    const mobilePhoneValidationResult = contactFormValidator.fields.mobile_phone.validate();
    const rolTypeContactIdValidation = contactFormValidator.fields.rol_type_contact_id.validate();
    const iheTypeValidation = contactFormValidator.fields.ihe_type.validate();

    //Pre-rendering treatments
    function formatRolTypeContactCombo(rawComboItems) {
        rawComboItems = rawComboItems || [];
        const formattedComboItems = [{ text: "", value: 0 }];
        rawComboItems.forEach(function (rawItem) {
            formattedComboItems.push({
                text: rawItem?.name,
                value: rawItem?.id,
                authorize_access_dossier_liaison: rawItem.authorize_access_dossier_liaison,
                required_rol_contact_permission: rawItem.required_rol_contact_permission,
            });
        });
        return formattedComboItems;
    }

    return (
        <Form className="contactFormContainer" onSubmit={onSubmitForm} noValidate>
            <Row className="noMargin">
                <PortalSection title="Informations obligatoires (*)" titleAlign="left" xs={12} md={6}>
                    <Row className="noMargin">
                        <Form.Group xs={12} md={6} as={Col} controlId="last_name">
                            {!!props.i18n("label.field.last_name") && <Form.Label>{props.i18n("label.field.last_name")}&nbsp;*</Form.Label>}
                            <PortalText
                                id="last_name"
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                value={contactForm.last_name}
                                placeholder={props.i18n("placeholder.field.last_name")}
                                onChange={(e) => setContactForm("last_name", e.target.value)}
                                onBlur={(e) => setContactForm("last_name", e.target.value.trim())}
                                isInvalid={!lastNameValidationResult.success && formValidationCount > 0}
                                aria-invalid={!lastNameValidationResult.success && formValidationCount > 0}
                                aria-describedby="last_name-1 last_name-2"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="last_name"
                                    msgList={lastNameValidationResult.errors}
                                    i18n={props.i18n}
                                    viewName={props.viewName} //used by i18n
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group xs={12} md={6} as={Col} controlId="first_name">
                            {!!props.i18n("label.field.first_name") && (
                                <Form.Label>{props.i18n("label.field.first_name")}&nbsp;*</Form.Label>
                            )}
                            <PortalText
                                id="first_name"
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                value={contactForm.first_name}
                                placeholder={props.i18n("placeholder.field.first_name")}
                                onChange={(e) => setContactForm("first_name", e.target.value)}
                                onBlur={(e) => setContactForm("first_name", e.target.value.trim())}
                                isInvalid={!firstNameValidationResult.success && formValidationCount > 0}
                                aria-invalid={!firstNameValidationResult.success && formValidationCount > 0}
                                aria-describedby="first_name-1 first_name-2"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="first_name"
                                    msgList={firstNameValidationResult.errors}
                                    i18n={props.i18n}
                                    viewName={props.viewName} //used by i18n
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group xs={12} md={6} as={Col} controlId="email">
                            {!!props.i18n("label.field.email") && <Form.Label>{props.i18n("label.field.email")}&nbsp;*</Form.Label>}
                            <PortalText
                                id="email"
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                value={contactForm.used_email}
                                placeholder={props.i18n("placeholder.field.email")}
                                onChange={(e) => setContactForm(["email", "used_email"], [e.target.value, e.target.value])}
                                onBlur={(e) => setContactForm(["email", "used_email"], [e.target.value.trim(), e.target.value.trim()])}
                                isInvalid={!emailValidationResult.success && formValidationCount > 0}
                                aria-invalid={!emailValidationResult.success && formValidationCount > 0}
                                aria-describedby="email-1 email-2"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="email"
                                    msgList={emailValidationResult.errors}
                                    i18n={props.i18n}
                                    viewName={props.viewName} //used by i18n
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group xs={12} md={6} as={Col} controlId="phone">
                            {!!props.i18n("label.field.phone") && <Form.Label>{props.i18n("label.field.phone")}&nbsp;*</Form.Label>}
                            <PortalPhone
                                id="phone"
                                placeholder={props.i18n("placeholder.field.phone")}
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                defaultValue={contactForm.used_phone}
                                onChange={(e, newValue) => setContactForm(["phone", "used_phone"], [newValue, newValue])}
                                isInvalid={!phoneValidationResult.success && formValidationCount > 0}
                                aria-invalid={!phoneValidationResult.success && formValidationCount > 0}
                                aria-describedby="phone-1 phone-2"
                            />
                            {contactType === "personal" && <span className="field_tip">{props.i18n("tip.field.phone")}</span>}
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="phone"
                                    msgList={phoneValidationResult.errors}
                                    i18n={props.i18n}
                                    viewName={props.viewName} //used by i18n
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group xs={12} md={6} as={Col} controlId="mobile_phone">
                            {!!props.i18n("label.field.mobile_phone") && (
                                <Form.Label>{props.i18n("label.field.mobile_phone")}&nbsp;*</Form.Label>
                            )}
                            <PortalPhone
                                id="mobile_phone"
                                placeholder={props.i18n("placeholder.field.mobile_phone")}
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                defaultValue={contactForm.mobile_phone}
                                onChange={onChangeMobilePhone}
                                isInvalid={!mobilePhoneValidationResult.success && formValidationCount > 0}
                                aria-invalid={!mobilePhoneValidationResult.success && formValidationCount > 0}
                                aria-describedby="mobile_phone-1 mobile_phone-2"
                            />
                            {contactType === "personal" && <span className="field_tip">{props.i18n("tip.field.phone")}</span>}
                            {formValidationCount > 0 ? (
                                <PortalFeedback fieldId="mobile_phone" msgList={mobilePhoneValidationResult.errors} i18n={props.i18n} />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        {contactType === "personal" && (
                            <Form.Group xs={12} md={6} as={Col} controlId="rol_type_contact_id" className="rol_type_contact">
                                {!!props.i18n("label.field.rol_type_contact_id") && (
                                    <Form.Label>{props.i18n("label.field.rol_type_contact_id")}&nbsp;*</Form.Label>
                                )}
                                <PortalCombo
                                    inputName="rol_type_contact_id"
                                    placeholder={props.i18n("placeholder.field.rol_type_contact_id")}
                                    activeActions={["upd"]}
                                    actionAllowed={actionAllowed}
                                    store={{
                                        fields: ["text", "value", "authorize_access_dossier_liaison", "required_rol_contact_permission"],
                                        data: formatRolTypeContactCombo(staticStore.rol_type_contact_id),
                                    }}
                                    valueField="value"
                                    displayField="text"
                                    defaultValue={parseIntWithoutNaN(contactForm?.rol_type_contact_id)}
                                    onChange={onSelectRolTypeContact}
                                    invalid={!rolTypeContactIdValidation.success && formValidationCount > 0}
                                    aria-invalid={!rolTypeContactIdValidation.success && formValidationCount > 0}
                                    aria-describedby="rol_type_contact_id-1 rol_type_contact_id-2"
                                    editable
                                />
                                {formValidationCount > 0 ? (
                                    <PortalFeedback
                                        fieldId="rol_type_contact_id"
                                        msgList={rolTypeContactIdValidation.errors}
                                        i18n={props.i18n}
                                    />
                                ) : (
                                    ""
                                )}
                            </Form.Group>
                        )}
                        {contactType === "personal" && (
                            <Form.Group xs={12} md={6} as={Col} controlId="ihe_type">
                                {!!props.i18n("label.field.ihe_type") && (
                                    <Form.Label>{props.i18n("label.field.ihe_type")}&nbsp;*</Form.Label>
                                )}
                                <PortalCombo
                                    inputName="ihe_type"
                                    placeholder={props.i18n("placeholder.field.ihe_type")}
                                    activeActions={["upd"]}
                                    actionAllowed={actionAllowed}
                                    items={formatComboItems(staticStore.ihe_type)}
                                    defaultValue={parseIntWithoutNaN(contactForm?.ihe_type)}
                                    onChange={(e) => setContactForm("ihe_type", e.value)}
                                    invalid={!iheTypeValidation.success && formValidationCount > 0}
                                    aria-invalid={!iheTypeValidation.success && formValidationCount > 0}
                                    aria-describedby="ihe_type-1 ihe_type-2"
                                    editable
                                />
                                {formValidationCount > 0 ? (
                                    <PortalFeedback fieldId="ihe_type" msgList={iheTypeValidation.errors} i18n={props.i18n} />
                                ) : (
                                    ""
                                )}
                            </Form.Group>
                        )}
                        {contactType === "personal" && (
                            <Form.Group xs={12} md={6} as={Col}>
                                <fieldset className="form-group mainContactSet">
                                    <legend className="form-label">{props.i18n("label.field.main_contact")}</legend>
                                    <Row className="radio-row mainContactRow">
                                        <PortalCheck
                                            inputName="mainContact"
                                            baseIcon="far fa-circle"
                                            activeIcon="far fa-dot-circle"
                                            text="Oui"
                                            textAlign="left"
                                            activeActions={["upd"]}
                                            actionAllowed={actionAllowed}
                                            active={!!+contactForm.main_contact}
                                            onChange={(active) => onChangeMainContact(true)}
                                        ></PortalCheck>
                                        <PortalCheck
                                            inputName="notMainContact"
                                            baseIcon="far fa-circle"
                                            activeIcon="far fa-dot-circle"
                                            text="Non"
                                            activeActions={["upd"]}
                                            actionAllowed={actionAllowed}
                                            active={!+contactForm.main_contact}
                                            onChange={(active) => onChangeMainContact(false)}
                                        ></PortalCheck>
                                    </Row>
                                </fieldset>
                            </Form.Group>
                        )}
                    </Row>
                </PortalSection>
                <PortalSection title="Coordonnées complémentaires" titleAlign="left" xs={12} md={6}>
                    <Row className="noMargin">
                        <Col xs={12} className="streetAddressContainer">
                            <Form.Group controlId="number">
                                {!!props.i18n("label.field.number") && <Form.Label>{props.i18n("label.field.number")}</Form.Label>}
                                <PortalText
                                    id="number"
                                    placeholder={props.i18n("placeholder.field.number")}
                                    activeActions={["upd", "upd_person"]}
                                    actionAllowed={actionAllowed}
                                    value={postalAddressData.number}
                                    onChange={(e) => setContactForm(postalAddressKey + ".number", e.target.value)}
                                    onBlur={(e) => setContactForm(postalAddressKey + ".number", e.target.value.trim())}
                                />
                            </Form.Group>
                            <Form.Group controlId="street_address" className="streetAddressGroup">
                                {!!props.i18n("label.field.street_address") && (
                                    <Form.Label>{props.i18n("label.field.street_address")}</Form.Label>
                                )}
                                <PortalText
                                    id="street_address"
                                    placeholder={props.i18n("placeholder.field.street_address")}
                                    activeActions={["upd", "upd_person"]}
                                    actionAllowed={actionAllowed}
                                    value={postalAddressData.street_address}
                                    onChange={(e) => setContactForm(postalAddressKey + ".street_address", e.target.value)}
                                    onBlur={(e) => setContactForm(postalAddressKey + ".street_address", e.target.value.trim())}
                                />
                            </Form.Group>
                        </Col>
                        <Form.Group xs={12} md={6} as={Col} controlId="city">
                            {!!props.i18n("label.field.city") && <Form.Label>{props.i18n("label.field.city")}</Form.Label>}
                            <PortalText
                                id="city"
                                placeholder={props.i18n("placeholder.field.city")}
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                value={postalAddressData.city}
                                onChange={(e) => setContactForm(postalAddressKey + ".city", e.target.value)}
                                onBlur={(e) => setContactForm(postalAddressKey + ".city", e.target.value.trim())}
                            />
                        </Form.Group>
                        <Form.Group xs={12} md={6} as={Col} controlId="postcode">
                            {!!props.i18n("label.field.postcode") && <Form.Label>{props.i18n("label.field.postcode")}</Form.Label>}
                            <PortalText
                                id="postcode"
                                placeholder={props.i18n("placeholder.field.postcode")}
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                value={postalAddressData.postcode}
                                onChange={(e) => setContactForm(postalAddressKey + ".postcode", e.target.value)}
                                onBlur={(e) => setContactForm(postalAddressKey + ".postcode", e.target.value.trim())}
                            />
                        </Form.Group>
                        <Form.Group xs={12} md={6} as={Col} controlId="par_country_id">
                            {!!props.i18n("label.field.country") && <Form.Label>{props.i18n("label.field.country")}</Form.Label>}
                            <PortalCombo
                                inputName="par_country_id"
                                placeholder={props.i18n("placeholder.field.country")}
                                activeActions={["upd", "upd_person"]}
                                actionAllowed={actionAllowed}
                                items={formatComboItems(staticStore.par_nationality_id)}
                                defaultValue={parseIntWithoutNaN(postalAddressData.par_country_id)}
                                onChange={(e) => setContactForm(postalAddressKey + ".par_country_id", e.value)}
                                editable
                            />
                        </Form.Group>
                    </Row>
                </PortalSection>
                {(contactForm.required_rol_contact_permission || contactForm.rol_contact_permission_status_treatment_id) && (
                    <ContactPermissionSection {...props} />
                )}
                {contactForm.authorize_access_dossier_liaison && <ContactAccessSection {...props} />}
            </Row>
            <ItemRow fill="stretch">
                <PortalBtn className="reverse" onClick={returnHome}>
                    Annuler
                </PortalBtn>
                <PortalBtn
                    className="validate"
                    type="submit"
                    displayActions={["dis"]}
                    activeActions={["upd"]}
                    actionAllowed={actionAllowed}
                    state={requestState}
                    disabled={formModificationCount < 1}
                >
                    Valider
                </PortalBtn>
            </ItemRow>
        </Form>
    );
}
