import React from "react";
import PortalText from "../portalText/portalText.js";

/*
Install : - La bibliothèque font-awesome doit être intallée dans le projet
Configs :
{    
    tooltip = STRING (opt) [''] => Le contenu du tooltip associé au champ
        # Pour afficher une brève description du contenu du champ    
    
    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input

    iconsLeft = ARRAY || STRING (opt) [''] => La liste des classes d'icones que l'on veut afficher avant la zone de saisie du champ
        # Si on n'a qu'un seul icône à insérer, pas besoin de le mettre dans une liste

    iconsRight = ARRAY || STRING (opt) [''] => La liste des classes d'icones que l'on veut afficher avant la zone de saisie du champ
        # Si on n'a qu'un seul icône à insérer, pas besoin de le mettre dans une liste

    displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
        # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

    activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'actif'
        # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

    actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
        # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
        # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
        # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'
}
*/

const PortalPassword = React.forwardRef(function (props, ref) {
    //Pre-rendering treatments
    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }

    const filteredProps = deleteProps("ref", "type", "className");
    return (
        <PortalText ref={ref} type="password" className={`password${props.className ? " " + props.className : ""}`} {...filteredProps} />
    );
});
export default PortalPassword;
