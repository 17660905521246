import { createSlice } from "@reduxjs/toolkit";
const cloneDeep = require("clone-deep");
/*
Action formats handled by this reducer : {type: 'private/<reducerName>', payload: <anything>}
    # <reducerName> : name of the reducers inside this slice (addview, removeView...)
    # <anything> : Any data, UI component want to pass to this action to modify the store
*/

//Store init values for this slice
function initialState() {
    let initialState = {
        adm_view: { client: [], user: [] },
        adm_menu: { client: null, user: null },
        default_view: null,
        logged_user: {
            adm_user_id: null,
            ent_entity_id: null,
            first_name: "",
            last_name: "",
        },
        attached_client: [],
        selected_client: {},
    };
    return initialState;
}

//Reducer treatments for this slice (portion) of the store
const privateSlice = createSlice({
    name: "private",
    initialState: initialState(),
    reducers: {
        // We can do mutating treatments here because createSlice handles the non-mutating state return itself
        setLoginData: function (privateState, action) {
            var data = action.payload;
            var newState = data;
            return newState;
        },
        resetData: function (privateState, action) {
            let newState = initialState();
            return newState;
        },
        changeSelectedClient: function (privateState, action) {
            var newClient = { ...action.payload };
            let newPrivateState = cloneDeep(privateState);
            newPrivateState.selected_client = newClient;
            return newPrivateState;
        },
    },
});

//Action generators for this slice
const resetPrivateData = privateSlice.actions.resetData;
const setLoginData = privateSlice.actions.setLoginData;
const changeSelectedClient = privateSlice.actions.changeSelectedClient;

//Selectors for this slice (portion) of the store
const selectPrivateHash = function (state) {
    const loggedEntEntity = state?.private?.logged_user?.ent_entity_id;
    const selectedEntEntity = state?.private?.selected_client?.ent_entity_id;
    if (selectedEntEntity && loggedEntEntity && loggedEntEntity != selectedEntEntity) {
        //On est dans le carnet de liaison
        return state?.private?.adm_view?.client || [];
    } else {
        return state?.private?.adm_view?.user || [];
    }
};
const selectLoggedUser = function (state) {
    return { ...state.private.logged_user };
};
const selectSelectedClient = function (state) {
    return { ...state.private.selected_client };
};
const selectAttachedClient = function (state) {
    return [...state.private.attached_client];
};
const selectMenu = function (state) {
    const loggedEntEntity = state?.private?.logged_user?.ent_entity_id;
    const selectedEntEntity = state?.private?.selected_client?.ent_entity_id;
    let menu = null;
    if (selectedEntEntity && loggedEntEntity && loggedEntEntity != selectedEntEntity) {
        //On est dans le carnet de liaison
        menu = state?.private?.adm_menu?.client || null;
    } else {
        menu = state?.private?.adm_menu?.user || null;
    }
    return cloneDeep(menu);
};
const selectSSoDisconnect = function (state) {
    return state.private.sso_disconnect;
};

//Exports for further use in UI components
export {
    resetPrivateData,
    setLoginData,
    changeSelectedClient,
    selectPrivateHash,
    selectLoggedUser,
    selectSelectedClient,
    selectAttachedClient,
    selectMenu,
    selectSSoDisconnect,
};
export default privateSlice.reducer;
