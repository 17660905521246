import React from "react";
import { useEffect } from "react";
export default function Health(props) {
    const url = "https://www.monespacesante.fr/";
    useEffect(function () {
        props
            .openExternal({
                url: url,
                msgBoxConfig: {
                    className: "monespacesante",
                },
            })
            .catch(function (e) {})
            .finally(function () {
                props.redirectTo("home", null, true);
            });
    }, []);

    return "";
}
