//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
import React from "react";
import { useState, useEffect } from "react";
import cloneDeep from "clone-deep";
//----------------------------------------------------//
//---------- UI Components ----------//
//Custom UI Components
import DesktopScreen from "./screens/base/desktop/screen.js";
//-----------------------------------//
export default function Controller(props) {
    //Specific view states
    const [isEdited, setIsEdited] = useState(false);
    function initFormEditProfile() {
        return {
            coordinates: {},
            address: {},
        };
    }
    const [formEditProfile, setFormEditProfile] = useState(initFormEditProfile());
    //Specific view effects
    useEffect(function () {
        //props.init();//Infinite loop
    }, []);

    //Here to write views communication actions (post, ...) and post data sates (editForm ...)
    //They are common to every screen

    function editProfile(section, field, value) {
        // Exemple d'utilisation : editProfile('coordinates', 'phone', '0645645643') => form['coordinates']['phone'] = "0645645643";
        // Exemple d'utilisation : editProfile('address', 'city', 'Morzine') => form['address']['city'] = "Morzine";
        setIsEdited(true);
        let newFormEditProfile = cloneDeep(formEditProfile);
        newFormEditProfile[section][field] = value;
        setFormEditProfile(newFormEditProfile);
    }

    function postUpdFileData(event) {
        if (typeof event?.preventDefault === "function") event.preventDefault();
        return new Promise(function (rs, rj) {
            console.log(formEditProfile);
            props.setRequestState("loading");
            props
                .request({
                    action: "post_client_file_data",
                    data: formEditProfile,
                })
                .then(function (response) {
                    const msg = props.i18n("msg.success_account_modif_request");
                    props.msgBox(msg, "info", "msg").then(function (res) {
                        setIsEdited(false);
                        setFormEditProfile(initFormEditProfile());
                        return rs(response);
                    });
                    //console.log(response);
                })
                .catch(function (error) {
                    console.error("Erreur lors de l'enregistrement des nouvelles données du bénéficiaire (post_client_file_data)");
                    console.error(error.msg);
                    props.msgBox("Échec de la mise à jour de vos informations : " + error.msg, "error", "msg");
                    return rj(error);
                })
                .finally(() => props.setRequestState(""));
        });
    }
    return <DesktopScreen {...props} postUpdFileData={postUpdFileData} isEdited={isEdited} editProfile={editProfile} />;
}
