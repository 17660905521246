import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalTitle from "../../../../../../../ressources/components/portalTitle/portalTitle.js";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalFeedback from "../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback.js";
import PortalPhone from "../../../../../../../ressources/components/portalFields/portalPhone/portalPhone.js";
import PortalCombo from "../../../../../../../ressources/components/portalFields/portalCombo/portalCombo.js";
import ImgBackground from "../../../../../../../ressources/components/imgBackground/imgBackground.js";
export default function Screen(props) {
    const formData = props.formData;
    const setFormData = props.setFormData;
    const formValidator = props.formValidator;
    const postData = props.postData;
    const formValidationCount = props.formValidationCount;
    const requestState = props.requestState;
    const legalContent = props.store.legal_content;
    const objectCombo = props.store.object_combo || [];
    //UX event handlers
    function onSubmitForm(e) {
        e.preventDefault();
        postData();
    }
    //Pre-rendering treatments
    const formValidation = formValidator.validate();
    function buildObjectCombo() {
        const myObjectCombo = [{ value: "", text: "" }];
        objectCombo.forEach(function (comboItem) {
            myObjectCombo.push({
                value: comboItem.name,
                text: comboItem.name,
            });
        });
        return myObjectCombo;
    }
    //console.log(formValidation);
    return (
        <>
            {props.setStyle("./base/desktop")}
            <div className="contentContainer">
                <div className="titlePart">
                    <PortalTitle title={props.i18n("label.view.contact_platform")} subtitle={props.i18n("info.process_description")} />
                    <ImgBackground className="contactLogo" />
                </div>
                <Form className="contactFormContainer" onSubmit={onSubmitForm} noValidate>
                    {!!props.i18n("label.field.form_filling_instructions") && (
                        <Form.Group>
                            <h2>Merci de renseigner les champs ci-dessous :</h2>
                        </Form.Group>
                    )}
                    {!!props.i18n("label.field.mandatory_fields") && (
                        <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                    )}
                    <Row>
                        <Form.Group as={Col} xs={12} md={6} controlId="last_name">
                            {!!props.i18n("label.field.last_name") && (
                                <Form.Label>
                                    {props.i18n("label.field.last_name")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="last_name"
                                onChange={(e) => setFormData("last_name", e.target.value)}
                                type="text"
                                name="lastName"
                                placeholder={props.i18n("placeholder.field.last_name")}
                                value={formData.last_name}
                                autoFocus
                                required={true}
                                aria-required={true}
                                isInvalid={!formValidator.fields.last_name.validationState.success && formValidationCount > 0}
                                aria-invalid={!formValidator.fields.last_name.validationState.success && formValidationCount > 0}
                                aria-describedby="lastName-1"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="lastName"
                                    msgList={formValidator.fields.last_name.validationState.errors}
                                    i18n={props.i18n}
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={6} controlId="first_name">
                            {!!props.i18n("label.field.first_name") && (
                                <Form.Label>
                                    {props.i18n("label.field.first_name")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="first_name"
                                onChange={(e) => setFormData("first_name", e.target.value)}
                                type="text"
                                name="firstName"
                                placeholder={props.i18n("placeholder.field.first_name")}
                                value={formData.first_name}
                                required={true}
                                aria-required={true}
                                isInvalid={!formValidator.fields.first_name.validationState.success && formValidationCount > 0}
                                aria-invalid={!formValidator.fields.first_name.validationState.success && formValidationCount > 0}
                                aria-describedby="firstName-1"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="firstName"
                                    msgList={formValidator.fields.first_name.validationState.errors}
                                    i18n={props.i18n}
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={6} controlId="email">
                            {!!props.i18n("label.field.email") && (
                                <Form.Label>
                                    {props.i18n("label.field.email")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="email"
                                onChange={(e) => setFormData("email", e.target.value)}
                                type="email"
                                name="email"
                                iconsLeft="far fa-envelope"
                                placeholder={props.i18n("placeholder.field.email")}
                                value={formData.email}
                                required={true}
                                aria-required={true}
                                isInvalid={!formValidator.fields.email.validationState.success && formValidationCount > 0}
                                aria-invalid={!formValidator.fields.email.validationState.success && formValidationCount > 0}
                                aria-describedby="email-1 email-2"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="email"
                                    msgList={formValidator.fields.email.validationState.errors}
                                    i18n={props.i18n}
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={6} controlId="mobilePhone">
                            {!!props.i18n("label.field.mobile_phone") && (
                                <Form.Label>
                                    {props.i18n("label.field.mobile_phone")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalPhone
                                id="mobilePhone"
                                onChange={(e, value) => setFormData("mobile_phone", value)}
                                name="mobilePhone"
                                placeholder={props.i18n("placeholder.field.mobile_phone")}
                                value={formData.mobile_phone}
                                required={true}
                                aria-required={true}
                                isInvalid={!formValidator.fields.mobile_phone.validationState.success && formValidationCount > 0}
                                aria-invalid={!formValidator.fields.mobile_phone.validationState.success && formValidationCount > 0}
                                aria-describedby="mobilePhone-1 mobilePhone-2"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="mobilePhone"
                                    msgList={formValidator.fields.mobile_phone.validationState.errors}
                                    i18n={props.i18n}
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={12} controlId="object">
                            {!!props.i18n("label.field.message_object") && (
                                <Form.Label>
                                    {props.i18n("label.field.message_object")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalCombo
                                inputName="object"
                                items={buildObjectCombo()}
                                value={buildObjectCombo()[0]}
                                onChange={function (event) {
                                    console.log(event);
                                    setFormData("object", event.value);
                                }}
                                placeholder={props.i18n("placeholder.field.message_object")}
                                required={true}
                                invalid={!formValidator.fields.object.validationState.success && formValidationCount > 0}
                                aria-describedby="object-1"
                                editable
                            />
                            {/*<PortalText
                                onChange={(e) => setFormData("object", e.target.value)}
                                type="text"
                                name="object"
                                placeholder={props.i18n("placeholder.field.message_object")}
                                value={formData.object}
                                required={true}
                                aria-required={true}
                                isInvalid={!formValidator.fields.object.validationState.success && formValidationCount > 0}
                                aria-invalid={!formValidator.fields.object.validationState.success && formValidationCount > 0}
                                aria-describedby="object-1"
                            />*/}
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="object"
                                    msgList={formValidator.fields.object.validationState.errors}
                                    i18n={props.i18n}
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group as={Col} xs={12} controlId="content">
                            {!!props.i18n("label.field.message_content") && (
                                <Form.Label>
                                    {props.i18n("label.field.message_content")}&nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                            )}
                            <PortalText
                                id="content"
                                onChange={(e) => setFormData("content", e.target.value)}
                                type="text"
                                as="textarea"
                                name="content"
                                placeholder={props.i18n("placeholder.field.message_content")}
                                value={formData.content}
                                required={true}
                                aria-required={true}
                                isInvalid={!formValidator.fields.content.validationState.success && formValidationCount > 0}
                                aria-invalid={!formValidator.fields.content.validationState.success && formValidationCount > 0}
                                aria-describedby="content-1"
                            />
                            {formValidationCount > 0 ? (
                                <PortalFeedback
                                    fieldId="content"
                                    msgList={formValidator.fields.content.validationState.errors}
                                    i18n={props.i18n}
                                />
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <div className="sendRequestContainer">
                            <PortalBtn
                                state={requestState}
                                className="sendRequestBtn"
                                type="submit"
                                iconCls="far fa-location-arrow"
                                iconAlign="right"
                            >
                                {props.i18n("label.action.send_request")}
                            </PortalBtn>
                        </div>
                    </Row>
                </Form>
            </div>
            <div className="legalInfoContainer" dangerouslySetInnerHTML={{ __html: legalContent }}></div>
        </>
    );
}
