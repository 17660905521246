import TileView from "../../../../../../ressources/components/tileView/tileView.js";
import { useEffect } from "react";
import { isArray, isObject } from "../../../../../../ressources/lib/js/globalFunctions.js";
import React from "react";
function Screen(props) {
    //local static variables
    const menuHome = isObject(props?.menu?.menu_home) ? props.menu.menu_home : {};
    //console.log("menuHome : ", menuHome);
    //Effects
    let viewHash = props.viewHash;
    useEffect(
        function () {
            if (props.viewHash === "home") {
                props.updateUserJourney(["home"]);
            } else {
                props.updateUserJourney(["home", props.viewHash]);
            }
        },
        [viewHash]
    );
    function itemClick(event) {
        props.redirectTo(event.target.value);
    }
    function buildTileItems(viewList) {
        if (!isArray(viewList)) viewList = [];
        const tileItemList = [];
        let tileItem, viewDirection;
        viewList.forEach(function (viewItem) {
            viewDirection = viewItem?.direction || "";
            tileItem = {
                direction: viewDirection,
                description: props.i18n("info.view." + viewDirection),
                title: props.i18n("label.view." + viewDirection),
                icon: props.i18n("icon.view." + viewDirection + "_icon"),
            };
            tileItemList.push(tileItem);
        });
        return tileItemList;
    }
    return (
        <>
            <TileView items={buildTileItems(menuHome[props.viewHash])} onClick={itemClick} className="homeView" />
        </>
    );
}

export default Screen;
