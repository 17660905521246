import React from "react";
import PortalCombo from "../../../../../ressources/components/portalFields/portalCombo/portalCombo";
import "./spaceSelector.css";
import { formatName } from "../../../../../ressources/lib/js/globalFunctions";
export default function SpaceSelector(props) {
    /**
     * @param props {Object} [{}]: {
     *
     *      @param {Object} userSpace [{}] : {
     *          @param {Int} adm_user_id : L'adm_user_id de l'espace de la personne connectée
     *
     *          @param {Int} ent_entity_id : L'ent_entity_id de la personne connectée
     *
     *          @param {String} first_name [''] : Le prénom de l'espace de la personne connectée
     *
     *          @param {String} last_name [''] : Le nom de famille de l'espace de la personne connectée
     *      }
     *
     *      @param {Object[]} clientSpaces [[]] : [{
     *          @desc Contenu d'un objet d'espace tel que décrit dans le param @param userSpace
     *      }, {...}, ...]
     *
     *      @callback onChange(selectedSpace) [emptyFctn]
     *          @descr Fonction déclenchée à chaque fois que l'utilisateur sélectionne un nouvel espace
     *
     *          @param {Object} selectedSpace : Contenu d'un objet d'espace tel que décrit dans le param @param userSpace
     *
     * }
     */

    const userSpace = props.userSpace || {};
    const clientSpaces = props.clientSpaces || [];

    if (clientSpaces.length < 1) {
        return (
            <div className="spaceSelectorContainer">{formatName({ first_name: userSpace.first_name, last_name: userSpace.last_name })}</div>
        );
    }

    function buildSpaceSelectionList() {
        let spaceSelectionList = [];
        if (clientSpaces && typeof (clientSpaces === "object") && clientSpaces.length > 0) {
            spaceSelectionList.push({
                value: userSpace.ent_entity_id,
                text: formatName({ first_name: userSpace.first_name, last_name: userSpace.last_name }) + " - Votre espace",
                client: userSpace,
                //action: "changeClient",
            });
            clientSpaces.forEach(function (client) {
                spaceSelectionList.push({
                    value: client.ent_entity_id,
                    text: formatName({ first_name: client.first_name, last_name: client.last_name }) + " - Bénéficiaire",
                    client: client,
                    //action: "changeClient",
                });
            });
        }
        return spaceSelectionList;
    }

    function onChangeSpace(event) {
        if (typeof props.onChange === "function") {
            const itemData = event?.source?.record?.data;
            //console.log(itemData);
            if (itemData) {
                props.onChange(itemData.client);
            }
        }
    }
    return (
        <div className="spaceSelectorContainer">
            <label for="spaceSelector">Choix aidant / bénéficiaire</label>
            <PortalCombo
                id="spaceSelector"
                inputName="spaceSelector"
                aria-label="Sélecteur d'espace"
                className="spaceSelector"
                items={buildSpaceSelectionList()}
                defaultValue={userSpace.ent_entity_id}
                onChange={onChangeSpace}
                editable={false}
            />
        </div>
    );
}
