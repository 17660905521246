import React, { useRef, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import "./tileView.css";
function TileView(props) {
    /*
        {
            items:[{
                direction : 'viewHash',
                description : 'ToDelete',
                title: 'i18n(this.direction)',
                icon: 'i18n(this.direction_icon)'
            }]
        }
    
    */
    let focusRef = useRef();
    //console.warn(props);
    useEffect(function () {
        if (focusRef && focusRef.current) {
            focusRef.current.focus();
        }
    }, []);

    function findColor(itemIndex, colorRange) {
        for (let i = 1; i <= colorRange; i++) {
            if (itemIndex % colorRange === i - 1) {
                return i;
            }
        }
    }

    function handleClick(direction) {
        if (props.onClick && typeof props.onClick === "function") {
            props.onClick({
                target: { value: direction },
            });
        }
    }

    let itemDisplay = "";
    if (props.items) {
        let color = "";
        itemDisplay = [];
        let focusItem;
        props.items.map(function (item, index) {
            color = "color" + findColor(index, 4);
            if (index === 0) {
                //On focus toujours sur le 1er élément de la liste de tuiles
                focusItem = focusRef;
            } else {
                focusItem = null;
            }
            if (props.itemRender && typeof props.itemRender === "function") {
                itemDisplay.push(
                    <div className={"hippoTileItemContainer " + color} key={index}>
                        {props.itemRender(item)}
                    </div>
                );
            } else {
                itemDisplay.push(
                    <Button
                        className={"hippoTileItemContainer " + color}
                        key={index}
                        tabIndex={0}
                        onClick={() => handleClick(item.direction)}
                        ref={focusItem}
                        aria-describedby={`hippoTileItemTitle-${index + 1} hippoTileItemDescr-${index + 1}`}
                        role="button"
                    >
                        <span className={`icon${item.icon ? " " + item.icon : ""}`}></span>

                        <h3 className="title" id={`hippoTileItemTitle-${index + 1}`}>
                            {item.title}
                        </h3>
                    </Button>
                );
            }
            return null;
        });
    }

    return (
        <div className={"hippoTileListContainer" + (props.className ? " " + props.className : "")}>
            <div className="hippoTileListContent">{itemDisplay}</div>
        </div>
    );
}

export default TileView;
