import React from "react";
//import { useState } from "react";
import { Col } from "react-bootstrap";
import "./portalSection.css";

/*
Configs : 
{
    Toutes les propriété d'un composant <Col /> de bootstrap 

    title : STRING (opt) [''] => Titre de la section

    titleAlign : STRING (opt) ['left'] => Alignement du titre de la section

*/
export default function PortalSection(props) {
    const titleAlignValues = ["left", "center", "right"];
    const titleAlign = titleAlignValues.filter((value) => props.titleAlign === value)[0] || "";

    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    const filteredProps = deleteProps("title", "titleAlign", "children", "className");
    return (
        <Col className={"portalSectionContainer" + (props.className ? " " + props.className : "")} {...filteredProps}>
            <div className="portalSection">
                {props.title ? <h4 className={"portalSectionTitle" + (titleAlign ? " " + titleAlign : "")}>{props.title}</h4> : ""}
                {props.children}
            </div>
        </Col>
    );
}
