import React from "react";
import PropTypes from "prop-types";
import List from "devextreme-react/list";
import { Col, Row } from "react-bootstrap";
import PortalBtn from "../../../../../ressources/components/portalBtn/portalBtn.js";
import BtnToolTip from "../../../../../ressources/components/btnToolTip/btnToolTip.js";
PecList.propTypes = {
    dataSource: PropTypes.array,
    onClickCesu: PropTypes.func,
    isActive: PropTypes.bool,
};
export default function PecList(props) {
    const isActive = !!props.isActive;
    //Event UI functions
    function downloadPec(pecId) {
        props
            .request({
                type: "ajaxGetFile",
                action: "download_pec",
                data: {
                    pec_id: pecId,
                },
            })
            .then(function (response) {})
            .catch(function (error) {
                props.msgBox(error.msg, "error", "msg");
            });
    }

    // pre-rendering treatments
    function renderPecItem(content) {
        const hasDJF = parseFloat(content.quantity_djf) > 0;
        const displayPriceHelp = content.price_help !== null;
        return (
            <Row className="itemPecContainer portalRow">
                <Col xs={12} md={3} className="itemPecCol presta">
                    <div className="itemPecRow titleRow">
                        <h3 className="header">{content.prestation}</h3>
                        <PortalBtn className="reverse rowBtn" iconCls="fal fa-download" onClick={() => downloadPec(content?.id)}>
                            Télécharger
                        </PortalBtn>
                        {typeof props.onClickCesu === "function" && content.has_cesu ? (
                            <PortalBtn className="reverse rowBtn" onClick={() => props.onClickCesu(content, isActive)}>
                                Déclaration CESU
                            </PortalBtn>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="itemPecRow last">
                        <div className={"statusContainer " + content.par_status_treatment}>
                            <div className="led"></div>
                            <p className="content">
                                {content.par_status_treatment.slice(0, 1).toUpperCase() + content.par_status_treatment.slice(1)}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={3} className="itemPecCol str">
                    {/*<span className="itemPecRow"><h1 className='header'>{content.payer_organization_name}</h1></span>*/}
                    <span className="itemPecRow">
                        <h4 className="header">{content.organization_name}</h4>
                    </span>
                    <span className="itemPecRow">
                        <p className="content soft">{content.formatted_address}</p>
                    </span>
                    <span className="itemPecRow last">
                        <p className="content">
                            {content.email}
                            <br />
                            {content.phone}
                        </p>
                    </span>
                </Col>
                <Col xs={12} md={3} className="itemPecCol">
                    <span className="itemPecRow product_name_short">
                        <h2 className="label">Nature&nbsp;:</h2>&nbsp;<p className="content">{content.product_name_short}</p>
                    </span>
                    <span className="itemPecRow">
                        <h2 className="label">N°&nbsp;:</h2>&nbsp;<p className="content">{content.id}</p>
                    </span>
                    <span className="itemPecRow gir">
                        <h2 className="label">GIR&nbsp;:</h2>&nbsp;
                        <p className="content">{!!content.gir ? content.gir : "N.R."}</p>
                    </span>
                    <span className="itemPecRow created_at">
                        <h2 className="label">Notifié le&nbsp;:</h2>&nbsp;
                        <p className="content">{new Date(content.created_at).toLocaleDateString("fr-FR")}</p>
                    </span>
                    <span className="itemPecRow">
                        <h2 className="label">Début&nbsp;:</h2>&nbsp;
                        <p className="content">{new Date(content.start_date).toLocaleDateString("fr-FR")}</p>
                    </span>
                    <span className="itemPecRow last">
                        <h2 className="label">Fin&nbsp;:</h2>&nbsp;
                        <p className="content">{new Date(content.end_date).toLocaleDateString("fr-FR")}</p>
                    </span>
                </Col>
                <Col xs={12} md={3} className="itemPecCol">
                    {content.quantity !== null ? (
                        <span className="itemPecRow">
                            <h2 className="label">Quantité accordée&nbsp;:</h2>&nbsp;
                            <p className="content">
                                {parseFloat(content.quantity) + " " + content.pro_type_unit_name}
                                {hasDJF ? (
                                    <p className="subcontent">{`${parseFloat(content.quantity_jo)} ${
                                        content.pro_type_unit_name
                                    } JO / ${parseFloat(content.quantity_djf)} ${content.pro_type_unit_name} DJF`}</p>
                                ) : (
                                    ""
                                )}
                            </p>
                        </span>
                    ) : (
                        ""
                    )}
                    {displayPriceHelp ? (
                        <span className="itemPecRow">
                            <h2 className="label">{content.inv_type_rate_name || "Aide attribuée"}&nbsp;:</h2>&nbsp;
                            <p className="content">{parseFloat(content.price_help) + " €/heure"}</p>
                        </span>
                    ) : (
                        ""
                    )}
                    <span className={`itemPecRow${content.amount_max_djf == 0 ? " last" : ""}`}>
                        <h2 className="label">Financement maximum{hasDJF ? " JO" : ""}&nbsp;:</h2>&nbsp;
                        <p className="content">{parseFloat(content.amount_max) + " €"}</p>
                    </span>
                    {hasDJF ? (
                        <span className="itemPecRow last">
                            <h2 className="label">Financement maximum DJF&nbsp;:</h2>&nbsp;
                            <p className="content">{parseFloat(content.amount_max_djf) + " €"}</p>
                        </span>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
        );
    }
    //rendering part
    return (
        <div className={`pecContainer${isActive ? " active" : " inactive"}${props.className ? " " + props.className : ""}`}>
            <Row className="pecHeader">
                <Col xs={12} md={3} className="itemPecHeader">
                    <h2>Prestation</h2>
                </Col>
                <Col xs={12} md={3} className="itemPecHeader">
                    <h2>Structure</h2>
                </Col>
                <Col xs={12} md={3} className="itemPecHeader">
                    <h2>Plan d'aide</h2>
                </Col>
                <Col xs={12} md={3} className="itemPecHeader">
                    <h2>Détails de la prise en charge (par mois)</h2>
                </Col>
            </Row>
            <List dataSource={props.dataSource || []} itemRender={renderPecItem} tabIndex={-1} />
        </div>
    );
}
