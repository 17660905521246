import React from "react";
import { useState, useEffect } from "react";
import ContextMenu from "../../../../../ressources/components/contextMenu/contexMenu";
import { isDate, findAbsolutePosition } from "../../../../../ressources/lib/js/globalFunctions";
export default function MsgItem({ msgData, senderAlign, displaySendingDate, loggedUser, onCancelled, onSend }) {
    senderAlign = senderAlign === "left" ? "left" : "right";
    const otherAlign = senderAlign === "left" ? "right" : "left";
    const postingState = msgData.posting_state;
    const [contextMenuTrigger, setContextMenuTrigger] = useState(false);
    const [contexMenuCloseTrigger, setContextMenuCloseTrigger] = useState(false);
    const [maskVisibility, setMaskVisibility] = useState("");
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [contextMenuOptions, setContextMenuOptions] = useState([]);

    //helper functions
    function isOnError() {
        return postingState === "error" ? " onError" : "";
    }

    function isInteractable() {
        return contextMenuOptions.length > 0 ? " interactable" : "";
    }
    function displayNotified(notificationDate) {
        return notificationDate ? " / Notifié" : "";
    }

    //Effects functions
    function buildMenuOptionConfig() {
        const menuOptions = [];
        switch (true) {
            case postingState === "error":
                menuOptions.push({
                    text: "Renvoyer",
                    iconCls: "fas fa-filter",
                    onClick: function (optionConfig) {
                        setContextMenuCloseTrigger(!contexMenuCloseTrigger);
                        onSend(msgData);
                    },
                });
                menuOptions.push({
                    text: "Annuler l'envoi",
                    iconCls: "fas fa-filter",
                    onClick: function (optionConfig) {
                        onCancelled(msgData);
                    },
                });
                break;
            case !postingState:
                /*for (let i = 1; i <= 5; i++) {
                    menuOptions.push({
                        text: "Option-" + i,
                        iconCls: "fas fa-filter",
                        onClick: function (optionConfig) {
                            console.log(optionConfig);
                        },
                    });
                }*/
                break;
            default:
                break;
        }
        setContextMenuOptions(menuOptions);
    }
    useEffect(buildMenuOptionConfig, [postingState]);
    //UX handler functions
    function onMsgOption(event) {
        if (isInteractable()) {
            event.preventDefault();
            const clientX = event?.clientX || findAbsolutePosition(event?.target)["x"] || 0;
            const clientY = event?.clientY || findAbsolutePosition(event?.target)["y"] || 0;
            setContextMenuX(clientX);
            setContextMenuY(clientY);
            setContextMenuTrigger(!contextMenuTrigger);
        }
    }
    function onMouseDown(event) {
        if (isInteractable()) {
            setMaskVisibility(" opaque");
        }
    }
    //UI Rendering functions
    function setDisplayAuthor(msgData) {
        const authorDisplayName = msgData?.created_by_entity_name || msgData?.created_by_username || "Inconnu";
        const authorDisplayStatus = msgData?.created_by_status ? " (" + msgData.created_by_status + ")" : "";
        return msgData?.created_by == loggedUser?.adm_user_id ? "Vous" : authorDisplayName + authorDisplayStatus;
    }
    function msgStatusRender() {
        switch (true) {
            case postingState == "loading":
                return (
                    <>
                        <span className="fas fa-spinner rotate"></span>&nbsp;Envoi en cours
                    </>
                );

            case postingState == "error":
                return "Echec de l'envoi";
            default:
                return displaySendingDate(msgData?.send_at) + displayNotified(msgData?.notified_at);
        }
    }
    function setMsgAlign(msgData) {
        //TODO: Se baser sur l'ent_entity_id
        return loggedUser?.adm_user_id == msgData?.created_by ? senderAlign : otherAlign; //Math.round(Math.random()) > 0 ? "right" : "left";
    }
    return (
        <>
            <ContextMenu
                openTrigger={contextMenuTrigger}
                closeTrigger={contexMenuCloseTrigger}
                autoClose={true}
                xPos={contextMenuX}
                yPos={contextMenuY}
                headerTxt=""
                optionConfigs={contextMenuOptions}
            />
            <div
                className={`msg-item-container ${setMsgAlign(msgData)}${isInteractable()}${maskVisibility}${isOnError()}`}
                onContextMenu={onMsgOption}
                onMouseDown={onMouseDown}
                onMouseUp={(e) => setMaskVisibility("")}
            >
                <div className="msg-item-header">
                    <h1 className="txt">{setDisplayAuthor(msgData)}</h1>
                    {isInteractable() ? (
                        <span className="icon-container">
                            <button className="fas fa-cog icon" onClick={onMsgOption}></button>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <div className="msg-item-content" dangerouslySetInnerHTML={{ __html: msgData?.message || "" }}></div>
                <div className="msg-item-footer">{msgStatusRender()}</div>
            </div>
        </>
    );
}
