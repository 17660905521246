import React from "react";
import { useState, useEffect } from "react";
import PortalTitle from "../../../../../../../ressources/components/portalTitle/portalTitle";
import SetPasswordForm from "./components/setPasswordForm";
import SuccessPwdSet from "./components/successPwdSet";
export default function Screen(props) {
    const viewTitle = props.viewTitle;
    const postData = props.postData;
    const setViewLayoutData = props.setViewLayoutData;
    const [step, setStep] = useState("setPasswordForm"); //"setPasswordForm" || "successPwdSet"

    //Effect treatments
    useEffect(function () {
        setViewLayoutData("title", viewTitle);
    }, []);
    //UX event handlers
    function onSubmitForm(e) {
        e.preventDefault();
        postData()
            .then(function (res) {
                setStep("successPwdSet");
            })
            .catch(function (err) {
                props
                    .msgBox(err.msg, "error", "msg")
                    .then(function () {})
                    .catch(function () {});
            });
    }
    function renderScreen() {
        switch (step) {
            case "successPwdSet":
                return <SuccessPwdSet {...props} />;
            default:
                return <SetPasswordForm {...props} onSubmitForm={onSubmitForm} />;
        }
    }

    return (
        <div className="contentContainer">
            {props.setStyle("./v_yvelines/desktop")}
            {/*<PortalTitle title={viewTitle} subtitle={props.i18n("info.process_description")} />*/}
            {renderScreen()}
        </div>
    );
}
