//######################################## Imports ########################################//
//External
import React from "react";
import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom
import BubbleView from "../../../../../../ressources/components/bubbleView/bubbleView";
import PortalCheck from "../../../../../../ressources/components/portalFields/portalCheck/portalCheck";
import PortalPassword from "../../../../../../ressources/components/portalFields/portalPassword/portalPassword.js";
import PortalText from "../../../../../../ressources/components/portalFields/portalText/portalText.js";
import Fconnect from "../../../../../../ressources/components/fconnect/fconnect.js";
import PortalBtn from "../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalFeedback from "../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
//#########################################################################################//
export default function Screen(props) {
    const step = props.step;
    const formData = props.formData;
    const formValidator = props.formValidator;
    const formValidationCount = props.formValidationCount;
    const setFormData = props.setFormData;
    const postRegister = props.postRegister;
    const requestState = props.requestState;
    const registeredUsername = props.registeredUsername;
    const openCgu = props.openCgu;
    const fConnect = props.fConnect;

    const [rawDate, setRawDate] = useState(formData.birth_datetime);
    useEffect(
        function () {
            if (rawDate) {
                setFormData("birth_datetime", rawDate);
            }
        },
        [rawDate]
    );
    if (!formData) return "";

    const lastNameValidationResult = formValidator.fields.last_name.validate();
    const firstNameValidationResult = formValidator.fields.first_name.validate();
    const emailValidationResult = formValidator.fields.username.validate();
    const dossierValidationResult = formValidator.fields.referencedossier.validate();
    const birthValidationResult = formValidator.fields.birth_datetime.validate();
    const cguValidationResult = formValidator.fields.cgu.validate();
    function onSubmitForm(event) {
        event.preventDefault();
        if (typeof postRegister === "function") postRegister();
    }
    //Rendering treatments
    let registerContent = (
        <Form id="registerForm" onSubmit={onSubmitForm} noValidate>
            <Form.Group>
                <h4>Merci de renseigner les champs ci-dessous :</h4>
            </Form.Group>
            <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
            <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="lastName">
                    <Form.Label>
                        Nom <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalText
                        id="lastName"
                        onChange={(e) => setFormData("last_name", e.target.value)}
                        type="text"
                        autoComplete="family-name"
                        name="lastName"
                        value={formData.last_name}
                        autoFocus
                        required={true}
                        aria-required={true}
                        isInvalid={!lastNameValidationResult.success && formValidationCount > 0}
                        aria-invalid={!lastNameValidationResult.success && formValidationCount > 0}
                        aria-describedby="lastName-1"
                    />
                    {formValidationCount > 0 ? (
                        <PortalFeedback fieldId="lastName" msgList={lastNameValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} controlId="firstName">
                    <Form.Label>
                        Prénom <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalText
                        id="firstName"
                        onChange={(e) => setFormData("first_name", e.target.value)}
                        type="text"
                        autoComplete="given-name"
                        name="firstName"
                        value={formData.first_name}
                        required={true}
                        aria-required={true}
                        isInvalid={!firstNameValidationResult.success && formValidationCount > 0}
                        aria-invalid={!firstNameValidationResult.success && formValidationCount > 0}
                        aria-describedby="firstName-1"
                    />
                    {formValidationCount > 0 ? (
                        <PortalFeedback fieldId="firstName" msgList={firstNameValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="fileNumber">
                    <Form.Label>
                        {props.i18n("label.field.file_number")} <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalText
                        id="fileNumber"
                        onChange={(e) => setFormData("referencedossier", e.target.value)}
                        type="text"
                        tooltip={props.i18n("info.field.file_number")}
                        name="fileNumber"
                        value={formData.referencedossier}
                        aria-required={true}
                        required={true}
                        isInvalid={!dossierValidationResult.success && formValidationCount > 0}
                        aria-invalid={!dossierValidationResult.success && formValidationCount > 0}
                        aria-describedby="fileNumber-1"
                    />
                    {formValidationCount > 0 ? (
                        <PortalFeedback fieldId="fileNumber" msgList={dossierValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} controlId="birthDate">
                    <Form.Label>
                        Date de naissance <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalText
                        id="birthDate"
                        onChange={(e) => setRawDate(e.target.value)}
                        type="date"
                        autoComplete="bday"
                        name="birthDate"
                        value={rawDate}
                        required={true}
                        aria-required={true}
                        isInvalid={!birthValidationResult.success && formValidationCount > 0}
                        aria-invalid={!birthValidationResult.success && formValidationCount > 0}
                        aria-describedby="birthDate-1"
                    />
                    {formValidationCount > 0 ? (
                        <PortalFeedback fieldId="birthDate" msgList={birthValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="username">
                    <Form.Label>
                        {props.i18n("label.field.username")} <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalText
                        id="username"
                        onChange={(e) => setFormData("username", e.target.value)}
                        type="email"
                        iconsLeft="far fa-envelope"
                        autoComplete="email"
                        name="username"
                        value={formData.username}
                        tooltip={props.i18n("info.field.new_username")}
                        required={true}
                        aria-required={true}
                        isInvalid={!emailValidationResult.success && formValidationCount > 0}
                        aria-invalid={!emailValidationResult.success && formValidationCount > 0}
                        aria-describedby="username-1 username-2"
                    />
                    {formValidationCount > 0 ? (
                        <PortalFeedback fieldId="username" msgList={emailValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            {/* Ligne qui sépare les deux sections 
            <Row className="line-space-row">
                <hr id="line-space" />
            </Row>
            <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="password">
                    <Form.Label>
                        {props.i18n("label.field.current_password")} <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalPassword
                        id="password"
                        onChange={(e) => setFormData("password", e.target.value)}
                        autoComplete="new-password"
                        name="password"
                        value={formData.password}
                        tooltip={props.i18n("info.field.new_password")}
                        required={true}
                        aria-required={true}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} controlId="confirmPassword">
                    <Form.Label>
                        Confirmation du mot de passe <span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalPassword
                        id="confirmPassword"
                        onChange={(e) => setFormData("password_confirm", e.target.value)}
                        autoComplete="new-password"
                        name="confirmPassword"
                        value={formData.password_confirm}
                        required={true}
                        aria-required={true}
                    />
                </Form.Group>
            </Row>
            */}
            <Row className="justify-content-center">
                <Form.Group className="cguCheck">
                    <PortalCheck
                        inputName="checkCGU"
                        active={formData.cgu}
                        onChange={() => setFormData(["cgu", "consent", "rgpd"], [!formData.cgu, !formData.cgu, !formData.cgu])}
                        text={
                            //TODO: Changer ce paramètre en 'label' = car on peut y insérer du JSX'
                            <>
                                J’ai lu et j’accepte les&nbsp;
                                <button className="portalLinkBtn cguLink" onClick={openCgu}>
                                    conditions générales d’utilisation
                                </button>
                                &nbsp;
                                <span aria-hidden={true}>*</span>
                            </>
                        }
                        textAlign="right"
                        required={true}
                        invalid={!cguValidationResult.success && formValidationCount > 0}
                        aria-describedby="checkCgu-1"
                    />
                    {formValidationCount > 0 ? (
                        <PortalFeedback fieldId="checkCgu" msgList={cguValidationResult.errors} i18n={props.i18n} />
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Row className="justify-content-center">
                <PortalBtn state={requestState} className="validate" type="submit" style={{ marginRight: "20px" }}>
                    {props.i18n("label.action.send_signup")}
                </PortalBtn>
                <PortalBtn onClick={() => props.redirectTo("login")}>Retour</PortalBtn>
            </Row>
        </Form>
    );

    var successContent = (
        <Container>
            <Row className="justify-content-center">
                <h4 className="register-panel-title">Validation de votre adresse e-mail</h4>
            </Row>
            <Row>
                <p>
                    Un message vous a été envoyé {registeredUsername ? `à l'adresse ${registeredUsername}` : "sur votre messagerie"} afin
                    que vous puissiez activer votre compte.
                </p>
                <p>Merci de vous rendre sur votre messagerie et de cliquer sur le lien envoyé.</p>
                <p>
                    Attention : le mail envoyé pourrait se trouver dans le répertoire "Spam" ou "Courriers indésirables" de votre
                    messagerie. Dans ce cas, remettre le message dans votre boîte de réception, puis cliquer sur le lien pour activer votre
                    compte.
                </p>
            </Row>
            <Row className="justify-content-center">
                <PortalBtn onClick={() => props.redirectTo("login")} style={{ marginRight: "20px" }}>
                    Retour
                </PortalBtn>
            </Row>
        </Container>
    );

    /********************************************/
    let content;
    switch (step) {
        case "modeChoice":
        case "localCreation":
            content = (
                <>
                    {fConnect && (
                        <Row className="portalRow">
                            <h2 className="portalRow fc-title">Inscription simplifiée avec FranceConnect</h2>
                            <Fconnect
                                txtInfos={props.i18n("info.fconnect_desc")}
                                //tabIndex={[6, 7]}
                            />

                            <span className="lineSeparator text bold">OU</span>
                        </Row>
                    )}

                    <h2 className="portalRow">Inscription sans FranceConnect</h2>
                    <Row className="portalRow">{registerContent}</Row>
                </>
            );
            break;
        case "end":
            content = successContent;
            break;
        default:
            console.log(step);
            content = null;
            break;
    }

    return (
        <BubbleView className="register-panel" title="Inscription" logo={true}>
            {props.setStyle("./base/desktop")}
            {content}
        </BubbleView>
    );
}
