//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect } from "react";
//Custom Library
import {
    isArray,
    editFormState,
    formValidator,
    isEmailType,
    isPhoneType,
    formatName,
} from "../../../../ressources/lib/js/globalFunctions.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//Custom UI Components
import BaseDesktopScreen from "./screens/base/desktop/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";
//-----------------------------------//
export default function Controller(props) {
    //Specific view states
    const [initContactForm, _setInitContactForm] = useState(null);
    function setInitContactForm(form) {
        const defaultContactForm = {
            id: null,
            ans_annuaire_id: null,
            php_action: "add",
            action_allowed: ["dis", "upd", "upd_person"],
            last_name: "",
            first_name: "",
            email: "",
            email_pro: "",
            adresse_bal: "",
            used_email: "", //Pour afficher l'un des 3 champs email (priorité définie en fctn du rol_type_rol_id)
            //C'est ce champ qui sert de référence pour les validateurs et l'adresse d'envoi du mail d'invitation
            phone: "",
            phone_pro: "",
            used_phone: "", //Pour afficher l'un des 2 numéros de téléphone principaux (phone || phone_pro)
            //C'est ce champ qui sert de référence pour les validateurs et la confirmation du numéro de téléphone par le contact perso
            mobile_phone: "",
            rol_type_contact_id: "",
            rol_type_rol_id: 4, //2 => Employee, 3 => Annuaire, 4 => Personnal
            ihe_type: "",
            dossier_liaison: "", //Mettre à true pour donner accès au carnet de liaison
            is_health_team: "",
            main_contact: "",
            libelle_profession: "",
            add_rol_contact_permission: "", //Mettre à true pour envoyer une demande de consentement au contact pour faire partie de la BDD
            rol_contact_permission_status_treatment_id: "", //Le statut du consentement du contact à faire partie de la BDD (901 => 'En cours', 902 => 'rejeté par le contact', 903 => 'accepté')
            required_rol_contact_permission: false, //Le Bénéficiaire DOIT envoyer une demande de consentement à son contact
            authorize_access_dossier_liaison: false, //Le Bénéficiaire peut donner accès à son dossier à ce contact
            json_ent_entity_postal_address_1: {
                id: null,
                ent_type_address_id: "1", //Adresse principale
                formatted_address: "", //A re-remplir après chaque changement d'un des champs de l'adresse
                number: "",
                street_address: "",
                city: "",
                postcode: "",
                par_country_id: "",
            },
            json_ent_entity_postal_address_2: {
                id: null,
                ent_type_address_id: "2", //Adresse principale pour les PS
                formatted_address: "", //A re-remplir après chaque changement d'un des champs de l'adresse
                number: "",
                street_address: "",
                city: "",
                postcode: "",
                par_country_id: "",
            },
        };
        switch (true) {
            case !form:
                _setInitContactForm(null);
                break;
            default:
                Object.keys(form).forEach(function (formKey) {
                    defaultContactForm[formKey] = form[formKey];
                });
                if (defaultContactForm.id) defaultContactForm["php_action"] = "upd"; //On est en 'upd'quand on set l'init avec un id
                _setInitContactForm(defaultContactForm);
        }
    }
    const [contactForm, _setContactForm] = useState(initContactForm);
    function setContactForm(field, value) {
        editFormState(field, value, contactForm, _setContactForm);
        setFormModificationCount(formModificationCount + 1);
        //Searching for formatted postal_address update
        if (typeof field === "string") {
            const potentialAddressKey = field.split(".")[0];
            if (potentialAddressKey.includes("json_ent_entity_postal_address")) setUpdateFormattedAddressKey(potentialAddressKey);
        }
    }
    const [proContactList, setProContactList] = useState([]);
    const [loadProContactState, setLoadProContactState] = useState(""); //"loading" || "error"
    const defaultSearchProContactForm = {
        last_name: "",
        first_name: "",
        phone: "",
        email: "",
        city: "",
        code_profession_id: "",
    };
    const [searchProContactForm, _setSearchProContactForm] = useState(defaultSearchProContactForm);
    function setSearchProContactForm(field, value) {
        if (field === "code_profession_id" && !value) value = "";
        editFormState(field, value, searchProContactForm, _setSearchProContactForm);
    }
    const [formValidationCount, setFormValidationCount] = useState(0);
    const [formModificationCount, setFormModificationCount] = useState(0);
    const [updateFormattedAddressKey, setUpdateFormattedAddressKey] = useState(""); //To update the formatted address of the specified postal_address
    const contactFormValidator = new formValidator({
        //Validateur pour contacts persos
        last_name: [{ conditionCode: "required_last_name", validate: () => !!contactForm.last_name }],
        first_name: [{ conditionCode: "required_first_name", validate: () => !!contactForm.first_name }],
        rol_type_contact_id: [{ conditionCode: "required_rol_type_contact_id", validate: () => !!contactForm.rol_type_contact_id }],
        ihe_type: [{ conditionCode: "required_ihe_type", validate: () => !!contactForm.ihe_type }],
        email: [
            { conditionCode: "required_email", validate: () => !!contactForm.used_email },
            { conditionCode: "is_email_type", validate: () => isEmailType(contactForm.used_email ? contactForm.used_email.trim() : "") }, //trim car le back peut renvoyer des données non trimées
        ],
        phone: [
            {
                conditionCode: "required_phone",
                validate: () => !!contactForm.used_phone || !!contactForm.mobile_phone,
            },
            {
                conditionCode: "is_phone_type",
                validate: () => !contactForm.used_phone || (contactForm.used_phone ? contactForm.used_phone.trim().length >= 10 : false), //trim car le back peut renvoyer des données non trimées
            },
        ],
        mobile_phone: [
            {
                conditionCode: "required_phone",
                validate: () => !!contactForm.used_phone || !!contactForm.mobile_phone,
            },
            {
                conditionCode: "is_phone_type",
                validate: () =>
                    !contactForm.mobile_phone || (contactForm.mobile_phone ? contactForm.mobile_phone.trim().length >= 10 : false), //trim car le back peut renvoyer des données non trimées
            },
            {
                conditionCode: "is_mobile_phone_type",
                validate: () => !contactForm.mobile_phone || isPhoneType(contactForm.mobile_phone ? contactForm.mobile_phone.trim() : ""), //trim car le back peut renvoyer des données non trimées
            },
        ],
        add_rol_contact_permission: [
            {
                conditionCode: "required_add_rol_contact_permission",
                validate: () =>
                    contactForm.add_rol_contact_permission ||
                    !contactForm.required_rol_contact_permission ||
                    contactForm.php_action !== "add",
            },
        ],
    });
    const proContactFormValidator = new formValidator({
        //Validateur pour contacts pros
        id: [{ conditionCode: "required_pro_contact_selection", validate: () => !!contactForm.id || !!contactForm.ans_annuaire_id }],
        email: [
            {
                conditionCode: "required_ps_email_for_give_access",
                validate: () =>
                    !contactForm.add_rol_contact_permission || isEmailType(contactForm.used_email ? contactForm.used_email.trim() : ""),
            },
        ],
        add_rol_contact_permission: [{ conditionCode: "required_add_rol_contact_permission", validate: () => true }],
    });
    //Effect treatments
    useEffect(
        function () {
            _setContactForm(initContactForm);
            if (!initContactForm) {
                setFormValidationCount(0);
                setFormModificationCount(0);
            }
        },
        [initContactForm]
    );
    function updateFormattedAddress(addressKey) {
        if (contactForm && addressKey) {
            const jsonAddress = contactForm[addressKey];
            if (jsonAddress) {
                //console.log(jsonAddress);
                const countryCombo = props.store.static_store.par_nationality_id;
                let countryTxt = "";
                if (countryCombo) {
                    const countryItem = countryCombo.filter(function (comboItem) {
                        return comboItem.id == jsonAddress.par_country_id;
                    })[0];
                    if (countryItem) countryTxt = countryItem.name;
                }
                //console.log(countryTxt);
                const newFormattedAddress = `${jsonAddress.number} ${jsonAddress.street_address} , ${jsonAddress.postcode} ${jsonAddress.city} , ${countryTxt}`;
                console.log(newFormattedAddress);
                editFormState(
                    [addressKey + ".formatted_address", addressKey + ".id"],
                    [newFormattedAddress, -1],
                    contactForm,
                    _setContactForm
                );
            }
        }
    }
    useEffect(
        function () {
            if (updateFormattedAddressKey) updateFormattedAddress(updateFormattedAddressKey);
            setUpdateFormattedAddressKey("");
        },
        [updateFormattedAddressKey]
    );
    //Data functions
    function concatActionAllowed(viewAction, itemAction) {
        //Rule : An action has to be in both inputs to be effective
        if (!isArray(viewAction)) viewAction = [];
        if (!isArray(itemAction)) itemAction = [];
        itemAction = itemAction.concat(["dis"]);
        let allActions = viewAction.concat(itemAction);
        let actionAllowed = [];
        let isInViewAction = false,
            isInItemAction = false,
            isAlreadyPushed = false;
        allActions.forEach(function (action) {
            isInViewAction = !!viewAction.filter((el) => el === action)[0];
            isInItemAction = !!itemAction.filter((el) => el === action)[0];
            isAlreadyPushed = !!actionAllowed.filter((el) => el === action)[0];
            if (isInViewAction && isInItemAction && !isAlreadyPushed) actionAllowed.push(action);
        });
        return actionAllowed;
    }
    /*function updateContactList(newItemData) {
        //If newItemdata.id is found inside viewData.contactList AND there is only ONE item ! => update of this line (only keys inside newItemData are updated)
        //else (adding of a new contactRow inside viewData.contactList) => Nothing is done for the moment (too risky)
        const cleanNewItemData = cloneDeep(newItemData);
        const newViewData = cloneDeep(viewData);
        if (isArray(newViewData?.contact_list)) {
            let itemToModifyList = newViewData.contact_list.filter((itemInList) => itemInList?.id === cleanNewItemData?.id);
            if (itemToModifyList.length === 1) {
                const itemToModify = itemToModifyList[0];
                for (let key in cleanNewItemData) {
                    if (key !== "id") itemToModify[key] = cleanNewItemData[key];
                }
                setViewData(newViewData);
            }
        }
    }*/
    function formatRelationType(data) {
        const typeId = +data.rol_type_contact_id;
        const typeList = props?.store?.static_store?.rol_type_contact_id || [];
        const fundType = typeList.filter((el) => +el?.id === typeId)[0];
        return fundType?.name || "Relation inconnue";
    }
    //Posting functions
    function postContact() {
        setFormValidationCount(formValidationCount + 1);
        const validationRes = contactForm.rol_type_rol_id == 4 ? contactFormValidator.validate() : proContactFormValidator.validate();
        console.warn(contactForm);
        //console.log(validationRes);
        if (!validationRes.success) {
            //props.msgBox(props.i18n("error." + validationRes.errors[0]), "error", "msg", "Erreur");
            props.msgBox(props.i18n("error.invalid_form"), "error", "msg", "Erreur");
            return Promise.reject(validationRes);
        }
        props.setRequestState("loading");
        return props
            .request({
                action: "post_contact",
                data: contactForm,
            })
            .then(function (res) {
                props.refresh().finally(function () {
                    props.setRequestState("");
                });
                return Promise.resolve(res);
            })
            .catch(function (err) {
                props.msgBox(err.msg, "error", "msg", "Erreur").finally(function () {
                    props.setRequestState("");
                });
                return Promise.reject(err);
            });
    }
    function postDeleteContact(contactData) {
        props.setRequestState("loading");
        props.msgBox("Suppression du contact en cours", "info", "process", "Suppression").then(function (boxInstance) {
            props
                .request({
                    action: "post_del_contact",
                    data: contactData,
                })
                .then(function (res) {
                    boxInstance.close();
                    return props.refresh();
                })
                .catch(function (err) {
                    boxInstance.close();
                    return props.msgBox(err.msg, "error", "msg", "Erreur");
                })
                .finally(() => props.setRequestState(""));
        });
    }
    function postRemoveContactAccess(contactData) {
        props.setRequestState("loading");
        return props
            .request({
                action: "post_remove_contact_access",
                data: contactData,
            })
            .then(function (res) {
                return props.refresh();
            })
            .catch(function (err) {
                props.msgBox(err.msg, "error", "msg", "Erreur");
                return Promise.reject(err);
            })
            .finally(() => props.setRequestState(""));
    }
    function postSearchProContact() {
        let paramUsed = [];
        //La flemme de faire un validateur juste pour ça...
        Object.keys(searchProContactForm).forEach(function (searchProContactParam) {
            if (!!searchProContactForm[searchProContactParam]) paramUsed.push(searchProContactParam);
        });
        if (paramUsed.length < 2) {
            return props
                .msgBox(props.i18n("error.required_at_least_n_fields", [2]), "error")
                .then(function () {})
                .catch(function () {});
        }
        setLoadProContactState("loading");
        return props
            .request({
                action: "post_search_pro_contact",
                data: searchProContactForm,
            })
            .then(function (res) {
                setProContactList(res.data || []);
                setLoadProContactState("");
            })
            .catch(function (err) {
                setLoadProContactState("error");
            });
    }
    //Formular Functions

    const viewControllerProps = {
        postContact: postContact,
        postDeleteContact: postDeleteContact,
        postRemoveContactAccess: postRemoveContactAccess,
        formatContactName: formatName,
        formatRelationType: formatRelationType,
        concatActionAllowed: concatActionAllowed,
        setInitContactForm: setInitContactForm,
        initContactForm: initContactForm,
        contactForm: contactForm,
        contactFormValidator: contactFormValidator,
        proContactFormValidator: proContactFormValidator,
        setContactForm: setContactForm,
        formValidationCount: formValidationCount,
        formModificationCount: formModificationCount,
        searchProContactForm: searchProContactForm,
        setSearchProContactForm: setSearchProContactForm,
        loadProContactState: loadProContactState,
        postSearchProContact: postSearchProContact,
        proContactList: proContactList,
    };
    switch (props.appTheme) {
        case "yvelines":
            return <YvelinesDesktopScreen {...props} {...viewControllerProps} />;
        default:
            return <YvelinesDesktopScreen {...props} {...viewControllerProps} />; //Ecran de base obsolète... //<BaseDesktopScreen {...props} {...viewControllerProps} />;
    }
}
