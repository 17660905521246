import React from "react";
import PortalController from "../../../../ressources/components/portalController/portalController.js";
import Controller from "./controller.js";
export default function Compass(props) {
    //const url = "https://develop.d1oat7qlagu8dn.amplifyapp.com/solutions-group";
    //URL: https://develop.d1zewbh793lo4s.amplifyapp.com/
    return (
        <PortalController {...props} usePlug={false} storePlug={{}} customInit={false}>
            <Controller />
        </PortalController>
    );
}
