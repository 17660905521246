//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect } from "react";

//---------- UI Components ----------//
//External libraries
import { Form } from "react-bootstrap";
//Custom UI Components
import BubbleView from "../../../../../../../ressources/components/bubbleView/bubbleView";
import PortalPhone from "../../../../../../../ressources/components/portalFields/portalPhone/portalPhone";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalFeedback from "../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback";
//-----------------------------------//
//#########################################################################################//
export default function Screen(props) {
    const clientData = props.clientData || {};
    const registerForm = props.registerForm || {};
    const step = props.step;
    const [acceptDialogTrigger, setAcceptDialogTrigger] = useState(false);
    function triggerDialog() {
        setAcceptDialogTrigger(!acceptDialogTrigger);
    }
    useEffect(
        function () {
            if (step === "acceptPermission") {
                console.log(registerForm);
                if (registerForm.contact_action === "accept") {
                    //Validation de l'acceptation
                    const i18nKey = "info.action.confirm_contact_permission";
                    const boxMsg = props.i18n(i18nKey, [props.formattedClientName]);
                    props
                        .msgBox(boxMsg, "info", "confirm")
                        .then(function () {
                            props.setStep("confirmPhone");
                        })
                        .catch(function () {
                            window.close();
                            props.redirectTo("login");
                        });
                } else if (registerForm.contact_action === "refuse") {
                    //Validation du refus
                    const i18nKey = "info.action.refuse_contact_permission";
                    const boxMsg = props.i18n(i18nKey, [props.formattedClientName]);
                    props
                        .msgBox(boxMsg, "info", "confirm")
                        .then(function () {
                            props.refusePermission().catch(function (err) {
                                triggerDialog();
                            });
                        })
                        .catch(function () {
                            window.close();
                            props.redirectTo("login");
                        });
                } else {
                    //Action inconnue
                    props
                        .msgBox("Action inconnue", "error")
                        .then(() => {
                            props.redirectTo("login");
                        })
                        .catch(() => {
                            window.close();
                            props.redirectTo("login");
                        });
                }
            }
        },
        [acceptDialogTrigger]
    );

    useEffect(
        function () {
            let msg = "";
            switch (step) {
                case "contactRefused":
                    msg = props.i18n("info.success_refuse_contact_permission", [props.formattedClientName]);
                    props
                        .msgBox(msg)
                        .then(function () {
                            window.close();
                            props.redirectTo("login");
                        })
                        .catch(function () {});
                    break;
                case "permissionActive":
                    //Contact simple sans accès à la plateforme
                    msg = props.i18n("info.success_confirm_contact_permission", [props.formattedClientName, props.appName, props.appName]);
                    props
                        .msgBox(msg)
                        .then(() => {
                            window.close();
                            props.redirectTo("login");
                        })
                        .catch(() => {});

                    break;
                default:
                    break;
            }
        },
        [step]
    );

    function displayConfirmPhone() {
        function onSubmitForm(e) {
            e.preventDefault();
            props.confirmContactPermission();
        }
        const contactPhoneValidation = props.registerValidator.fields.confirm_contact_phone.validate();
        return (
            <Form id="confirmPhoneForm" onSubmit={onSubmitForm}>
                <Form.Group>
                    <h4 className="header">
                        Veuillez confirmer votre numéro de téléphone
                        <br />({clientData.masked_contact_phone})
                    </h4>
                </Form.Group>
                <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                <Form.Group controlId="confirmContactPhone">
                    <Form.Label>
                        {props.i18n("label.field.confirm_contact_phone")}&nbsp;<span aria-hidden={true}>*</span>
                    </Form.Label>
                    <PortalPhone
                        id="confirmContactPhone"
                        autoFocus
                        onChange={(e, newValue) => props.setRegisterForm("confirm_contact_phone", newValue)}
                        autoComplete="tel-national"
                        name="confirmContactPhone"
                        value={props.registerForm.confirm_contact_phone}
                        //required={true}
                        aria-required={true}
                        tooltip={props.i18n("info.field.confirm_contact_phone", [
                            props.formattedClientName,
                            clientData.masked_contact_phone,
                        ])}
                        isInvalid={!contactPhoneValidation.success && props.formValidationCount > 0}
                        aria-invalid={!contactPhoneValidation.success && props.formValidationCount > 0}
                        aria-describedby="confirmContactPhone-1 confirmContactPhone-2 confirmContactPhone-3 confirmContactPhone-4"
                    />
                    {props.formValidationCount > 0 ? (
                        <PortalFeedback
                            fieldId="confirmContactPhone"
                            msgList={contactPhoneValidation.errors}
                            i18n={props.i18n}
                            viewName={props.viewName}
                        />
                    ) : (
                        ""
                    )}
                </Form.Group>
                <PortalBtn type="submit" className="validate centered" state={props.requestState}>
                    Valider
                </PortalBtn>
            </Form>
        );
    }

    function displayContent() {
        switch (step) {
            case "confirmPhone":
                return displayConfirmPhone();
            default:
                return "";
        }
    }
    //Rendering part
    return (
        <>
            {props.setStyle("./desktop/base")}
            <BubbleView
                logo={true}
                title="Inscription entourage proche"
                className={"registerPersoPannel" + (!displayContent() ? " noContent" : "")}
            >
                {displayContent()}
            </BubbleView>
        </>
    );
}
