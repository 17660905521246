import React, { useState, useEffect, useRef } from "react";
import cloneDeep from "clone-deep";
import MsgItem from "./msgItem.js";
import { Form } from "react-bootstrap";
import PortalText from "../../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import { formatAsaproDate, isObject, uniqueId, crudDisplay } from "../../../../../../../../ressources/lib/js/globalFunctions.js";
import InitView from "../../../../../../../../ressources/components/initView/initView.js";
import BtnToolTip from "../../../../../../../../ressources/components/btnToolTip/btnToolTip.js";
//import BtnToolTip from "../../../../../ressources/components/btnToolTip/btnToolTip.js";
export default function MsgFlux({
    displaySendingDate,
    loggedUser,
    refreshState,
    openedTopic,
    actionAllowed,
    topicContent,
    pendingTopicContent,
    loadTopicContentState,
    addNote,
    postNote,
    cancelNote,
    newNoteContent,
    setNewNoteContent,
}) {
    /*dataList = [
        {
            //BddModel fields :
            id: 54587967,
            gbl_mandant_id: 2,
            adm_company_id: 2,
            adm_company_name: "Hippocad",
            created_by_ent_entity_id: 54554,
            created_by_username: "username@gmail.com",
            created_by_entity_name: "Jean blaguin",
            created_by_status: "Tuteur",
            msg_messenger_header_id: 45781,
            created_at: "2022-09-15 08:30:58",
            updated_at: null,
            deleted_at: null,
            message:
                "Patanouk et Gerciflet.... Non mais sérieux, vous être vraiment un sacré pignouf ! <br> En même temps si j'étais à cheval sur le prestige je vous aurais pas épousé déjà",
            //Calculated fields (back or front process to determine) :
            is_automatic: false, //maybe irrelevant
            is_sender: false, //could be calculated with an inline react function
            is_updatable: false, //could be calculated with an inline react function
        },
    ];*/
    //Const definition
    const topicId = !!openedTopic ? openedTopic?.id : "";
    const scrollTag = useRef();
    //Effects treatments
    useEffect(
        function () {
            if (scrollTag?.current) {
                scrollTag.current.scrollTop = 1000000;
            }
        },
        [topicContent, pendingTopicContent]
    );

    //Event handler functions
    function onClickNewMsg(event) {
        addNote();
    }

    function onPendingNoteCancelled(pendingNoteData) {
        cancelNote(pendingNoteData.pending_id);
    }

    function onPendingNoteSend(pendingNoteData) {
        postNote(pendingNoteData.pending_id).catch(function (err) {});
    }

    function fluxContentRender() {
        const msgListDisplay = [];
        for (let i = 0; i < topicContent?.content.length; i++) {
            if (topicContent.content[i]["created_by"] == "-1") {
                msgListDisplay.push(
                    <div
                        key={"notif-" + uniqueId()}
                        className="msg-notif"
                        dangerouslySetInnerHTML={{ __html: topicContent.content[i]["message"] }}
                    ></div>
                );
            } else {
                msgListDisplay.push(
                    <MsgItem
                        key={"posted-" + uniqueId()}
                        msgData={topicContent.content[i]}
                        senderAlign="right"
                        displaySendingDate={displaySendingDate}
                        loggedUser={loggedUser}
                    />
                );
            }
        }
        pendingTopicContent.forEach(function (msgData, index) {
            msgListDisplay.push(
                <MsgItem
                    key={"toPost-" + uniqueId()} //!!! VERY IMPORTANT TO HAVE A KEY UNIQUE AND NOT SOMETHING COMING FROM MSGDATA
                    msgData={msgData}
                    senderAlign="right"
                    displaySendingDate={displaySendingDate}
                    loggedUser={loggedUser}
                    onCancelled={onPendingNoteCancelled}
                    onSend={onPendingNoteSend}
                />
            );
        });
        return msgListDisplay;
    }
    return (
        <>
            <div className="msg-flux-container">
                <div className="msg-flux-content" ref={scrollTag}>
                    {!!loadTopicContentState ? <InitView type={loadTopicContentState} /> : fluxContentRender()}
                </div>
                {crudDisplay(["send"], actionAllowed) ? (
                    <div className="msg-flux-footer">
                        <div className="msg-flux-footer-content-container">
                            <div className="newMsg-section">
                                <PortalText
                                    activeActions={["send"]}
                                    actionAllowed={actionAllowed}
                                    value={newNoteContent}
                                    as="textarea"
                                    className="new-msg"
                                    onChange={(e) => setNewNoteContent(e.target.value)}
                                    placeholder="Nouveau message..."
                                />
                                <BtnToolTip
                                    activeActions={["send"]}
                                    actionAllowed={actionAllowed}
                                    className="fa fa-paper-plane send-btn"
                                    onClick={onClickNewMsg}
                                    disabled={!!loadTopicContentState}
                                ></BtnToolTip>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}
