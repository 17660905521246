import React from "react";
import BtnToolTip from "../btnToolTip";
import "./newBtn.css";
/*
Configs : 
{
    Toutes les configs d'un <BtnTooltip />
}*/
export default function NewBtn(props) {
    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    const filteredProps = deleteProps("className", "iconCls");
    return <BtnToolTip iconCls="fas fa-plus" className={`newBtn${props.className ? " " + props.className : ""}`} {...filteredProps} />;
}
