import React from "react";
import BaseScreen from "./screens/base/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";

export default function PrivateLayout(props) {
    //TODO WRITE LOGIC FOR ADAPTIVE CONTENT
    switch (props.appTheme) {
        case "yvelines":
            return <YvelinesDesktopScreen {...props} />; //MenuBestOfPlus, met une claque à cette prétentieuse !
        default:
            return <BaseScreen {...props} />;
    }
}
