//Logical Components
import React from "react";
import { useState, useEffect } from "react";
import { formValidator, editFormState, isPhoneType, isEmailType } from "../../../../ressources/lib/js/globalFunctions";
//UI Components
import BaseDesktopScreen from "./screens/base/desktop/screen.js";
export default function Controller(props) {
    const defaultFormData = {
        username: "",
        //external_ref: "",
    };
    const [formValidationCount, setFormValidationCount] = useState(0);
    const [formModificationCount, setFormModificationCount] = useState(0);
    const [initFormData, _setInitFormData] = useState(defaultFormData);
    const [formData, _setFormData] = useState(initFormData);
    function setFormData(field, value) {
        editFormState(field, value, formData, _setFormData);
        setFormModificationCount(formModificationCount + 1);
    }
    const formValidatorInstance = new formValidator({
        username: [
            { conditionCode: "required_username", validate: () => !!formData.username.trim() },
            { conditionCode: "username_is_email_type", validate: () => isEmailType(formData.username.trim()) },
        ],
    });
    //Effect treatments
    useEffect(
        function () {
            _setFormData(initFormData);
            if (!initFormData) {
                setFormModificationCount(0);
                setFormValidationCount(0);
            }
        },
        [initFormData]
    );
    //Communication functions
    function postData() {
        return new Promise(function (rs, rj) {
            console.log(formData);
            setFormValidationCount(formValidationCount + 1);
            const validationRes = formValidatorInstance.validate();
            //if (!validationRes.success) return rj({ msg: props.i18n("error." + validationRes.errors[0]) });
            if (!validationRes.success) return rj({ msg: props.i18n("error.invalid_form") });
            props.setRequestState("loading");
            props
                .request({
                    action: "post_reset_password",
                    data: formData,
                })
                .then(function (res) {
                    _setInitFormData(defaultFormData);
                    setFormModificationCount(0);
                    setFormValidationCount(0);
                    return rs(res);
                })
                .catch(function (error) {
                    return rj(error);
                })
                .finally(function () {
                    props.setRequestState("");
                });
        });
    }
    const viewControllerProps = {
        formData: formData,
        setFormData: setFormData,
        formValidator: formValidatorInstance,
        formValidationCount: formValidationCount,
        formModificationCount: formModificationCount,
        postData: postData,
    };
    switch (props.appTheme) {
        default:
            return <BaseDesktopScreen {...props} {...viewControllerProps} />;
    }
}
