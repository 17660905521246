import React from "react";
import { Row } from "react-bootstrap";
import PortalSection from "../../../../../../../../ressources/components/portalPage/portalSection/portalSection";
import PortalCheck from "../../../../../../../../ressources/components/portalFields/portalCheck/portalCheck";
export default function ContactAccessSection(props) {
    const contactForm = props.contactForm;
    const formatContactName = props.formatContactName;
    const rolTypeContactCombo = props?.store?.static_store?.rol_type_contact_id || [];
    const setContactForm = props.setContactForm;
    const actionAllowed = props.actionAllowed;
    const msgBox = props.msgBox;
    const setInitContactForm = props.setInitContactForm;
    const postRemoveContactAccess = props.postRemoveContactAccess;

    React.useEffect(function () {
        props.scrollLayout(0);
    }, []);

    //UX event handlers
    function onClickGiveAccessCheck() {
        //Put into access component
        if (!contactForm.dossier_liaison) {
            /*const message = props.i18n("info.action.authorize_access_dossier_liaison", [formatContactName(contactForm), props.appName]);
            msgBox(message, "info", "confirm", "Confirmation")
                .then(function () {
                    setContactForm("dossier_liaison", true);
                })
                .catch(function () {});*/
            setContactForm("dossier_liaison", true);
        }
    }
    function onClickDontGiveAccessCheck() {
        //Put into access component
        if (contactForm.dossier_liaison) {
            const message = props.i18n(`info.action.remove_${contactForm.rol_type_rol_id == 3 ? "pro" : "perso"}_access_dossier_liaison`, [
                formatContactName(contactForm),
            ]);
            if (contactForm.dossier_liaison == 2) {
                msgBox(message, "warning", "confirm", "Confirmation")
                    .then(function () {
                        setContactForm("dossier_liaison", false);
                    })
                    .catch(function () {});
            } else {
                setContactForm("dossier_liaison", "");
            }
        }
    }
    //Rendering functions
    return (
        <PortalSection
            title={props.i18n(`title.access_${contactForm.rol_type_rol_id == 3 ? "pro" : "perso"}_section`)}
            titleAlign="left"
            xs={props.xs || 12}
            md={props.md || 6}
        >
            {(contactForm.dossier_liaison != 1 || contactForm.dossier_liaison === true) && (
                <fieldset className="form-group giveAccessSet">
                    <legend>
                        {props.i18n(`info.action.authorize_${contactForm.rol_type_rol_id == 3 ? "pro" : "perso"}_access_dossier_liaison`, [
                            props.appName,
                        ])}
                    </legend>
                    <Row className="radio-row giveAccessRow">
                        <PortalCheck
                            inputName="giveAccess"
                            baseIcon="far fa-circle"
                            activeIcon="far fa-dot-circle"
                            text="Oui"
                            textAlign="left"
                            activeActions={["upd"]}
                            actionAllowed={actionAllowed}
                            active={contactForm.dossier_liaison}
                            onChange={onClickGiveAccessCheck}
                        ></PortalCheck>
                        <PortalCheck
                            inputName="dontGiveAccess"
                            baseIcon="far fa-circle"
                            activeIcon="far fa-dot-circle"
                            text="Non"
                            activeActions={["upd"]}
                            actionAllowed={actionAllowed}
                            active={!contactForm.dossier_liaison}
                            onChange={onClickDontGiveAccessCheck}
                        ></PortalCheck>
                    </Row>
                </fieldset>
            )}
            {contactForm.dossier_liaison == 1 && contactForm.dossier_liaison !== true && (
                <p className="accessStatusInfo">Invitation envoyée</p>
            )}
        </PortalSection>
    );
}
