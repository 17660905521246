import React from "react";
import { useState, useEffect } from "react";
import FloatContainer from "../../../floatContainer/floatContainer";
import NavSectionMenu from "../navSectionMenu/navSectionMenu";
import "./floatMenu.css";
export default function FloatMenu(props) {
    /**
     * @param {Object} props :{
     *      @param {Object} data [{}] : {
     *          @param {String} direction [undefined] : Le hash de la vue sur laquelle l'item pointe
     *
     *          @param {Object[]} items [null] : les sous-items de menus possibles que cet item contient
     *              @example [{direction: '', items: [{direction:'', items:[...]}, ...]}]
     *      }
     *
     *      @param {Function} i18n [emptyFctn] : La fonction pour traduire les champs 'direction' en des noms de vues lisibles
     *
     *      @param {Function} redirectTo [emptyFctn] : La fonction pour rediriger vers les vues quand on clique sur un lien du menu
     *
     *      @param {Boolean} triggerOpen [false] : Propriété qui permet de déclencher l'ouverture du menu flottant depuis un composant supérieur
     *
     *      @param {Boolean} triggerCollapse [false] : Propriété qui permet de déclencher la fermeture du menu flottant depuis un composant supérieur
     * }
     */

    const data = props.data || [];
    const [opened, setOpened] = useState(false);
    const [triggerActivation, setTriggerActivation] = useState(false);
    const triggerOpen = !!props.triggerOpen;
    const triggerCollapse = !!props.triggerCollapse;

    useEffect(
        function () {
            if (triggerActivation) setOpened(true);
        },
        [triggerOpen]
    );

    useEffect(
        function () {
            if (triggerActivation) setOpened(false);
        },
        [triggerCollapse]
    );

    useEffect(function () {
        setTriggerActivation(true);
    }, []);

    return (
        <FloatContainer className="floatmenuContainer" opened={opened}>
            {opened ? <NavSectionMenu title="Menu" data={data} i18n={props.i18n} redirectTo={props.redirectTo} /> : ""}
        </FloatContainer>
    );
}
