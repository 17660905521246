import React from "react";
import { useState, useEffect } from "react";
import { formatAsaproDate } from "../../../../../ressources/lib/js/globalFunctions";
import Form from "react-bootstrap/Form";
import BtnToolTip from "../../../../../ressources/components/btnToolTip/btnToolTip";
import PortalText from "../../../../../ressources/components/portalFields/portalText/portalText";
//TODO: Faire une requête pour charger dynamiquement les dsp_timeslot_id à l'init de la page
export default function MealToolBar(props) {
    /*function getDateRange(date, daysDisplayed) {
        visualisationType = visualisationType === "week" ? "week" : "day";
        function changeNumDay(numDay) {
            //On passe Lun à l'index 0 et Dim à l'index 6
            if (numDay == 0) return 6;
            return numDay - 1;
        }
        if (date.length < 1) {
            return {
                start_date: "",
                end_date: "",
            };
        }
        if (visualisationType === "day") {
            return {
                start_date: formatAsaproDate(date),
                end_date: formatAsaproDate(date),
            };
        }
        const startDate = new Date(date);
        const endDate = new Date(date);
        startDate.setDate(startDate.getDate() - changeNumDay(startDate.getDay()));
        endDate.setDate(endDate.getDate() + (6 - changeNumDay(endDate.getDay())));
        return {
            start_date: formatAsaproDate(startDate),
            end_date: formatAsaproDate(endDate),
        };
    }*/
    function getDateRange(date, numDays) {
        numDays = numDays || 3;
        if (date.length < 1) {
            return {
                start_date: "",
                end_date: "",
            };
        }
        const startDate = new Date(date);
        const endDate = new Date(date);
        endDate.setDate(endDate.getDate() + numDays - 1);
        return {
            start_date: formatAsaproDate(startDate),
            end_date: formatAsaproDate(endDate),
        };
    }
    function submitData() {
        if (typeof props.onSubmit === "function") {
            const dataSet1 = {
                dsp_timeslot_id: dspTimeslotId,
                selected_date: selectedDate,
                client_ent_entity_id: props.clientEntEntityId,
            };
            const submitData = Object.assign(dataSet1, getDateRange(selectedDate, props.numDays));
            props.onSubmit(submitData);
        }
    }
    //Component Constants and States
    const [dspTimeslotId, setDspTimeslotId] = useState("200");
    const [selectedDate, setSelectedDate] = useState(formatAsaproDate(new Date()));

    //Effects treatments
    useEffect(
        function () {
            submitData();
        },
        [props.clientEntEntityId, props.numDays, dspTimeslotId, selectedDate, props.triggerReload]
    );
    function switchToPreviousPeriod() {
        const selDate = new Date(selectedDate);
        selDate.setDate(selDate.getDate() - props.numDays);
        setSelectedDate(formatAsaproDate(selDate));
    }
    function switchToNextPeriod() {
        const selDate = new Date(selectedDate);
        selDate.setDate(selDate.getDate() + props.numDays);
        setSelectedDate(formatAsaproDate(selDate));
    }
    return (
        <div className="mealToolbar">
            <Form.Group controlId="selectedDate" className="selectedDateGroup">
                <Form.Label>Date du repas</Form.Label>
                <div className="inputBlock">
                    <BtnToolTip iconCls="fas fa-chevron-left" onClick={switchToPreviousPeriod} disabled={props.isLoading} aria-label="" />
                    <PortalText
                        id="selectedDate"
                        onChange={(e) => setSelectedDate(e.target.value)}
                        type="date"
                        autoComplete="off"
                        name="selectedDate"
                        value={selectedDate}
                        required={true}
                        aria-required={true}
                        isInvalid={false}
                        aria-invalid={false}
                        autoFocus
                    />
                    <BtnToolTip iconCls="fas fa-chevron-right" onClick={switchToNextPeriod} disabled={props.isLoading} aria-label="" />
                </div>
            </Form.Group>
            <Form.Group controlId="mealMoment" className="mealMomentGroup">
                <Form.Label>Moment de la journée</Form.Label>
                <Form.Control
                    id="mealMoment"
                    onChange={(e) => setDspTimeslotId(e.target.value)}
                    as="select"
                    autoComplete="off"
                    name="mealMoment"
                    defaultValue={dspTimeslotId}
                    aria-required={false}
                    aria-invalid={false}
                >
                    <optgroup label="Moment du repas">
                        <option value="200">Midi</option>
                        <option value="202">Soir</option>
                    </optgroup>
                </Form.Control>
            </Form.Group>
        </div>
    );
}
