import React from "react";
import { useState, useEffect } from "react";
//External Library
import { Row, Col } from "react-bootstrap";
import List from "devextreme-react/list";
import DataSource from "devextreme/data/data_source";
//Custom UI Components
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import FilterToolbar from "../components/filterToolbar.js";
export default function Screen(props) {
    //Init constants
    const viewData = props.store;
    const exportAsaproExcel = props.exportAsaproExcel;
    //Local state definition
    const [dataList, setDataList] = useState([]);

    //Effect treatments
    useEffect(
        function () {
            setDataList(viewData.payments.data);
        },
        [viewData]
    );
    //Pre-rendering treatments
    const listDataSource = new DataSource({
        store: dataList,
        paginate: true,
        pageSize: 5,
    });
    function renderContent() {
        function renderInvoice(content) {
            return (
                <Row className="itemListContainer portalRow">
                    <Col xs={12} sm={4} className="itemListCol title">
                        <span className="itemListRow titleRow">
                            <h1 className="header">{content.supplier_name}</h1>
                            {/*<BtnToolTip iconCls="dx-icon dx-icon-xlsxfile" onClick={exportAsaproExcel}>
                                Export Excel
                            </BtnToolTip>*/}
                        </span>
                        <span className="itemListRow last">
                            <p className="content">{content.product_name}</p>
                        </span>
                    </Col>
                    <Col xs={12} sm={4} className="itemListCol">
                        <span className="itemListRow">
                            <h2 className="label">{props.i18n("label.field.invoice_issue_date")}&nbsp;:</h2>&nbsp;
                            <p className="content">{content.invoice_issue_date_display}</p>
                        </span>
                        <span className="itemListRow">
                            <h2 className="label">{props.i18n("label.field.payment_due_date")}&nbsp;:</h2>&nbsp;
                            <p className="content">{content.payment_due_date_display}</p>
                        </span>
                    </Col>
                    <Col xs={12} sm={4} className="itemListCol">
                        <span className="itemListRow">
                            <h2 className="label">{props.i18n("label.field.quantity")}&nbsp;:</h2>&nbsp;
                            <p className="content">{content.quantity_display}</p>
                        </span>
                        {
                            //<span className="itemListRow"><h2 className='label'>{props.i18n('label.field.product_unit')}&nbsp;:</h2>&nbsp;<p className='content'>{content.product_unit}</p></span>
                        }
                        <span className="itemListRow">
                            <h2 className="label">{props.i18n("label.field.total_due")}&nbsp;:</h2>&nbsp;
                            <p className="content">{content.total_due_display}</p>
                        </span>
                    </Col>
                </Row>
            );
        }
        return (
            <div className="listContainer">
                <List
                    dataSource={listDataSource}
                    itemRender={renderInvoice}
                    searchEnabled={false} //Search bar into component
                    searchExpr={[
                        "product_name",
                        "supplier_name",
                        "invoice_issue_date",
                        "invoice_issue_date_display",
                        "payment_due_date",
                        "payment_due_date_display",
                        "quantity",
                        "quantity_display",
                        "product_unit",
                        "total_due",
                        "total_due_display",
                    ]}
                />
            </div>
        );
    }
    //Rendering part
    return (
        <div className="inv_invoice">
            {props.setStyle("./base/phone")}
            <div className="viewHeader">
                <h2 className="title">Synthèse des paiements&nbsp;:&nbsp;{viewData.header.total_paid_display}</h2>
                <PortalBtn className="reverse" iconCls="fal fa-download" /*"dx-icon dx-icon-xlsxfile"*/ onClick={exportAsaproExcel}>
                    Télécharger au format Excel
                </PortalBtn>
            </div>
            <FilterToolbar {...props} realTimeFilter={false} />
            {renderContent()}
        </div>
    );
}
