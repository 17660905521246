//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React library
import React from "react";
import { useState, useEffect, useRef } from "react";
//External Library
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
//Custom UI Components
import InitView from "../../../../../ressources/components/initView/initView";
import List from "./components/List.js";
import FormCesu from "./components/FormCesu.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//
function MainCesu(props) {
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        listData: [
            {
                id: "58",
                periode: "2021-11",
                quantity: "1.000",
                file_id: "2223",
                filename: "bouchon.pdf",
                par_status_treatment_id: null,
                inv_type_motif_name: null,
                declare: "01:00",
            },
            {
                id: "49",
                periode: "2021-11",
                quantity: "5.000",
                file_id: "2167",
                filename: "fanchon.pdf",
                par_status_treatment_id: null,
                inv_type_motif_name: null,
                declare: "05:00",
            },
            {
                id: "50",
                periode: "2021-11",
                quantity: "4.000",
                file_id: "2215",
                filename: "manchon.pdf",
                par_status_treatment_id: null,
                inv_type_motif_name: null,
                declare: "03:00",
            },
            {
                id: "51",
                periode: "2021-11",
                quantity: "7.000",
                file_id: "2216",
                filename: "bichon.pdf",
                par_status_treatment_id: "652",
                inv_type_motif_name: 'Animal référencé non considéré comme un canidé mais plutôt comme une "peluche à mémère"',
                declare: "01:00",
            },
            {
                id: "52",
                periode: "2021-10",
                quantity: "3.517",
                file_id: "2138",
                filename: "reblochon.pdf",
                par_status_treatment_id: "650",
                inv_type_motif_name: null,
                declare: "06:00",
            },
            {
                id: "53",
                periode: "2021-06",
                quantity: "1.333",
                file_id: "2137",
                filename: "cochon.pdf",
                par_status_treatment_id: "651",
                inv_type_motif_name: null,
                declare: "1:20",
            },
            {
                id: "54",
                periode: "2021-08",
                quantity: "3.5",
                file_id: "2140",
                filename: "baluchon.pdf",
                par_status_treatment_id: "651",
                inv_type_motif_name: null,
                declare: "4:45",
            },
            {
                id: "55",
                periode: "2021-11",
                quantity: "5.125",
                file_id: "2146",
                filename: "artichon.pdf",
                par_status_treatment_id: "652",
                inv_type_motif_name: "Légume issu d'une exprérience transgénique non authorisée",
                declare: "1:35",
            },
            {
                id: "56",
                periode: "2021-10",
                quantity: "5.125",
                file_id: "2146",
                filename: "artichon.pdf",
                par_status_treatment_id: "652",
                inv_type_motif_name: "Légume issu d'une exprérience transgénique non authorisée",
                declare: "1:35",
            },
            {
                id: "57",
                periode: "2021-09",
                quantity: "4.000",
                file_id: "2149",
                filename: "torchon.pdf",
                par_status_treatment_id: "650",
                inv_type_motif_name: null,
                declare: "01:40",
            },
        ],
    };
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [successPost, setSuccessPost] = useState(null);
    const [pecData, setPecData] = useState(props.pecData || null);
    const [listData, setListData] = useState([]);
    const [formData, setFormData] = useState(null);
    const [cesuDescription, setCesuDescription] = useState("");
    const [cesuOpenPeriod, setCesuOpenPeriod] = useState({});
    // Init functions
    function loadViewData() {
        setIsloading(true);
        setErrorLoading(false);
        if (dataPlug && useDataPlug) {
            setListData(dataPlug.listData);
            setIsloading(false);
            return;
        }
        props
            .request({
                action: "get_cesu_data",
                data: pecData,
            })
            .then(function (response) {
                setListData(response.data.list);
                setCesuDescription(response?.data?.cesuDescription);
                setCesuOpenPeriod(response?.data?.cesuOpenPeriod || {});
                setIsloading(false);
            })
            .catch(function (error) {
                setErrorLoading(true);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            });
    }
    function refreshViewData() {
        //A voir, si on utilise pas loadViewData()
        setIsloading(true);
        setErrorLoading(false);
        props
            .request({
                action: "get_cesu_data",
                data: pecData,
            })
            .then(function (response) {
                setListData(response.data.list);
                setIsloading(false);
            })
            .catch(function (error) {
                setErrorLoading(true);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            });
    }
    function displayMonth(date) {
        let str = date.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
        });
        return str[0].toUpperCase() + str.slice(1);
    }
    function getDateValue(dateJS) {
        let zeroPrefix = dateJS.getMonth() + 1 < 10 ? "0" : "";
        return `${dateJS.getFullYear()}-${zeroPrefix + (dateJS.getMonth() + 1)}`;
    }
    function isInDeclarationRange(currentPeriode) {
        if (!cesuOpenPeriod.start_date) return false;
        const startDateJS = new Date(cesuOpenPeriod.start_date);
        startDateJS.setDate(1);
        startDateJS.setHours(0);
        startDateJS.setMinutes(0);
        startDateJS.setSeconds(0);
        startDateJS.setMilliseconds(0);
        const currentDateJS = new Date(currentPeriode);
        if (currentDateJS < startDateJS) return false;
        if (!cesuOpenPeriod.end_date) return true;
        const endDateJS = new Date(cesuOpenPeriod.end_date);
        endDateJS.setDate(1);
        endDateJS.setMonth(endDateJS.getMonth() + 1);
        endDateJS.setHours(0);
        endDateJS.setMinutes(0);
        endDateJS.setSeconds(0);
        endDateJS.setMilliseconds(0);
        if (currentDateJS >= endDateJS) return false;
        return true;
    }
    function declarationIsOpened() {
        return !!cesuOpenPeriod.start_date;
    }
    function displayOpenedPeriod() {
        let str = "";
        if (!cesuOpenPeriod.start_date) return str;
        const startDateJS = new Date(cesuOpenPeriod.start_date);
        if (!cesuOpenPeriod.end_date) {
            return `à partir de ${displayMonth(startDateJS)}`;
        }
        if (cesuOpenPeriod.start_date === cesuOpenPeriod.end_date) {
            return `pour ${displayMonth(startDateJS)}`;
        }
        const endDateJS = new Date(cesuOpenPeriod.end_date);
        return `de ${displayMonth(startDateJS)} à ${displayMonth(endDateJS)}`;
    }
    function buildCesuTitle() {
        let cesuTitle = "";
        let cesuSubtitle = "";
        switch (true) {
            case formData:
                cesuTitle = "Nouvelle déclaration";
                if (formData.id >= 0) {
                    cesuTitle = "Déclaration n° " + formData.id;
                }
                break;
            default:
                cesuTitle = "Mes déclarations CESU";
                cesuSubtitle = !isLoading && !errorLoading ? "Périodes de déclaration fermées" : "";
                if (declarationIsOpened()) {
                    cesuSubtitle = `Déclarations ouvertes ${displayOpenedPeriod()}`;
                }
                break;
        }
        return (
            <h2 className="cesu-section-title">
                <div className="main">{cesuTitle}</div>
                <div className="subtitle">{cesuSubtitle}</div>
            </h2>
        );
    }
    function buildCesuContent() {
        let cesuContent = (
            <List
                listData={listData}
                setFormData={(data) => setFormData(data)}
                headerData={pecData}
                downloadFile={downloadFile}
                displayMonth={displayMonth}
                getDateValue={getDateValue}
                declarationIsOpened={declarationIsOpened}
                isInDeclarationRange={isInDeclarationRange}
            />
        );
        if (formData) {
            cesuContent = (
                <FormCesu
                    formData={formData || {}}
                    setFormData={setFormData}
                    headerData={pecData}
                    onHide={() => setFormData(null)}
                    setSuccessPost={setSuccessPost}
                    refresh_list={refreshViewData}
                    downloadFile={downloadFile}
                    displayMonth={displayMonth}
                    getDateValue={getDateValue}
                    request={props.request}
                    invPecId={props.pecData ? props.pecData.id : null}
                    cesuDescription={cesuDescription}
                    cesuOpenPeriod={cesuOpenPeriod}
                />
            );
        }
        return cesuContent;
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        if (!pecData) {
            props.msgBox("Aucune Prise En Charge sélectionnée", "error", "msg");
            props.close();
        } else {
            //Chargement des données de la vue (Bouchon ou non)
            console.log("pecData : ", pecData);
            loadViewData();
        }
    }, []);
    // Init render treatments
    if (isLoading && !errorLoading) {
        return (
            <>
                {buildCesuTitle()}
                <InitView type="loading" />
            </>
        );
    }
    if (errorLoading) {
        return (
            <>
                {buildCesuTitle()}
                <InitView type="errorLoading" />
            </>
        );
    }
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // View constants

    // View global functions
    // UI event handlers
    function downloadFile(fileId) {
        props
            .request({
                type: "ajaxGetFile", //Ouvre un nouvel onglet et fait cette token_request dans ce nouvel onglet (La réponse n'est pas hippocad_encodée mais renvoyée en brut)
                action: "download_file",
                data: {
                    file_id: fileId,
                },
            })
            .catch(function (error) {
                props.msgBox(error.msg, "error", "msg");
            });
    }
    // Rendering part
    return (
        <>
            {buildCesuTitle()}
            {buildCesuContent()}
        </>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default MainCesu;
