import React from "react";
import { useEffect, useRef } from "react";
import Footer from "../../../../components/footer/footer.js";
import ImgBackground from "../../../../../../ressources/components/imgBackground/imgBackground.js";
import PortalBtn from "../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalMenu from "../../../../../../ressources/components/portalMenu/portalMenu.js";
import SpaceSelector from "../../../components/spaceSelector/spaceSelector.js";
import ViewBanner from "../../../components/viewBanner/viewBanner.js";
export default function Screen(props) {
    //console.warn(props);
    const viewHash = props.viewHash;
    const viewContentRef = useRef();
    //Event handlers
    function onClickLoginBtn() {
        //TODO: Afficher un mini-formulaire flottant à la place?
        props.redirectTo("login");
    }

    function onClickLogoutBtn() {
        props.logout();
    }

    function onChangeSpace(newSpace) {
        //console.log(newSpace);
        props.updateSelectedClient(newSpace);
    }

    function onClickLogo() {
        if (typeof props.returnHome === "function") props.returnHome();
    }

    //Rendering functions
    function menuDataConstruct() {
        const menuLeft = props?.menu?.menu_left || [];
        const menuRight = props?.menu?.menu_right || [];
        return menuLeft.concat(menuRight);
    }

    function logBtnRender() {
        if (!props.isConnected) {
            return (
                <PortalBtn onClick={onClickLoginBtn} iconCls="fas fa-user">
                    {props.i18n("label.action.goto_personal_space")}
                </PortalBtn>
            );
        }
        return (
            <PortalBtn className="logoutBtn reverse" onClick={onClickLogoutBtn} iconCls="far fa-lock-open">
                Déconnexion
            </PortalBtn>
        );
    }

    function spaceInfoRender() {
        if (!props.isConnected) return "";
        return (
            <>
                <SpaceSelector userSpace={props.loggedUser} clientSpaces={props.attachedClient} onChange={onChangeSpace} />
                {props.selectedClient.rol_type_name === "client" && !!props.selectedClient.formatted_department_name && (
                    <span className="complementary">
                        Bénéficiaire&nbsp;
                        {props.loggedUser.formatted_department_name}
                    </span>
                )}
            </>
        );
    }

    function viewBannerRender() {
        if (!props.isConnected || props.viewHash === "home" || props.viewType !== "private") return "";
        return (
            <ViewBanner
                className="headerRow"
                i18n={props.i18n}
                redirectTo={props.redirectTo}
                userJourney={props.userJourney}
                viewInfo={props.viewInfo}
                viewHash={props.viewHash}
            />
        );
    }

    return (
        <>
            {props.setStyle("./private/v_yvelines/desktop")}
            <div className={`layoutContainer${props.viewGroup && " " + props.viewGroup} ${props.selectedClient.rol_type_name || ""}`}>
                <div className="headerContainer">
                    <div className="headerRow primary">
                        <ImgBackground onClick={onClickLogo} className="companyLogo" />
                        <div className="spaceInfoContainer">{spaceInfoRender()}</div>
                        {logBtnRender()}
                    </div>
                    <div className="headerRow secondary">
                        <PortalMenu {...props} usePlug={false} desktopDisplay={true} data={menuDataConstruct()} />
                    </div>
                    {viewBannerRender()}
                </div>
                <div
                    id="layoutViewContainer"
                    className="viewContainer layoutViewContainer"
                    tabIndex={1001}
                    onClick={null}
                    ref={viewContentRef}
                >
                    <div className="viewBlock">
                        <div className="layoutViewContent">{props.children}</div>
                        <Footer {...props} />
                    </div>
                </div>
            </div>
        </>
    );
}
