//######################################## Imports ########################################//
/**********Logic Components and modules**********/
//External libraries
import deepClone from "clone-deep";
//React Hooks
import React from "react";
import { useState, useEffect } from "react";
//React Redux Hooks (https://react-redux.js.org/) => Global store API
/************************************************/

/**********UI Components**********/
//External Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import List from "devextreme-react/list";
//Custom Components
import PortalCheck from "../../../../ressources/components/portalFields/portalCheck/portalCheck";
import InitView from "../../../../ressources/components/initView/initView";
import PopupView from "../../../../ressources/components/popupView/popupView";
import ItemRow from "../../../../ressources/components/itemRow/itemRow";
import BtnToolTip from "../../../../ressources/components/btnToolTip/btnToolTip";
import PortalText from "../../../../ressources/components/portalFields/portalText/portalText.js";
import PortalBtn from "../../../../ressources/components/portalBtn/portalBtn";
/*********************************/
//#########################################################################################//

//######################################## Component definition ########################################//
function MainMails(props) {
    //---------- Initialisation des états ----------//
    //Etats de communication de la vue
    const [isLoading, setIsLoading] = useState(true);
    const [isSynchronizing, setIsSynchronizing] = useState(false);
    const [isSendingMail, setIsSendingMail] = useState(false);
    const [isLoadingFlux, setIsLoadingFlux] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [sendMailReady, setSendMailReady] = useState(false);
    const [sendMailError, setSendMailError] = useState(false);
    const [loadFluxError, setLoadFluxError] = useState(false);

    //Etats pour la gestion des options d'affichage de la boîte mail
    const [filterType, setFilterType] = useState("all"); //Valeurs possibles : ['all', 'read', 'marked']
    const [currentInboxType, setCurrentInboxType] = useState("inbox"); //Valeurs possibles : ['inbox', 'sent', 'trash']
    const bodyOptions = {
        //Valeurs que l'on peut affecter à 'bodyDisplay'
        LIST_CONTENT: "listContent",
        CURRENT_MAIL_CONTENT: "currentMailContent",
        CURRENT_FLUX_CONTENT: "currentFluxContent",
    };
    const [bodyDisplay, setBodyDisplay] = useState(bodyOptions.LIST_CONTENT);
    const popupType = {
        //Valeurs que l'on peut affecter à 'currentPopupType'
        NEW_MESSAGE: "newMessage",
        RESPONDING: "responding",
    };
    const [currentPopupType, setCurrentPopupType] = useState(popupType.NEW_MESSAGE);
    const [showModal, setShowModal] = useState(false); //Affichage du popup pour envoyer un message

    //Etats pour la gestion des données pour générer des nouveaux mails
    const [diffusionListData, setDiffusionListData] = useState(null);
    const [target, setTarget] = useState(null);
    const [object, setObject] = useState(null);
    const [message, setMessage] = useState("");
    const [msgFluxId, setMsgFluxId] = useState(null);

    //Etats pour la gestion des emails présents dans la boîte mail
    const [mailSelection, setMailSelection] = useState([]);
    const [currentMail, setCurrentMail] = useState(null);
    const [currentMailFlux, setCurrentMailFlux] = useState([]);
    const [viewData, setViewData] = useState({
        //Tous les mails disponibles dans la boîte mail (Equivalent à viewData)
        inbox: [],
        sent: [],
        trash: [],
    });
    const [mailList, setMailList] = useState([]);
    //----------------------------------------------//
    //---------- Définition des effets ----------//
    //Fonctions utilisées par les effets
    function updateMailList() {
        let filteredMailList;
        switch (filterType) {
            case "marked":
                filteredMailList = viewData[currentInboxType].filter(function (emailRecord) {
                    return parseInt(emailRecord.marked) > 0;
                });
                break;
            case "read":
                filteredMailList = viewData[currentInboxType].filter(function (emailRecord) {
                    return !emailRecord.read_at;
                });
                break;
            default:
                filteredMailList = viewData[currentInboxType].filter(function (emailRecord) {
                    //On fait ça pour casser la référence entre les 2 listes
                    return true;
                });
                break;
        }
        setMailList(filteredMailList);
    }
    function loadSendMailDisplay(event) {
        setSendMailReady(false);
        setSendMailError(false);
        props
            .request({
                action: "get_new_mail_options",
                selected_client: null,
            })
            .then(function (response) {
                const data = response.data;
                console.log("Liste de diffusion : ", data);
                setDiffusionListData(data.list_diffusion);
                setSendMailError(false);
                setSendMailReady(true);
            })
            .catch(function (error) {
                console.error("Echec lors du chargement des options d'envoi de mail (get_client_mail_data)");
                console.error(error);
                props.msgBox(error.msg, "error", "msg", "Erreur chargement listes de diffusion");
                setSendMailError(true);
            });
    }
    function loadData() {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        props
            .request({
                selected_client: null,
            })
            .then(function (response) {
                const data = response.data;
                console.log("MAILS", data);
                setViewData(data);
                handleResponseHelp(response);
                //setCurrentMail(data.inbox[0]);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.error("Echec lors du chargement des mails");
                console.error(error);
                setIsLoading(false);
                setErrorLoading(true);
            });
    }
    // Chargement des mails
    useEffect(function () {
        props.updateUserJourney(["home", "home_communication", "client_mail"]);
        loadData();
    }, []);

    // Chargement des listes de diffusion
    useEffect(function () {
        loadSendMailDisplay();
    }, []);
    // Mise à jour de la liste des mails affichés
    useEffect(
        function () {
            updateMailList();
            // Dès que les emails affichés changent, il faut 'nettoyer' les emails sélectionnés pour éviter les mélanges par la suite
            setMailSelection([]);
        },
        [viewData, filterType]
    );
    useEffect(
        function () {
            updateMailList();
            // Dès que les emails affichés changent, il faut 'nettoyer' les emails sélectionnés pour éviter les mélanges par la suite
            setMailSelection([]);
            //Quand on change de type de boîte mail, il faut se remettre en affichage liste
            setBodyDisplay(bodyOptions.LIST_CONTENT);
        },
        [currentInboxType]
    );
    useEffect(
        function () {
            //Dès qu'on change de 'vue', on nettoie les emails sélectionnés (Peut-être pas obligatoire à voir)
            setMailSelection([]);
        },
        [showModal, bodyDisplay]
    );
    useEffect(
        //Lorsqu'on revient sur une boîte mail, il faut 'reset' l'email courant et son flux
        function () {
            if (bodyDisplay === bodyOptions.LIST_CONTENT) {
                setCurrentMail(null);
                setCurrentMailFlux([]);
            }
        },
        [bodyDisplay]
    );
    //-------------------------------------------//
    //---------- Fonctions globales de gestion de la vue ----------//

    // Fonctions de communication vers l'extérieur de la vue
    function synchronizeMailBox() {
        //On fait la même chose que dans loadData
        setIsSynchronizing(true);
        setErrorLoading(false);
        props
            .request({
                selected_client: null,
            })
            .then(function (response) {
                const data = response.data;
                console.log("MAILS ", data);
                setViewData(data);
            })
            .catch(function (error) {
                console.error("Echec lors de la synchronization des mails");
                console.error(error);
            })
            .finally(function () {
                setIsSynchronizing(false);
            });
        if (sendMailError) {
            //Quand on refresh la boîte, si l'envoi des mails n'est pas chargé, on essaye de le recharger
            loadSendMailDisplay();
        }
    }
    function loadFlux(selectedMail) {
        setLoadFluxError(false);
        setIsLoadingFlux(true);
        props
            .request({
                selected_client: null,
                action: "get_flux",
                data: {
                    msg_flux_mail_id: selectedMail.msg_flux_mail_id,
                },
            })
            .then(function (response) {
                const data = response.data;
                console.log("FLUX FLUX", data);
                setCurrentMailFlux(data);
            })
            .catch(function (error) {
                console.error(error);
                setLoadFluxError(true);
                setCurrentMailFlux([]);
            })
            .finally(function () {
                setIsLoadingFlux(false);
            });
    }
    function postMessage(event) {
        event.preventDefault();
        setIsSynchronizing(true);
        setIsSendingMail(true);
        const postObject = object || {};
        props
            .request({
                action: "post_client_mail_data",
                selected_client: null,
                data: {
                    to_ent_entity_id: postObject.ent_entity_id,
                    subject: postObject.name,
                    message: message,
                    msg_flux_mail_id: msgFluxId,
                },
            })
            .then(function (response) {
                //console.log("MAIL INSERTED", response);
                props.msgBox("Votre email a bien été envoyé", "info", "msg").then(function () {
                    handleClose();
                    setIsSendingMail(false);
                    switchInboxType("inbox");
                });
                if (currentMail) {
                    //Quand on envoie un mail, on ré-actualise le flux du mail visualisé si il existe
                    loadFlux(currentMail);
                }
                synchronizeMailBox();
            })
            .catch(function (error) {
                props.msgBox(`Un problème s'est produit lors de l'envoi du mail :\n${error.msg}`, "error", "msg");
                console.error("Erreur lors de l'envoi du mail (post_client_mail_data)");
                console.error(error.msg);
                setIsSynchronizing(false);
                setIsSendingMail(false);
            });
    }
    function markAsRead(selectedMail) {
        setIsSynchronizing(true);
        props
            .request({
                action: "post_mail_as_read",
                selected_client: null,
                data: {
                    mail: selectedMail,
                },
            })
            .then(function (response) {
                synchronizeMailBox();
            })
            .catch(function (error) {
                console.error("Erreur lors du marquage du message comme lu (post_mail_as_read)");
                console.error(error.msg);
                setIsSynchronizing(false);
            });
    }
    function markAsMarked(selectedMail, markState) {
        setIsSynchronizing(true);
        props
            .request({
                action: "post_mail_as_marked",
                selected_client: null,
                data: {
                    mail: selectedMail,
                    mark_state: +markState, //En entrée de cette fonction markSate est un booléen (donc ici on aura soit 0 ou 1)
                },
            })
            .then(function (response) {
                synchronizeMailBox();
            })
            .catch(function (error) {
                console.error("Erreur lors du marquage du message comme marqué (post_mail_as_marked)");
                console.error(error.msg);
                setIsSynchronizing(false);
            });
    }
    function changeStatusMailList(mailList, statusCode) {
        setIsSynchronizing(true);
        props
            .request({
                action: "post_change_status_code_mails",
                selected_client: null,
                data: {
                    mail_list: mailList || [],
                    status_code: statusCode,
                },
            })
            .then(function (response) {
                synchronizeMailBox();
            })
            .catch(function (error) {
                console.error("Erreur lors du changement de status des emails (post_change_status_code_mails)");
                console.error(error.msg);
                setIsSynchronizing(false);
            });
    }

    // Fonctions de réaction à des évènements
    function handleClose() {
        setShowModal(false);
        setMessage("");
        setMsgFluxId(null);
        setCurrentPopupType(null);
        setTarget(null);
        setObject(null);
    }
    function handleModalShow(popupType) {
        setCurrentPopupType(popupType);
        setShowModal(true);
    }
    function handleClickNewMessage() {
        let newTarget = null;
        let newObject = null;
        if (diffusionListData && Object.keys(diffusionListData).length > 0) {
            newTarget = deepClone(diffusionListData[Object.keys(diffusionListData)[0]]);
            if (newTarget && newTarget.object) {
                newObject = newTarget.object[Object.keys(newTarget.object)[0]] || null;
            }
        }
        setTarget(newTarget);
        setObject(newObject);
        handleModalShow(popupType.NEW_MESSAGE);
    }
    function handleChangeTarget(targetId) {
        let newTarget = deepClone(diffusionListData[targetId]);
        let newObject = null;
        if (newTarget && newTarget.object) {
            //Il faut mettre un objet associé à la liste de diffusion sélectionnée si il existe
            newObject = newTarget.object[Object.keys(newTarget.object)[0]] || null;
        }
        setTarget(newTarget);
        setObject(newObject);
    }
    function onClickMailList(selectedMail) {
        console.log("SELECTED MAIL : ", selectedMail);
        setCurrentMail(selectedMail);
        //setBodyDisplay(bodyOptions.CURRENT_MAIL_CONTENT);
        setBodyDisplay(bodyOptions.CURRENT_FLUX_CONTENT);
        if (/*!selectedMail.is_automatic &&*/ selectedMail.msg_flux_mail_id > 0) {
            loadFlux(selectedMail);
        }
        if (!selectedMail.read_at && !selectedMail.is_sender) {
            //Si le mail n'est pas lu et que c'est un mail reçu (peu importe qu'il soit en 'trash' ou en 'inbox')
            markAsRead(selectedMail);
        }
    }
    function onClickSelect(selectedMail, active) {
        let newState = mailSelection.filter(function (mailInSelection) {
            return !(mailInSelection.id === selectedMail.id);
        });
        if (active) {
            newState.push(selectedMail);
        }
        setMailSelection(newState);
    }
    function switchInboxType(inboxType) {
        setCurrentInboxType(inboxType);
        //setBodyDisplay(bodyOptions.LIST_CONTENT);
    }
    function onClickRespond(mail) {
        const isSource = mail.is_sender;
        const responseObject = {
            id: 1,
            ent_entity_id: isSource ? mail.to_ent_entity_id : mail.from_ent_entity_id,
            name: mail.subject,
            display_name: isSource ? mail.display_to : mail.display_from,
        };
        setMsgFluxId(mail.msg_flux_mail_id);
        setObject(responseObject);
        handleModalShow(popupType.RESPONDING);
    }
    function onClickReturnBtn() {
        switch (bodyDisplay) {
            /*case bodyOptions.CURRENT_FLUX_CONTENT:
                setBodyDisplay(bodyOptions.CURRENT_MAIL_CONTENT);
                break;*/
            default:
                setBodyDisplay(bodyOptions.LIST_CONTENT);
                break;
        }
    }
    function onClickSeeFlux() {
        setBodyDisplay(bodyOptions.CURRENT_FLUX_CONTENT);
    }
    //Fonctions de rendu
    function MailContent(mail) {
        //Utilisée pour le rendu de l'aperçu d'un message
        return (
            <Container className={"mail-list-cont"}>
                <Row className={`mail-list-item-content ${!mail.is_sender && !mail.read_at ? "unread" : ""}`}>
                    <Col xs={1} md={2} className="mail-list-item-content-buttons">
                        <PortalCheck
                            onChange={function (active, event) {
                                onClickSelect(mail, active);
                            }}
                            disabled={isLoading || isSynchronizing}
                            active={
                                mailSelection.findIndex(function (selectionRecord) {
                                    return selectionRecord.id === mail.id;
                                }) === -1
                                    ? false
                                    : true
                            }
                            aria-label="Sélectionner ce mail"
                        />
                        {currentInboxType === "inbox" ? (
                            <PortalCheck
                                activeIcon="far fa-envelope-open"
                                baseIcon="far fa-envelope"
                                active={mail.read_at}
                                aria-label={mail.read_at ? "Email lu" : "Email non lu"}
                            />
                        ) : (
                            ""
                        )}
                        <PortalCheck
                            activeIcon="fas fa-star"
                            baseIcon="far fa-star"
                            active={parseInt(mail.marked) > 0}
                            onChange={function (active, event) {
                                markAsMarked(mail, active);
                            }}
                            disabled={isLoading || isSynchronizing}
                            aria-label="Marquer ce mail"
                        />
                    </Col>
                    <Col xs={9} className="mail-list-item-content-body">
                        <div className="mail-list-item-content-group">
                            <p className="mail-list-item-content-sender">
                                {currentInboxType === "inbox" || currentInboxType === "trash" ? <p>De : {mail.display_from}</p> : ""}
                                {currentInboxType === "sent" || currentInboxType === "trash" ? <p>À : {mail.display_to}</p> : ""}
                            </p>
                            <p className="mail-list-item-content-subject">
                                {(mail.is_automatic ? "Email automatique - " : "") + mail.subject}
                            </p>
                        </div>
                        <p className="mail-list-item-content-receive-at">
                            {mail?.receive_at ? (
                                <>
                                    <p>{new Date(mail.receive_at).toLocaleDateString("FR-fr")}</p>
                                    <p>{new Date(mail.receive_at).toLocaleTimeString("FR-fr")}</p>
                                </>
                            ) : (
                                ""
                            )}
                        </p>
                    </Col>

                    {mail.par_statuscode_id === 0 ? (
                        <Col xs={1} className="mail-list-item-content-buttons">
                            <BtnToolTip
                                iconCls="fas fa-trash"
                                onClick={function (e) {
                                    e.stopPropagation(); //To prevent firing item mail selection
                                    changeStatusMailList([mail], +mail.par_statuscode_id === 0 ? 1 : 2);
                                }}
                                disabled={isSynchronizing || isLoading}
                                aria-label={
                                    mail.par_statuscode_id === 0 ? "Envoyer ce mail dans la corbeille" : "Supprimer définitivement ce mail"
                                }
                            />
                        </Col>
                    ) : (
                        ""
                    )}
                </Row>
            </Container>
        );
    }
    function buildMailOptions() {
        let dynamicOptions = [];
        let globalSelectBtn = ""; //Construction du bouton de sélection/désélection global des mails
        switch (true) {
            case mailSelection.length === mailList.length:
                //Tous les messages affichés sont sélectionnés
                globalSelectBtn = (
                    <BtnToolTip
                        iconCls="fas fa-check-square"
                        onClick={() => setMailSelection([])}
                        disabled={isSynchronizing || isLoading}
                        aria-label="Désélectionner tous les mails"
                    />
                );
                break;
            case mailSelection.length === 0:
                //Aucun mail n'est sélectionné
                globalSelectBtn = (
                    <BtnToolTip
                        iconCls="far fa-square"
                        onClick={() => setMailSelection([...mailList])}
                        disabled={isSynchronizing || isLoading}
                        aria-label="Sélectionner tous les mails"
                    />
                );
                break;
            default:
                //Certains messages sont sélectionnés
                globalSelectBtn = (
                    <BtnToolTip
                        iconCls="fas fa-square"
                        onClick={() => setMailSelection([...mailList])}
                        disabled={isSynchronizing || isLoading}
                        aria-label="Sélectionner tous les mails"
                    />
                );
                break;
        }
        let historizeBtn = //Bouton pour mettre les mails sélectionnés à la corbeille
            (
                <BtnToolTip
                    iconCls="far fa-trash-alt"
                    onClick={() => changeStatusMailList(mailSelection, 1)}
                    disabled={isLoading || isSynchronizing}
                    aria-label="Envoyer les mails sélectionnés dans la corbeille"
                />
            );
        let deleteBtn = //Bouton pour supprimer les mails sélectionnés définitivement
            (
                <BtnToolTip
                    onClick={() => changeStatusMailList(mailSelection, 2)}
                    disabled={isLoading || isSynchronizing}
                    aria-label="Supprimer définitivement les mails sélectionnés"
                >
                    Supprimer définitivement
                </BtnToolTip>
            );
        let cleanTrashBtn = //Bouton pour supprimer tous les mails se trouvant dans la corbeille
            (
                <BtnToolTip
                    onClick={() => changeStatusMailList(viewData.trash, 2)}
                    disabled={isLoading || isSynchronizing}
                    aria-label="Supprimer définitivement les mails sélectionnés"
                >
                    Vider la corbeille
                </BtnToolTip>
            );
        let restoreBtn = //Bouton pour restaurer les mails sélectionnés (repasser à un status_code à 0)
            (
                <BtnToolTip
                    onClick={() => changeStatusMailList(mailSelection, 0)}
                    disabled={isLoading || isSynchronizing}
                    aria-label="Restaurer les mails sélectionnés"
                >
                    Restaurer
                </BtnToolTip>
            );
        let filterCombo = //Combo pour switcher de mode de visualisation //TODO: Mettre un PortalCombo
            (
                <Form.Control
                    as="select"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    aria-label="Sélectionner le type de filtre"
                >
                    <optgroup label="Liste des filtres">
                        <option value="all">Voir tous les messages</option>
                        <option value="read">Voir uniquement les messages non-lus</option>
                        <option value="marked">Voir uniquement les messages marqués</option>
                    </optgroup>
                </Form.Control>
            );
        let returnBtn = (
            <BtnToolTip onClick={onClickReturnBtn} disabled={isLoading || isSynchronizing}>
                Retour
            </BtnToolTip>
        );

        //Séquence de construction des options dynamiques
        if (bodyDisplay === bodyOptions.LIST_CONTENT) {
            //On est en vue liste
            if (mailList.length > 0) {
                //Quand il y a des messages à afficher
                dynamicOptions.push(globalSelectBtn); //Quelquesoit le type d'inbox
                switch (currentInboxType) {
                    case "trash":
                        if (mailSelection.length > 0) {
                            //On a sélectionné au moins un des mails de la liste
                            dynamicOptions.push(restoreBtn);
                            dynamicOptions.push(deleteBtn);
                            dynamicOptions.push(cleanTrashBtn);
                        } else {
                            dynamicOptions.push(cleanTrashBtn);
                        }
                        break;
                    default:
                        //Quand on est en 'inbox' ou 'sent'
                        if (mailSelection.length > 0) {
                            //On a sélectionné au moins un des mails de la liste
                            dynamicOptions.push(historizeBtn);
                        }
                        break;
                }
                dynamicOptions.push(filterCombo); //Quelquesoit le type d'inbox
            } else {
                //La liste des messages est vide
                dynamicOptions.push(filterCombo); //On n'affiche que le filtre
            }
        } else {
            //On visualise un message
            dynamicOptions.push(returnBtn);
        }
        return (
            <>
                <BtnToolTip
                    iconCls={`fas fa-sync-alt ${isSynchronizing || isLoading ? "rotate" : ""}`}
                    onClick={synchronizeMailBox}
                    disabled={isSynchronizing || isLoading}
                    aria-label="Synchroniser la boîte mail"
                />
                {dynamicOptions}
            </>
        );
    }
    function buildMailFooter() {
        let btnRespond = (
            <PortalBtn onClick={() => onClickRespond(currentMail)} disabled={!sendMailReady}>
                Répondre
            </PortalBtn>
        );
        //Construction du btnSeeFlux
        let btnSeeFlux = (
            <PortalBtn
                state={isLoadingFlux ? "loading" : loadFluxError ? "error" : ""}
                loadingTxt="Chargement du fil de discussion"
                errorTxt="Fil de discussion indisponible"
                onClick={onClickSeeFlux}
                disabled={!currentMailFlux}
            >
                Voir le fil de discussion
            </PortalBtn>
        );
        //Séquence de construction des options dynamiques
        let dynamicOptions = [];
        dynamicOptions.push(btnSeeFlux);
        if (!currentMail.is_automatic) {
            dynamicOptions.push(btnRespond);
        }
        return dynamicOptions;
    }
    function buildListContentDisplay() {
        //Affichage de la liste des mails
        let listContentDisplay;
        if (mailList.length <= 0) {
            listContentDisplay = (
                <Container className="mail-list-container-empty">
                    <p>Aucun message à afficher</p>
                </Container>
            );
        } else {
            listContentDisplay = (
                <Container className="mail-list-container">
                    <Row>
                        <List
                            dataSource={mailList}
                            height={700}
                            showSelectionControls={true}
                            onItemClick={function (e) {
                                //e.stopPropagation();
                                onClickMailList(e.itemData);
                            }}
                            itemRender={MailContent}
                            disabled={isLoading || isSynchronizing}
                        ></List>
                    </Row>
                </Container>
            );
        }
        return listContentDisplay;
    }
    function buildCurrentMailContentDisplay() {
        //Construction de l'affichage du contenu d'un email
        return (
            <div className="mail-display-container">
                <div className="mail-display-header">
                    <h4 className="mail-list-content-subject">
                        {(currentMail.is_automatic ? "Email automatique - " : "") + currentMail.subject}
                    </h4>
                    <div>De : {currentMail.display_from}</div>
                    <div>À : {currentMail.display_to}</div>
                    <div>
                        Le :
                        {`${new Date(currentMail.receive_at).toLocaleDateString("FR-fr")} à ${new Date(
                            currentMail.receive_at
                        ).toLocaleTimeString("FR-fr")}`}
                    </div>
                </div>
                <div className="mail-display-body">
                    <div dangerouslySetInnerHTML={{ __html: currentMail.message }} />
                </div>
                <div className="mail-display-footer">{buildMailFooter()}</div>
            </div>
        );
    }
    function buildFluxDisplay() {
        //Construction de l'affichage du flux
        let mailHistoryDisplay = [];
        let isSource = false;
        let dateMail;
        if (isLoadingFlux) {
            return <InitView type="loading" loadingMsg="Chargement du flux" />;
        }
        if (loadFluxError) {
            return <InitView type="errorLoading" errorMsg="Erreur lors du chargement du flux" />;
        }
        if (currentMailFlux.length < 1) {
            return <InitView type="errorLoading" errorMsg="Aucun flux pour ce message" />;
        }
        currentMailFlux.map(function (formerMail) {
            isSource = formerMail.is_sender;
            dateMail = new Date(formerMail.receive_at);
            mailHistoryDisplay.push(
                <div className={"msg-flux-container" + (isSource ? " source" : "")}>
                    <h1 className="msg-header">{isSource ? "Vous avez écrit :" : formerMail.display_from}</h1>
                    <p className="msg-content" dangerouslySetInnerHTML={{ __html: formerMail.message }}></p>
                    <div className="msg-footer">
                        {`${isSource ? "Envoyé" : "Reçu"} le ${dateMail.toLocaleDateString("FR-fr")} à ${dateMail.toLocaleTimeString(
                            "FR-fr"
                        )}`}
                    </div>
                </div>
            );
        });
        return (
            <div className="flux-display-container">
                <div className="flux-display-body">{mailHistoryDisplay}</div>
                <div className="flux-display-footer">
                    {!currentMail.is_sender && !currentMail.is_automatic ? (
                        //Pour l'instant on ne renvoie pas de message sur le même flux quand on visualise l'un de nos messages
                        <PortalBtn onClick={() => onClickRespond(currentMail)} disabled={!sendMailReady}>
                            Répondre
                        </PortalBtn>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
    function buildPopupFooterContent() {
        return (
            <ItemRow fill="stretch">
                <PortalBtn
                    type="submit"
                    iconCls="far fa-paper-plane"
                    state={isSendingMail ? "loading" : sendMailError ? "error" : ""}
                    loadingTxt="Envoi du message"
                    errorTxt="Envoi d'email indisponible"
                    onClick={postMessage}
                    className="validate"
                    disabled={isLoading || isSynchronizing || isSendingMail || !sendMailReady}
                >
                    Envoyer
                </PortalBtn>
                <PortalBtn onClick={handleClose}>Annuler</PortalBtn>
            </ItemRow>
        );
    }
    function buildPopupNewEmail() {
        let optionsTargets;
        if (target) {
            optionsTargets = (
                <Form.Control
                    id="contactTarget"
                    as="select"
                    defaultValue={target.id}
                    onChange={(e) => handleChangeTarget(e.target.value)}
                    autoComplete="off"
                    aria-required={true}
                >
                    <optgroup label="Liste des contacts">
                        {Object.keys(diffusionListData).map((id) => (
                            <option key={diffusionListData[id].id} value={diffusionListData[id].id}>
                                {diffusionListData[id].name}
                            </option>
                        ))}
                    </optgroup>
                </Form.Control>
            );
        } else {
            optionsTargets = (
                <Form.Control id="contactTarget" as="select" value={0} disabled={true}>
                    <option value={0}>Aucun contact disponible</option>
                </Form.Control>
            );
        }
        let optionsObjects;
        if (!object) {
            //console.log("LE FAIL", object);
            optionsObjects = (
                <Form.Control id="contactObject" as="select" value={0} disabled={true}>
                    <option value={0}>Aucun objet disponible</option>
                </Form.Control>
            );
        } else {
            //console.log("OBJECT", object);
            optionsObjects = (
                <Form.Control
                    id="contactObject"
                    as="select"
                    defaultValue={object.id}
                    onChange={(e) => setObject(target.object[e.target.value])}
                    aria-required={true}
                    autoComplete="off"
                >
                    <optgroup label="Motifs de contacts">
                        {Object.keys(target.object).map((i) => (
                            <option key={target.object[parseInt(i)].id} value={target.object[parseInt(i)].id}>
                                {target.object[parseInt(i)].name}
                            </option>
                        ))}
                    </optgroup>
                </Form.Control>
            );
        }
        return (
            <Form className="popup-form">
                <Form.Group controlId="contactTarget">
                    <Form.Label>Contacter</Form.Label>
                    {optionsTargets}
                </Form.Group>
                <Form.Group controlId="contactObject">
                    <Form.Label>Objet</Form.Label>
                    {optionsObjects}
                </Form.Group>
                <Form.Group className="new-mail-content-section" controlId="messageBody">
                    <Form.Label>Votre message</Form.Label>
                    <PortalText id="messageBody" as="textarea" defaultValue={message} onChange={(e) => setMessage(e.target.value)} />
                </Form.Group>
            </Form>
        );
    }
    function buildPopupRespondToMail() {
        return (
            <Form className="popup-form">
                <Form.Group className="new-mail-content-section" controlId="messageBody">
                    <Form.Label>Votre message</Form.Label>
                    <PortalText id="messageBody" as="textarea" defaultValue={message} onChange={(e) => setMessage(e.target.value)} />
                </Form.Group>
            </Form>
        );
    }
    //-------------------------------------------------------------//
    //---------- Traitements de pré-rendu ----------//
    let popupContent;
    let popupTitle;
    switch (currentPopupType) {
        case popupType.NEW_MESSAGE:
            popupContent = buildPopupNewEmail();
            popupTitle = "Nouveau message";
            break;
        case popupType.RESPONDING:
            popupContent = buildPopupRespondToMail();
            popupTitle = `À : ${object?.display_name || "Inconnu"}`;
            break;
        default:
            popupContent = null;
            popupTitle = "";
            break;
    }
    let content;
    if (isLoading) {
        content = <InitView type="loading" autoFocus />;
    } else if (errorLoading) {
        content = <InitView type="errorLoading" />;
    } else {
        switch (bodyDisplay) {
            case bodyOptions.LIST_CONTENT:
                content = buildListContentDisplay();
                break;
            case bodyOptions.CURRENT_MAIL_CONTENT:
                content = buildCurrentMailContentDisplay();
                break;
            case bodyOptions.CURRENT_FLUX_CONTENT:
                content = buildFluxDisplay();
                break;
            default:
                content = null;
                break;
        }
    }
    //----------------------------------------------//
    //---------- Rendu de la vue ----------//
    return (
        <div id="client_mail">
            {props.setStyle("./normal")}
            <PopupView
                footerRender={buildPopupFooterContent}
                className="mail-popup"
                onHiding={handleClose}
                showCloseButton={true}
                title={popupTitle}
                visible={showModal}
            >
                {popupContent}
            </PopupView>
            <div className="mail-section">
                <Container className="mail-section-container">
                    <Row xs={1}>
                        <Col md={3} className="mail-panel-container">
                            <Row>
                                <Col className="row-button-new-message">
                                    <PortalBtn
                                        iconCls="far fa-envelope"
                                        state={sendMailError ? "error" : !sendMailReady ? "loading" : ""}
                                        loadingTxt="Chargement..."
                                        errorMsg="Envoi d'email indisponible"
                                        onClick={handleClickNewMessage}
                                        className="button-new-message"
                                    >
                                        Nouveau message
                                    </PortalBtn>
                                </Col>
                            </Row>
                            <Row xs={1} className="mail-panel-container-header">
                                <Col>
                                    <Button
                                        className={
                                            "mail-panel-container-header-button " + (currentInboxType === "inbox" ? "selected-box" : "")
                                        }
                                        onClick={() => switchInboxType("inbox")}
                                    >
                                        <span className="fas fa-inbox"></span>
                                        Messages reçus
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className={
                                            "mail-panel-container-header-button " + (currentInboxType === "sent" ? "selected-box" : "")
                                        }
                                        onClick={() => switchInboxType("sent")}
                                    >
                                        <span className={"fas fa-paper-plane"}></span>
                                        Messages envoyés
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className={
                                            "mail-panel-container-header-button " + (currentInboxType === "trash" ? "selected-box" : "")
                                        }
                                        onClick={() => switchInboxType("trash")}
                                    >
                                        <span className={"fas fa-trash-alt"}></span>
                                        Corbeille
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            {/*
                                            <Col xs={2}>
                                                <span className="fas fa-info-circle"></span>
                                            </Col>
                                            <Col xs={10}>
                                                <p>
                                                    Aucune information relative à la santé du bénéficiaire ne doit être communiquée par
                                                    message
                                                </p>
                                            </Col>*/}
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={9} className="mail-block-content-container">
                            <div className="mail-options-container">{buildMailOptions()}</div>
                            <div className="mail-content-container">{content}</div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
    //-------------------------------------//
}
export default MainMails;
