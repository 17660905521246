import React from "react";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import ImgBackground from "../../../../../../../../ressources/components/imgBackground/imgBackground";
export default function SuccessContactAdd(props) {
    const returnHome = props.returnHome;
    const contactForm = props.contactForm || {};
    const formatContactName = props.formatContactName;
    //const contactName = formatContactName(contactForm);
    React.useEffect(function () {
        props.scrollLayout(0);
    }, []);
    return (
        <div className="successContactAddContainer">
            <div className="block left">
                <div>
                    <p className="successTxt">{props.i18n("title.success_contact_add")}</p>
                    <p className="desc">
                        {contactForm.add_rol_contact_permission
                            ? props.i18n("info.success_ask_contact_permission")
                            : props.i18n("info.success_add_contact")}
                    </p>
                </div>
                <PortalBtn iconCls="fas fa-arrow-left" className="returnBtn reverse" onClick={returnHome}>
                    Retour à la liste
                </PortalBtn>
            </div>
            <div className="block right">
                <ImgBackground className="successAddImg" />
            </div>
        </div>
    );
}
