//######################################## Imports ########################################//
//External
import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//Custom
import { formValidator, editFormState, isEmailType, formatAsaproDate } from "../../../../ressources/lib/js/globalFunctions";
import { selectPscConnect, selectFconnect } from "../../../reducers/public/public.js";
//Screens
import BaseScreen from "./screens/base/screen.js";
import YvelinesDesktopScreen from "./screens/v_yvelines/desktop/screen.js";
//#########################################################################################//
export default function Controller(props) {
    //Url format to access this view in client mode : https://${portalDns}#register
    //Url format to access this view in medic mode : https://${portalDns}?portalType=medic#register
    const portalType = props.portalType;
    const showcaseList = props.showcaseList;
    const defaultFormData = {
        last_name: "",
        first_name: "",
        username: "",
        referencedossier: "", //For clients
        ssn_number_patient: "", //For clients => Numéro de sécurité sociale
        rpps: "", //For professionals
        birth_datetime: "",
        password: "",
        password_confirm: "",
        cgu: false,
        consent: false,
        rgpd: false,
    };
    const pscConnect = useSelector(selectPscConnect);
    const fConnect = useSelector(selectFconnect);
    const [step, setStep] = useState("modeChoice"); //"modeChoice" || "localCreation" || "end"
    const [initFormData, _setInitFormData] = useState(defaultFormData);
    function setInitFormData(form) {
        switch (true) {
            case !form:
                _setInitFormData(null);
            default:
                Object.keys(form).forEach(function (formKey) {
                    defaultFormData[formKey] = form[formKey];
                });
                _setInitFormData(defaultFormData);
                break;
        }
    }
    const [formData, _setFormData] = useState(initFormData);
    const [formValidationCount, setFormValidationCount] = useState(0);
    const [formModificationCount, setFormModificationCount] = useState(0);
    function setFormData(field, value) {
        editFormState(field, value, formData, _setFormData);
        setFormModificationCount(formModificationCount + 1);
    }
    const clientFormValidator = new formValidator({
        last_name: [{ conditionCode: "required_last_name", validate: () => !!formData.last_name }],
        first_name: [{ conditionCode: "required_first_name", validate: () => !!formData.first_name }],
        referencedossier: [{ conditionCode: "required_file_number", validate: () => !!formData.referencedossier }],
        ssn_number_patient: [{ conditionCode: "required_ssn_number_patient", validate: () => true /*!!formData.ssn_number_patient*/ }],
        rpps: [{ conditionCode: "required_rpps", validate: () => true }],
        birth_datetime: [{ conditionCode: "required_birth_datetime", validate: () => !!formData.birth_datetime }],
        username: [
            { conditionCode: "required_username", validate: () => !!formData.username },
            { conditionCode: "username_is_email_type", validate: () => isEmailType(formData.username) },
        ],
        cgu: [{ conditionCode: "required_cgu_agreement", validate: () => !!formData.cgu }],
        consent: [{ conditionCode: "required_consent", validate: () => !!formData.consent }],
        rgpd: [{ conditionCode: "required_rgpd_agreement", validate: () => !!formData.rgpd }],
    });
    const proFormValidator = new formValidator({
        last_name: [{ conditionCode: "required_last_name", validate: () => !!formData.last_name }],
        first_name: [{ conditionCode: "required_first_name", validate: () => !!formData.first_name }],
        referencedossier: [{ conditionCode: "required_file_number", validate: () => true }],
        ssn_number_patient: [{ conditionCode: "required_ssn_number_patient", validate: () => true }],
        rpps: [{ conditionCode: "required_rpps", validate: () => !!formData.rpps }],
        birth_datetime: [{ conditionCode: "required_birth_datetime", validate: () => !!formData.birth_datetime }],
        username: [
            { conditionCode: "required_username", validate: () => !!formData.username },
            { conditionCode: "username_is_email_type", validate: () => isEmailType(formData.username) },
        ],
        cgu: [{ conditionCode: "required_cgu_agreement", validate: () => !!formData.cgu }],
        consent: [{ conditionCode: "required_consent", validate: () => !!formData.consent }],
        rgpd: [{ conditionCode: "required_rgpd_agreement", validate: () => !!formData.rgpd }],
    });
    const [registeredUsername, setRegisteredUsername] = useState("");
    //Effect treatments
    useEffect(
        function () {
            _setFormData(initFormData);
            if (!initFormData) {
                setFormValidationCount(0);
                setFormModificationCount(0);
            }
        },
        [initFormData]
    );
    useEffect(
        function () {
            setFormModificationCount(0);
            setFormValidationCount(0);
        },
        [step]
    );
    //Communication functions
    function registerClient() {
        console.warn(formData);
        setFormValidationCount(formValidationCount + 1);
        const validationRes = clientFormValidator.validate();
        if (!validationRes.success) {
            //props.msgBox(props.i18n("error." + validationRes.errors[0]), "error", "msg", "Erreur");
            props.msgBox(props.i18n("error.invalid_form"), "error", "msg", "Erreur");
            return;
        }
        props.setRequestState("loading");
        props
            .request({
                action: "post_register_client",
                data: formData,
            })
            .then(function (res) {
                setRegisteredUsername(res?.data?.username);
                setStep("end");
            })
            .catch(function (err) {
                console.error(err.msg);
                props.msgBox(`Échec de l'inscription : ${err.msg}`, "error", "msg");
            })
            .finally(function () {
                props.setRequestState("");
            });
    }
    function registerPro() {
        console.warn(formData);
        setFormValidationCount(formValidationCount + 1);
        const validationRes = proFormValidator.validate();
        if (!validationRes.success) {
            props.msgBox(props.i18n("error." + validationRes.errors[0]), "error", "msg", "Erreur");
            return;
        }
        props.setRequestState("loading");
        props
            .request({
                action: "post_register_pro",
                data: formData,
            })
            .then(function (res) {
                setRegisteredUsername(res?.data?.username);
                setStep("end");
            })
            .catch(function (err) {
                console.error(err.msg);
                props.msgBox(`Échec de l'inscription : ${err.msg}`, "error", "msg");
            })
            .finally(function () {
                props.setRequestState("");
            });
    }
    function registerEmployee() {
        props.msgBox("Création d'un compte intervenant de la structure non disponible", "error", "msg");
        return;
    }
    function postRegister() {
        switch (portalType) {
            case "client":
                return registerClient();
            case "pro":
                return registerPro();
            case "employee":
                return registerEmployee();
            default:
                props.msgBox("Type de compte à créer inconnu", "error", "msg");
                return;
        }
    }
    function openCgu() {
        //DEPRECATED
        console.log(showcaseList);
        let cguItem = showcaseList.find(function (item) {
            return item?.content_param_id === 9014;
        });
        let cguParamId = cguItem?.content_param_id;
        let cguParamTitle = cguItem?.title;
        if (cguParamId) {
            /*props.redirectTo("showcase_view", {
                admParameterId: cguParamId,
                viewTitle: cguParamTitle,
            });*/
            //we open a new tab
            window.open(
                window.location.origin + "/#/showcase_view?admParameterId=" + cguParamId + "&viewTitle=" + cguParamTitle,
                "__blank"
            );
        }
    }
    function getCguData() {
        //console.log(showcaseList);
        let cguItem = showcaseList.find(function (item) {
            return item?.content_param_id === 9014;
        });
        const cguParamId = cguItem?.content_param_id;
        const cguParamTitle = cguItem?.title;
        return {
            content_param_id: cguParamId,
            title: cguParamTitle,
            url: "/#/showcase_view?admParameterId=" + cguParamId + "&viewTitle=" + cguParamTitle,
        };
    }
    function getRgpdData() {
        let rgpdItem = showcaseList.find(function (item) {
            return item?.content_param_id === 9017;
        });
        const rgpdParamId = rgpdItem?.content_param_id;
        const rgpdParamTitle = rgpdItem?.title;
        return {
            content_param_id: rgpdParamId,
            title: rgpdParamTitle,
            url: "/#/showcase_view?admParameterId=" + rgpdParamId + "&viewTitle=" + rgpdParamTitle,
        };
    }
    const viewControllerProps = {
        postRegister: postRegister,
        openCgu: openCgu, //DEPRECATED
        cguData: getCguData(),
        rgpdData: getRgpdData(),
        registeredUsername: registeredUsername,
        step: step,
        setStep: setStep,
        initFormData: initFormData,
        setInitFormData: setInitFormData,
        formData: formData,
        setFormData: setFormData,
        formModificationCount: formModificationCount,
        formValidationCount: formValidationCount,
        formValidator: portalType !== "pro" ? clientFormValidator : proFormValidator, //TODO: Mettre un validateur dynamique en fonction du portalType
        pscConnect: pscConnect,
        fConnect: fConnect,
    };
    switch (props.appTheme) {
        case "yvelines":
            return <YvelinesDesktopScreen {...props} {...viewControllerProps} />;
        default:
            //return <YvelinesDesktopScreen {...props} {...viewControllerProps} />;
            return <BaseScreen {...props} {...viewControllerProps} />;
    }
}
