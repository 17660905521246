import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import BtnToolTip from "../../../../../../../ressources/components/btnToolTip/btnToolTip";
import NewBtn from "../../../../../../../ressources/components/btnToolTip/newBtn/newBtn";
import Popup from "../../../../../../../ressources/components/popupView/popupView";
import PortalToolbar from "../../../../../../../ressources/components/portalToolbar/portalToolbar";
import PortalList from "../../../../../../../ressources/components/portalList/portalList";
import PortalCombo from "../../../../../../../ressources/components/portalFields/portalCombo/portalCombo";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText";
import MsgFlux from "../../../components/msgFlux";
import { isArray, isObject, crudDisplay, formatName, isDate } from "../../../../../../../ressources/lib/js/globalFunctions";
export default function Screen(props) {
    const storeSortingType = props.storeSortingType;
    const setStoreSortingType = props.setStoreSortingType;
    const selectedClient = props.selectedClient;
    const actionAllowed = props.actionAllowed;
    const store = props.store || {};
    const refresh = props.refresh;
    const refreshState = props.refreshState;
    const requestState = props.requestState;
    const initTopicForm = props.initTopicForm;
    const setInitTopicForm = props.setInitTopicForm;
    const topicForm = props.topicForm;
    const setTopicForm = props.setTopicForm;
    const openedTopic = props.openedTopic;
    const setOpenedTopic = props.setOpenedTopic;
    const postEditTopic = props.postEditTopic;
    const postDeleteTopic = props.postDeleteTopic;

    // UX event handlers
    function closePopup() {
        setInitTopicForm();
    }
    function onListItemClick(itemData) {
        //console.warn(itemData);
        setOpenedTopic(itemData);
    }
    function onSubmitPopup(e) {
        if (typeof e?.preventDefault === "function") e.preventDefault();
        postEditTopic()
            .then(function (res) {
                closePopup();
            })
            .catch(function (err) {});
    }
    //Pre-Rendering functions
    function displaySendingDate(rawSendingDate, unknownDateText) {
        //TODO: Est-ce qu'on remonte ça dans le controlleur ?
        const sendingDate = new Date(rawSendingDate);
        unknownDateText = unknownDateText || "Date inconnue";
        if (rawSendingDate && isDate(rawSendingDate)) {
            const now = new Date();
            const timeFromNow = now.getTime() - sendingDate.getTime(); //In milliseconds
            switch (true) {
                case timeFromNow <= 60 * 1000:
                    return "Il y a 1 minute";

                case timeFromNow <= 60 * 10 * 1000:
                    return `Il y a ${Math.floor(timeFromNow / (1000 * 60))} minutes`;

                case sendingDate.getDate() <= now.getDate() && timeFromNow <= 48 * 60 * 60 * 1000:
                    return `${sendingDate.getDate() == now.getDate() ? "Aujourd'hui" : "Hier"} à ${sendingDate.toLocaleTimeString(
                        "FR-fr"
                    )}`;

                default:
                    return `Le ${sendingDate.toLocaleDateString("FR-fr")} à ${sendingDate.toLocaleTimeString("FR-fr")}`;
            }
        } else {
            return unknownDateText;
        }
    }
    function getImportanceClass(impId) {
        return impId == 2 ? "imp-medium" : impId == 3 ? "imp-high" : "imp-normal";
    }
    function forumItemTitleRender(itemData) {
        function categoryRender(categoryIdList) {
            categoryIdList = isArray(categoryIdList) ? categoryIdList : [categoryIdList];
            let categoryItem,
                categoryDisplay = [];
            categoryIdList.forEach(function (categoryId, index) {
                if (isArray(store?.msg_category_id)) {
                    categoryItem = store.msg_category_id.filter((categoryItemTest) => categoryItemTest?.id == categoryId)[0];
                    if (isObject(categoryItem)) {
                        if (categoryItem.icon_cls)
                            categoryDisplay.push(
                                <>
                                    &nbsp;<span key={"category-" + categoryId} className={categoryItem.icon_cls}></span>
                                </>
                            );
                    }
                }
            });
            return categoryDisplay.length > 0 ? <>{categoryDisplay}&nbsp;-&nbsp;</> : "";
        }
        return (
            <div className="forum-title">
                {categoryRender(itemData?.msg_category_id)}
                {itemData?.subject}
            </div>
        );
    }
    function renderToolbarTitle() {
        switch (true) {
            case topicForm:

            case !!openedTopic:
                return forumItemTitleRender(openedTopic);
            default:
                return "Vos Forums de discussion";
        }
    }
    function renderStartTools() {
        const refreshBtn = (
            <BtnToolTip
                key="refreshBtn"
                iconCls={`fas fa-sync-alt${refreshState === "loading" || requestState === "loading" ? " rotate" : ""}`}
                disabled={refreshState == "loading"}
                onClick={(e) => refresh()}
            />
        );
        const returnBtn = (
            <BtnToolTip key="returnBtn" iconCls="" onClick={(e) => setOpenedTopic()} disabled={refreshState == "loading"}>
                Retour
            </BtnToolTip>
        );
        const newForumBtn = (
            <NewBtn
                key="newForumBtn"
                onClick={(e) => setInitTopicForm({})}
                disabled={refreshState == "loading"}
                displayActions={["add"]}
                activeActions={["add"]}
                actionAllowed={actionAllowed}
            >
                Nouveau Forum
            </NewBtn>
        );
        const toolsList = [refreshBtn];
        if (!openedTopic) {
            //Display forumList
            toolsList.push(newForumBtn);
        }
        if (!!openedTopic) {
            toolsList.push(returnBtn);
        }
        return toolsList;
    }
    function renderEndTools() {
        const modifyBtn = (
            <BtnToolTip
                key="modifyBtn"
                iconCls="fa fa-edit"
                onClick={(e) => setInitTopicForm(openedTopic)}
                disabled={refreshState == "loading"}
                displayActions={["upd"]}
                activeActions={["upd"]}
                actionAllowed={actionAllowed}
            >
                Modifier le forum
            </BtnToolTip>
        );
        const quitBtn = (
            <BtnToolTip
                key="quitBtn"
                className="quitBtn"
                iconCls="fas fa-lock"
                onClick={(e) => postDeleteTopic(openedTopic)}
                disabled={refreshState == "loading"}
                displayActions={["del"]}
                activeActions={["del"]}
                actionAllowed={actionAllowed}
            >
                Clôturer le forum
            </BtnToolTip>
        );
        const comboTest = (
            <div key="comboTest">
                <label key="forumSort-label" className="forumSort-label" htmlFor="forumSort">
                    Tri des forums
                </label>
                <PortalCombo
                    key="forumSort"
                    items={[
                        {
                            value: "lastMsgDate",
                            text: "Dernier message",
                        },
                        {
                            value: "imp",
                            text: "Importance",
                        },
                    ]}
                    inputName="forumSort"
                    defaultValue={storeSortingType}
                    onChange={function (event) {
                        setStoreSortingType(event.value);
                    }}
                    disabled={refreshState == "loading"}
                />
            </div>
        );
        const toolsList = [];
        if (!openedTopic) {
            toolsList.push(comboTest);
        }
        if (!!openedTopic) {
            toolsList.push(modifyBtn);
            toolsList.push(quitBtn);
        }
        return toolsList;
    }
    function forumItemRender(itemData) {
        return (
            <div className="forum-title-container">
                {forumItemTitleRender(itemData)}
                <div className="lastMessage">{displaySendingDate(itemData?.send_at, "Aucun message")}</div>
            </div>
        );
    }
    function setItemContextMenuOption(itemData) {
        const options = [];
        if (crudDisplay(["upd"], actionAllowed)) {
            options.push({
                text: "Modifier le forum",
                iconCls: "fas fa-edit",
                onClick: function (optionConfig) {
                    setInitTopicForm(itemData);
                },
            });
        }
        if (crudDisplay(["del"], actionAllowed)) {
            options.push({
                text: "Clôturer le forum",
                iconCls: "fas fa-lock",
                onClick: function (optionConfig) {
                    postDeleteTopic(itemData);
                },
            });
        }
        return options;
    }
    function popupTitleRender() {
        //TODO : Est-ce qu'on remonterait pas ça au niveau du controlleur avec du i18n ?
        switch (true) {
            case !!initTopicForm:
                if (initTopicForm.id > -1) {
                    return "Modification du forum" + (initTopicForm.subject ? ` - '${initTopicForm.subject}'` : "");
                } else {
                    return "Création d'un nouveau Forum";
                }
            default:
                return "";
        }
    }
    function popupContentRender() {
        function patientOptionRender() {
            return [
                {
                    text: formatName(selectedClient),
                    value: "" + selectedClient?.ent_entity_id,
                },
            ];
        }
        function categoryOptionsRender() {
            const optDisp = [];
            const catList = isArray(store?.msg_category_id) ? store?.msg_category_id : [];
            catList.forEach(function (categoryItem) {
                optDisp.push({
                    text: categoryItem.name,
                    value: categoryItem.id,
                    iconCls: categoryItem.icon_cls,
                });
            });
            return optDisp;
        }
        function categoryOptionsDisplay(itemData) {
            return (itemData?.iconCls ? `<span class='${itemData.iconCls}'></span>&nbsp;` : "") + itemData?.text;
        }
        function classificationOptionsRender() {
            const optDisp = [];
            const classifList = isArray(store?.msg_classification_id) ? store?.msg_classification_id : [];
            classifList.forEach(function (classItem) {
                optDisp.push({
                    text: classItem.name,
                    value: classItem.id,
                });
            });
            return optDisp;
        }
        function accessOptionsRender() {
            const optDisp = [];
            const accList = isArray(store?.msg_messenger_group_id) ? store?.msg_messenger_group_id : [];
            accList.forEach(function (accessItem) {
                optDisp.push({
                    text: accessItem.name,
                    value: accessItem.id,
                    icon: "fas fa-user",
                });
            });
            return optDisp;
        }
        function importanceOptionsRender() {
            const optDisp = [];
            const impList = isArray(store?.msg_importance_id) ? store?.msg_importance_id : [];
            impList.forEach(function (importanceItem) {
                optDisp.push({
                    text: importanceItem.name,
                    value: importanceItem.id,
                    cls: getImportanceClass(importanceItem.id),
                });
            });
            return optDisp;
        }
        return (
            !!topicForm && (
                <Form onSubmit={onSubmitPopup} className="forum-edit-form">
                    <Row>
                        <Form.Group controlId="forum-title" as={Col} xs={12} md={6}>
                            <Form.Label>Titre</Form.Label>
                            <PortalText
                                id="forum-title"
                                type="text"
                                name="forum-title"
                                defaultValue={topicForm.subject}
                                onChange={(e) => setTopicForm("subject", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={6}>
                            <Form.Label htmlFor="forum-patient">Patient</Form.Label>
                            <PortalCombo
                                inputName="forum-patient"
                                defaultValue={topicForm.client_ent_entity_id}
                                items={patientOptionRender()}
                                onChange={(e) => setTopicForm("client_ent_entity_id", e.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={6}>
                            <Form.Label htmlFor="forum-category">Catégorie(s)</Form.Label>
                            <PortalCombo
                                inputName="forum-category"
                                defaultValue={topicForm.msg_category_id}
                                onChange={(e) => setTopicForm("msg_category_id", [e.value])}
                                items={categoryOptionsRender()}
                                multiSelect
                                listItemTpl={categoryOptionsDisplay}
                                chipView={{
                                    iconTpl: (itemData) => (itemData?.iconCls ? `<span class='${itemData.iconCls}'></span>` : ""),
                                }}
                            />
                        </Form.Group>
                        {/*<Form.Group as={Col} xs={12} md={6}>
                        <Form.Label htmlFor="forum-classification">Classification</Form.Label>
                        <PortalCombo
                            inputName="forum-classification"
                            defaultValue={topicForm.msg_classification_id}
                            onChange={(e) => setTopicForm("msg_classification_id", e.value)}
                            items={classificationOptionsRender()}
                        />
                        </Form.Group>*/}
                        {/*<Form.Group as={Col} xs={12} md={6}>
                        <Form.Label htmlFor="forum-access">Droits d'accès</Form.Label>
                        <PortalCombo
                            inputName="forum-access"
                            defaultValue={topicForm.msg_messenger_group_id}
                            onChange={(e) => setTopicForm("msg_messenger_group_id", e.value)}
                            items={accessOptionsRender()}
                        />
                        </Form.Group>*/}
                        <Form.Group as={Col} xs={12} md={6}>
                            <Form.Label htmlFor="forum-importance">Importance</Form.Label>
                            <PortalCombo
                                inputName="forum-importance"
                                defaultValue={topicForm.msg_importance_id}
                                onChange={(e) => setTopicForm("msg_importance_id", e.value)}
                                items={importanceOptionsRender()}
                                className={getImportanceClass(topicForm?.msg_importance_id)}
                                //listItemTpl={null}
                            />
                        </Form.Group>
                    </Row>
                    {/*editForumData?.id == -1 ? ( //No firstMessage field when modifiying a forum
                    <Form.Group controlId="forum-first-msg" className="forum-first-msg-group">
                        <Form.Label>Premier message</Form.Label>
                        <PortalText
                            id="forum-first-msg"
                            as="textarea"
                            className="forum-first-msg"
                            name="forum-first-msg"
                            defaultValue={topicForm.first_message}
                            onChange={(e) => setTopicForm("first_message", e.target.value)}
                        />
                    </Form.Group>
                ) : (
                    ""
                )*/}
                </Form>
            )
        );
    }
    function renderDynamicContent() {
        const forumList = (
            <PortalList
                data={store.msg_messenger}
                isLoading={refreshState === "loading"}
                errorLoading={false}
                noDataMsg="Aucun échange disponible"
                itemSelectable={true}
                header={null}
                setItemContextMenuOption={setItemContextMenuOption}
                onItemClick={onListItemClick}
                itemRender={forumItemRender}
                setItemClass={(itemData) => getImportanceClass(itemData?.msg_importance_id)}
            />
        );
        switch (true) {
            case !!openedTopic:
                return <MsgFlux {...props} displaySendingDate={displaySendingDate} />;
            default:
                return forumList;
        }
    }
    return (
        <div className="client-note-container">
            {props.setStyle("./base/desktop")}
            <Popup
                visible={!!topicForm}
                className="note-popup-config"
                title={popupTitleRender()}
                submitSection={true}
                submitBtnState={requestState == "loading" ? "loading" : ""}
                onSubmit={onSubmitPopup}
                showCloseButton={true}
                onHiding={closePopup}
            >
                {popupContentRender()}
            </Popup>
            <PortalToolbar
                breakPoint={500}
                title={renderToolbarTitle()}
                startToolsRender={renderStartTools}
                endToolsRender={renderEndTools}
                className="note-toolbar"
            />
            <div className="note-dynamic-content">{renderDynamicContent()}</div>
        </div>
    );
}
