import React from "react";
import { useState, useEffect } from "react";
import TooltipWrapper from "../tooltipWrapper/tooltipWrapper";
import PortalCheck from "../portalCheck/portalCheck.js";
import "./portalText.css";
/*
Install : - La bibliothèque font-awesome doit être intallée dans le projet
Configs :
{
    tooltip = STRING (opt) [''] => Le contenu du tooltip associé au champ
        # Pour afficher une brève description du contenu du champ    
    
    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input

    iconsLeft = ARRAY || STRING (opt) [''] => La liste des classes d'icones que l'on veut afficher avant la zone de saisie du champ
        # Si on n'a qu'un seul icône à insérer, pas besoin de le mettre dans une liste

    iconsRight = ARRAY || STRING (opt) [''] => La liste des classes d'icones que l'on veut afficher avant la zone de saisie du champ
        # Si on n'a qu'un seul icône à insérer, pas besoin de le mettre dans une liste

    displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
        # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

    activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'actif'
        # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

    actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
        # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
        # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
        # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'

    onBlur = Callback(event)

    onFocus = Callback(event)

}
*/
const PortalText = React.forwardRef(function (props, ref) {
    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    const as = props.as === "textarea" ? "textarea" : "input";
    const iconsLeft = typeof props.iconsLeft !== "object" ? [props.iconsLeft] : props.iconsLeft;
    const iconsRight = typeof props.iconsRight !== "object" ? [props.iconsRight] : props.iconsRight;
    const displayActions = ["dis"];
    const isInvalid = !!props.isInvalid;
    const defaultType = props.type;
    if (typeof props?.displayActions?.forEach === "function") {
        props.displayActions.forEach(function (action) {
            displayActions.push(action);
        });
    }
    const activeActions = typeof props?.activeActions?.forEach === "function" ? props.activeActions : ["upd"];
    const filteredProps = deleteProps(
        "ref",
        "tooltip",
        "tooltipAlign",
        "className",
        "activeActions",
        "actionAllowed",
        "isInvalid",
        "iconsLeft",
        "iconsRight",
        "type"
    );
    //States definition
    const [hasFocus, setHasFocus] = useState(false);
    const [inputType, setInputType] = useState(defaultType);
    //UX handlers
    function onFocusInput(e) {
        setHasFocus(true);
        if (typeof props.onFocus === "function") props.onFocus(e);
    }
    function onBlurInput(e) {
        //TODO: Gérer le blur interne
        setHasFocus(false);
        if (typeof props.onBlur === "function") props.onBlur(e);
    }

    //pre-rendering treatments
    function pwdCheckRender() {
        if (defaultType !== "password") return "";
        function checkIsActive() {
            return inputType !== "password";
        }
        return (
            <PortalCheck
                baseIcon="fas fa-eye"
                activeIcon="fas fa-eye-slash"
                onChange={function (active, event) {
                    console.log(active);
                    setInputType(active ? "input" : "password");
                }}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                active={checkIsActive()}
                aria-label={checkIsActive() ? "Cliquer pour masquer le mot de passe" : "Cliquer pour afficher le mot de passe"}
            />
        );
    }
    function defaultDisplay() {
        let display = true;
        if (typeof props.actionAllowed?.forEach !== "function") return display;
        displayActions.forEach(function (requiredAction) {
            if (
                props.actionAllowed.findIndex(function (viewAction) {
                    return requiredAction === viewAction;
                }) < 0
            ) {
                display = false;
            }
        });
        return display;
    }
    function getReadOnly() {
        if (props.hasOwnProperty("readOnly")) {
            return props.readOnly;
        }
        let readOnly = false;
        if (typeof props.actionAllowed?.forEach !== "function") return readOnly;
        activeActions.forEach(function (requiredAction) {
            if (props.actionAllowed.findIndex((viewAction) => viewAction === requiredAction) < 0) readOnly = true;
        });
        return readOnly;
    }
    function iconContainerRender(position) {
        position = position === "right" ? "right" : "left";
        const iconList = position === "right" ? iconsRight : iconsLeft;
        const iconDisplay = [];
        iconList.forEach(function (iconCls) {
            if (!!iconCls) {
                iconDisplay.push(<span className={`icon ${iconCls}`} key={`inputIcon-${iconCls}-${position}`}></span>);
            }
        });
        return iconDisplay;
    }
    function inputRender() {
        const inputProps = {
            type: inputType,
            ref: ref,
            readOnly: getReadOnly(),
            onFocus: onFocusInput,
            onBlur: onBlurInput,
        };
        switch (as) {
            case "textarea":
                return <textarea {...filteredProps} {...inputProps}></textarea>;
            default:
                return <input {...filteredProps} {...inputProps} />;
        }
    }
    return defaultDisplay() ? (
        <TooltipWrapper tooltip={props?.tooltip} tooltipAlign={props?.tooltipAlign}>
            <div
                className={`portalText${props.className ? " " + props.className : ""}${hasFocus ? " focused" : ""}${
                    isInvalid ? " invalid" : ""
                }${getReadOnly() ? " readOnly" : ""}${props.disabled ? " disabled" : ""}`}
            >
                <div className="iconContainer left">{iconContainerRender("left")}</div>
                <div className="inputContainer">{inputRender()}</div>
                <div className="iconContainer right">{iconContainerRender("right")}</div>
                {pwdCheckRender()}
            </div>
        </TooltipWrapper>
    ) : (
        ""
    );
});
export default PortalText;
