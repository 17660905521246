import React from "react";
import { Button } from "react-bootstrap";
import { crudDisplay, crudDisabled } from "../../lib/js/globalFunctions";
import "./btnToolTip.css";
export default function BtnToolTip(props) {
    /*  Configs : 
    {
        iconCls : STRING (opt) [''] => Icône à l'intérieur du bouton
            # Si on rajoute du contenu au bouton, l'icône sera placé à gauche de ce contenu

        iconAlign : STRING (opt) ['left'] => Pour savoir si on affiche l'icône à gauche ou à droite
            # Valeurs : 'left' || 'right'
        Tous les paramètres disponibles dans un élément <Button /> bootstrap

        displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
            # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!   

        activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'modifiable'
            # Par défaut on considère que c'est le droit 'upd' mais on peut customiser 

        actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
            # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
            # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
            # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'
    }
*/
    function deleteProps(propsToDelete) {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }

    const filteredProps = deleteProps("iconCls", "disabled", "actionAllowed", "displayActions", "activeActions");
    filteredProps.className = `btnTooltip${filteredProps.className ? " " + filteredProps.className : ""}`;
    const iconAlign = props.iconAlign === "right" ? "right" : "left";
    return crudDisplay(props.displayActions, props.actionAllowed) ? (
        <Button disabled={props.disabled || crudDisabled(props.activeActions, props.actionAllowed)} {...filteredProps}>
            {props.iconCls && iconAlign === "left" ? <span className={`icon ${iconAlign} ${props.iconCls}`}></span> : ""}
            {props.children ? <span>{props.children}</span> : ""}
            {props.iconCls && iconAlign === "right" ? <span className={`icon ${iconAlign} ${props.iconCls}`}></span> : ""}
        </Button>
    ) : (
        ""
    );
}
