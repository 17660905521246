//Logical Components
import React from "react";
import { useState, useEffect } from "react";
import { formValidator, editFormState, isPhoneType, isEmailType } from "../../../../ressources/lib/js/globalFunctions";
//UI Components
import BaseDesktopScreen from "./screens/base/desktop/screen.js";
export default function Controller(props) {
    const defaultFormData = {
        first_name: "",
        last_name: "",
        mobile_phone: "",
        email: "",
        object: "",
        content: "",
    };
    const [formValidationCount, setFormValidationCount] = useState(0);
    const [formModificationCount, setFormModificationCount] = useState(0);
    const [initFormData, _setInitFormData] = useState(defaultFormData);
    const [formData, _setFormData] = useState(initFormData);
    function setFormData(field, value) {
        editFormState(field, value, formData, _setFormData);
        setFormModificationCount(formModificationCount + 1);
    }
    const formValidatorInstance = new formValidator({
        last_name: [{ conditionCode: "required_last_name", validate: () => !!formData.last_name.trim() }],
        first_name: [{ conditionCode: "required_first_name", validate: () => !!formData.first_name.trim() }],
        email: [
            { conditionCode: "required_email", validate: () => !!formData.email.trim() },
            { conditionCode: "is_email_type", validate: () => isEmailType(formData.email.trim()) },
        ],
        mobile_phone: [
            { conditionCode: "required_mobile_phone", validate: () => !!formData.mobile_phone.trim() },
            { conditionCode: "is_mobile_phone_type", validate: () => isPhoneType(formData.mobile_phone.trim()) },
        ],
        object: [{ conditionCode: "required_object", validate: () => !!formData.object.trim() }],
        content: [{ conditionCode: "required_content", validate: () => !!formData.content.trim() }],
    });
    //Effect treatments
    useEffect(
        function () {
            _setFormData(initFormData);
            if (!initFormData) {
                setFormModificationCount(0);
                setFormValidationCount(0);
            }
        },
        [initFormData]
    );
    //Communication functions
    function postData() {
        console.log(formData);
        setFormValidationCount(formValidationCount + 1);
        const validationRes = formValidatorInstance.validate();
        if (!validationRes.success) {
            //props.msgBox(props.i18n("error." + validationRes.errors[0]), "error", "msg", "Erreur");
            props.msgBox(props.i18n("error.invalid_form"), "error", "msg", "Erreur");
            return;
        }
        props.setRequestState("loading");
        props
            .request({
                action: "post_contact_platform",
                data: formData,
            })
            .then(function (res) {
                const successMsg = props.i18n("info.success_contact", [props.appName]);
                props
                    .msgBox(successMsg, "info", "msg")
                    .then(function () {
                        _setInitFormData(defaultFormData);
                        setFormModificationCount(0);
                        setFormValidationCount(0);
                    })
                    .catch(function () {});
            })
            .catch(function (error) {
                props
                    .msgBox(error.msg, "error", "msg")
                    .then(function () {
                        _setInitFormData(defaultFormData);
                    })
                    .catch(function () {});
            })
            .finally(function () {
                props.setRequestState("");
            });
    }
    const viewControllerProps = {
        formData: formData,
        setFormData: setFormData,
        formValidator: formValidatorInstance,
        formValidationCount: formValidationCount,
        formModificationCount: formModificationCount,
        postData: postData,
    };
    switch (props.appTheme) {
        default:
            return <BaseDesktopScreen {...props} {...viewControllerProps} />;
    }
}
