import React from "react";
import "./fconnect.css";
export default function Fconnect(props) {
    function onClickFconnect() {
        document.Wrapper.request({
            type: "ajax",
            module: "view",
            view: "login",
            action: "ask_login_france_connect",
            data: {
                callback_url: window.location.origin + "?type=france_connect",
            },
        })
            .then(function (response) {
                let fconnectDns = response.data.url;
                window.location.href = fconnectDns;
            })
            .catch(function (error) {
                console.error(error.msg);
                props.msgBox(error.msg, "error", "msg");
            });
    }
    return (
        <div className="fconnectContainer">
            {props.txtInfos ? <p className="fconnectTxt">{props.txtInfos}</p> : ""}
            <div className="fconnectBtnRow">
                <button
                    className="btnFranceConnect"
                    onClick={onClickFconnect}
                    tabIndex={props.tabIndex ? props.tabIndex[0] : null}
                    aria-label="S'identifier avec FranceConnect"
                ></button>
            </div>
            <a
                className="fconnectLink"
                href="https://franceconnect.gouv.fr/"
                target="_blank"
                rel="noreferrer"
                tabIndex={props.tabIndex ? props.tabIndex[1] : null}
            >
                Qu'est-ce que FranceConnect ?
            </a>
        </div>
    );
}
