import React from "react";
import MenuItem from "../menuItem/menuItem";
import "./barMenu.css";
export default function BarMenu(props) {
    const data = props.data || [];

    //Event handlers
    function onMenuItemClick(event) {
        if (event.type === "goToDirection") props.redirectTo(event.data.direction);
    }

    function displayItemList() {
        const menuItemList = [];
        data.forEach(function (itemData) {
            menuItemList.push(
                <MenuItem
                    key={`menuitem_${itemData.direction}`}
                    data={itemData}
                    subNavType="float"
                    onClick={onMenuItemClick}
                    redirectTo={props.redirectTo}
                    i18n={props.i18n}
                />
            );
        });
        return menuItemList;
    }
    return <ul className="barmenuContainer">{displayItemList()}</ul>;
}
