import React, { useEffect, useState, useMemo } from "react";
import PortalText from "../portalText/portalText.js";
import AirDatepicker from "air-datepicker";
import localeFr from "air-datepicker/locale/fr";
import { crudDisplay, crudDisabled, uniqueId, formatAsaproDate } from "../../../lib/js/globalFunctions.js";
import "./portalDate.css";
/*Install : - La bibliothèque font-awesome doit être intallée dans le projet
Configs :
{
    tooltip = STRING (opt) [''] => Le contenu du tooltip associé au champ
        # Pour afficher une brève description du contenu du champ

    tooltipAlign = STRING (opt) ['right'] => La position du tooltip par rapport à l'input

    iconsLeft = ARRAY || STRING (opt) [''] => La liste des classes d'icones que l'on veut afficher avant la zone de saisie du champ
        # Si on n'a qu'un seul icône à insérer, pas besoin de le mettre dans une liste

    iconsRight = ARRAY || STRING (opt) [''] => La liste des classes d'icones que l'on veut afficher avant la zone de saisie du champ
        # Si on n'a qu'un seul icône à insérer, pas besoin de le mettre dans une liste

    displayActions = ARRAY_Of_STRINGs [['dis']] => Liste des droits de la vue nécessaires pour que le composant s'affiche
        # !! Si on sette des droits au composant, le droit 'dis' doit toujours être présent pour que le composant s'affiche !!

    activeActions = ARRAY_OF_STRINGs [['upd']] => Liste des droits de la vue nécessaires pour que le composant soit 'actif'
        # Par défaut on considère que c'est le droit 'upd' mais on peut customiser

    actionAllowed = ARRAY_OF_STRINGs (opt) [null] => La liste des actions autorisées sur la vue en question
        # Si rien n'est setté, le composant n'est pas contraint même si des contraintes sont settées dans 'displayActions' et 'activeActions'
        # Si on sette des droits, le droit 'dis' est TOUJOURS obligatoire pour afficher le composant
        # Par défaut , le droit 'upd' est obligatoire pour que le composant soit actif mais on peut changer via 'activeActions'
}
*/
export default function PortalDate(props) {
    const value = props.value;
    const [cmpId, setCmpId] = useState(props.id ? props.id : "portalDate-" + uniqueId());
    function formatDisplayValue(rawValue) {
        //Date object or Asapro date format (y-m-d)
        if (!rawValue) return "";
        const dateValue = new Date(rawValue);
        return dateValue.toLocaleDateString("fr-FR");
    }
    const [displayValue, setDisplayValue] = useState(formatDisplayValue(value));

    //UX event handlers
    function onChangeValue(e) {
        let rawValue = e.target.value;
        setDisplayValue(rawValue); //When we manually type the date, the formatting is done only on focusout
    }
    function onSelectDate({ date, formattedDate, datePicker }) {
        setDisplayValue(formatDisplayValue(date));
    }

    function onBlurField(e) {
        const newValue = formatAsaproDate(displayValue);
        setDisplayValue(formatDisplayValue(newValue));
        if (typeof props.onChange === "function") {
            props.onChange({
                type: "change",
                target: {
                    value: newValue,
                },
            });
        }
        if (typeof props.onBlur === "function") props.onBlur(e);
    }
    useEffect(function () {
        new AirDatepicker("#" + cmpId, {
            locale: localeFr,
            isMobile: window.innerWidth < 800,
            //container: ".portalDate",
            view: "days",
            onSelect: onSelectDate,
        });
    }, []);

    useEffect(() => {
        setDisplayValue(formatDisplayValue(value));
    }, [value]);

    function deleteProps() {
        const forbiddenPropsToPass = [...arguments];
        let filteredProps = { ...props };
        forbiddenPropsToPass.forEach(function (forbiddenProps) {
            delete filteredProps[forbiddenProps];
        });
        return filteredProps;
    }
    function buildIconsRight() {
        const iconsRight = props.iconsRight && typeof props.iconsRight === "object" ? props.iconsRight : [];
        iconsRight.push("far fa-calendar");
        return iconsRight;
    }
    const filteredProps = deleteProps("className", "type", "id", "iconsRight", "onChange", "value", "onBlur");
    return (
        <PortalText
            id={cmpId}
            className={`portalDate${props.className ? " " + props.className : ""}`}
            iconsRight={buildIconsRight()}
            onChange={onChangeValue}
            value={displayValue}
            onBlur={onBlurField}
            onFocus={props.onFocus}
            {...filteredProps}
        />
    );
}
