import Nav from "react-bootstrap/Nav";
import React from "react";
import "./footer.css";
function Footer(props) {
    //pre-rendering part (always executed)
    const customContent = props.footerContent || ""; //"Footer à configurer"
    const isConnected = !!props.isConnected;
    function buildNavLinks() {
        let navListDisplay = [];
        const showcaseList = props.showcaseList || [];
        function onClickNavLink(showcaseParam) {
            let hash = "";
            switch (true) {
                case !!showcaseParam.internal_hash:
                    //It's not a showcase view, but still an internal view
                    hash = showcaseParam.internal_hash;
                    break;
                default:
                    hash = `showcase_view?admParameterId=${showcaseParam?.content_param_id}&viewTitle=${showcaseParam?.title}`;
            }
            props.redirectTo(hash);
            /*props
                .openExternal({
                    url: window.location.origin + "/#/" + hash,
                    force: true,
                })
                .then(function () {})
                .catch(function () {}); //*/
        }
        if (!!showcaseList) {
            showcaseList.forEach(function (showcaseParam) {
                navListDisplay.push(
                    <Nav.Link
                        key={showcaseParam?.content_param_id}
                        onClick={function (e) {
                            onClickNavLink(showcaseParam);
                        }}
                        role="navigation"
                    >
                        {showcaseParam?.title}
                    </Nav.Link>
                );
            });
        }
        return navListDisplay;
    }
    function customContentRender() {
        if (!customContent) return "";
        return <div className="customContentContainer" dangerouslySetInnerHTML={{ __html: customContent }}></div>;
    }
    return (
        <footer className="footerContainer">
            {customContentRender()}
            <Nav /*onSelect={(selectedKey) => props.redirectTo(selectedKey)}*/ className="showcaseNavContainer">
                <span className="copyrightContainer">
                    {!isConnected ? props.i18n("info.copyright") : props.i18n("info.copyright_connected")}
                </span>
                <span className="navLinkContainer">{buildNavLinks()}</span>
            </Nav>
        </footer>
    );
}

export default Footer;
