//######################################## Imports ########################################//
//External
import React from "react";
import { useEffect } from "react";
//Custom
import ModeChoice from "./components/modeChoice";
import LocalCreationForm from "./components/localCreationForm";
import SuccessCreation from "./components/successCreation";
import PscConnect from "../../../../../../../ressources/components/pscConnect/pscConnect";
//#########################################################################################//
export default function Screen(props) {
    const step = props.step; //Faudrait peut-être gérer ça au niveau du screen (les step de sous-navigation sont propres à une interface)
    useEffect(
        function () {
            props.scrollLayout(0);
        },
        [step]
    );
    //Rendering treatments
    function chooseDisplay() {
        switch (step) {
            case "modeChoice":
                return <ModeChoice {...props} />;
            case "localCreation":
                return <LocalCreationForm {...props} />;
            case "end":
                return <SuccessCreation {...props} />;
            default:
                return "UNKNOWN STEP";
        }
    }

    return (
        <div className="registerContainer">
            {props.setStyle("./v_yvelines/desktop")}
            {chooseDisplay()}
        </div>
    );
}
