import React from "react";
import { useState } from "react";
import { setRightCase, formatName } from "../../../../../../../../ressources/lib/js/globalFunctions";
import ItemRow from "../../../../../../../../ressources/components/itemRow/itemRow";
import { Row, Form, Col } from "react-bootstrap";
import PortalBtn from "../../../../../../../../ressources/components/portalBtn/portalBtn";
import PortalSection from "../../../../../../../../ressources/components/portalPage/portalSection/portalSection";
import PortalText from "../../../../../../../../ressources/components/portalFields/portalText/portalText";
import PortalPhone from "../../../../../../../../ressources/components/portalFields/portalPhone/portalPhone";
import PortalCombo from "../../../../../../../../ressources/components/portalFields/portalCombo/portalCombo";
import PortalList from "../../../../../../../../ressources/components/portalList/portalList";
import ContactAccessSection from "./contactAccessSection.js";
export default function ProContactSelector(props) {
    /**
     * @param {Object} props [{}]: {
     *      @param {Object} contactForm [{}] : Les données du contact sélectionné (ou du futur contact à ajouter)
     *
     *
     * }
     */

    const contactForm = props.contactForm;
    const setContactForm = props.setContactForm;
    const proContactList = props.proContactList;
    const formModificationCount = props.formModificationCount;
    const staticStore = props.store.static_store;
    const requestState = props.requestState;
    const postContact = props.postContact;
    const actionAllowed = props.actionAllowed;
    const searchProContactForm = props.searchProContactForm;
    const setSearchProContactForm = props.setSearchProContactForm;
    const postSearchProContact = props.postSearchProContact;
    const loadProContactState = props.loadProContactState;
    const formatComboItems = props.formatComboItems;
    const returnHome = props.returnHome;

    const [selectedProContact, setSelectedProContact] = useState({});

    React.useEffect(function () {
        props.scrollLayout(0);
    }, []);
    //Event handlers
    function onSubmitForm(event) {
        event.preventDefault();
        if (typeof postContact === "function") postContact();
    }
    function onSubmitSearchForm(event) {
        event.preventDefault();
        if (typeof postSearchProContact === "function") postSearchProContact();
    }
    function onProListItemClick(itemData) {
        if (itemData) {
            setSelectedProContact(itemData);
            setContactForm(
                [
                    "id",
                    "ans_annuaire_id",
                    "last_name",
                    "first_name",
                    "email",
                    "email_pro",
                    "adresse_bal",
                    "used_email",
                    "phone",
                    "phone_pro",
                    "used_phone",
                    "mobile_phone",
                ],
                [
                    itemData.id,
                    itemData.ans_annuaire_id,
                    itemData.last_name,
                    itemData.first_name,
                    itemData.email,
                    itemData.email_pro,
                    itemData.adresse_bal,
                    itemData.used_email,
                    itemData.phone,
                    itemData.phone_pro,
                    itemData.used_phone,
                    itemData.mobile_phone,
                ]
            );
        }
    }
    //Pre-rendering functions
    function selectedProContactRender() {
        if (!selectedProContact.hasOwnProperty("id")) return <p className="noContactDisplay">Aucun</p>;
        const lastNameDisplay = setRightCase(selectedProContact.last_name) || "Inconnu";
        const firstNameDisplay = setRightCase(selectedProContact.first_name) || "Inconnu";
        const emailDisplay = selectedProContact.used_email || "Inconnu";
        const professionDisplay = setRightCase(selectedProContact.libelle_profession) || "Inconnue";
        const cityDisplay = setRightCase(selectedProContact.city) || "Inconnue";
        return (
            <Row className="noMargin">
                <Col xs={12} md={6}>
                    <div className="dataGroup">
                        <label>{props.i18n("label.field.last_name")} :&nbsp;</label>
                        <p>{lastNameDisplay}</p>
                    </div>
                    <div className="dataGroup">
                        <label>{props.i18n("label.field.first_name")} :&nbsp;</label>
                        <p>{firstNameDisplay}</p>
                    </div>
                    <div className="dataGroup">
                        <label>{props.i18n("label.field.email")} :&nbsp;</label>
                        <p>{emailDisplay}</p>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="dataGroup">
                        <label>{props.i18n("label.field.code_profession_id")} :&nbsp;</label>
                        <p>{professionDisplay}</p>
                    </div>
                    <div className="dataGroup">
                        <label>{props.i18n("label.field.city")} :&nbsp;</label>
                        <p>{cityDisplay}</p>
                    </div>
                </Col>
            </Row>
        );
    }
    function listItemRender(itemData) {
        let proContactData = itemData || {};
        let proContactTxt = formatName(proContactData);
        if (proContactData.libelle_profession) proContactTxt += ` - ${setRightCase(proContactData.libelle_profession)}`;
        if (proContactData.city) proContactTxt += ` (${setRightCase(proContactData.city)})`;
        return proContactTxt;
    }
    function setListItemClass(itemData) {
        return (!!contactForm.id && itemData.id == contactForm.id) ||
            (!!contactForm.ans_annuaire_id && itemData.ans_annuaire_id == contactForm.ans_annuaire_id)
            ? "selected"
            : "";
    }
    return (
        <>
            <PortalSection
                className="contactSelectorContainer"
                title="Recherchez dans l'annuaire des professionnels de santé"
                titleAlign="left"
            >
                <Row className="searchRow noMargin">
                    <Col className="subsectionContainer searchPro" xs={12} md={6}>
                        <Form onSubmit={onSubmitSearchForm} noValidate>
                            <h5 className="subsectionTitle">Formulaire de recherche (Renseigner au moins 2 champs)</h5>
                            <Row className="noMargin">
                                <Form.Group xs={12} md={6} as={Col} controlId="last_name">
                                    {!!props.i18n("label.field.last_name") && (
                                        <Form.Label>{props.i18n("label.field.last_name")}&nbsp;</Form.Label>
                                    )}
                                    <PortalText
                                        id="last_name"
                                        placeholder={props.i18n("placeholder.field.last_name")}
                                        value={searchProContactForm.last_name}
                                        onChange={(e) => setSearchProContactForm("last_name", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group xs={12} md={6} as={Col} controlId="first_name">
                                    {!!props.i18n("label.field.first_name") && (
                                        <Form.Label>{props.i18n("label.field.first_name")}&nbsp;</Form.Label>
                                    )}
                                    <PortalText
                                        id="first_name"
                                        placeholder={props.i18n("placeholder.field.first_name")}
                                        value={searchProContactForm.first_name}
                                        onChange={(e) => setSearchProContactForm("first_name", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group xs={12} md={6} as={Col} controlId="phone">
                                    {!!props.i18n("label.field.phone") && <Form.Label>{props.i18n("label.field.phone")}&nbsp;</Form.Label>}
                                    <PortalPhone
                                        id="phone"
                                        placeholder={props.i18n("placeholder.field.phone")}
                                        value={searchProContactForm.phone}
                                        onChange={(e, newValue) => setSearchProContactForm("phone", newValue)}
                                    />
                                </Form.Group>
                                <Form.Group xs={12} md={6} as={Col} controlId="email">
                                    {!!props.i18n("label.field.email") && <Form.Label>{props.i18n("label.field.email")}&nbsp;</Form.Label>}
                                    <PortalText
                                        id="email"
                                        placeholder={props.i18n("placeholder.field.email")}
                                        value={searchProContactForm.email}
                                        onChange={(e) => setSearchProContactForm("email", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group xs={12} md={6} as={Col} controlId="city">
                                    {!!props.i18n("label.field.city") && <Form.Label>{props.i18n("label.field.city")}&nbsp;</Form.Label>}
                                    <PortalText
                                        id="city"
                                        placeholder={props.i18n("placeholder.field.city")}
                                        value={searchProContactForm.city}
                                        onChange={(e) => setSearchProContactForm("city", e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group xs={12} md={6} as={Col} controlId="code_profession_id">
                                    {!!props.i18n("label.field.code_profession_id") && (
                                        <Form.Label>{props.i18n("label.field.code_profession_id")}</Form.Label>
                                    )}
                                    <PortalCombo
                                        inputName="code_profession_id"
                                        placeholder={props.i18n("placeholder.field.code_profession_id")}
                                        items={formatComboItems(staticStore.code_profession)}
                                        defaultValue={searchProContactForm.code_profession_id}
                                        onChange={(e) => setSearchProContactForm("code_profession_id", e.value)}
                                        editable
                                    />
                                </Form.Group>
                            </Row>
                            <PortalBtn type="submit" className="searchProBtn" state={loadProContactState === "loading" ? "loading" : ""}>
                                Rechercher sur l'annuaire de santé
                            </PortalBtn>
                        </Form>
                    </Col>
                    <Col className="subsectionContainer foundPro" xs={12} md={6}>
                        <h5 className="subsectionTitle">Résultats</h5>
                        <PortalList
                            noPagination
                            data={proContactList}
                            noDataMsg=" "
                            errorMsg=" "
                            itemRender={listItemRender}
                            itemSelectable={true}
                            setItemClass={setListItemClass}
                            isLoading={loadProContactState === "loading"}
                            errorLoading={loadProContactState === "error"}
                            onItemClick={onProListItemClick}
                        />
                    </Col>
                </Row>
            </PortalSection>

            <Form className="contactPostContainer" onSubmit={onSubmitForm} noValidate>
                <Row className="noMargin">
                    <PortalSection
                        className="selectedProContactContainer"
                        title="Professionnel sélectionné"
                        titleAlign="left"
                        xs={12}
                        md={6}
                    >
                        {selectedProContactRender()}
                    </PortalSection>
                    <ContactAccessSection {...props} md={6} />
                </Row>
                <ItemRow fill="stretch">
                    <PortalBtn className="reverse" onClick={returnHome}>
                        Annuler
                    </PortalBtn>
                    <PortalBtn
                        className="validate"
                        type="submit"
                        displayActions={["dis"]}
                        activeActions={["upd"]}
                        actionAllowed={actionAllowed}
                        state={requestState}
                        disabled={formModificationCount < 1}
                    >
                        Valider
                    </PortalBtn>
                </ItemRow>
            </Form>
        </>
    );
}
