//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect, useRef } from "react";
//External Library
//import ReactToPdf from "react-to-pdf";
/*
On utilise 'react-to-print' pour transférer certains éléments de la page dans une vue d'impression gérée par le navigateur via "window.print()"
    # !!! Window.print n'est pas géré par certaines webViews mobiles (firefox android) mais par contre est supporté par IE5...
    # Et en plus c'est galère à mettre en forme...

On peut utiliser 'react-to-pdf' pour générer directement un PDF à télécharger en insérant des éléments de la page via les modules "jsPDF" et "html2canvas"
    # !!! Peut-être plus stable sur les webViews mobiles mais nécessité d'implémenter des polyfills pour IE et pas de prévisualisation ni d'option pour imprimer directement

Si l'on veut générer un PDF totalement différent de la page web avec les données fournies, utiliser 'react-pdf/renderer' ou 'pdfmake' (choix à définir)
*/
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
//Custom UI Components
import InitView from "../../../../ressources/components/initView/initView";
import PecList from "./components/PecList.js";
import MainCesu from "./cesu/main_cesu.js";
import PopupView from "../../../../ressources/components/popupView/popupView";
import PortalBtn from "../../../../ressources/components/portalBtn/portalBtn";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//
function ClientSubscription(props) {
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        viewData1: "Donnée n°1",
        viewData2: "Donnée n°2",
    };
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [oldPecDisplay, setOldPecDisplay] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [pecDataCesu, setPecDataCesu] = useState(null);
    const [pecDataCesuIsActive, setPecDataCesuIsActive] = useState(false);
    const printPdfRef = useRef();
    // Init functions
    function loadViewData() {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        setIsloading(true);
        setErrorLoading(false);
        if (dataPlug && useDataPlug) {
            setViewData(dataPlug);
            setIsloading(false);
            return;
        }
        props
            .request()
            .then(function (response) {
                setViewData(response.data);
                handleResponseHelp(response);
                setIsloading(false);
            })
            .catch(function (error) {
                setErrorLoading(true);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            });
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        //Mise à jour du fil d'ariane
        props.updateUserJourney(["home", "home_demandes", "client_subscription"]); // A personnaliser en fonction de la vue
        return function () {
            // Ré-initialiser les warning du layout à la destruction de la vue (Le layout ne peut pas le faire lui-même car le onhashchange est plus lent que l'instanciation d'une nouvelle vue)
            props.setHelpDisplay(false);
            props.setHelpContent("");
            props.setHelpImportance("info");
        };
    }, []);
    let ent_entity_id = props.selectedClient.ent_entity_id;
    useEffect(
        function () {
            //Chargement des données de la vue (Bouchon ou non)
            loadViewData();
        },
        [ent_entity_id]
    );

    // Init render treatments
    if (isLoading && !errorLoading) {
        return <InitView type="loading" />;
    }
    if (errorLoading) {
        return <InitView type="errorLoading" />;
    }
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)
    // View constants

    // View global functions

    // UI event handlers
    function onClickCesu(pecData, isActive) {
        if (pecData.has_cesu) {
            setPecDataCesu(pecData);
            setPecDataCesuIsActive(isActive);
            setShowPopup(true);
        }
    }
    function togglePec() {
        setOldPecDisplay(!oldPecDisplay);
    }
    function handleClosePopup() {
        setShowPopup(false);
        setPecDataCesu(null);
        setPecDataCesuIsActive(false);
    }
    // rendering functions
    function buildPecHeader() {
        if (showPopup && pecDataCesu) {
            return (
                <>
                    <h2 className="cesu-section-title">Prise en charge accordée par le Conseil départemental</h2>
                    <PecList dataSource={[pecDataCesu]} msgBox={props.msgBox} request={props.request} isActive={pecDataCesuIsActive} />
                </>
            );
        }
        return "";
    }
    // Rendering part
    return (
        <div className="clientSubscription" ref={printPdfRef}>
            {props.setStyle("./normal")}
            <PopupView
                footerRender={null}
                className="cesu-popup"
                onHiding={handleClosePopup}
                showCloseButton={true}
                title="Déclarations CESU"
                visible={showPopup}
            >
                {buildPecHeader()}
                <div className="cesuContainer">
                    <MainCesu pecData={pecDataCesu} close={handleClosePopup} msgBox={props.msgBox} request={props.request} />
                </div>
            </PopupView>
            {/*<ReactToPdf
                targetRef={printPdfRef}
                filename="Plan d'aide.pdf"
                x={10}
                y={10}
                options={{
                    orientation: "landscape",
                    unit: "px",
                    format: [window.innerWidth, window.innerHeight],
                    hotfixes: ["px_scaling"],
                }}
                scale={1}
                >
                {({ toPdf }) => (
                    <PortalBtn onClick={toPdf} className="btnTooltip" disabled={!viewData}>
                    <span className="far fa-file-pdf"></span>
                    <span style={{ marginLeft: "0.5em" }}>Enregistrer en PDF</span>
                    </PortalBtn>
                    )}
                </ReactToPdf>*/}
            <h2 className="viewSubtitle">Prises en charge en cours</h2>
            <PecList dataSource={viewData.actif} onClickCesu={onClickCesu} msgBox={props.msgBox} request={props.request} isActive={true} />
            {/*<PortalBtn className="btnPecShow" onClick={togglePec} autoFocus>
                {`${oldPecDisplay ? "Masquer" : "Afficher"} mes plans d'aide précédents`}
            </PortalBtn>*/}
            <h2 className="viewSubtitle">Historique des prises en charge</h2>
            <PecList
                dataSource={viewData.inactif}
                onClickCesu={onClickCesu}
                msgBox={props.msgBox}
                request={props.request}
                isActive={false}
            />
        </div>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default ClientSubscription;
