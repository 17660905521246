import React from "react";
import "./viewItem.css";
export default function ViewItem(props) {
    /**
     * @param {Object} props :{
     *      @param {String} direction : Le hash de la vue sur laquelle on redirige quand on clique de dessus
     *
     *      @param {Function} i18n : La fonction i18n pour récupérer les textes et icônes
     *
     *      @param {Function} redirectTo : La fonction pour rediriger vers la vue spécifiée dans @param direction
     * }
     */

    const direction = props.direction || "";

    //event handlers
    function onClick() {
        if (direction) {
            props.redirectTo(direction);
        }
    }

    //rendering functions
    function renderIcon() {
        const icon = props.i18n("icon.view." + direction + "_icon");
        if (icon) {
            return <span className={"viewIcon " + icon}></span>;
        }
        return "";
    }
    return (
        <button className="viewItemContainer" onClick={onClick}>
            {renderIcon()}
            <h3 className="viewName">{props.i18n("label.view." + direction)}</h3>
            <p className="viewDescription">{props.i18n("info.view." + direction)}</p>
        </button>
    );
}
