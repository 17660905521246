import React from "react";
import "./imgBackground.css";
export default function ImgBackground(props) {
    return (
        <div
            {...props}
            className={`imgBackgroundContainer${typeof props.onClick === "function" ? " clickable" : ""}${
                props.className ? " " + props.className : ""
            }`}
        >
            <div className="imgBackground"></div>
        </div>
    );
}
