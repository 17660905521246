import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../../ressources/i18n/i18n.js";
/*
Action formats handled by this reducer : {type: 'private/<reducerName>', payload: <anything>}
    # <reducerName> : name of the reducers inside this slice (addview, removeView...)
    # <anything> : Any data, UI component want to pass to this action to modify the store
*/

//Store init values for this slice
function initialState(appTheme, selectedI18n) {
    appTheme = appTheme || "hippocad";
    let availableHash, defaultView;
    switch (appTheme) {
        default:
            availableHash = [
                "forgotten_password",
                "confirm_contact_permission",
                "contact_platform",
                "login",
                "mfa_login",
                "register",
                "register_personnal_contact",
                "showcase_view",
                "set_password",
                "faq",
            ];
            defaultView = "login";
            break;
    }
    let initialState = {
        appName: "Portail usagers",
        appTheme: appTheme,
        appContext: {}, //From url queryString params url?param=value/#/login
        availableHash: availableHash,
        defaultView: defaultView,
        i18n: { root: i18n["root"] },
        showcase_list: [],
        contact_assist: null,
        contact_url: null,
        public_home_url: null,
        psc_connect: false,
        fconnect: false,
        dynamic_footer: "",
        statistics_url: "",
        statistics_id: "",
    };
    return initialState;
}

//Reducer treatments for this slice (portion) of the store
const publicSlice = createSlice({
    name: "public",
    initialState: initialState(),
    reducers: {
        // We can do mutating treatments here because createSlice handles the non-mutating state return itself
        addHash: function (publicState, action) {
            return publicState; //TODO : Write the logic for this treatment
        },
        removeHash: function (publicState, action) {
            return publicState; //TODO : Write the logic for this treatment
        },
        resetPublicSlice: function (publicState, action) {
            let newPublicState = initialState(action.payload?.appTheme, action.payload?.i18nCode);
            let loadedParams = action.payload?.appParams;
            if (typeof loadedParams !== "object") {
                loadedParams = {};
            }
            newPublicState.appName = loadedParams.app_name || "Portail usagers";
            newPublicState.contact_assist = loadedParams.contact_assist || null;
            newPublicState.contact_url = loadedParams.contact_url || null;
            newPublicState.public_home_url = loadedParams.public_home_url || null;
            newPublicState.showcase_list = loadedParams.showcase_list || [];
            newPublicState.psc_connect = loadedParams.psc_connect || false;
            newPublicState.fconnect = !!loadedParams.fconnect;
            newPublicState.appContext = action.payload?.appContext || {};
            newPublicState.dynamic_footer = loadedParams.dynamic_footer || "";
            newPublicState.statistics_url = loadedParams.statistics_url || "";
            newPublicState.statistics_id = loadedParams.statistics_id || "";
            if (typeof loadedParams?.i18n === "object") {
                loadedParams.i18n.root = newPublicState.i18n.root;
                newPublicState.i18n = loadedParams.i18n;
            }
            return newPublicState;
        },
    },
});

//Action generators for this slice
const addPublicHash = publicSlice.actions.addHash;
const removePublicHash = publicSlice.actions.removeHash;
const resetPublicSlice = publicSlice.actions.resetPublicSlice;
const setAppParams = publicSlice.actions.setAppParams;

//Selectors for this slice (portion) of the store
const selectPublicHash = function (state) {
    return state.public.availableHash;
};
const selectAppTheme = function (state) {
    return state.public.appTheme;
};
const selectAppName = function (state) {
    return state.public.appName;
};
const selectDefaultView = function (state) {
    return state.public.defaultView;
};
const selectI18n = function (state) {
    return state.public.i18n;
};
const selectShowcaseList = function (state) {
    return state.public.showcase_list;
};
const selectContactAssist = function (state) {
    return state.public.contact_assist;
};
const selectContactUrl = function (state) {
    return state.public.contact_url;
};
const selectStatisticsUrl = function (state) {
    return state.public.statistics_url;
};
const selectStatisticsId = function (state) {
    return state.public.statistics_id;
};
const selectPublicHomeUrl = function (state) {
    return state.public.public_home_url;
};
const selectPscConnect = function (state) {
    return !!state.public.psc_connect;
};
const selectFconnect = function (state) {
    return !!state.public.fconnect;
};
const selectAppContext = function (state) {
    return state.public.appContext;
};
const selectDynamicFooter = function (state) {
    return state.public.dynamic_footer;
};
//Exports for further use in UI components
export {
    addPublicHash,
    removePublicHash,
    resetPublicSlice,
    setAppParams,
    selectPublicHash,
    selectAppTheme,
    selectAppName,
    selectDefaultView,
    selectI18n,
    selectShowcaseList,
    selectContactAssist,
    selectContactUrl,
    selectStatisticsUrl,
    selectStatisticsId,
    selectPublicHomeUrl,
    selectPscConnect,
    selectFconnect,
    selectAppContext,
    selectDynamicFooter,
};
export default publicSlice.reducer;
