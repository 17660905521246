//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect, useRef } from "react";
//Custom Libraries
import { formatAsaproDate, formatMonthTitleDisplay } from "../../../../ressources/lib/js/globalFunctions";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import PieChart, { Animation, Series, Label, Legend } from "devextreme-react/pie-chart";
//Custom UI Components
import InitView from "../../../../ressources/components/initView/initView";
import Form from "react-bootstrap/Form";
import BtnToolTip from "../../../../ressources/components/btnToolTip/btnToolTip";
import PortalText from "../../../../ressources/components/portalFields/portalText/portalText.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//
function ClientMonitoring(props) {
    //---------- Global Init ----------//
    // Init constants
    const useDataPlug = false; //Pour activer ou non le mode bouchon de la vue (!!! NE PAS OUBLIER DE LE DESACTIVER AVANT DE DEPLOYER !!!)
    const plugLoadingDelay = 1000; //Pour simuler un temps de chargement
    const plugLoadingError = false; //Pour simuler une erreur de chargement
    const dataPlug = {
        //Constante permettant de remplacer les données chargées de la vue par des données "en bouchon"
        2021: {
            "01": null,
            "02": null,
            "03": null,
            "04": null,
            "05": null,
            "06": null,
            "07": null,
            "08": {
                1478: {
                    month: "2021-08",
                    name: "le Conseil départemental des Deux-sèvres",
                    supplier_ent_entity_id: 20147,
                    supplier_name: "SAD de Niort",
                    pro_product_id: 1478,
                    pro_product_name: "Assistance à la personne",
                    pro_product_data: {
                        stat: {
                            quantity_pec_granted: 50,
                            quantity_pec_consumed: 30,
                            quantity_pec_pending: 20,
                            quantity_pec_overconsumed: 0,
                            total_consumed: 30,
                        },
                        chart: [
                            {
                                title: "quantity_pec_granted",
                                quantity1: 0,
                                quantity2: 50,
                            },
                            {
                                title: "total_consumed",
                                quantity1: 30,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_pending",
                                quantity1: 20,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_overconsumed",
                                quantity1: 0,
                                quantity2: 0,
                            },
                        ],
                    },
                },
                1522: {
                    month: "2021-08",
                    name: "le Conseil départemental des Deux-sèvres",
                    supplier_ent_entity_id: 204578,
                    supplier_name: "SAD de Niort",
                    pro_product_id: 1522,
                    pro_product_name: "Nettoyage à domicile",
                    pro_product_data: {
                        stat: {
                            quantity_pec_granted: 20,
                            quantity_pec_consumed: 20,
                            quantity_pec_pending: 0,
                            quantity_pec_overconsumed: 0,
                            total_consumed: 20,
                        },
                        chart: [
                            {
                                title: "quantity_pec_granted",
                                quantity1: 0,
                                quantity2: 20,
                            },
                            {
                                title: "total_consumed",
                                quantity1: 20,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_pending",
                                quantity1: 0,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_overconsumed",
                                quantity1: 0,
                                quantity2: 0,
                            },
                        ],
                    },
                },
            },
            "09": {
                1478: {
                    month: "2021-09",
                    name: "le Conseil départemental des Deux-sèvres",
                    supplier_ent_entity_id: 20147,
                    supplier_name: "SAD de Parthenay",
                    pro_product_id: 1478,
                    pro_product_name: "Assistance à la personne",
                    pro_product_data: {
                        stat: {
                            quantity_pec_granted: 60,
                            quantity_pec_consumed: 30,
                            quantity_pec_pending: 30,
                            quantity_pec_overconsumed: 0,
                            total_consumed: 30,
                        },
                        chart: [
                            {
                                title: "quantity_pec_granted",
                                quantity1: 0,
                                quantity2: 60,
                            },
                            {
                                title: "total_consumed",
                                quantity1: 30,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_pending",
                                quantity1: 30,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_overconsumed",
                                quantity1: 0,
                                quantity2: 0,
                            },
                        ],
                    },
                },
                1498: {
                    month: "2021-09",
                    name: "le Conseil départemental des Deux-sèvres",
                    supplier_ent_entity_id: 20147,
                    supplier_name: "SAD de Parthenay",
                    pro_product_id: 1498,
                    pro_product_name: "Consultations médicales",
                    pro_product_data: {
                        stat: {
                            quantity_pec_granted: 10,
                            quantity_pec_consumed: 8,
                            quantity_pec_pending: 2,
                            quantity_pec_overconsumed: 0,
                            total_consumed: 8,
                        },
                        chart: [
                            {
                                title: "quantity_pec_granted",
                                quantity1: 0,
                                quantity2: 10,
                            },
                            {
                                title: "total_consumed",
                                quantity1: 8,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_pending",
                                quantity1: 2,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_overconsumed",
                                quantity1: 0,
                                quantity2: 0,
                            },
                        ],
                    },
                },
                1522: {
                    month: "2021-09",
                    name: "le Conseil départemental des Deux-sèvres",
                    supplier_ent_entity_id: 204578,
                    supplier_name: "Bessine ménage",
                    pro_product_id: 1522,
                    pro_product_name: "Nettoyage à domicile",
                    pro_product_data: {
                        stat: {
                            quantity_pec_granted: 20,
                            quantity_pec_consumed: 20,
                            quantity_pec_pending: 0,
                            quantity_pec_overconsumed: 12,
                            total_consumed: 32,
                        },
                        chart: [
                            {
                                title: "quantity_pec_granted",
                                quantity1: 0,
                                quantity2: 20,
                            },
                            {
                                title: "total_consumed",
                                quantity1: 32,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_pending",
                                quantity1: 0,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_overconsumed",
                                quantity1: 0,
                                quantity2: 12,
                            },
                        ],
                    },
                },
            },
            10: {
                1478: {
                    month: "2021-10",
                    name: "le Conseil départemental des Deux-sèvres",
                    supplier_ent_entity_id: 20147,
                    supplier_name: "SAD de Parthenay",
                    pro_product_id: 1478,
                    pro_product_name: "Assistance à la personne",
                    pro_product_data: {
                        stat: {
                            quantity_pec_granted: 50,
                            quantity_pec_consumed: 50,
                            quantity_pec_pending: 0,
                            quantity_pec_overconsumed: 10,
                            total_consumed: 60,
                        },
                        chart: [
                            {
                                title: "quantity_pec_granted",
                                quantity1: 0,
                                quantity2: 50,
                            },
                            {
                                title: "total_consumed",
                                quantity1: 60,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_pending",
                                quantity1: 0,
                                quantity2: 0,
                            },
                            {
                                title: "quantity_pec_overconsumed",
                                quantity1: 0,
                                quantity2: 10,
                            },
                        ],
                    },
                },
            },
            11: null,
            12: null,
        },
    };
    // Init AND view states (Les hooks doivent obligatoirement être appelés avant le premier return)
    function initMonth() {
        //Doit être définie au début pour être utilisée pour l'initialisation des states
        let today = new Date();
        let initYear = today.getFullYear();
        let initMonthNumber = today.getMonth() - 1;
        if (initMonthNumber < 0) {
            initYear--;
            initMonthNumber = 11;
        }
        let initDate = new Date(initYear, initMonthNumber, 1, 0, 0);
        return initDate;
    }
    const [viewData, setViewData] = useState(""); //Etat qui va stocker les données chargées de la vue
    const [isLoading, setIsloading] = useState(true); //Pour spécifier si la vue est en cours de chargement (Elle l'est toujours au début, c'est le "useEffect" qui va décider par la suite)
    const [errorLoading, setErrorLoading] = useState(false); //Pour spécifier si la vue n'a pas réussi à récupérer les données
    const [selectedMonth, setSelectedMonth] = useState(initMonth());
    const [selectedPrestaId, setSelectedPrestaId] = useState(null); //number
    const [prestaIdList, setPrestaIdList] = useState([]); //[{id: number, pro_product_id:number, supplier_ent_entity_id: number,  name: string}]
    const [selectedDataSet, setSelectedDataSet] = useState(null); //Indique si il y a des suivi à afficher
    const [canRefresh, setCanRefresh] = useState(true); //Pour lancer 1 seule fois le process de refresh du graphique via l'event 'onDrawn'
    const [isReloading, setIsReloading] = useState(false);
    const [errorReloading, setErrorReloading] = useState(false);
    // Init functions
    function selectCurrentData(date, viewData) {
        let currentYear = date.getFullYear();
        //console.warn('currentYear : ', currentYear);
        let currentMonthNumber = date.getMonth() + 1;
        let currentMonthKey = currentMonthNumber < 10 ? "0" + currentMonthNumber.toString() : currentMonthNumber.toString();
        console.warn("currentMonthKey : ", currentMonthKey);
        //Determine default year
        if (!viewData[currentYear]) {
            // Il n'y a aucune donnée à afficher => Il faut ré-initialiser les états d'affichage du graphique et du combo
            console.error("No year data loaded");
            return {
                status: false,
                selectedPrestaId: null,
                prestaIdList: [],
                selectedDataSet: null,
            };
        }
        //Determine PrestaIdList
        if (viewData[currentYear][currentMonthKey]) {
            //On a UN ou DES suivi à afficher => on paramètres les states pour construire la vue
            let prestaIdList = [],
                defaultPrestaId = null,
                defaultDataSet = null;
            let rawPrestaContent = viewData[currentYear][currentMonthKey];
            for (var key in rawPrestaContent) {
                prestaIdList.push({
                    id: key,
                    pro_product_id: rawPrestaContent[key]["pro_product_id"],
                    supplier_ent_entity_id: rawPrestaContent[key]["supplier_ent_entity_id"],
                    name:
                        rawPrestaContent[key]["pro_product_name"] +
                        (rawPrestaContent[key]["payer_name"] ? " (" + rawPrestaContent[key]["payer_name"] + ")" : "Inconnu"),
                });
            }
            defaultPrestaId = prestaIdList[0].id;
            defaultDataSet = rawPrestaContent[defaultPrestaId];
            // On set les états d'affichage du graphique et du combo
            return {
                status: true,
                selectedPrestaId: defaultPrestaId,
                prestaIdList: prestaIdList,
                selectedDataSet: defaultDataSet,
            };
        } else {
            // Il n'y a aucune donnée à afficher => Il faut ré-initialiser les états d'affichage du graphique et du combo
            console.error("No month data loaded");
            return {
                status: false,
                selectedPrestaId: null,
                prestaIdList: [],
                selectedDataSet: null,
            };
        }
    }
    function loadViewData() {
        function handleResponseHelp(response) {
            if (response?.help && response?.help?.content) {
                props.setHelpContent(response?.help?.content);
                props.setHelpImportance(response?.help?.importance);
                if (response?.help?.default_display) {
                    props.setHelpDisplay(true);
                }
            }
        }
        setIsloading(true);
        setErrorLoading(false);
        if (dataPlug && useDataPlug) {
            setTimeout(function () {
                if (plugLoadingError) {
                    setErrorLoading(true);
                    setIsloading(false);
                } else {
                    let dataSelected = selectCurrentData(selectedMonth, dataPlug);
                    setViewData(dataPlug);
                    setSelectedPrestaId(dataSelected.selectedPrestaId);
                    setPrestaIdList(dataSelected.prestaIdList);
                    setSelectedDataSet(dataSelected.selectedDataSet);
                    setIsloading(false);
                }
            }, plugLoadingDelay);
            return;
        }
        let startDate = new Date(selectedMonth);
        startDate.setDate(1);
        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(endDate.getDate() - 1);
        props
            .request({
                data: {
                    start_date: formatAsaproDate(startDate),
                    end_date: formatAsaproDate(endDate),
                }, // A personnaliser en fonction de la vue
            })
            .then(function (response) {
                let dataSelected = selectCurrentData(selectedMonth, response.data);
                setSelectedPrestaId(dataSelected.selectedPrestaId);
                setPrestaIdList(dataSelected.prestaIdList);
                setSelectedDataSet(dataSelected.selectedDataSet);
                setViewData(response.data);
                handleResponseHelp(response);
                setTimeout(function () {
                    //Pour laisser le temps au css de se charger pour éviter les bugs graphiques (c'est 'dirty' mais c'est un patch rapide)
                    setIsloading(false);
                }, 1000);
            })
            .catch(function (error) {
                setErrorLoading(true);
                setIsloading(false);
                if (error.msg) {
                    console.error(error.msg);
                }
                console.error(error);
            });
    }
    // Init AND view effects (Les hooks doivent obligatoirement être appelés avant le premier return)
    useEffect(function () {
        //Mise à jour du fil d'ariane
        props.updateUserJourney(["home", "home_demandes", "client_monitoring"]); // A personnaliser en fonction de la vue
    }, []);
    let ent_entity_id = props.selectedClient.ent_entity_id;
    useEffect(
        function () {
            //Chargement des données de la vue (Bouchon ou non)
            loadViewData();
        },
        [ent_entity_id]
    );
    // Init render treatments
    if (isLoading && !errorLoading) {
        return <InitView type="loading" autoFocus />;
    }
    if (errorLoading) {
        return <InitView type="errorLoading" />;
    }
    //---------------------------------//
    //---------- View code when data is loaded correctly ----------//
    // Dans cette partie, l'état "viewData" est correctement instancié sans erreur de chargement (peut quand même charger des données vides)

    // View constants

    // View global functions

    // UI event handlers
    function onCalendarValueChanged(value) {
        if (!isReloading) {
            //Pour bloquer le calendrier lorsqu'on est déjà en train de charger une date
            const selectedDate = new Date(value);
            var newSelectedMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1, 0, 0);
            setSelectedMonth(newSelectedMonth);
            let dataSelected = selectCurrentData(newSelectedMonth, viewData);
            if (dataSelected.status) {
                // Les données que l'on cherche sont déjà dans viewData donc pas besoin de les charger
                setSelectedPrestaId(dataSelected.selectedPrestaId);
                setPrestaIdList(dataSelected.prestaIdList);
                setSelectedDataSet(dataSelected.selectedDataSet);
            } else {
                // On doit récupérer les données que l'on souhaite
                setIsReloading(true);
                let startDate = new Date(newSelectedMonth);
                let endDate = new Date(startDate);
                endDate.setMonth(endDate.getMonth() + 1);
                endDate.setDate(endDate.getDate() - 1);
                props
                    .request({
                        data: {
                            start_date: formatAsaproDate(startDate),
                            end_date: formatAsaproDate(endDate),
                        },
                    })
                    .then(function (response) {
                        let dataSelected = selectCurrentData(newSelectedMonth, response.data);
                        setSelectedPrestaId(dataSelected.selectedPrestaId);
                        setPrestaIdList(dataSelected.prestaIdList);
                        setSelectedDataSet(dataSelected.selectedDataSet);
                        setViewData(response.data); // TODO: Remplacer par concatViewData (pour enrichir "viewData" au fur et à mesure des appels au lieu de le remplacer)
                        setIsReloading(false);
                    })
                    .catch(function (error) {
                        setIsReloading(false);
                        setErrorReloading(true);
                        if (error.msg) {
                            console.error(error.msg);
                        }
                        console.error(error);
                    });
            }
        } else {
            setSelectedMonth(new Date(selectedMonth));
        }
    }
    function switchToPreviousPeriod() {
        const date = new Date(selectedMonth);
        date.setMonth(date.getMonth() - 1);
        onCalendarValueChanged(date);
    }
    function switchToNextPeriod() {
        const date = new Date(selectedMonth);
        date.setMonth(date.getMonth() + 1);
        onCalendarValueChanged(date);
    }
    // Pre-rendering treatments
    let prestaDisplay, contentPart;
    if (selectedDataSet && !isReloading && !errorReloading) {
        //prestaDisplay settings
        function valueChanged(event) {
            let monthNumber = selectedMonth.getMonth() + 1;
            let monthKey = monthNumber < 10 ? "0" + monthNumber.toString() : monthNumber.toString();
            setSelectedPrestaId(event.target.value);
            let newDataSet = viewData[selectedMonth.getFullYear()][monthKey][event.target.value];
            setSelectedDataSet(newDataSet);
        }
        function buildPrestaOptions(prestaList) {
            const optList = [];
            if (typeof prestaList === "object") {
                prestaList.forEach(function (prestaOption, index) {
                    optList.push(
                        <option key={prestaOption?.id || index} value={prestaOption?.id}>
                            {prestaOption?.name}
                        </option>
                    );
                });
            }
            return optList;
        }
        prestaDisplay = (
            <Form.Group controlId="prestaSelect" className="mealMomentGroup">
                <Form.Label>Prestation financée</Form.Label>
                <Form.Control
                    id="prestaSelect"
                    onChange={valueChanged}
                    as="select"
                    autoComplete="off"
                    name="prestaSelect"
                    defaultValue={selectedPrestaId}
                    //disabled={!(prestaIdList.length > 1)}
                    //aria-disabled={!(prestaIdList.length > 1)}
                    aria-required={false}
                    aria-invalid={false}
                >
                    <optgroup label="Liste des prestations">{buildPrestaOptions(prestaIdList)}</optgroup>
                </Form.Control>
            </Form.Group>
        );
        //contentPart Settings (chart)
        function customizeDataSource(rawDataMonth, openingAngle) {
            let dataMonth = [...rawDataMonth]; //Pour casser la référence avec les éléments du store
            let quantitySum1 = 0,
                quantitySum2 = 0,
                vOpening1 = 0,
                vOpening2,
                vDelta2 = 0;
            openingAngle = openingAngle ? openingAngle % 360 : 0;
            dataMonth.map(function (dataItem) {
                quantitySum1 += dataItem.quantity1;
                quantitySum2 += dataItem.quantity2;
                return null;
            });
            if (openingAngle !== 0) {
                vOpening1 = -(quantitySum1 * openingAngle) / (openingAngle - 360);
                vOpening2 = -(quantitySum2 * openingAngle) / (openingAngle - 360);
            } else {
                vOpening1 = 0;
                vOpening2 = 0;
            }
            vDelta2 = quantitySum1 + vOpening1 - vOpening2 - quantitySum2;
            dataMonth.push({
                title: "chartOpening",
                quantity1: vOpening1,
                quantity2: vOpening2 + vDelta2,
            });
            return dataMonth;
        }
        function customizePoint(pointInfo) {
            let config = {};
            //console.log('pointInfo : ',pointInfo);
            switch (pointInfo.argument) {
                case "chartOpening":
                    config = { color: "rgba(0, 0, 0, 0)" };
                    break;
                case "quantity_pec_granted":
                    config = { color: "rgba(123, 211, 247, 1)" };
                    break;
                case "total_consumed":
                    config = { color: "rgba(113, 188, 104, 1)" };
                    break;

                case "quantity_pec_pending":
                    config = { color: "rgba(113, 188, 104, 0.5)" };
                    break;
                case "quantity_pec_overconsumed":
                    config = { color: "rgba(240, 74, 0, 1)" };
                    break;
                default:
                    break;
            }
            return config;
        }
        function customizeLabel(pointInfo) {
            //console.log("pointInfo : ", pointInfo);
            switch (true) {
                case pointInfo.value == 0:
                    return {
                        customizeText: function () {
                            return "";
                        },
                    };
                case pointInfo.argument === "chartOpening":
                    return {
                        font: {
                            color: "#000000",
                            //size: "30px",
                            //weight: 200,
                        },
                        radialOffset: -50,
                        customizeText: function () {
                            return pointInfo.seriesName === "insideQuantity"
                                ? +selectedDataSet?.pro_product_data?.stat?.percentage_consumed + " % réalisé"
                                : "";
                        },
                    };
                default:
                    return {
                        customizeText: function () {
                            return pointInfo.value + " h";
                        },
                    };
            }
        }
        function displayLegendItems() {
            var displayLegendItems = [],
                itemLabel;
            const keyOrdered = [];
            if (selectedDataSet.pro_product_data.stat.quantity_pec_granted > 0) keyOrdered.push("quantity_pec_granted");
            keyOrdered.push("total_consumed");
            if (selectedDataSet.pro_product_data.stat.quantity_pec_granted > 0) keyOrdered.push("quantity_pec_pending");
            keyOrdered.push("quantity_pec_overconsumed");
            keyOrdered.push("quantity_pec_consumed");
            keyOrdered.forEach(function (key) {
                itemLabel = props.i18n("label.field." + key);
                if (key === "quantity_pec_granted" || key === "quantity_pec_consumed") {
                    //itemLabel += ` ${selectedDataSet.payer_name}`;//On met un texte i18n bateau finalement
                }
                displayLegendItems.push(
                    <span className={`pieLegendItem${key === "quantity_pec_consumed" ? " bold" : ""}`} key={key}>
                        <div className="pieLegendLabelContainer">
                            <i className={`pieLegendColor ${key}`}></i>
                            <p className="pieLegendLabel">{`${itemLabel}`}</p>&nbsp;
                        </div>
                        <p className="pieLegendValue">{`${selectedDataSet.pro_product_data.stat[key]} h`}</p>
                    </span>
                );
            });
            return displayLegendItems;
        }
        function redrawChart(event) {
            if (canRefresh) {
                setCanRefresh(false);
                function redraw(delay, number) {
                    setTimeout(function () {
                        chart.refresh();
                        if (number - 1 > 0) redraw(delay, number - 1);
                    }, delay);
                }
                const chart = event.component;
                const delay = 0.2; //sec
                const duration = 0.2; //sec
                let redrawNumber = duration * (1 / delay);
                redraw(delay * 1000, redrawNumber);
            }
        }
        contentPart = (
            <div className="pieContainer">
                {/*<h1 className="pieTitle">
                    {selectedDataSet.name ? "Suivi des heures financées par : " + selectedDataSet.name : "Suivi des heures financées"}
                    <br />
                    <span className="pieSubtitle">
                        {selectedDataSet.supplier_name ? "(Prestation réalisée par : " + selectedDataSet.supplier_name + ")" : ""}
                    </span>
                </h1>*/}
                <div className="pieContent">
                    <div className="pieLegendContainer">
                        {/*<div className="pieLegendTitleContainer">
                            <h2 className="pieLegendTitle">Détail de la répartition des interventions</h2>
                            <h3 className="pieLegendSubtitle">(Unité : heures et centièmes d'heure)</h3>
                           </div>*/}
                        <div className="pieLegendItemContainer">{displayLegendItems()}</div>
                    </div>
                    <div className="pieDrawingContainer">
                        <PieChart
                            //adaptiveLayout = {false}
                            //centerRender = {function(){return(<p>test</p>);}}
                            //customizeLabel = {function(pointInfo){if(pointInfo.index===0){return({backgroundColor: 'red'})}}} //To change a specific label appearance
                            //className='pieChart'
                            customizePoint={customizePoint}
                            customizeLabel={customizeLabel}
                            dataSource={customizeDataSource(selectedDataSet.pro_product_data.chart, 120)}
                            diameter={1} //Ratio of the width of the svg container
                            disabled={false}
                            //elementAttr = {{class: 'pieContainer'}}
                            innerRadius={0.5}
                            //minDiameter = {1}
                            //palette={['rgba(113, 188, 104, 1)','rgba(113, 188, 104, 1)','rgba(113, 188, 104, 1)']}
                            redrawOnResize={true}
                            startAngle={-150}
                            onDrawn={redrawChart}
                            //title = {`Suivi des heures financées par ${selectedDataSet.name}`}
                            type="doughnut"
                        >
                            <Animation easing="linear" duration={500} maxPointCountSupported={100} />
                            <Legend visible={false} />
                            <Series
                                argumentField="title"
                                //hoverMode= 'none'
                                //minSegmentSize={4}
                                name="insideQuantity"
                                valueField="quantity1"
                            >
                                <Label
                                    //customizeText={customizeLabelTxt}
                                    visible
                                    position="inside"
                                    radialOffset={0}
                                    backgroundColor="transparent"
                                    font={{ color: "#000000" }}
                                ></Label>
                            </Series>
                            <Series
                                argumentField="title"
                                //hoverMode= 'none'
                                //minSegmentSize={4}
                                name="outsideQuantity"
                                valueField="quantity2"
                            >
                                <Label
                                    //customizeText={customizeLabelTxt}
                                    visible
                                    position="inside"
                                    radialOffset={0}
                                    backgroundColor="transparent"
                                    font={{ color: "#000000" }}
                                ></Label>
                            </Series>
                        </PieChart>
                    </div>
                </div>
            </div>
        );
    } else {
        prestaDisplay = "";
        if (isReloading) {
            contentPart = <InitView type="loading" />;
        } else if (errorReloading) {
            contentPart = <InitView type="errorLoading" />;
        } else {
            contentPart = <InitView type="errorLoading" errorMsg="Pas de données à afficher" />;
        }
    }
    // Rendering part
    return (
        <>
            {props.setStyle("./normal")}
            <h2 className="titlePart">Répartition des interventions</h2>
            <div className="headerPart">
                {/*calendarDisplay*/}
                <Form.Group controlId="selectedDate" className="selectedDateGroup">
                    <Form.Label>Mois sélectionné</Form.Label>
                    <div className="inputBlock">
                        <BtnToolTip
                            iconCls="fas fa-chevron-left"
                            onClick={switchToPreviousPeriod}
                            disabled={isLoading || isReloading}
                            aria-label=""
                        />
                        <PortalText
                            id="selectedDate"
                            //onChange={(e) => onCalendarValueChanged(e.target.value)}
                            type="text"
                            autoComplete="off"
                            name="selectedDate"
                            value={formatMonthTitleDisplay(selectedMonth)}
                            required={true}
                            aria-required={true}
                            isInvalid={false}
                            aria-invalid={false}
                            autoFocus
                            disabled
                        />
                        <BtnToolTip
                            iconCls="fas fa-chevron-right"
                            onClick={switchToNextPeriod}
                            disabled={isLoading || isReloading}
                            aria-label=""
                        />
                    </div>
                </Form.Group>
                {prestaDisplay}
            </div>
            <div className="contentPart">{contentPart}</div>
            <p className="unityDisplay">Unité : heures et centièmes d'heure</p>
        </>
    );
    //-------------------------------------------------------------//
}
//###########################################################################################//
export default ClientMonitoring;
