import React from "react";
import { useState } from "react";
import "./warningBlock.css";
function WarningBlock(props) {
    const [active, setActive] = useState(false);

    function onClosing() {
        props.destroy();
    }
    function onAdaptiveClick(event) {
        setActive(!active);
    }
    function getImportance(rawImportance) {
        let allowedImp = ["info", "warning", "danger"];
        let imp = allowedImp.find(function (level) {
            return level === rawImportance;
        });
        return imp || "info";
    }
    const importance = getImportance(props?.importance);
    if (props.adaptive) {
        return (
            <section className={`warningBlockContainer adaptive ${props.className || ""}`}>
                <i className={`${props.i18n(`icon.${importance}_icon`)} iconWarning`} onClick={onAdaptiveClick}></i>
                <div className={`floatingContainer ${active ? "active" : ""}`}>
                    <p className="txtPart" dangerouslySetInnerHTML={{ __html: props.content || "" }}></p>
                    <i className="fas fa-times iconClose" onClick={onClosing}></i>
                </div>
            </section>
        );
    }

    return (
        <section className={`warningBlockContainer ${props.className || ""} ${importance}`}>
            <i className={`${props.i18n(`icon.${importance}_icon`)} iconWarning`}></i>
            <p className="txtPart" dangerouslySetInnerHTML={{ __html: props.content || "" }}></p>
            <i className="fas fa-times iconClose" onClick={onClosing}></i>
        </section>
    );
}

export default WarningBlock;
