//######################################## Imports ########################################//
//---------- Logical Components and modules ----------//
//React librarie
import React from "react";
import { useState, useEffect, useRef } from "react";
//Custom Library
import { isEmailType, buildFeedBack } from "../../../../../../../ressources/lib/js/globalFunctions.js";
import PortalFeedback from "../../../../../../../ressources/components/portalFields/portalFeedback/portalFeedback.js";
//----------------------------------------------------//
//---------- UI Components ----------//
//External UI Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//Custom UI Components
import BubbleView from "../../../../../../../ressources/components/bubbleView/bubbleView.js";
import PopupView from "../../../../../../../ressources/components/popupView/popupView.js";
import ItemRow from "../../../../../../../ressources/components/itemRow/itemRow.js";
import PortalBtn from "../../../../../../../ressources/components/portalBtn/portalBtn.js";
import PortalPassword from "../../../../../../../ressources/components/portalFields/portalPassword/portalPassword.js";
import PortalText from "../../../../../../../ressources/components/portalFields/portalText/portalText.js";
import Fconnect from "../../../../../../../ressources/components/fconnect/fconnect.js";
import PscConnect from "../../../../../../../ressources/components/pscConnect/pscConnect.js";
//-----------------------------------//
//#########################################################################################//
//######################################## View code ########################################//

export default function Screen(props) {
    //Controller params
    const portalType = props.portalType;
    const contactAssist = props.contactAssist;
    const contactUrl = props.contactUrl;
    const pscConnect = props.pscConnect;
    const fConnect = props.fConnect;
    const loginForm = props.loginForm;
    const editLoginForm = props.editLoginForm;
    const recoverPasswordForm = props.recoverPasswordForm;
    const editRecoverPasswordForm = props.editRecoverPasswordForm;
    const clearRecoverPasswordForm = props.clearRecoverPasswordForm;
    const recoverLoginForm = props.recoverLoginForm;
    const editRecoverLoginForm = props.editRecoverLoginForm;
    const clearRecoverLoginForm = props.clearRecoverLoginForm;
    const recoveredLogin = props.recoveredLogin;
    const loginFirstTry = props.loginFirstTry;
    const setLoginFirstTry = props.setLoginFirstTry;
    const recoverPasswordFirstTry = props.recoverPasswordFirstTry;
    const setRecoverPasswordFirstTry = props.setRecoverPasswordFirstTry;
    const recoverLoginFirstTry = props.recoverLoginFirstTry;
    const setRecoverLoginFirstTry = props.setRecoverLoginFirstTry;
    const loginState = props.loginState;
    const recoverPassState = props.recoverPassState;
    const recoverLoginState = props.recoverLoginState;
    const postLogin = props.postLogin;
    const postRecoverPassword = props.postRecoverPassword;
    const postRecoverLogin = props.postRecoverLogin;
    const loginFormValidator = props.loginFormValidator;
    const recoverPasswordFormValidator = props.recoverPasswordFormValidator;
    const recoverLoginFormValidator = props.recoverLoginFormValidator;
    //States
    const [showPopup, setShowPopup] = useState("");

    //Refs
    const autoFocusRef = useRef();
    const issueOptionsFocusRef = useRef();
    const recoverPasswordFocusRef = useRef();
    const recoverLoginFocusRef = useRef();
    const contactAssistanceFocusRef = useRef();
    function contactAssistDisplay() {
        return contactAssist || "<Moyen de contact à renseigner>";
    }

    //Effects treatments
    useEffect(
        function () {
            switch (showPopup) {
                case "issue_options":
                    if (issueOptionsFocusRef.current) issueOptionsFocusRef.current.focus();
                    break;
                case "recover_password":
                    if (recoverPasswordFocusRef.current) recoverPasswordFocusRef.current.focus();
                    break;
                case "recover_login":
                    if (recoverLoginFocusRef.current) recoverLoginFocusRef.current.focus();
                    break;
                case "contact_assistance":
                    if (contactAssistanceFocusRef.current) contactAssistanceFocusRef.current.focus();
                    break;
                default:
                    autoFocusRef.current.focus();
                    break;
            }
        },
        [showPopup]
    );
    useEffect(
        //Screen specific
        function () {
            const specialAction = props.getViewContext();
            if (specialAction?.open) {
                setShowPopup(specialAction?.open);
                props.redirectTo(props.viewName);
                //To force tooltip Popover to close
                const body = document.body;
                if (body) body.click();
            }
        },
        [props.viewHash]
    );

    //View global functions
    function clearPopupFieldStates() {
        clearRecoverPasswordForm();
        clearRecoverLoginForm();
        setRecoverPasswordFirstTry(false);
        setRecoverLoginFirstTry(false);
    }

    //UI event handlers
    function onHidePopup() {
        setShowPopup("");
        clearPopupFieldStates();
    }
    function onReturnPopup() {
        setShowPopup("issue_options");
        clearPopupFieldStates();
    }
    function onSubmitLogin(event) {
        event.preventDefault();
        postLogin().catch(function (err) {
            console.error(err);
            props.msgBox(err.msg, "error", "msg");
        });
    }
    function onSubmitForgottenPassword(event) {
        event.preventDefault();
        postRecoverPassword()
            .then(function (res) {
                props
                    .msgBox(
                        "Votre demande a bien été prise en compte.\nUn lien de ré-initialisation a été envoyé dans votre boîte mail",
                        "info",
                        "msg"
                    )
                    .then(function (res) {
                        setShowPopup("");
                        clearPopupFieldStates();
                    });
            })
            .catch(function (err) {
                console.error(err);
                props.msgBox(err.msg, "error", "msg");
            });
    }
    function onSubmitForgottenLogin(event) {
        event.preventDefault();
        postRecoverLogin().catch(function (err) {
            console.error(err);
            props.msgBox(err.msg, "error", "msg");
        });
    }
    // Pre-rendering treatments
    //loginFormValidation
    const loginUsernameValidationResult = loginFormValidator.fields.login.validate();
    const loginPasswordValidationResult = loginFormValidator.fields.password.validate();
    //recoverPasswordFormValidation
    const recovPasswordUsernameResult = recoverPasswordFormValidator.fields.username.validate();
    const recovPasswordExternalRefResult = recoverPasswordFormValidator.fields.external_ref.validate();
    //recoverLoginFormValidation
    const recovLoginFirstNameResult = recoverLoginFormValidator.fields.first_name.validate();
    const recovLoginLastNameResult = recoverLoginFormValidator.fields.last_name.validate();
    const recovLoginBirthdateResult = recoverLoginFormValidator.fields.birth_date.validate();
    const recovLoginPostalCodeResult = recoverLoginFormValidator.fields.postal_code.validate();
    function contactAssistDisplay() {
        return contactAssist || "<Moyen de contact à renseigner>";
    }
    // Popup building
    function popupFooterRender() {
        let popupFooter;
        switch (true) {
            case showPopup === "issue_options":
                popupFooter = (
                    <ItemRow>
                        <PortalBtn onClick={onHidePopup}>Annuler</PortalBtn>
                    </ItemRow>
                );
                break;
            default:
                popupFooter = (
                    <ItemRow>
                        <PortalBtn onClick={onReturnPopup}>Précédent</PortalBtn>
                        <PortalBtn onClick={onHidePopup}>Annuler</PortalBtn>
                    </ItemRow>
                );
                break;
        }
        return popupFooter;
    }
    let popupVisible = false,
        popupContent = "",
        popupTitle = "";
    switch (true) {
        case showPopup === "issue_options":
            popupVisible = true;
            popupTitle = "Quel problème rencontrez-vous ?";
            popupContent = (
                <>
                    <Container className="popupFormContainer">
                        <Row as={"ul"}>
                            <Col xs={12} as={"li"} className="issueOption">
                                <button
                                    className="portalLinkBtn"
                                    onClick={() => setShowPopup("recover_password")}
                                    ref={issueOptionsFocusRef}
                                >
                                    J'ai oublié mon mot de passe
                                </button>
                            </Col>
                            <Col xs={12} as={"li"} className="issueOption">
                                <button className="portalLinkBtn" onClick={() => setShowPopup("recover_login")}>
                                    J'ai oublié mon identifiant
                                </button>
                            </Col>
                            {contactAssist ? (
                                <Col xs={12} as={"li"} className="issueOption">
                                    <button className="portalLinkBtn" onClick={() => setShowPopup("contact_assistance")}>
                                        {props.i18n("label.contact_assist")}
                                    </button>
                                </Col>
                            ) : (
                                ""
                            )}
                        </Row>
                    </Container>
                </>
            );
            break;
        case showPopup === "recover_password":
            popupVisible = true;
            popupTitle = "J'ai oublié mon mot de passe";
            popupContent = (
                <>
                    <Row className="popupFormDescrRow">
                        <Col xs={12} className="popupFormDescrTxt">
                            Veuillez renseigner l'identifiant de votre compte ainsi que votre numéro de dossier
                        </Col>
                    </Row>
                    <Form className="popupFormContainer" onSubmit={onSubmitForgottenPassword} noValidate>
                        <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                        <Form.Group controlId="currentMail">
                            <Form.Label>
                                {props.i18n("label.field.username")}&nbsp;
                                <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="currentMail"
                                onChange={(event) => editRecoverPasswordForm("username", event.target.value)}
                                type="email"
                                iconsLeft="far fa-envelope"
                                name="currentMail"
                                autoComplete="email"
                                value={recoverPasswordForm.username}
                                ref={recoverPasswordFocusRef}
                                required={true}
                                aria-required={true}
                                aria-invalid={!recovPasswordUsernameResult.success && recoverPasswordFirstTry}
                                isInvalid={!recovPasswordUsernameResult.success && recoverPasswordFirstTry}
                                aria-describedby="currentMail-1 currentMail-2"
                                tooltip={props.i18n("info.field.new_username")}
                            />
                            {recoverPasswordFirstTry && (
                                <PortalFeedback fieldId="currentMail" msgList={recovPasswordUsernameResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        {/*<Form.Group controlId="fileNumber">
                            <Form.Label>
                                Numéro de dossier <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="fileNumber"
                                onChange={(event) => editRecoverPasswordForm("external_ref", event.target.value)}
                                type="text"
                                value={recoverPasswordForm.external_ref}
                                name="fileNumber"
                                aria-required={true}
                                required={true}
                                isInvalid={!recovPasswordExternalRefResult.success && recoverPasswordFirstTry}
                                aria-invalid={!recovPasswordExternalRefResult.success && recoverPasswordFirstTry}
                                aria-describedby="fileNumber-1"
                                tooltip={props.i18n("info.field.file_number")}
                            />
                            {recoverPasswordFirstTry && (
                                <PortalFeedback fieldId="fileNumber" msgList={recovPasswordExternalRefResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>*/}
                        <PortalBtn className="validate centered" state={recoverPassState} type="submit">
                            Valider pour ré-initialiser votre mot de passe
                        </PortalBtn>
                        <div className="passwordWarningMsg">
                            Attention, ce mot de passe est celui de votre compte local et en aucun cas celui du compte que vous utilisez au
                            travers de FranceConnect. Il vous servira uniquement lorsque vous vous connecterez avec votre adresse mail
                            plutôt que via FranceConnect
                        </div>
                    </Form>
                </>
            );
            break;
        case showPopup === "recover_login":
            popupVisible = true;
            popupTitle = "J'ai oublié mon identifiant";
            popupContent = (
                <>
                    <Row className="popupFormDescrRow">
                        <Col xs={12} className="popupFormDescrTxt">
                            Merci de renseigner les éléments suivants
                        </Col>
                    </Row>
                    <Form className="popupFormContainer" onSubmit={onSubmitForgottenLogin} noValidate>
                        <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>
                                Nom <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="lastName"
                                ref={recoverLoginFocusRef}
                                onChange={(event) => editRecoverLoginForm("last_name", event.target.value)}
                                type="text"
                                autoComplete="family-name"
                                value={recoverLoginForm.last_name}
                                name="lastName"
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginLastNameResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginLastNameResult.success && recoverLoginFirstTry}
                                aria-describedby="lastName-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="lastName" msgList={recovLoginLastNameResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <Form.Group controlId="firstName">
                            <Form.Label>
                                Prénom <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="firstName"
                                onChange={(event) => editRecoverLoginForm("first_name", event.target.value)}
                                type="text"
                                autoComplete="given-name"
                                value={recoverLoginForm.first_name}
                                name="firstName"
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginFirstNameResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginFirstNameResult.success && recoverLoginFirstTry}
                                aria-describedby="firstName-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="firstName" msgList={recovLoginFirstNameResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <Form.Group controlId="birthDate">
                            <Form.Label>
                                Date de naissance <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="birthDate"
                                onChange={(event) => editRecoverLoginForm("birth_date", event.target.value)}
                                type="date"
                                autoComplete="bday"
                                name="birthDate"
                                value={recoverLoginForm.birth_date}
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginBirthdateResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginBirthdateResult.success && recoverLoginFirstTry}
                                aria-describedby="birthDate-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="birthDate" msgList={recovLoginBirthdateResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        <Form.Group controlId="postalCode">
                            <Form.Label>
                                Code postal <span aria-hidden={true}>*</span>
                            </Form.Label>
                            <PortalText
                                id="postalCode"
                                onChange={(event) => editRecoverLoginForm("postal_code", event.target.value)}
                                type="text"
                                value={recoverLoginForm.postal_code}
                                name="postalCode"
                                required={true}
                                aria-required={true}
                                isInvalid={!recovLoginPostalCodeResult.success && recoverLoginFirstTry}
                                aria-invalid={!recovLoginPostalCodeResult.success && recoverLoginFirstTry}
                                aria-describedby="postalCode-1"
                            />
                            {recoverLoginFirstTry && (
                                <PortalFeedback fieldId="postalCode" msgList={recovLoginPostalCodeResult.errors} i18n={props.i18n} />
                            )}
                        </Form.Group>
                        {recoveredLogin ? (
                            <div className="recoveredLogin">
                                <span className="content">{recoveredLogin}</span>
                            </div>
                        ) : (
                            ""
                        )}
                        <PortalBtn
                            className="validate centered"
                            state={recoverLoginState}
                            type="submit"
                            //aria-label="Récupérer mon identifiant"
                        >
                            Récupérer mon identifiant
                        </PortalBtn>
                    </Form>
                </>
            );
            break;
        case showPopup === "contact_assistance":
            popupVisible = true;
            popupTitle = "Autres problèmes de connexion";
            popupContent = (
                <>
                    <Container className="popupFormContainer">
                        <Row className="popupFormDescrRow">
                            <Col xs={12} className="popupFormDescrTxt">
                                Si vous rencontrez des difficultés pour vous connecter, veuillez contacter l'assistance :
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="phoneContact" id="phoneContact">
                                <span className="sr-only">
                                    Si vous rencontrez des difficultés pour vous connecter, veuillez contacter l'assistance :
                                </span>
                                <address>{contactAssistDisplay()}</address>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
            break;
        default:
            popupVisible = false;
            popupContent = "";
            popupTitle = "";
            break;
    }
    // Rendering part
    return (
        <div className="loginContainer">
            {props.setStyle("./base/desktop")}
            <PopupView
                onHiding={onHidePopup}
                showCloseButton={true}
                title={popupTitle}
                visible={popupVisible}
                footerRender={popupFooterRender}
            >
                {<div className="popupFormBlock">{popupContent}</div>}
            </PopupView>
            <BubbleView logo={true} title="" className="loginPanel">
                <a
                    id="contact"
                    className={!contactUrl ? "disabled" : ""}
                    href={contactUrl || null}
                    target="_blank"
                    rel="noreferrer"
                    //tabIndex={10}
                    aria-label="Site web du gestionnaire de portail"
                >
                    {"Contact".toUpperCase()}
                </a>
                <Row className="portalRow fcIdent">
                    <Row className="portalRow">
                        {fConnect && portalType == "client" ? (
                            <Col xs={12}>
                                <h2 className="portalRow">Identification simplifiée avec FranceConnect</h2>
                                <Fconnect
                                    txtInfos={props.i18n("info.fconnect_desc")}
                                    //tabIndex={[6, 7]}
                                    msgBox={props.msgBox}
                                />
                            </Col>
                        ) : (
                            ""
                        )}
                        {pscConnect && portalType == "pro" ? (
                            <Col xs={12}>
                                <h2 className="portalRow">Connexion simplifiée des intervenants / Professionnels de santé</h2>
                                <PscConnect txtInfos={props.i18n("info.pscConnect_desc")} msgBox={props.msgBox} />
                            </Col>
                        ) : (
                            ""
                        )}
                    </Row>
                </Row>
                <Row className="portalRow noMargin separator">
                    <span className="lineSeparator text bold">OU</span>
                </Row>
                <Row className="portalRow classicIdent">
                    <h2 className="portalRow">Identification classique</h2>
                    <Col md={12} id="signinBlock">
                        <h2 className="portalRow">{props.i18n("label.login_header")}</h2>
                        {/*<Row className="portalRow">
                            <Fconnect
                                txtInfos="Se connecter avec FranceConnect. Accédez à tous les services avec un seul compte : ameli, impôts, la poste, Mobile Connect et moi ou MSA."
                                tabIndex={[1, 2]}
                            />
                        </Row>
                        <Row className="portalRow">
                            <span className="lineSeparator text bold">OU</span>
                        </Row>*/}
                        <Form id="loginForm" className="portalRow" onSubmit={onSubmitLogin} noValidate>
                            <Form.Group className="smallFont">(*) : Champs obligatoires</Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>
                                    {props.i18n("label.field.username")}
                                    &nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                                <PortalText
                                    id="formBasicEmail"
                                    value={loginForm.login}
                                    type="email"
                                    iconsLeft="far fa-envelope"
                                    autoComplete="email"
                                    onChange={(e) => {
                                        editLoginForm("login", e.target.value);
                                    }}
                                    ref={autoFocusRef}
                                    //tabIndex={3}
                                    tooltip={props.i18n("info.field.username", [props.i18n("label.issue_global")])}
                                    aria-required={true}
                                    isInvalid={!loginUsernameValidationResult.success && loginFirstTry}
                                    aria-invalid={!loginUsernameValidationResult.success && loginFirstTry}
                                    aria-describedby="formBasicEmail-1 formBasicEmail-2"
                                />
                                {loginFirstTry && (
                                    <PortalFeedback
                                        fieldId="formBasicEmail"
                                        msgList={loginUsernameValidationResult.errors}
                                        i18n={props.i18n}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>
                                    {props.i18n("label.field.current_password")}
                                    &nbsp;<span aria-hidden={true}>*</span>
                                </Form.Label>
                                <PortalPassword
                                    id="formBasicPassword"
                                    type="text"
                                    iconsLeft="far fa-lock-alt"
                                    value={loginForm.password}
                                    tooltip={props.i18n("info.field.current_password", [props.i18n("label.issue_global")])}
                                    autoComplete="current-password"
                                    onChange={(e) => {
                                        editLoginForm("password", e.target.value);
                                    }}
                                    //tabIndex={4}
                                    aria-required={true}
                                    aria-describedby="passwordError-1"
                                    isInvalid={!loginPasswordValidationResult.success && loginFirstTry}
                                    aria-invalid={!loginPasswordValidationResult.success && loginFirstTry}
                                />
                                {loginFirstTry && (
                                    <PortalFeedback
                                        fieldId="passwordError"
                                        msgList={loginPasswordValidationResult.errors}
                                        i18n={props.i18n}
                                    />
                                )}
                            </Form.Group>
                            <PortalBtn className="centered" state={loginState} loadingTxt="Connexion en cours" id="loginBtn" type="submit">
                                {props.i18n("label.action.send_login")}
                            </PortalBtn>
                        </Form>
                        <button className="connectIssues portalLinkBtn" onClick={() => setShowPopup("issue_options")} /*tabIndex={9}*/>
                            {props.i18n("label.issue_global")}
                        </button>
                    </Col>
                    <Col md={12} id="signupBlock">
                        <h2 className="portalRow">{props.i18n("label.signin_header")}</h2>
                        <PortalBtn
                            className="centered"
                            id="signupBtn"
                            onClick={() => props.redirectTo("register")}
                            //tabIndex={8}
                        >
                            {props.i18n("label.view.register")}
                        </PortalBtn>
                    </Col>
                </Row>
            </BubbleView>
        </div>
    );
}
//###########################################################################################//
