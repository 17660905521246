import WebSocketMQTT from "./WebSocketMQTT";
import { isArray } from "../ressources/lib/js/globalFunctions";
const pako = require("pako");
// Version 1.0.3 de pako installée obligatoirement
const base64 = require("base-64");
const jsMd5 = require("js-md5");
//TODO: Savoir pourquoi la dernière version de pako (2.0.3 au moment où j'écris) ne sait pas deflate les réponses du serveur hippocad.
// Problématique des 3 octets d'en-tête qui font peut-être bugger (Voir avec Silien)
/*const dataToDecode = '1VRdT9swFP0rll/2AlOS0o9U07SWUkRX2o0OEIwpcpzbxsyxI9spBdT/Pttp1qJJk/a4KJHse+J7z7kffsVUZoD7eP4ZH2FdUQpa475RFRzhQq8sYu0ZMQT3XzHJimTN4An3v+NcFmChSoNKCKWyEqbZFoTxZi3gSTfrkhMhmFjZPeUMhEmWjMN+x8RaMnpg2DlqdlIwI9UbB7pKNVWsNEwK/MMyhSWpuNmxbEhyuVpBljgSToaRP0FYdDKatCejs6fJKA/u+XB++zgb392WIzLmd2mQLS4fJ+ReXLfhbPK8ODcBvS5Ppiyefo2yzuJmOL8fT07vXm4upi+zm6mglY3kMuS1sswGaMe9XmCtjqn9mHmu7a2wG8RRxyJLprRJBClcDaayYhqMgeNLopgnTvboYHh1MZjirQ1iDKG5FVQnwVbj1YcwzyUklK0ZbyJFf4tdEpVo2NTmaEfT+3iUaW3ttQ7NK0WyirhU78/8uwDrcGNACcITBUtrD8NeHCH3WMyaQIGgkEmtmasXjnvdTvvEEc6lcI6CEHXbKAxQJ0C9qAGSUkkHRujgtWAhU9tlye/DHRR0UdBDQWxdOAVkg/ui4twS8w3XxyUoLT/53XsqC7xD6gj1rylTJk/sYIBhXl0Yd06Og9ZxELo2qAdip7w+kcmCUVsan8C9G9osWUpEPW51cnutsBYntdkNabfbChxlURWpT43DtVEAJiFZpvzs4qsKUAaIvxuoVAFaAHXzYhvAYmMpDGECUg7E9asAtspTqXIpXdDhQxUEEDlJwqBzHz2VG+fUI7FmmSsOWoMyrr65tN2+q69xF4CdcQpvdLs289lQnsDVYHZ65lK08q1b/8NJCi7xpzm8oMsC0Oj6y3z2zVVHqsL2u232vcAI/SnxQ6o+PgifIPRW5NZeCxo4UHMwMf/7JbDd/gI=';
// Option 1 (old way)
    dataToDecode = '1VRdT9swFP0rll/2AlOS0o9U07SWUkRX2o0OEIwpcpzbxsyxI9spBdT/Pttp1qJJk/a4KJHse+J7z7kffsVUZoD7eP4ZH2FdUQpa475RFRzhQq8sYu0ZMQT3XzHJimTN4An3v+NcFmChSoNKCKWyEqbZFoTxZi3gSTfrkhMhmFjZPeUMhEmWjMN+x8RaMnpg2DlqdlIwI9UbB7pKNVWsNEwK/MMyhSWpuNmxbEhyuVpBljgSToaRP0FYdDKatCejs6fJKA/u+XB++zgb392WIzLmd2mQLS4fJ+ReXLfhbPK8ODcBvS5Ppiyefo2yzuJmOL8fT07vXm4upi+zm6mglY3kMuS1sswGaMe9XmCtjqn9mHmu7a2wG8RRxyJLprRJBClcDaayYhqMgeNLopgnTvboYHh1MZjirQ1iDKG5FVQnwVbj1YcwzyUklK0ZbyJFf4tdEpVo2NTmaEfT+3iUaW3ttQ7NK0WyirhU78/8uwDrcGNACcITBUtrD8NeHCH3WMyaQIGgkEmtmasXjnvdTvvEEc6lcI6CEHXbKAxQJ0C9qAGSUkkHRujgtWAhU9tlye/DHRR0UdBDQWxdOAVkg/ui4twS8w3XxyUoLT/53XsqC7xD6gj1rylTJk/sYIBhXl0Yd06Og9ZxELo2qAdip7w+kcmCUVsan8C9G9osWUpEPW51cnutsBYntdkNabfbChxlURWpT43DtVEAJiFZpvzs4qsKUAaIvxuoVAFaAHXzYhvAYmMpDGECUg7E9asAtspTqXIpXdDhQxUEEDlJwqBzHz2VG+fUI7FmmSsOWoMyrr65tN2+q69xF4CdcQpvdLs289lQnsDVYHZ65lK08q1b/8NJCi7xpzm8oMsC0Oj6y3z2zVVHqsL2u232vcAI/SnxQ6o+PgifIPRW5NZeCxo4UHMwMf/7JbDd/gI=';
    decodedData = document.base64.decode(dataToDecode);
    console.log('64 decoded data : ', decodedData);
    inflatedData = pako.inflate(decodedData, {to: 'string', raw:true});
    console.log(inflatedData);
// Option 2 (new way)
    dataToDecode = '1VRdT9swFP0rll/2AlOS0o9U07SWUkRX2o0OEIwpcpzbxsyxI9spBdT/Pttp1qJJk/a4KJHse+J7z7kffsVUZoD7eP4ZH2FdUQpa475RFRzhQq8sYu0ZMQT3XzHJimTN4An3v+NcFmChSoNKCKWyEqbZFoTxZi3gSTfrkhMhmFjZPeUMhEmWjMN+x8RaMnpg2DlqdlIwI9UbB7pKNVWsNEwK/MMyhSWpuNmxbEhyuVpBljgSToaRP0FYdDKatCejs6fJKA/u+XB++zgb392WIzLmd2mQLS4fJ+ReXLfhbPK8ODcBvS5Ppiyefo2yzuJmOL8fT07vXm4upi+zm6mglY3kMuS1sswGaMe9XmCtjqn9mHmu7a2wG8RRxyJLprRJBClcDaayYhqMgeNLopgnTvboYHh1MZjirQ1iDKG5FVQnwVbj1YcwzyUklK0ZbyJFf4tdEpVo2NTmaEfT+3iUaW3ttQ7NK0WyirhU78/8uwDrcGNACcITBUtrD8NeHCH3WMyaQIGgkEmtmasXjnvdTvvEEc6lcI6CEHXbKAxQJ0C9qAGSUkkHRujgtWAhU9tlye/DHRR0UdBDQWxdOAVkg/ui4twS8w3XxyUoLT/53XsqC7xD6gj1rylTJk/sYIBhXl0Yd06Og9ZxELo2qAdip7w+kcmCUVsan8C9G9osWUpEPW51cnutsBYntdkNabfbChxlURWpT43DtVEAJiFZpvzs4qsKUAaIvxuoVAFaAHXzYhvAYmMpDGECUg7E9asAtspTqXIpXdDhQxUEEDlJwqBzHz2VG+fUI7FmmSsOWoMyrr65tN2+q69xF4CdcQpvdLs289lQnsDVYHZ65lK08q1b/8NJCi7xpzm8oMsC0Oj6y3z2zVVHqsL2u232vcAI/SnxQ6o+PgifIPRW5NZeCxo4UHMwMf/7JbDd/gI=';
    decodedData = document.base64.decode(dataToDecode);
    console.log('64 decoded data : ', decodedData);
    inflatedData = document.pako.inflate(decodedData, {to: 'string', raw:true});
    console.log(inflatedData);
*/
class Ajax {
    //---------- TOOL FUNCTIONS ----------//
    /**
     * str => chaine hippocad_encodée venant du serveur
     * @param str
     * @returns {any}
     */
    static hippocad_decode(str, xhr, formattedStatus) {
        console.warn("hippocad decode response");
        try {
            let data = base64.decode(str);
            data = pako.inflate(data, { to: "string", raw: true });
            return JSON.parse(data);
        } catch (error) {
            console.error(`[${formattedStatus}] Réponse serveur non standard :\n`, str);
            console.error("Erreur de décodage : ", error);
            return {
                success: false,
                code: xhr.status,
                msg: `[${formattedStatus}] Réponse serveur non standard, veuillez contacter l'assistance`,
                data: "",
            };
        }
    }

    static clean_res(str) {
        //Extrait toutes les parties de la string de réponse qui ne se trouvent pas encapsulées dans des zones de commentaire
        //Cela permet de pouvoir 'dumper' les requêtes dans la console sans faire crasher le système de token request
        const commentsPattern = /(\/\*([^*]|[\r\n]|(\*+([^*/]|[\r\n])))*\*+\/)/gim;
        const commentArray = str.match(commentsPattern) || [];
        return {
            res: str.replace(commentsPattern, "").trim(),
            comment: commentArray.join("\n\n"),
        };
    }

    /**
     * input => {}
     * @param input
     * @returns {*}
     */
    static hippocad_encode(input) {
        let data = JSON.stringify(input);
        data = pako.deflate(data, { to: "string", raw: true });
        data = base64.encode(data);
        return data;
    }

    static onLoadXHR(xhr, asareactDns, rs, rj, WrapperInstance) {
        //This function is executed inside a Promise, so you have to pass the 'resolve' (rs) 'reject' (rj) callbacks into it
        const formattedStatus = `Code ${xhr.status} - ${xhr.statusText}`;
        //console.log("xhr : ", xhr);
        switch (true) {
            case xhr.response.length < 1:
                //Le serveur n'a rien renvoyé (certainement une erreur)
                rj({
                    success: false,
                    code: xhr.status,
                    msg: `[${formattedStatus}] Erreur serveur, veuillez contacter l'assistance`,
                    data: "",
                });
                break;
            default:
                const cleanRes = Ajax.clean_res(xhr.response);
                if (cleanRes?.comment) console.warn(cleanRes.comment);
                const response = Ajax.hippocad_decode(cleanRes?.res, xhr, formattedStatus, rj);
                const code = response.code || "undef";
                const formattedCode = `Code ${code}`;
                response.msg = `[${formattedCode}] ${response.msg}`;
                if (response.success === true) {
                    console.log("XHR-ONLOAD", response);
                    //Cursor handling here
                    if (
                        response.cursor &&
                        //!response.hasOwnProperty("data") &&
                        isArray(response?.cursor)
                        //!response.viewScope === true
                    ) {
                        function handleEndOfCursorTreatment(response, cursorList, cursorCompletionList, rs, rj) {
                            if (cursorCompletionList.length >= cursorList.length) {
                                if (!cursorCompletionList.includes(true)) {
                                    //All cursors failed
                                    //Discussion needed to determine if the request fulfills if all cursors failed (maybe root data is still available)
                                    response.success = false;
                                    response.msg = "All cursors failed to load data";
                                    response.code = 404;
                                    rj(response);
                                }
                                rs(response);
                            }
                        }
                        const cursorList = response.cursor;
                        const cursorCompletionList = [];
                        cursorList.forEach(function (cursor, index) {
                            if (cursor.status === "pending") {
                                WrapperInstance.request(cursor.request)
                                    .then(function (cursorResponse) {
                                        cursor.status = "loaded";
                                        cursor.code = cursorResponse.code;
                                        cursor.msg = cursorResponse.msg;
                                        cursor.data = cursorResponse.data;
                                        cursorCompletionList.push(true);
                                    })
                                    .catch(function (cursorError) {
                                        cursor.status = "error";
                                        cursor.code = cursorError.code;
                                        cursor.msg = cursorError.msg;
                                        cursor.data = cursorError.data;
                                        console.error(cursorError.msg);
                                        cursorCompletionList.push(false);
                                    })
                                    .finally(function () {
                                        handleEndOfCursorTreatment(response, cursorList, cursorCompletionList, rs, rj);
                                    });
                            } else {
                                //Same treatment as the one inside 'finally' above
                                cursorCompletionList.push(cursor.status === "loaded" ? true : false);
                                handleEndOfCursorTreatment(response, cursorList, cursorCompletionList, rs, rj);
                            }
                        });
                    } else {
                        rs(response);
                    }
                } else {
                    console.error("ERROR-RESPONSE", response);
                    // Expiration du token : code 4221
                    if (response.code === 4221) {
                        const msg = `[${formattedCode}] Votre session a expiré veuillez vous reconnecter`;
                        if (document.React && typeof document.React.msgBox === "function") {
                            document.React.msgBox(msg, "error", "msg", "Session expirée").then(function () {
                                sessionStorage.clear();
                                window.location.replace(asareactDns);
                                rj(response);
                            });
                            return;
                        } else {
                            alert(msg);
                            sessionStorage.clear();
                            window.location.replace(asareactDns);
                            rj(response);
                        }
                    } else {
                        rj(response);
                    }
                }
                break;
        }
    }
    static onErrorXhr(xhr, rs, rj) {
        //This function is executed inside a Promise, so you have to pass the 'resolve' (rs) 'reject' (rj) callbacks into it
        rj({
            success: false,
            code: 504,
            msg: "[504] Erreur réseau, veuillez contacter l'assistance",
            data: "",
        });
    }

    static md5File(file) {
        return new Promise(function (rs, rj) {
            try {
                const reader = new FileReader();
                reader.onerror = function (event) {
                    console.error("[md5-error] - Erreur lors de la lecture du fichier : ", event);
                };
                reader.onload = function (event) {
                    const fileContent = reader.result;
                    if (file.size === event.loaded) {
                        const filemd5 = jsMd5(fileContent);
                        rs(filemd5);
                    } else {
                        console.error(
                            `[md5-error] - La taille du fichier lu est différente du fichier source : \norigin file size : ${file.size}\nread file size : ${event.loaded}`
                        );
                        rj();
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                console.error("Erreur md5File : ", error);
                rj();
            }
        });
    }

    static reset_form_file() {
        const fileForm = document.getElementById("fileForm");
        const fileTokenForm = document.getElementById("fileTokenForm");
        const fileDataForm = document.getElementById("fileDataForm");
        fileForm.action = null;
        fileTokenForm.value = null;
        fileDataForm.value = null;
    }

    static periodExec(callback, period, callNumber, callFirst, stopExec) {
        if (typeof callback !== "function") {
            console.error("periodExec - ERROR - Callback is not a function");
            return;
        }
        stopExec = stopExec || { stop: false };
        period = +period * 1000 || 5000; //period in seconds
        let callCount = !+callNumber ? -1 : 0;
        function recurCall() {
            if (callFirst && !stopExec.stop) {
                callback();
                callCount = callCount == -1 ? callCount : callCount + 1;
                callFirst = false;
            }
            if (callCount < callNumber || callCount == -1) {
                setTimeout(function () {
                    if (!stopExec.stop) {
                        callback();
                        callCount = callCount == -1 ? callCount : callCount + 1;
                        recurCall(callback);
                    }
                }, period);
            }
        }
        recurCall(callback);
    }
    //------------------------------------//
    //---------- PUBLIC FUNCTIONS ----------//
    static request(asaproDns, asareactDns, input, WrapperInstance) {
        return new Promise(function (rs, rj) {
            const ACTION = "POST";
            //const SERVER_NAME = "asapro.hippocad.eu";
            //const TOKEN = "wassyl_blabla";
            //const encodedData = "RY3BCsJADET%2FJWfpwZv9FZEQN8EGt5vQ3baI%2BO9m6aGQy8y8yXyBS4URqJIvNkzqbol4kBUuQO5ZEzW10pFTYeCLUGod4hk3lR2VA7rewmr2lt7YqdZPxmemuPBn4zVLBFq2kL11CHRJYfjkGE%2BPuZc0PCOmRjDeH78%2F";
            console.warn("Hippocad encode request");
            const encodedData = Ajax.hippocad_encode(input);
            const URL = asaproDns;
            const DATASTRING = "token=" + encodeURIComponent(input.token) + "&data=" + encodeURIComponent(encodedData);
            console.log("AJAX - DATA :    ", input);
            console.log("AJAX - ENCODE :  ", encodedData);
            console.log("AJAX - REQUEST : ", URL + "\nPAYLOAD : ", DATASTRING);
            let xhr = new XMLHttpRequest();
            xhr.open(ACTION, asaproDns);
            //To pass data into the body (POST standard to increase query string limitation)
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            xhr.onload = function () {
                Ajax.onLoadXHR(xhr, asareactDns, rs, rj, WrapperInstance);
            };
            xhr.onerror = function () {
                Ajax.onErrorXhr(xhr, rs, rj);
            };
            xhr.send(DATASTRING);
        });
    }

    static getFile(asaproDns, asareactDns, input) {
        return new Promise(function (rs, rj) {
            const encodedData = Ajax.hippocad_encode(input);
            const fileForm = document.getElementById("fileForm");
            const fileTokenForm = document.getElementById("fileTokenForm");
            const fileDataForm = document.getElementById("fileDataForm");
            fileForm.action = asaproDns;
            fileTokenForm.value = input.token;
            fileDataForm.value = encodedData;
            fileForm.submit();
            Ajax.reset_form_file();
            rs();
        });
    }

    static postFile(asaproDns, asareactDns, input) {
        return new Promise(function (rs, rj) {
            if (input.file) {
                const file = input.file;
                delete input.file;
                Ajax.md5File(file)
                    .then(function (res) {
                        input.data.checksum = res;
                    })
                    .catch(function (error) {
                        //Nothing happens
                    })
                    .finally(function () {
                        const ACTION = "POST";
                        console.warn("Hippocad encode request");
                        const encodedData = Ajax.hippocad_encode(input);
                        console.log("AJAX - DATA :    ", input);
                        console.log("AJAX - ENCODE :  ", encodedData);
                        //We send the data of the token request into the query string
                        const strRequest = asaproDns + `?token=${encodeURIComponent(input.token)}&data=${encodeURIComponent(encodedData)}`;
                        console.log("AJAX - REQUEST : ", asaproDns + "\nPAYLOAD : ", strRequest);
                        //We send the file with a 'multipart/form-data' body to recover the file and all its meta inside $_FILES php variable
                        const body = new FormData();
                        body.append("0", file); //Important to set a number as key, to have an indexed array inside the php $_FILES
                        let xhr = new XMLHttpRequest();
                        xhr.open(ACTION, strRequest);
                        xhr.onload = function () {
                            Ajax.onLoadXHR(xhr, asareactDns, rs, rj);
                        };
                        xhr.onerror = function () {
                            Ajax.onErrorXhr(xhr, rs, rj);
                        };
                        xhr.send(body);
                    });
            } else {
                console.error("[Blocage client] - Aucun fichier à uploader, input.file vide : ", input);
                rj({
                    success: false,
                    code: 410,
                    msg: "[410] No file to upload",
                    data: [],
                });
            }
        });
    }

    static requestWebSocket(input) {
        console.log("Ajax.requestWebSocket() input : ", input);
        const REQUEST_TIMEOUT = 45; //seconds
        let errorCode = 4500;
        return new Promise(function (rs, rj) {
            if (!input?.action?.publish) {
                errorCode = 4500;
                rj({
                    success: false,
                    code: errorCode,
                    msg: `[${errorCode}] - ERROR REQUEST WEBSOCKET - Aucun topic de publication "input.action.publish" spécifié :\n${JSON.stringify(
                        input
                    )}`,
                    data: [],
                });
                return;
            }
            let referenceStartTime = new Date();
            WebSocketMQTT.bindAction(input)
                .then(function (WSInstance) {
                    const stopTestTimeout = { stop: false };
                    WSInstance.onResponse = function (data) {
                        errorCode = 5200;
                        console.log(`[${errorCode}] - RESPONSE REQUEST WEBSOCKET - Action "${WSInstance.action.publish}" :\n`, data);
                        rs(data);
                        stopTestTimeout.stop = true;
                        WSInstance.disconnect();
                    };
                    WSInstance.onProgress = function (data) {
                        referenceStartTime = new Date();
                        errorCode = 5100;
                        console.log(`[${errorCode}] - PROGRESS REQUEST WEBSOCKET - Action "${WSInstance.action.publish}" :\n`, data);
                    };
                    WSInstance.onError = function (e) {
                        if (WSInstance.isConnected) WSInstance.disconnect();
                        stopTestTimeout.stop = true;
                        rj(e);
                    };
                    function testTimeout() {
                        const now = new Date();
                        if (now - referenceStartTime > REQUEST_TIMEOUT * 1000) {
                            errorCode = 4501;
                            stopTestTimeout.stop = true;
                            rj({
                                success: false,
                                code: errorCode,
                                msg: `[${errorCode}] - TIMEOUT REQUEST WEBSOCKET - Les topics de l'action "${input.action.publish}" sont inactifs et ont dépassé le TIMEOUT de ${REQUEST_TIMEOUT}s`,
                                data: [],
                            });
                            WSInstance.disconnect();
                        }
                    }
                    Ajax.periodExec(testTimeout, REQUEST_TIMEOUT / 2, null, false, stopTestTimeout);
                })
                .catch((e) => rj(e));
        });
    }
    //--------------------------------------//
}
export default Ajax;
